import React from 'react';
import MarketOrder from '../features/MarketOrder';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function MarketOrderScreen() {
    return (
        <ScreenWrapper>
            <MarketOrder />
        </ScreenWrapper>
    );
}
