import React, { useEffect, useState } from 'react';
import { FlatList, Platform, StyleSheet, useWindowDimensions } from 'react-native';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { global } from '~/constants/global';
import { isDesktop, scaleSize } from '~/constants/scale';

import { useWatchlistScreenData, useStyles, useAppSelector } from '~/hooks';
import { Theme } from '~/theme';

import MarketChartCard from '../MarketChartCard';
import MarketChartCardDesktop from '../MarketChartCardDesktop';
import { SearchHeader } from '../../Favorites/SearchHeader';
import AddFavoritesListSearch from '../../Favorites/AddFavoritesListSearch';
import { useRoute } from '@react-navigation/native';
import { extractWatchlistItemId } from '~/navigation/utilts';
import { dealerSelector } from '~/state/selectors';
import VerificationService from '~/api-services/VerificationService';
import VerificationCenterMessage from '../../VerificationCenterMessage';


type Props = {
    watchListId?: number;
    setActiveChart?: Function;
    setOpenEditFavorites?: Function;
};

export default function MarketWatchlist({ watchListId, setActiveChart, setOpenEditFavorites }: Props) {
    const styles = useStyles(stylesGenerator);
    const { codes, numColumns, onEndReached, onViewableItemsChanged } = useWatchlistScreenData({
        watchListId: watchListId,
    });
    const dimensions = useWindowDimensions();
    const [searchTerm, setSearchTerm] = useState('');
    const route = useRoute();
    const dealer = useAppSelector(dealerSelector);
    const [showVCButton, setShowVCButton] = useState(false);

    useEffect(() => {
        loadCustomerData();
    }, [dealer]);

    async function loadCustomerData() {
        if (!isDesktop()) {
            if (dealer === 'EVER') {
                const { status, data } = await VerificationService.getCustomerData();
                if (status === 200 && data.status) {
                    if (data.returnData.businessAndProfessionalActivity != null &&
                        data.returnData.servicesToUse != null &&
                        data.returnData.assetManagement != null &&
                        data.returnData.howManyTransactions != null &&
                        data.returnData.educationFinancialServicesPast3Years != null &&
                        data.returnData.depositLeverageQuestion != null &&
                        data.returnData.tradedUsingLeverageMagnifiesPotentialProfitsLossesText != null &&
                        data.returnData.realDeliveryAsset != null &&
                        data.returnData.automaticallyClosedPosition != null &&
                        data.returnData.whatHappenStopLossOrder != null &&
                        data.returnData.howCharacterizeKnowledge != null) {
                    }
                    else {
                        setShowVCButton(true);
                    }
                }
            }
        }
    }

    if (isDesktop()) {
        return (
            <>
                <SearchHeader setOpenEditFavorites={setOpenEditFavorites} searchTerm={searchTerm} setSearchTerm={setSearchTerm} screen={'MarketWatchlist'} watchListId={watchListId} />
                {searchTerm !== '' ?
                    <AddFavoritesListSearch watchListId={watchListId} searchTerm={searchTerm} />
                    :
                    <ScrollbarWrapper>
                        <FlatList
                            style={styles.list}
                            data={codes}
                            numColumns={dimensions.width > global.DESKTOP_MIN_WIDTH ? 1 : numColumns}
                            renderItem={({ item }) => dimensions.width > global.DESKTOP_MIN_WIDTH ? (
                                <MarketChartCardDesktop symbol={item} setActiveChart={setActiveChart} />
                            ) : (
                                <MarketChartCard symbol={item} isLarge={numColumns === 1} />
                            )}
                            keyExtractor={(symbol) => symbol}
                            key={`MarketWatchlist:${numColumns}`}
                            onEndReachedThreshold={0.2}
                            onEndReached={onEndReached}
                            onViewableItemsChanged={onViewableItemsChanged} />
                    </ScrollbarWrapper>}
            </>
        );
    } else {
        return (
            <>
                <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} screen={'MarketWatchlist'} watchListId={extractWatchlistItemId(route.name)} />
                {searchTerm !== '' ?
                    <AddFavoritesListSearch watchListId={extractWatchlistItemId(route.name)} searchTerm={searchTerm} />
                    :
                    <FlatList
                        style={styles.list}
                        data={codes}
                        numColumns={numColumns}
                        renderItem={({ item }) => Platform.OS === 'web' && dimensions.width > global.DESKTOP_MIN_WIDTH ? (
                            <MarketChartCardDesktop symbol={item} setActiveChart={setActiveChart} />
                        ) : (
                            <MarketChartCard symbol={item} isLarge={numColumns === 1} />
                        )}
                        keyExtractor={(symbol) => symbol}
                        key={`MarketWatchlist:${numColumns}`}
                        onEndReachedThreshold={0.2}
                        onEndReached={onEndReached}
                        onViewableItemsChanged={onViewableItemsChanged} />}
                <VerificationCenterMessage visible={showVCButton} setVisible={setShowVCButton} />
            </>
        );
    }
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        list: {
            backgroundColor: theme.background,
            marginLeft: scaleSize(7),
            marginBottom: scaleSize(7),
        },
    });
