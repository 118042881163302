import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import AddFunds from '../features/AddFunds';
type Props = {
    setActiveRightTab?: Function;
    setCurrentAmount?: Function;
};

export default function AddFundsScreen({ setActiveRightTab, setCurrentAmount }: Props) {
    return (
        <ScreenWrapper>
            <AddFunds setActiveRightTab={setActiveRightTab} setCurrentAmount={setCurrentAmount} />
        </ScreenWrapper>
    );
}
