import { useEffect, useState, useRef } from 'react';
import NewsService from '~/api-services/NewsService';
import { InstrumentNews } from '~/api-services/NewsService/types';
import { userIsLoggedInSelector } from '~/state/selectors';
import { useAppSelector } from './useAppSelector';
import { useIsAppActive } from './useIsAppActive';

export const useInstrumentNews = (instrumentCode: string) => {
    const [newsArray, setNewsArray] = useState<InstrumentNews[]>([]);
    const reloadDataPollRef = useRef<NodeJS.Timer>();
    const isAppActive = useIsAppActive();
    const isLoggedIn = useAppSelector(userIsLoggedInSelector);

    useEffect(() => {
        if (isAppActive && isLoggedIn) {
            reloadDataPollRef.current = setInterval(() => {
                loadData();
            }, 300000); // refetch 5 min
            loadData();
        } else {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        }
    }, [isLoggedIn, isAppActive]);

    async function loadData() {
        const { status, data } = await NewsService.getInstrumentNews(instrumentCode);
        if (status === 200 && data.status) {
            setNewsArray(data.returnData);
        }
    }

    return {
        newsArray,
    };
};
