import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import Accounts from '../features/Accounts';
type Props = {
    setActiveRightTab?: Function;
};
export default function AccountsScreen({ setActiveRightTab }: Props) {
    return (
        <ScreenWrapper>
            <Accounts setActiveRightTab={setActiveRightTab} />
        </ScreenWrapper>
    );
}
