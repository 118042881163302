import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Text } from '~/components/core';
import { Theme } from '~/theme';
import { useStyles, useAppSelector } from '~/hooks';
import { formatCurrency, formatNumberWithPercent } from '~/lib/format';
import { accountBalanceSelector, appSettingsSelector } from '~/state/selectors';
import { FONT_SIZE_M } from '~/constants';
import config from '~/config';
import i18n from '~/i18n';


export default function DesktopFooter() {
    const styles = useStyles(stylesGenerator);
    const balanceValues = useAppSelector(accountBalanceSelector);
    const { colorScheme } = useAppSelector(appSettingsSelector);


    return (
        <View style={styles.container}>
            <View style={styles.greenLine} />
            <View style={styles.contentContainer}>
                <View style={styles.leftContainer}>
                    <Image
                        source={{
                            uri: 'https://' +
                                config.EXTERNAL_SOURCES_URL +
                                'mobileplatform/images/brand/' +
                                config.BRAND_NAME +
                                '/footer_logo_' +
                                colorScheme +
                                '.svg?1'
                        }}
                        style={styles.logoStyle}
                        resizeMode="contain"
                    />
                </View>
                <View style={styles.rightContainer}>
                    <Text fontType="LATO_BOLD" style={styles.balanceLabel}>
                        {i18n.t('account-balance-title') + ':'}
                    </Text>
                    <Text fontType="LATO_BOLD" style={[styles.equityValue, styles.equityValueColor, styles.equityMargin]}>
                        {formatCurrency(balanceValues?.accountBalance, 2, balanceValues?.currency)}
                    </Text>
                    <Text fontType="LATO_BOLD" style={styles.balanceLabel}>
                        account-balance-equity
                    </Text>
                    <Text
                        fontType="LATO_BOLD"
                        style={[styles.equityValue, styles.equityValueColor, styles.equityMargin]}
                    >
                        {formatCurrency(balanceValues?.equity, 2, balanceValues?.currency)}
                    </Text>
                    <Text fontType="LATO_BOLD" style={styles.balanceLabel}>
                        desktop-footer-margin
                    </Text>
                    <Text
                        fontType="LATO_BOLD"
                        style={[styles.equityValue, styles.equityValueColor, styles.equityMargin]}
                    >
                        {formatCurrency(balanceValues?.usedMargin, 2, balanceValues?.currency)}
                    </Text>
                    <Text fontType="LATO_BOLD" style={styles.balanceLabel}>
                        account-balance-free-margin
                    </Text>
                    <Text
                        fontType="LATO_BOLD"
                        style={[styles.equityValue, styles.equityValueColor, styles.equityMargin]}
                    >
                        {formatCurrency(balanceValues?.freeMargin, 2, balanceValues?.currency)}
                    </Text>
                    <Text fontType="LATO_BOLD" style={styles.balanceLabel}>
                        account-balance-margin-level
                    </Text>
                    <Text
                        fontType="LATO_BOLD"
                        style={[styles.equityValue, styles.equityValueColor, styles.equityMargin]}
                    >
                        {balanceValues?.level === 100000 ? '∞' : formatNumberWithPercent(balanceValues?.level, 2, false)}
                    </Text>
                    <Text fontType="LATO_BOLD" style={styles.balanceLabel}>
                        desktop-footer-ppl
                    </Text>
                    <Text
                        fontType="LATO_BOLD"
                        style={[
                            balanceValues?.potentialProfitLoss && balanceValues?.potentialProfitLoss < 0
                                ? styles.pplMinusColor
                                : styles.pplPlusColor,
                            styles.equityValue,
                            styles.equityMarginEnd,
                        ]}
                    >
                        {formatCurrency(balanceValues?.potentialProfitLoss, 2, balanceValues?.currency)}
                    </Text>
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        logoStyle: { width: 108, height: 30, marginLeft: 10 },
        pplPlusColor: { color: theme.text_upPrice },
        pplMinusColor: { color: theme.text_downPrice },
        equityMargin: { marginRight: 23 },
        equityMarginEnd: { marginRight: 10 },
        equityValue: { fontSize: FONT_SIZE_M, letterSpacing: 0.5 },
        equityValueColor: { color: theme.equityValue },
        balanceLabel: { fontSize: FONT_SIZE_M, color: theme.desktopHeaderTextColor, marginRight: 10 },
        greenLine: { width: '100%', height: 5, backgroundColor: theme.greenLineDesktop },
        contentContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.backgroundSecondary,
        },
        rightContainer: {
            flexDirection: 'row',
            height: 55,
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        leftContainer: {
            flexDirection: 'row',
            height: 55,
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        container: {
            width: '100%',
            height: 60,
        },
    });
