import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import EditFavoritesList from '../features/Favorites/EditFavoritesList';

export default function EditFavoritesScreen() {
    return (
        <ScreenWrapper>
            <EditFavoritesList />
        </ScreenWrapper>
    );
}
