import config from '~/config';
import axios from '../baseClient';
import {
    InvestorResultsResponse,
    ReportCashResponse,
    ReportCommissionsResponse,
    ReportInterestResponse,
    ReportOrdersRequest,
    ReportOrdersResponse,
    ReportResultResponse,
} from './types';

class ReportsService {
    private static domain = '/api/24yieldreporting';

    getReportOrders(reportOrdersRequest: ReportOrdersRequest) {
        return axios.post<ReportOrdersResponse>(`${ReportsService.domain}/getReportOrders`, reportOrdersRequest);
    }

    getReportResults(reportOrdersRequest: ReportOrdersRequest) {
        return axios.post<ReportResultResponse>(`${ReportsService.domain}/getReportResults`, reportOrdersRequest);
    }

    getReportCash(reportOrdersRequest: ReportOrdersRequest) {
        return axios.post<ReportCashResponse>(`${ReportsService.domain}/getReportCash`, reportOrdersRequest);
    }

    getReportInterestsV2(reportOrdersRequest: ReportOrdersRequest) {
        return axios.post<ReportInterestResponse>(`${ReportsService.domain}/getReportInterestsV2`, reportOrdersRequest);
    }

    getReportCommissions(reportOrdersRequest: ReportOrdersRequest) {
        return axios.post<ReportCommissionsResponse>(
            `${ReportsService.domain}/getReportCommissions`,
            reportOrdersRequest
        );
    }

    getInvestorResults(investorUid: string, language: string) {
        return axios.post<InvestorResultsResponse>(
            `${ReportsService.domain}/getInvestorResults`,
            {investorUid: investorUid, language: language, website: config.WEBSITE}
        );
    }
}

export default new ReportsService();
