import React from 'react';

import LoginIBForm from '../features/Authentication/LoginIBForm';
import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function LoginIBScreen() {
    return (
        <ScreenWrapper>
            <LoginIBForm />
        </ScreenWrapper>
    );
}
