import { useRoute } from '@react-navigation/native';
import React from 'react';
import { KeyboardAvoidingView, StyleSheet, View } from 'react-native';
import { Button, Text } from '~/components/core';
import { FONT_SIZE_M, FONT_SIZE_XXXXL } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { formatNumber } from '~/lib/format';
import BuySellHeader from '../BuySellHeader';
import { Theme } from '~/theme';
import PendingOrder from '../PendingOrderComponent';
import Quantity from './Quantity';
import ConfirmationMessage from '../ConfirmationMessage';
import { useMarketOrder } from '~/hooks/useMarketOrder';
import DesktopBackHeader from '../BuySellHeader/DesktopBackHeader';
import Loader from '~/components/core/Loader';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { SafeAreaView } from 'react-native-safe-area-context';


type Props = {
    selectedSymbol?: string;
    selectedSide?: string;
    setOpenBuySellType?: Function;
};

export default function MarketOrder({ selectedSymbol, selectedSide, setOpenBuySellType }: Props) {
    const route = useRoute();
    const styles = useStyles(stylesGenerator);
    const symbol = selectedSymbol ? selectedSymbol : (route.params as any).symbol;
    const side: 'buy' | 'sell' = selectedSide ? selectedSide : (route.params as any).side;
    const {
        minLimitProfitPrice,
        dataSymbol,
        maxLimitProfitPrice,
        getPriceColor,
        marketPrice,
        volume,
        setVolume,
        pendingOrderPrice,
        changePendingOrderPrice,
        isOpenPendingOrder,
        changePendingOrder,
        maxPendingOrderPrice,
        minPendingOrderPrice,
        limitProfitPrice,
        setLimitProfitPrice,
        isOpenTakeProfit,
        setIsOpenTakeProfit,
        stopLossPrice,
        setIsOpenStopLoss,
        setStopLossPrice,
        isOpenStopLoss,
        minStopLossPrice,
        maxStopLossPrice,
        disableConfirmButton,
        executeOrder,
        confirmationProps,
        confirmationVisible,
        setConfirmationVisible,
        loading,
        exchangeRates,
    } = useMarketOrder(symbol, side);

    return (
        <>
            <KeyboardAvoidingView behavior={'height'}  style={styles.keyBoard}>
                <ScrollbarWrapper>
                    <SafeAreaView style={styles.safeAreaStyle}>
                    <Loader loading={loading} />
                    <View style={styles.container}>
                        <DesktopBackHeader side={side} setOpenBuySellType={setOpenBuySellType} />
                        <BuySellHeader side={side} name={dataSymbol.nameTranslated} />
                        <View style={styles.currentPriceContainer}>
                            <Text fontType="LATO_BOLD" style={styles.currentRate}>
                                current-rate
                            </Text>
                            <Text fontType="LATO_BOLD" style={styles.currentRate}>
                                {': '}
                            </Text>
                            <Text
                                fontType="LATO_BOLD"
                                style={[styles.currentRate, styles.letterSpacing05, { color: getPriceColor() }]}
                            >
                                {formatNumber(marketPrice, dataSymbol.digits)}
                            </Text>
                        </View>
                        <Quantity volume={volume} setVolume={setVolume} dataSymbol={dataSymbol} />
                        <View style={styles.pendingOrderContainer}>
                            <PendingOrder
                                label={side === 'buy' ? 'buy-when-rate-is' : 'sell-when-rate-is'}
                                value={pendingOrderPrice}
                                setValue={changePendingOrderPrice}
                                marketPrice={marketPrice}
                                dataSymbol={dataSymbol}
                                side={side}
                                volume={volume}
                                isOpen={isOpenPendingOrder}
                                setIsOpen={(open: boolean) => changePendingOrder(open)}
                                showEstresult={false}
                                max={maxPendingOrderPrice}
                                min={minPendingOrderPrice}
                                exchangeRates={exchangeRates}
                            />
                            <PendingOrder
                                label="close-at-profit"
                                value={limitProfitPrice}
                                setValue={setLimitProfitPrice}
                                marketPrice={
                                    isOpenPendingOrder
                                        ? pendingOrderPrice
                                        : side === 'buy'
                                        ? dataSymbol.price.bid
                                        : dataSymbol.price.ask
                                }
                                dataSymbol={dataSymbol}
                                side={side}
                                volume={volume}
                                isOpen={isOpenTakeProfit}
                                setIsOpen={setIsOpenTakeProfit}
                                min={minLimitProfitPrice}
                                max={maxLimitProfitPrice}
                                exchangeRates={exchangeRates}
                            />
                            <PendingOrder
                                label="close-at-loss"
                                value={stopLossPrice}
                                setValue={setStopLossPrice}
                                marketPrice={
                                    isOpenPendingOrder
                                        ? pendingOrderPrice
                                        : side === 'buy'
                                        ? dataSymbol.price.bid
                                        : dataSymbol.price.ask
                                }
                                dataSymbol={dataSymbol}
                                side={side}
                                volume={volume}
                                isOpen={isOpenStopLoss}
                                setIsOpen={setIsOpenStopLoss}
                                min={minStopLossPrice}
                                max={maxStopLossPrice}
                                exchangeRates={exchangeRates}
                            />
                        </View>
                        <View style={styles.confirmContainer}>
                            <Button
                                disabled={disableConfirmButton}
                                onPress={() => executeOrder()}
                                title={'position-confirm'}
                                titleSize={FONT_SIZE_XXXXL}
                            />
                        </View>
                    </View>
                    </SafeAreaView>
                </ScrollbarWrapper>
            </KeyboardAvoidingView>
            <ConfirmationMessage
                text={confirmationProps.text}
                error={confirmationProps.error}
                visible={confirmationVisible}
                setVisible={setConfirmationVisible}
            />
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        keyBoard: { width: '100%', height: '100%' },
        safeAreaStyle: { flex: 1 },
        backButtonHeader: {
            width: '100%',
            height: scaleSize(20),
        },
        pendingOrderContainer: { width: '100%' },
        confirmContainer: {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: scaleSize(18),
            paddingHorizontal: scaleSize(17),
        },
        letterSpacing05: { letterSpacing: 0.5 },
        currentPriceContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: scaleSize(30),
        },
        currentRate: { fontSize: FONT_SIZE_M, color: theme.text_pendingOrder },
        container: { width: '100%' },
    });
