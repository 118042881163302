import React from 'react';
import { View, StyleSheet, Clipboard, Linking, Platform } from 'react-native';
import { BankAccount } from '~/api-services/PaymentConverterService/types';
import { Button, Text } from '~/components/core';
import { FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import { useAddFunds } from '~/hooks/useAddFunds';
import { Theme } from '~/theme';
import i18n from '~/i18n';
import config from '~/config';
import { dealerSelector } from '~/state/selectors';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';
import { useRoute } from '@react-navigation/native';

type Props = {
    setActiveRightTab?: Function;
    currentAmount?: string;
};

export default function AddFundsBank({ setActiveRightTab, currentAmount }: Props) {
    const route = useRoute();
    const amount = currentAmount ? currentAmount : (route.params as any).amount;
    const { configData } = useAddFunds(amount!='' ? Number(amount) : 0);
    const styles = useStyles(stylesGenerator);
    const dealer = useAppSelector(dealerSelector);

    const copyToClipboard = (text: string) => {
        Clipboard.setString(text);
    };

    const copyBankAccountToClipboard = (bankAccount: BankAccount) => {
        let text: string = '';
        text += i18n.t('beneficiary-name') + ' ' + bankAccount.beneficiaryName + '\n';
        text += i18n.t('beneficiary-address') + ' ' + bankAccount.beneficiaryAddress + '\n';
        text += i18n.t('bank-account-number') + ' ' + bankAccount.accountNumber + '\n';
        text += i18n.t('bank-account-currency') + ' ' + bankAccount.accountCurrency + '\n';
        text += i18n.t('bank-ref') + ' ' + bankAccount.reference + '\n';
        text += i18n.t('bank-bic') + ' ' + bankAccount.swiftBic + '\n';
        text += i18n.t('bank-name') + ' ' + bankAccount.bankName + '\n';
        text += i18n.t('bank-address') + ' ' + bankAccount.bankAddress + '\n';
        Clipboard.setString(text);
    };

    return (
        <>
            <DesktopScreenHeader
                goToScreenName="add-money-category"
                title="add-money"
                setActiveRightTab={setActiveRightTab}
            />
            <View style={styles.container}>
                {configData &&
                configData.bank &&
                configData.bank.length > 0 &&
                configData.bank[0].bankAccounts &&
                configData.bank[0].bankAccounts.length > 0 ? (
                    <>
                        <View style={styles.addFundsTitleContainer}>
                            <Text fontType="LATO_BOLD" style={styles.addFundsTitleText}>
                                {'bank-wire-title'}
                            </Text>
                        </View>
                        <ScrollbarWrapper style={styles.scrollViewContainer}>
                            {configData.bank[0].bankAccounts.map((bankAccount: BankAccount, key) => (
                                <View style={styles.bankAccountContainer} key={key}>
                                    <View style={styles.bankAccountHeadContainer}>
                                        <View style={styles.copyAllTextContainer}>
                                            <Text fontType="LATO_BOLD" style={styles.bankAccountDetailLabel}>
                                                {'saved-bank-details'}
                                            </Text>
                                            <Text
                                                fontType="LATO_BOLD"
                                                style={styles.bankAccountDetailText}
                                                numberOfLines={2}
                                            >
                                                {(key + 1).toString()}
                                            </Text>
                                        </View>
                                        <View style={styles.copyAllButtonContainer}>
                                            <Button
                                                disabled={false}
                                                onPress={() => {
                                                    copyBankAccountToClipboard(bankAccount);
                                                }}
                                                title="copy-all"
                                                buttonHeight={15}
                                                titleSize={FONT_SIZE_S}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.bankAccountDetailContainer}>
                                        <Text fontType="LATO_REGULAR" style={styles.bankAccountDetailLabel}>
                                            {'beneficiary-name'}
                                        </Text>
                                        <View style={styles.bankAccountDetailValueAndButtonContainer}>
                                            <Text
                                                fontType="LATO_REGULAR"
                                                style={styles.bankAccountDetailText}
                                                numberOfLines={2}
                                            >
                                                {bankAccount.beneficiaryName}
                                            </Text>
                                            <View style={styles.copyButtonContainer}>
                                                <Button
                                                    disabled={false}
                                                    onPress={() => {
                                                        copyToClipboard(bankAccount.beneficiaryName);
                                                    }}
                                                    title="copy"
                                                    buttonHeight={15}
                                                    titleSize={FONT_SIZE_S}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.bankAccountDetailContainer}>
                                        <Text fontType="LATO_REGULAR" style={styles.bankAccountDetailLabel}>
                                            {'beneficiary-address'}
                                        </Text>
                                        <View style={styles.bankAccountDetailValueAndButtonContainer}>
                                            <Text
                                                fontType="LATO_REGULAR"
                                                style={styles.bankAccountDetailText}
                                                numberOfLines={2}
                                            >
                                                {bankAccount.beneficiaryAddress}
                                            </Text>
                                            <View style={styles.copyButtonContainer}>
                                                <Button
                                                    disabled={false}
                                                    onPress={() => {
                                                        copyToClipboard(bankAccount.beneficiaryAddress);
                                                    }}
                                                    title="copy"
                                                    buttonHeight={15}
                                                    titleSize={FONT_SIZE_S}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.bankAccountDetailContainer}>
                                        <Text fontType="LATO_REGULAR" style={styles.bankAccountDetailLabel}>
                                            {'bank-account-number'}
                                        </Text>
                                        <View style={styles.bankAccountDetailValueAndButtonContainer}>
                                            <Text
                                                fontType="LATO_REGULAR"
                                                style={styles.bankAccountDetailText}
                                                numberOfLines={2}
                                            >
                                                {bankAccount.accountNumber}
                                            </Text>
                                            <View style={styles.copyButtonContainer}>
                                                <Button
                                                    disabled={false}
                                                    onPress={() => {
                                                        copyToClipboard(bankAccount.accountNumber);
                                                    }}
                                                    title="copy"
                                                    buttonHeight={15}
                                                    titleSize={FONT_SIZE_S}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.bankAccountDetailContainer}>
                                        <Text fontType="LATO_REGULAR" style={styles.bankAccountDetailLabel}>
                                            {'bank-account-currency'}
                                        </Text>
                                        <View style={styles.bankAccountDetailValueAndButtonContainer}>
                                            <Text
                                                fontType="LATO_REGULAR"
                                                style={styles.bankAccountDetailText}
                                                numberOfLines={2}
                                            >
                                                {bankAccount.accountCurrency}
                                            </Text>
                                            <View style={styles.copyButtonContainer}>
                                                <Button
                                                    disabled={false}
                                                    onPress={() => {
                                                        copyToClipboard(bankAccount.accountCurrency);
                                                    }}
                                                    title="copy"
                                                    buttonHeight={15}
                                                    titleSize={FONT_SIZE_S}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.bankAccountDetailContainer}>
                                        <Text fontType="LATO_REGULAR" style={styles.bankAccountDetailLabel}>
                                            {'bank-ref'}
                                        </Text>
                                        <View style={styles.bankAccountDetailValueAndButtonContainer}>
                                            <Text
                                                style={styles.bankAccountDetailText}
                                                fontType="LATO_BOLD"
                                                numberOfLines={2}
                                            >
                                                {bankAccount.reference}
                                            </Text>
                                            <View style={styles.copyButtonContainer}>
                                                <Button
                                                    disabled={false}
                                                    onPress={() => {
                                                        copyToClipboard(bankAccount.reference);
                                                    }}
                                                    title="copy"
                                                    buttonHeight={15}
                                                    titleSize={FONT_SIZE_S}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.bankAccountDetailContainer}>
                                        <Text fontType="LATO_REGULAR" style={styles.bankAccountDetailLabel}>
                                            {'bank-bic'}
                                        </Text>
                                        <View style={styles.bankAccountDetailValueAndButtonContainer}>
                                            <Text
                                                fontType="LATO_REGULAR"
                                                style={styles.bankAccountDetailText}
                                                numberOfLines={2}
                                            >
                                                {bankAccount.swiftBic}
                                            </Text>
                                            <View style={styles.copyButtonContainer}>
                                                <Button
                                                    disabled={false}
                                                    onPress={() => {
                                                        copyToClipboard(bankAccount.swiftBic);
                                                    }}
                                                    title="copy"
                                                    buttonHeight={15}
                                                    titleSize={FONT_SIZE_S}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.bankAccountDetailContainer}>
                                        <Text fontType="LATO_REGULAR" style={styles.bankAccountDetailLabel}>
                                            {'bank-name'}
                                        </Text>
                                        <View style={styles.bankAccountDetailValueAndButtonContainer}>
                                            <Text
                                                fontType="LATO_REGULAR"
                                                style={styles.bankAccountDetailText}
                                                numberOfLines={2}
                                            >
                                                {bankAccount.bankName}
                                            </Text>
                                            <View style={styles.copyButtonContainer}>
                                                <Button
                                                    disabled={false}
                                                    onPress={() => {
                                                        copyToClipboard(bankAccount.bankName);
                                                    }}
                                                    title="copy"
                                                    buttonHeight={15}
                                                    titleSize={FONT_SIZE_S}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.bankAccountDetailContainer}>
                                        <Text fontType="LATO_REGULAR" style={styles.bankAccountDetailLabel}>
                                            {'bank-address'}
                                        </Text>
                                        <View style={styles.bankAccountDetailValueAndButtonContainer}>
                                            <Text
                                                fontType="LATO_REGULAR"
                                                style={styles.bankAccountDetailText}
                                                numberOfLines={2}
                                            >
                                                {bankAccount.bankAddress}
                                            </Text>
                                            <View style={styles.copyButtonContainer}>
                                                <Button
                                                    disabled={false}
                                                    onPress={() => {
                                                        copyToClipboard(bankAccount.bankAddress);
                                                    }}
                                                    title="copy"
                                                    buttonHeight={15}
                                                    titleSize={FONT_SIZE_S}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            ))}
                        </ScrollbarWrapper>
                    </>
                ) : (
                    <View style={styles.noBankAccountsContainer}>
                        <Text
                            fontType="LATO_REGULAR"
                            style={styles.noBankAccountsText}
                            onPress={() => {
                                Linking.openURL(
                                    'mailto:' +
                                        (dealer === 'EVER'
                                            ? `${config.BANK_WIRE_EMAIL_EVER}`
                                            : `${config.BANK_WIRE_EMAIL}`) +
                                        '?subject=Add Funds'
                                );
                            }}
                        >
                            {i18n.t('payment-for-more-info-text') +
                                ' ' +
                                (dealer === 'EVER' ? `${config.BANK_WIRE_EMAIL_EVER}` : `${config.BANK_WIRE_EMAIL}`) +
                                ' '}
                        </Text>
                        <Text
                            fontType="LATO_REGULAR"
                            style={styles.noBankAccountsText}
                            onPress={() =>
                                Platform.OS !== 'web'
                                    ? Linking.openURL(
                                          `tel:${dealer === 'EVER' ? `${config.PHONE_EVER}` : `${config.PHONE}`}`
                                      )
                                    : null
                            }
                        >
                            {i18n.t('or-text') + ' ' + (dealer === 'EVER' ? `${config.PHONE_EVER}` : `${config.PHONE}`)}
                        </Text>
                    </View>
                )}
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        noBankAccountsContainer: { justifyContent: 'center', alignItems: 'center', height: '100%' },
        noBankAccountsText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        bankAccountDetailLabel: {
            fontSize: FONT_SIZE_XS,
            color: theme.text_symbol,
        },
        bankAccountDetailText: {
            fontSize: FONT_SIZE_XS,
            color: theme.text_symbol,
            flexWrap: 'wrap',
            maxWidth: scaleSize(200),
        },
        copyAllTextContainer: { flexDirection: 'row' },
        copyAllButtonContainer: {
            marginLeft: 10,
            width: 80,
            height: 15,
            justifyContent: 'center',
            alignItems: 'center',
        },
        copyButtonContainer: {
            marginLeft: 10,
            width: 40,
            height: 15,
            justifyContent: 'center',
            alignItems: 'center',
        },
        bankAccountDetailValueAndButtonContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        scrollViewContainer: { width: '100%' },
        bankAccountHeadContainer: { flexDirection: 'row', marginVertical: scaleSize(10) },
        bankAccountDetailContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginVertical: scaleSize(10),
        },
        bankAccountContainer: {
            width: '100%',
            marginVertical: scaleSize(10),
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
        },
        paymentCategoryBox: {
            justifyContent: 'center',
            alignItems: 'center',
            width: scaleSize(60),
            height: scaleSize(30),
            backgroundColor: theme.white,
            borderColor: theme.border_2,
            borderRadius: scaleSize(5),
            borderWidth: scaleSize(1),
            marginRight: 10,
        },
        descriptionText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        addFundsTitleContainer: {
            paddingVertical: scaleSize(40),
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
            marginHorizontal: '5%',
        },
        addFundsTitleText: { fontSize: FONT_SIZE_S, color: theme.greenLineDesktop, letterSpacing: 0.5 },
        content: { width: '100%' },
        paymentCategory: {
            width: scaleSize(50),
            height: scaleSize(20),
        },
        row: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: scaleSize(10),
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
            marginHorizontal: '5%',
        },
        container: {
            alignItems: 'center',
            flex: 1,
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            paddingTop: scaleSize(7),
            paddingHorizontal: scaleSize(17),
        },
    });
