import React, {useState, useEffect} from 'react';
import { Platform, StyleSheet } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Ionicons, Entypo } from '@expo/vector-icons';
import MarketStackNavigator from './MarketStackNavigator';
import i18n from '~/i18n';
import DealsStackNavigator from './DealsStackNavigator';
import { scaleSize } from '~/constants/scale';
import { FONT_FAMILIES } from '~/constants/typography';
import { useAppSelector, useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { global } from '~/constants/global';
import { FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import { favoritesDataSelector, pendingMessagesCountSelector } from '~/state/selectors';
import MoreStackNavigator from './MoreStackNavigator';
import NewsStackNavigator from './NewsStackNavigator';
import * as ScreenOrientation from "expo-screen-orientation";
import { useTheme } from '~/hooks/useTheme';


const Tab = createBottomTabNavigator();

export default function HomeTabsNavigator() {
    const theme = useTheme();
    const styles = useStyles(stylesGenerator);
    const favorites = useAppSelector(favoritesDataSelector);
    const pendingMessagesCount = useAppSelector(pendingMessagesCountSelector);

    const [orientation, setOrientation] = useState(3);
    useEffect(() => {
        lockOrientation();
    }, [orientation]);

    const lockOrientation = async () => {
        if(Platform.OS !== 'web') {
            await ScreenOrientation.lockAsync(
                ScreenOrientation.OrientationLock.PORTRAIT_UP
            );
            const o = await ScreenOrientation.getOrientationAsync();
            setOrientation(o);
        }
      };

    return (
        <Tab.Navigator
            initialRouteName="MarketsStack"
            screenOptions={{
                tabBarLabelPosition: 'below-icon',
                tabBarActiveTintColor: theme.tabIconSelected,
                tabBarInactiveTintColor: theme.tabIconDefault,
                ...styles,
            }}
        >
            <Tab.Screen
                name="MarketsStack"
                component={MarketStackNavigator}
                listeners={{
                    tabPress: (e) => {
                        e.preventDefault();
                        linkTo(paths.watchlistItem(favorites.id));
                    },
                }}
                options={{
                    headerShown: false,
                    tabBarLabel: i18n.t('bottom-tab-markets'),
                    tabBarIcon: ({ color }) => <Ionicons name="globe-outline" color={color} size={scaleSize(24)} />,
                }}
            />
            <Tab.Screen
                name="DealsStack"
                component={DealsStackNavigator}
                listeners={{
                    tabPress: (e) => {
                        e.preventDefault();
                        linkTo(paths.openTrades);
                    },
                }}
                options={{
                    headerShown: false,
                    tabBarLabel: i18n.t('bottom-tab-deals'),
                    tabBarIcon: ({ color }) => (
                        <Ionicons name="briefcase-outline" color={color} size={scaleSize(24)} />
                    ),
                }}
            />
            <Tab.Screen
                name="NewsStack"
                component={NewsStackNavigator}
                listeners={{
                    tabPress: (e) => {
                        e.preventDefault();
                        linkTo(paths.news);
                    },
                }}
                options={{
                    headerShown: false,
                    tabBarLabel: i18n.t('bottom-tab-news'),
                    tabBarIcon: ({ color }) => (
                        <Ionicons name="newspaper-outline" color={color} size={scaleSize(24)} />
                    ),
                    tabBarBadge: pendingMessagesCount
                        ? pendingMessagesCount > 9
                            ? '9+'
                            : pendingMessagesCount
                        : undefined,
                    tabBarBadgeStyle: {
                        fontSize: scaleSize(FONT_SIZE_XS),
                        backgroundColor: theme.tabIconSelected,
                        color: theme.white,
                    },
                }}
            />
            <Tab.Screen
                name="MoreStack"
                component={MoreStackNavigator}
                listeners={{
                    tabPress: (e) => {
                        e.preventDefault();
                        linkTo(paths.more);
                    },
                }}
                options={{
                    headerShown: false,

                    tabBarLabel: i18n.t('bottom-tab-more'),
                    tabBarIcon: ({ color }) => (
                        <Entypo name="dots-three-horizontal" color={color} size={scaleSize(24)} />
                    ),
                }}
            />
        </Tab.Navigator>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        tabBarBadgeStyle: {
            fontSize: scaleSize(FONT_SIZE_XS),
            top: scaleSize(15),
            height: scaleSize(15),
            minWidth: scaleSize(15),
            lineHeight: scaleSize(15),
            color: theme.white,
            backgroundColor: theme.tabIconSelected,
            fontFamily: FONT_FAMILIES.LATO_BOLD,
        },
        tabBarLabelStyle: {
            fontFamily: FONT_FAMILIES.LATO_BOLD,
            fontWeight: Platform.OS === 'web' ? 'bold' : 'regular',
            fontSize: scaleSize(FONT_SIZE_S),
            flex: 1,
            minHeight: scaleSize(12),
        },
        tabBarStyle: {
            minHeight: scaleSize(global.HOME_TABS_HEIGHT),
            alignItems: 'stretch',
        },
        tabBarIconStyle: {
            minHeight: scaleSize(24),
            flex: 1,
        },
        tabBarItemStyle: {
            paddingTop: scaleSize(4),
        },
    });
