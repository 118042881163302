import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import type { Props } from './types';

export default function ({ value, onChange, isOpen, setIsFromOpen, setIsToOpen }: Props) {
    return (
        <DateTimePicker
            disableClock
            value={value}
            onChange={onChange}
            format={'y-MM-dd'}
            isCalendarOpen={isOpen}
            onCalendarClose={() => {
                if (setIsFromOpen) {
                    setIsFromOpen(false);
                }
                if (setIsToOpen) {
                    setIsToOpen(false);
                }
            }}
            onClockClose={() => {
                if (setIsFromOpen) {
                    setIsFromOpen(false);
                }
                if (setIsToOpen) {
                    setIsToOpen(false);
                }
            }}
        />
    );
}
