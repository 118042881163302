import { CombinedTheme } from '../type';
import colors from '../colors';

const theme: CombinedTheme = {
    light: {
        primary: colors.blue_1,
        secondary: colors.gray_15,
        text: colors.black,
        textSecondary: colors.gray_14,
        background: colors.gray_6,
        backgroundSecondary: colors.white,
        tabIconDefault: colors.gray_10,
        tabIconSelected: colors.teal_3,
        card: colors.white,
        border: colors.gray_7,
        notification: colors.red_2,
        success: colors.green_1,
        warning: colors.orange,
        alert: colors.red_2,
        white: colors.white,
        black: colors.black,
        tint: colors.green_2,
        darkTint: colors.gray_16,
        priceRangeActiveTab: colors.blue_3,
        border_2: colors.gray_9,
        switch_trackOn: colors.teal_3,
        switch_trackOff: colors.gray_10,
        switch_tumbOn: colors.white,
        switch_tumbOff: colors.white,
        text_removeFromFavorites: colors.pink_2,
        text_upPrice: colors.teal_3,
        text_downPrice: colors.red_3,
        text_symbol: colors.gray_18,
        text_priceDown: colors.white,
        text_priceUp: colors.white,
        text_positionsCount: colors.blue_3,
        text_at: colors.gray_10,
        text_pendingOrder: colors.gray_4,
        text_positionId: colors.blue_3,
        text_balance: colors.blue_3,
        background_buttonInactive: colors.gray_10,
        background_positionsCount: colors.blue_3,
        background_message: colors.blue_3,
        wallet_text: colors.blue_3,
        background_positionId: colors.gray_10,
        background_closeTradeButton: colors.blue_3,
        background_closeTradeButtonPressed: colors.white,
        background_wallet: colors.gray_6,
        text_chartNumbers: colors.teal_1,
        background_addFav: colors.teal_1,
        text_chartPrices: colors.blue_3,
        greenLineDesktop: colors.teal_3,
        logoutButtonBackgroundColor: colors.blue_3,
        desktopHeaderTextColor: colors.gray_18,
        themeLightText: colors.gray_18,
        themeDarkText: colors.gray_4,
        languagePicker: colors.gray_10,
        equityValue: colors.gray_4,
        instrumentDescription: colors.gray_10,
        iconBorder: colors.gray_6,
        border_wallet: colors.gray_4,
        background_edit_favorirest: colors.white,
        border_edit_favorites: colors.blue_3,
        edit_favorites_text: colors.blue_3,
        search_header_gray: colors.gray_2,
        ctInfo: colors.gray_9,
        ctTextColor: colors.white,
        compleateVCStep: colors.green_3
    },
    dark: {
        primary: colors.gray_13,
        secondary: colors.gray_15,
        text: colors.white,
        textSecondary: colors.gray_14,
        background: colors.backgroud_dark_theme,
        backgroundSecondary: colors.gray_5,
        tabIconDefault: colors.gray_4,
        tabIconSelected: colors.teal_3,
        card: colors.gray_5,
        border: colors.gray_13,
        notification: colors.red_2,
        success: colors.green_1,
        warning: colors.orange,
        alert: colors.red_2,
        white: colors.white,
        black: colors.black,
        tint: colors.blue_2,
        darkTint: colors.gray_16,
        priceRangeActiveTab: colors.gray_5,
        border_2: colors.gray_17,
        switch_trackOn: colors.teal_3,
        switch_trackOff: colors.gray_4,
        switch_tumbOn: colors.gray_5,
        switch_tumbOff: colors.gray_5,
        text_upPrice: colors.teal_3,
        text_downPrice: colors.red_3,
        text_removeFromFavorites: colors.pink_2,
        text_symbol: colors.gray_2,
        text_priceDown: colors.white,
        text_priceUp: colors.white,
        text_positionsCount: colors.white,
        text_at: colors.gray_4,
        text_pendingOrder: colors.gray_2,
        text_positionId: colors.gray_2,
        text_balance: colors.gray_2,
        background_buttonInactive: colors.gray_4,
        background_positionsCount: colors.teal_3,
        background_message: colors.blue_3,
        wallet_text: colors.gray_6,
        background_positionId: colors.blue_3,
        background_closeTradeButton: colors.white,
        background_closeTradeButtonPressed: colors.blue_3,
        background_wallet: colors.backgroud_dark_theme,
        text_chartNumbers: colors.gray_4,
        background_addFav: colors.blue_3,
        text_chartPrices: colors.blue_3,
        greenLineDesktop: colors.teal_3,
        logoutButtonBackgroundColor: colors.blue_3,
        desktopHeaderTextColor: colors.gray_6,
        themeLightText: colors.gray_10,
        themeDarkText: colors.gray_6,
        languagePicker: colors.gray_6,
        equityValue: colors.gray_4,
        instrumentDescription: colors.teal_3,
        iconBorder: colors.white,
        border_wallet: colors.gray_4,
        background_edit_favorirest: colors.gray_6,
        border_edit_favorites: colors.gray_4,
        edit_favorites_text: colors.gray_5,
        search_header_gray: colors.blue_3,
        ctInfo: colors.gray_4,
        ctTextColor: colors.backgroud_dark_theme,
        compleateVCStep: colors.green_3
    },
};

export default theme;
