import axios from '../baseClient';

import { GetSubscribedCategoriesResponse } from './types';
import { EmptyResponse } from '../types';

class PushnotificationsService {
    private static domain = '/api/pushnotificationservice';

    registerToken(token: string) {
        return axios.post<EmptyResponse>(`${PushnotificationsService.domain}/register`, { registrationId: token });
    }

    unregisterToken(token: string) {
        return axios.post<EmptyResponse>(`${PushnotificationsService.domain}/unregister`, { registrationId: token });
    }

    getSubscribedCategories() {
        return axios.post<GetSubscribedCategoriesResponse>(`${PushnotificationsService.domain}/getCategoryList`, {});
    }

    setSubscribedCategories(list: string[]) {
        return axios.post<EmptyResponse>(`${PushnotificationsService.domain}/subscribe`, { list });
    }
}

export default new PushnotificationsService();
