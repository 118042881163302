import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { Text, TextInput } from '~/components/core';
import { FONT_SIZE_S, FONT_SIZE_XS, FONT_SIZE_XXXL } from '~/constants';
import { formatCurrency, formatNumber, getCurrencySign } from '~/lib/format';
import { scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { Feather } from '@expo/vector-icons';
import { DataSymbol } from '~/api-services/LoadService/types';
import { accountBalanceSelector } from '~/state/selectors';
import { CoreDataService } from '~/api-services';

type Props = {
    volume: number;
    setVolume: Function;
    dataSymbol: DataSymbol;
};

export default function Quantity({ volume, setVolume, dataSymbol }: Props) {
    const styles = useStyles(stylesGenerator);
    const [longPressedSign, setLongPressedSign] = useState<'+' | '-'>();
    const timeout = useRef<NodeJS.Timeout>();
    const timeoutMargin = useRef<NodeJS.Timeout>();
    const step = dataSymbol.minTradeVolume;
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';
    const [margin, setMargin] = useState<number | null>(null);
    const [loadMargin, setLoadMargin] = useState(false);
    const [showQuantityInput, setShowQuantityInput] = useState(false);
    const [volumeText, setVolumeText] = useState(volume.toString());

    useEffect(() => {
        if (!loadMargin) {
            setLoadMargin(true);
            getTradeMargin(volume);
        }
    }, [getTradeMargin, loadMargin, margin, volume]);

    useEffect(() => {
        timeout.current && clearInterval(timeout.current);
        if (!longPressedSign) {
            return;
        }
        let cnt = 1;
        const s = longPressedSign === '+' ? step : -step;
        timeout.current = setInterval(() => changeVolume(volume + s * cnt++), 50);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSymbol.digits, longPressedSign, setVolume, step]);

    useEffect(() => {
        timeoutMargin.current && clearInterval(timeoutMargin.current);
        timeoutMargin.current = setInterval(() => getTradeMargin(volume), 15000);
    }, [volume]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function getTradeMargin(newVolume: number) {
        const { status, data } = await CoreDataService.getTradeMargin({
            symbol: dataSymbol.symbol,
            volume: newVolume,
            volumePrecision: dataSymbol.volumePrecision,
        });
        if (status === 200 && data.status) {
            setMargin(data.returnData.margin);
        }
    }

    function changeVolume(newVolume: number) {
        if (newVolume > dataSymbol.minTradeVolume) {
            setVolume(newVolume);
        } else {
            setVolume(dataSymbol.minTradeVolume);
            getTradeMargin(dataSymbol.minTradeVolume);
        }
    }

    function onChangeVolume(text: string): number {
        var newText = '';
        var numbers = '0123456789.';
        text = text.replace(',', '.');
        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        setVolumeText(newText);
        if (newText !== '') {
            changeVolume(Number(newText));
            return Number(newText);
        }
    }

    return (
        <>
            <View style={styles.orderButtonsRow}>
                <Pressable
                    style={(args) => [
                        {
                            opacity: args.pressed ? 0.5 : 1,
                        },
                    ]}
                    onPress={() => {
                        changeVolume(volume - step);
                        setShowQuantityInput(false);
                    }}
                    onPressIn={() => {
                        setLongPressedSign('-');
                        setShowQuantityInput(false);
                    }}
                    onPressOut={() => {
                        setLongPressedSign(undefined);
                        if(volume - step >= dataSymbol.minTradeVolume) {
                            getTradeMargin(volume - step);
                        }
                        else {
                            getTradeMargin(dataSymbol.minTradeVolume);
                        }
                        setShowQuantityInput(false);
                    }}
                >
                    <View style={styles.roundButton}>
                        <Feather color="white" name={'minus'} size={scaleSize(15)} />
                    </View>
                </Pressable>
                <View style={styles.centerContainer}>
                    <Pressable
                        style={styles.centerContainer}
                        onPress={() => {
                            setVolumeText(formatNumber(volume, dataSymbol.volumePrecision));
                            setShowQuantityInput(true);
                        }}
                    >
                        {showQuantityInput ? (
                            <View style={styles.inputContainer}>
                                <TextInput
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    value={volumeText}
                                    onChangeText={(text) => {
                                        let newVol = onChangeVolume(text);
                                        if(newVol) {
                                            if(newVol >= dataSymbol.minTradeVolume) {
                                                getTradeMargin(newVol);
                                            }
                                            else {
                                                getTradeMargin(dataSymbol.minTradeVolume);
                                            }
                                        }
                                        else {
                                            if(Number(volumeText) >= dataSymbol.minTradeVolume) {
                                                getTradeMargin(Number(volumeText));
                                            }
                                            else {
                                                getTradeMargin(dataSymbol.minTradeVolume);
                                            }
                                        }
                                    }}
                                    keyboardType="decimal-pad"
                                    maxLength={10}
                                    onSubmitEditing={() => {
                                        setShowQuantityInput(false)
                                    }}
                                />
                            </View>
                        ) : (
                            <Text fontType="LATO_BOLD" style={styles.priceText}>
                                {formatNumber(volume, dataSymbol.volumePrecision)}
                            </Text>
                        )}
                        <Text fontType="LATO_REGULAR" style={styles.currencyText}>
                            {dataSymbol.marginCurrency + getCurrencySign(dataSymbol.marginCurrency)
                                ? dataSymbol.type !== 'FOREX' ? 'Units' : dataSymbol.marginCurrency + ' (' + getCurrencySign(dataSymbol.marginCurrency) + ')'
                                : ''}
                        </Text>
                    </Pressable>
                    <View style={[styles.centerContainer, styles.marginContainer]}>
                        <View style={styles.marginTextContainer}>
                            <Text fontType="LATO_BOLD" style={styles.marginText}>
                                necessary-margin
                            </Text>
                        </View>
                        <Text fontType="LATO_REGULAR" style={styles.marginNumber}>
                            {formatCurrency(margin, 2, currency)}
                        </Text>
                    </View>
                </View>
                <Pressable
                    style={(args) => [
                        {
                            opacity: args.pressed ? 0.5 : 1,
                        },
                    ]}
                    onPress={() => {
                        changeVolume(volume + step);
                        setShowQuantityInput(false);
                    }}
                    onPressIn={() => {
                        setLongPressedSign('+');
                        setShowQuantityInput(false);
                    }}
                    onPressOut={() => {
                        setLongPressedSign(undefined);
                        getTradeMargin(volume + step);
                        setShowQuantityInput(false);
                    }}
                >
                    <View style={styles.roundButton}>
                        <Feather color="white" name={'plus'} size={scaleSize(15)} />
                    </View>
                </Pressable>
            </View>
            <View style={styles.separator} />
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        inputContainer: {
            width: scaleSize(120),
            marginBottom: scaleSize(5),
        },
        marginText: { fontSize: FONT_SIZE_S, color: theme.tabIconSelected },
        marginNumber: { fontSize: FONT_SIZE_XS, letterSpacing: 0.5, color: theme.text_pendingOrder },
        priceText: {
            fontSize: FONT_SIZE_XXXL,
            letterSpacing: 0.5,
            color: theme.text_symbol,
        },
        currencyText: {
            fontSize: FONT_SIZE_S,
            letterSpacing: 0.5,
            color: theme.text_pendingOrder,
        },
        orderButtonsRow: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: scaleSize(50),
        },
        centerContainer: { justifyContent: 'center', alignItems: 'center' },
        marginContainer: { marginVertical: scaleSize(30) },
        marginTextContainer: { paddingBottom: scaleSize(6) },
        roundButton: {
            width: scaleSize(50),
            height: scaleSize(50),
            borderRadius: scaleSize(25),
            backgroundColor: theme.background_buttonInactive,
            justifyContent: 'center',
            alignItems: 'center',
        },
        separator: {
            marginHorizontal: scaleSize(17),
            height: scaleSize(1),
            backgroundColor: theme.text_pendingOrder,
        },
    });
