import { useEffect, useState } from 'react';

import { symbolsSelector } from '~/state/selectors';
import { useAppSelector } from './useAppSelector';
import { useSubscribeQuote } from './useSubscribeQuote';
import { CoreDataService } from '~/api-services';
import { OrderRequest } from '~/api-services/CoreDataService/types';
import AlertService from '~/api-services/AlertService';
import { useExchangeRates } from './useExchangeRates';
import { useTheme } from './useTheme';
import { Platform } from 'react-native';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import { isDesktop } from '~/constants/scale';

export const useMarketOrder = (symbol: string, side: 'buy' | 'sell') => {
    useSubscribeQuote(symbol);
    const exchangeRates = useExchangeRates(symbol).exchangeRates;
    const dataSymbol = useAppSelector((state) => symbolsSelector(state)[symbol]);
    const theme = useTheme();
    const marketPrice = side === 'buy' ? dataSymbol.price.ask : dataSymbol.price.bid;

    const [volume, setVolume] = useState(dataSymbol.minTradeVolume);
    const [limitProfitPrice, setLimitProfitPrice] = useState(
        side === 'buy' ? dataSymbol.price.bid * 1.005 : dataSymbol.price.ask * 0.995
    );
    const [minLimitProfitPrice, setMinLimitProfitPrice] = useState(
        side === 'buy'
            ? dataSymbol.price.ask + dataSymbol.minPendingDistSplitTP
            : dataSymbol.price.bid - dataSymbol.maxPendingDist
    );
    const [maxLimitProfitPrice, setMaxLimitProfitPrice] = useState(
        side === 'buy'
            ? dataSymbol.price.ask + dataSymbol.maxPendingDist
            : dataSymbol.price.bid - dataSymbol.minPendingDistSplitTP
    );
    const [stopLossPrice, setStopLossPrice] = useState(
        side === 'buy' ? dataSymbol.price.ask * 0.995 : dataSymbol.price.bid * 1.005
    );
    const [minStopLossPrice, setMinStopLossPrice] = useState(
        side === 'buy'
            ? dataSymbol.price.bid - dataSymbol.maxPendingDist
            : dataSymbol.price.ask + dataSymbol.minPendingDistSplitTP
    );
    const [maxStopLossPrice, setMaxStopLossPrice] = useState(
        side === 'buy'
            ? dataSymbol.price.bid - dataSymbol.minPendingDistSplitTP
            : dataSymbol.price.ask + dataSymbol.maxPendingDist
    );

    const [pendingOrderPrice, setPendingOrderPrice] = useState(
        side === 'buy' ? dataSymbol.price.ask * 1.005 : dataSymbol.price.bid * 0.995
    );
    const maxPendingOrderPrice =
        side === 'buy'
            ? dataSymbol.price.ask + dataSymbol.maxPendingDist
            : dataSymbol.price.ask + dataSymbol.maxPendingDist;
    const minPendingOrderPrice =
        side === 'buy'
            ? dataSymbol.price.bid - dataSymbol.maxPendingDist
            : dataSymbol.price.bid - dataSymbol.maxPendingDist;

    const [isOpenTakeProfit, setIsOpenTakeProfit] = useState(false);
    const [isOpenStopLoss, setIsOpenStopLoss] = useState(false);
    const [isOpenPendingOrder, setIsOpenPendingOrder] = useState(false);
    const [disableConfirmButton, setDisableConfirmButton] = useState(false);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });
    const [lastSymbol, setLastSymbol] = useState('');
    const [lastSide, setLastSide] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (symbol !== lastSymbol || side !== lastSide) {
            setVolume(dataSymbol.minTradeVolume);
            setLimitProfitPrice(side === 'buy' ? dataSymbol.price.bid * 1.005 : dataSymbol.price.ask * 0.995);
            setMinLimitProfitPrice(
                side === 'buy'
                    ? dataSymbol.price.ask + dataSymbol.minPendingDistSplitTP
                    : dataSymbol.price.bid - dataSymbol.maxPendingDist
            );
            setMaxLimitProfitPrice(
                side === 'buy'
                    ? dataSymbol.price.ask + dataSymbol.maxPendingDist
                    : dataSymbol.price.bid - dataSymbol.minPendingDistSplitTP
            );
            setStopLossPrice(side === 'buy' ? dataSymbol.price.bid * 0.995 : dataSymbol.price.ask * 1.005);
            setMinStopLossPrice(
                side === 'buy'
                    ? dataSymbol.price.bid - dataSymbol.maxPendingDist
                    : dataSymbol.price.ask + dataSymbol.minPendingDistSplitSL
            );
            setMaxStopLossPrice(
                side === 'buy'
                    ? dataSymbol.price.bid - dataSymbol.minPendingDistSplitSL
                    : dataSymbol.price.ask + dataSymbol.maxPendingDist
            );
            setPendingOrderPrice(side === 'buy' ? dataSymbol.price.ask * 1.005 : dataSymbol.price.bid * 0.995);
            setIsOpenTakeProfit(false);
            setIsOpenStopLoss(false);
            setIsOpenPendingOrder(false);
            setDisableConfirmButton(false);
            setConfirmationVisible(false);
            setConfirmationProps({
                text: '',
                error: false,
            });
            setLastSymbol(symbol);
            setLastSide(side);
        }
    }, [
        symbol,
        side,
        dataSymbol.minTradeVolume,
        dataSymbol.price.bid,
        dataSymbol.price.ask,
        dataSymbol.minPendingDistSplitTP,
        dataSymbol.maxPendingDist,
        dataSymbol.symbol,
        lastSymbol,
        lastSide,
        dataSymbol.minPendingDistSplitSL,
    ]);

    const getPriceColor = () => {
        switch (side === 'buy' ? dataSymbol.askPriceHasIncreased : dataSymbol.bidPriceHasIncreased) {
            case true:
                return theme.text_upPrice;
            case false:
                return theme.text_downPrice;
            default:
                return theme.text_pendingOrder;
        }
    };

    async function executeOrder() {
        setDisableConfirmButton(true);
        setLoading(true);
        let request: OrderRequest = {
            limitProfit: isOpenTakeProfit ? limitProfitPrice : null,
            stopLoss: isOpenStopLoss ? stopLossPrice : null,
            side: side,
            symbol: dataSymbol.symbol,
            volume: volume,
            volumePrecision: dataSymbol.volumePrecision,
            openPrice: isOpenPendingOrder ? pendingOrderPrice : marketPrice,
        };
        try {
            const { status, data } = isOpenPendingOrder
                ? await CoreDataService.addPendingOrder(request)
                : await CoreDataService.executeMarketOrder(request);
            setLoading(false);
            if (status !== 200) {
                setDisableConfirmButton(false);
                setConfirmationProps({ text: 'Error', error: true });
            }
            if (data.status) {
                setDisableConfirmButton(false);
                let message = 'order-executed';
                if (isOpenPendingOrder) {
                    message = 'pending-order-confirmed';
                }
                setConfirmationProps({ text: message, error: false });
                setConfirmationVisible(true);
                if (!isDesktop() && !isOpenPendingOrder) {
                    linkTo(paths.openTrades);
                }
            } else {
                setDisableConfirmButton(false);
                setConfirmationProps({ text: AlertService.getErrorResponse(data).message, error: true });
                setConfirmationVisible(true);
            }
        } catch (e) {
            setLoading(false);
            setDisableConfirmButton(false);
            setConfirmationProps({ text: 'Error', error: true });
            setConfirmationVisible(true);
        }
    }

    function calculateLimits(openPendingOrder: boolean) {
        if (openPendingOrder) {
            setLimitProfitPrice(side === 'buy' ? pendingOrderPrice * 1.005 : pendingOrderPrice * 0.995);
            setMinLimitProfitPrice(
                side === 'buy'
                    ? pendingOrderPrice + dataSymbol.minPendingDistSplitTP
                    : pendingOrderPrice - dataSymbol.maxPendingDist
            );
            setMaxLimitProfitPrice(
                side === 'buy'
                    ? pendingOrderPrice + dataSymbol.maxPendingDist
                    : pendingOrderPrice - dataSymbol.minPendingDistSplitTP
            );
            setStopLossPrice(side === 'buy' ? pendingOrderPrice * 0.995 : pendingOrderPrice * 1.005);
            setMinStopLossPrice(
                side === 'buy'
                    ? pendingOrderPrice - dataSymbol.maxPendingDist
                    : pendingOrderPrice + dataSymbol.minPendingDistSplitSL
            );
            setMaxStopLossPrice(
                side === 'buy'
                    ? pendingOrderPrice - dataSymbol.minPendingDistSplitSL
                    : pendingOrderPrice + dataSymbol.maxPendingDist
            );
        } else {
            setLimitProfitPrice(side === 'buy' ? dataSymbol.price.bid * 1.005 : dataSymbol.price.ask * 0.995);
            setMinLimitProfitPrice(
                side === 'buy'
                    ? dataSymbol.price.ask + dataSymbol.minPendingDistSplitTP
                    : dataSymbol.price.bid - dataSymbol.maxPendingDist
            );
            setMaxLimitProfitPrice(
                side === 'buy'
                    ? dataSymbol.price.ask + dataSymbol.maxPendingDist
                    : dataSymbol.price.bid - dataSymbol.minPendingDistSplitTP
            );
            setStopLossPrice(side === 'buy' ? dataSymbol.price.bid * 0.995 : dataSymbol.price.ask * 1.005);
            setMinStopLossPrice(
                side === 'buy'
                    ? dataSymbol.price.bid - dataSymbol.maxPendingDist
                    : dataSymbol.price.ask + dataSymbol.minPendingDistSplitSL
            );
            setMaxStopLossPrice(
                side === 'buy'
                    ? dataSymbol.price.bid - dataSymbol.minPendingDistSplitSL
                    : dataSymbol.price.ask + dataSymbol.maxPendingDist
            );
        }
    }

    function changePendingOrder(open: boolean) {
        setIsOpenPendingOrder(open);
        calculateLimits(open);
    }

    function changePendingOrderPrice(newPrice: number) {
        setPendingOrderPrice(newPrice);
        calculateLimits(true);
    }

    return {
        minLimitProfitPrice,
        maxLimitProfitPrice,
        dataSymbol,
        getPriceColor,
        marketPrice,
        volume,
        setVolume,
        pendingOrderPrice,
        changePendingOrderPrice,
        isOpenPendingOrder,
        changePendingOrder,
        maxPendingOrderPrice,
        minPendingOrderPrice,
        setIsOpenTakeProfit,
        setIsOpenStopLoss,
        minStopLossPrice,
        disableConfirmButton,
        confirmationVisible,
        confirmationProps,
        executeOrder,
        maxStopLossPrice,
        limitProfitPrice,
        setLimitProfitPrice,
        isOpenTakeProfit,
        stopLossPrice,
        setStopLossPrice,
        isOpenStopLoss,
        setConfirmationVisible,
        loading,
        exchangeRates,
    };
};
