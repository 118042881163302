import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, Platform } from 'react-native';
import { Button, Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { FONT_SIZE_S } from '~/constants';
import { scaleSize, scaleSizeMobile } from '~/constants/scale';
import { Theme } from '~/theme';
import config from '~/config';
import { appSettingsSelector } from '~/state/selectors';
import { SvgUri } from 'react-native-svg';
import ConfirmationMessage from '../../ConfirmationMessage';
import paths from '~/navigation/paths';
import { linkTo } from '~/navigation';
import { useRoute } from '@react-navigation/native';
import { AuthDataService } from '~/api-services';
import t from '~/i18n';
import { useBrowserLanguage } from '~/hooks/useBrowserLanguage';
import { enableForgotPasswordButton, enableRegistrationButton } from '~/lib/utils';

export default function ConfirmationForm() {
    const route: any = useRoute();
    const id: string = (route.params as any).id;
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const maxWidth: number = 600;
    const styles = useStyles(stylesGenerator, { maxWidth });
    const [email, setEmail] = useState('');
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    useBrowserLanguage();

    useEffect(() => {
        (async () => {
            if (id) {
                const { status, data } = await AuthDataService.confirmEmail({
                    id: id,
                });
                if (status === 200) {
                    if (data.status === true) {
                        setEmail(data.returnData);
                        setConfirmationProps({ text: t.t('confirmation-success'), error: false });
                        setConfirmationVisible(true);
                    } else {
                        if (data.errorCode === 'InvalidAccountException') {
                            setConfirmationProps({ text: 'confirmation-invalid-link', error: true });
                            setConfirmationVisible(true);
                        } else {
                            setConfirmationProps({ text: 'confirmation-error', error: true });
                            setConfirmationVisible(true);
                        }
                    }
                } else {
                    setConfirmationProps({ text: 'confirmation-error', error: true });
                    setConfirmationVisible(true);
                }
            }
        })();
    }, [id]);

    return (
        <View style={styles.container}>
            <View style={styles.logoContainer}>
                {Platform.OS === 'web' ? (
                    <Image
                        source={{
                            uri:
                                'https://' +
                                config.EXTERNAL_SOURCES_URL +
                                'mobileplatform/images/brand/' +
                                config.BRAND_NAME +
                                '/home_logo_' +
                                colorScheme +
                                '.svg?1'
                        }}
                        style={styles.logoStyle}
                        resizeMode="contain"
                    />
                ) : (
                    <SvgUri
                        width={'60%'}
                        style={styles.logoStyle}
                        uri={
                            'https://' +
                            config.EXTERNAL_SOURCES_URL +
                            'mobileplatform/images/brand/' +
                            config.BRAND_NAME +
                            '/home_logo_' +
                            colorScheme +
                            '.svg?1'
                        }
                    />
                )}
            </View>
            {email !== '' ? (
                <Button
                    disabled={!email}
                    onPress={() => linkTo(paths.login)}
                    title="confirmation-continue"
                    containerStyle={styles.loginButton}
                    maxWidth={maxWidth}
                />
            ) : null}
            <View style={styles.footerContainer}>
                {enableForgotPasswordButton() ?
                    <Text
                        fontType="LATO_BOLD"
                        style={styles.footerText}
                        onPress={() => linkTo(paths.login)}
                        maxWidth={maxWidth}
                    >
                        auth-title-login
                    </Text> : null}
                {enableRegistrationButton() ?
                    <>
                        <View style={styles.footerSeparator} /><Text
                            fontType="LATO_BOLD"
                            style={styles.footerText}
                            onPress={() => linkTo(paths.registration)}
                            maxWidth={maxWidth}
                        >
                            auth-button-go-to-signup
                        </Text>
                    </> : null}
            </View>
            <ConfirmationMessage
                text={confirmationProps.text}
                error={confirmationProps.error}
                visible={confirmationVisible}
                setVisible={setConfirmationVisible}
            />
        </View>
    );
}

const stylesGenerator = (theme: Theme, custom?: { maxWidth: number }) =>
    StyleSheet.create({
        footerText: { color: theme.switch_trackOn, fontSize: FONT_SIZE_S },
        footerSeparator: {
            width: 1,
            height: scaleSize(48, custom?.maxWidth),
            backgroundColor: theme.background_buttonInactive,
        },
        footerContainer: { flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', paddingBottom: scaleSize(10) },
        logoStyle: { width: config.HOME_LOGO_SIZE_IN_PERCENT, height: '100%', maxWidth: scaleSizeMobile(custom?.maxWidth), maxHeight: scaleSizeMobile(custom?.maxWidth) }, logoContainer: { height: '50%', justifyContent: 'center', alignItems: 'center' },
        container: {
            flex: 1,
            justifyContent: 'space-between',
            alignSelf: 'center',
            maxWidth: custom?.maxWidth,
            paddingHorizontal: scaleSize(12, custom?.maxWidth),
            width: '100%',
            height: '100%',
            maxHeight: '100%',
        },
        inputContainer: {
            marginBottom: scaleSize(50, custom?.maxWidth),
        },
        loginButton: {
            marginBottom: scaleSize(100, custom?.maxWidth),
        },
    });
