import * as React from 'react';
import { Entypo } from '@expo/vector-icons';
import { scaleSize } from '~/constants/scale';
import { Pressable, StyleSheet, View } from 'react-native';
import { goBack } from '~/navigation';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { Text } from '../core';
import { FONT_SIZE_L, FONT_SIZE_M } from '~/constants';

type Props = {
    title: string;
    hideBackButton?: boolean;
};

export default function CustomHeader({ title, hideBackButton }: Props) {
    const styles = useStyles(stylesGenerator);

    return (
        <View style={styles.container}>
            {hideBackButton && hideBackButton === true ? (
                <View style={styles.backContainer} />
            ) : (
                <Pressable onPress={() => goBack()} style={styles.backContainer}>
                    <Entypo size={scaleSize(24)} name="chevron-left" style={styles.backArrow} />
                    <Text fontType="LATO_REGULAR" style={styles.backText}>
                        {'back'}
                    </Text>
                </Pressable>
            )}
            <View style={styles.headerTitle}>
                <Text fontType="LATO_BOLD" style={styles.headerText}>
                    {title}
                </Text>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            width: '100%',
            paddingHorizontal: scaleSize(5),
            paddingBottom: scaleSize(5),
            paddingTop: scaleSize(5),
            backgroundColor: theme.backgroundSecondary,
        },
        backArrow: { color: theme.tabIconDefault },
        backContainer: {
            width: '15%',
            height: scaleSize(28),
            paddingLeft: scaleSize(2),
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row'
        },
        headerTitle: { width: '70%', justifyContent: 'center', alignItems: 'center' },
        headerText: { color: theme.tabIconDefault, fontSize: FONT_SIZE_L },
        backText: { color: theme.tabIconDefault, fontSize: FONT_SIZE_M, paddingBottom: scaleSize(2)},
    });
