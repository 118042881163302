import React from 'react';
import { StyleSheet } from 'react-native';
import { useStyles } from '~/hooks';
import { FONT_SIZE_M } from '~/constants/typography';
import Text from '../Text';
import { Theme } from '~/theme';

type Props = {
    text?: string;
    visible?: boolean;
};

const ErrorMessage = ({ text, visible }: Props) => {
    const styles = useStyles(stylesGenerator);

    if (!text || !visible) {
        return null;
    }

    return (
        <Text fontType="LATO_REGULAR" style={styles.errorMessageText}>
            {text}
        </Text>
    );
};

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        errorMessageText: {
            fontSize: FONT_SIZE_M,
            marginBottom: 10,
            color: theme.alert,
        },
    });

export default ErrorMessage;
