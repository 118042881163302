import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '~/components/core';

import { FONT_SIZE_XS } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';

import { Theme } from '~/theme';
import config from '~/config';
import dateFormat from "dateformat";


export default function CopyRight() {
    const styles = useStyles(stylesGenerator);

    return (
        <View style={styles.copyrightContainer}>
            <Text fontType="LATO_REGULAR" style={styles.copyrightText}>
                copyright
            </Text>
            <Text fontType="LATO_REGULAR" style={styles.copyrightText}>
                {dateFormat(new Date(), 'yyyy')}
            </Text>
            <Text fontType="LATO_REGULAR" style={styles.copyrightText}>
                {' '}
            </Text>
            <Text fontType="LATO_REGULAR" style={styles.copyrightText}>
                {config.BRAND_NAME}
            </Text>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        copyrightText: { fontSize: FONT_SIZE_XS, color: theme.switch_trackOn },
        copyrightContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: scaleSize(30),
        },
    });
