import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { thunkGenericApiReducerBuilder } from './utils';
import { ThunkAPI } from '../store';
import LocalStorage from '../localStorage';

export type AppSettings = {
    watchlistColumns: number;
    colorScheme: 'light' | 'dark';
    language: string;
    selectedBalance: 'balance' | 'equity' | 'margin' | 'freeMargin' | 'marginLevel' | 'ppl';
    hideBalance: boolean;
    lastRememberMe: boolean;
    lastEmail: string;
    lastPassword: string;
};

export interface AppSettingsState extends ResponseMetadata {
    data: AppSettings;
}

const initialState: AppSettingsState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    data: {
        watchlistColumns: 1,
        colorScheme: 'dark',
        language: 'en',
        selectedBalance: 'balance',
        hideBalance: false,
        lastRememberMe: true,
        lastEmail: '',
        lastPassword: '',
    },
};

export const saveAppSettings = createAsyncThunk<AppSettings, Partial<AppSettings>, ThunkAPI>(
    'appSettings/save',
    async (params, thunkAPI) => {
        const currentSettings = thunkAPI.getState().appSettings;
        const newSettings = { ...currentSettings.data, ...params };
        LocalStorage.appSettings.set(newSettings);
        return newSettings;
    }
);

export const loadAppSettings = createAsyncThunk<AppSettings, void, ThunkAPI>('appSettings/load', async () => {
    const localSettings = await LocalStorage.appSettings.get();
    return { ...initialState.data, ...(localSettings || {}) };
});

export const clearState = createAsyncThunk<AppSettings, void, ThunkAPI>('appSettings/clear', async () => {
    await LocalStorage.appSettings.clear();
    return initialState.data;
});

export const appSettingsSlice = createSlice({
    name: 'appSettings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        thunkGenericApiReducerBuilder(builder, saveAppSettings);
        thunkGenericApiReducerBuilder(builder, loadAppSettings);
        thunkGenericApiReducerBuilder(builder, clearState);
    },
});

export default appSettingsSlice.reducer;
