import React, { useState } from 'react';
import { View, StyleSheet, KeyboardAvoidingView, Keyboard } from 'react-native';
import { Text, Button, TextInput } from '~/components/core';
import config from '~/config';
import { FONT_FAMILY_BOLD, FONT_SIZE_L, FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import { accountBalanceSelector } from '~/state/selectors';
import { Theme } from '~/theme';
import i18n from '~/i18n';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import CopyRight from '../WithdrawFunds/CopyRight';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
type Props = {
    setActiveRightTab?: Function;
    setCurrentAmount?: Function;
};

export default function AddFunds({ setActiveRightTab, setCurrentAmount }: Props) {
    const styles = useStyles(stylesGenerator);
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';
    const [amount, setAmount] = useState('');

    function onChangedAmount(text: string) {
        var newText = '';
        var numbers = '0123456789.';
        text = text.replace(',', '.');
        if (text.length < 1) {
            setAmount('');
            if (setCurrentAmount) {
                setCurrentAmount('');
            }
        }
        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }

        setAmount(newText);
        if (setCurrentAmount) {
            setCurrentAmount(newText);
        }
    }

    return (
        <ScrollbarWrapper style={styles.scrollView}>
            <View style={styles.container}>
                <View style={styles.contentContainer}>
                    <View style={styles.headerTextContainer}>
                        <Text fontType="LATO_BOLD" style={styles.headerText}>
                            {'add-funds-header-text-part1'}
                        </Text>
                        <Text fontType="LATO_BOLD" style={styles.headerText}>
                            {i18n.t('add-funds-header-text-part2') + ' ' + config.BRAND_NAME}
                        </Text>
                    </View>
                    <View style={styles.depositTextContainer}>
                        <Text fontType="LATO_BOLD" style={styles.depositText}>
                            {i18n.t('deposit-funds') + ' ( ' + currency + ' )'}
                        </Text>
                    </View>
                    <KeyboardAvoidingView behavior={'height'}>
                        <TextInput
                            placeholder="enter-amount"
                            autoCapitalize="none"
                            autoCorrect={false}
                            value={amount}
                            onChangeText={(text) => onChangedAmount(text)}
                            keyboardType="decimal-pad"
                            maxLength={10}
                            autoFocus={true}
                            returnKeyType="done"
                            onSubmitEditing={() => {
                                if (amount && amount !== '') {
                                    Keyboard.dismiss;
                                    isDesktop() && setActiveRightTab
                                        ? setActiveRightTab('add-money-category')
                                        : linkTo(paths.addFundsCategory.replace(':amount', amount));
                                }
                            }}
                            style={[styles.textInputWidth, amount && amount!=='' ? styles.textInputText: null]}
                        />
                    </KeyboardAvoidingView>
                    <View style={styles.footerTextContainer}>
                        <Text fontType="LATO_REGULAR" style={styles.footerText1}>
                            {'add-funds-footer-text1'}
                        </Text>
                        <View style={styles.footerText2Container}>
                            <Text fontType="LATO_REGULAR" style={styles.footerText2}>
                                {'add-funds-footer-text2'}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={[styles.buttonContainer]}>
                    <Button
                        title={'account-balance-add-funds'}
                        disabled={!amount || amount === ''}
                        onPress={() => {
                            isDesktop() && setActiveRightTab
                                ? setActiveRightTab('add-money-category')
                                : linkTo(paths.addFundsCategory.replace(':amount', amount));
                        }}
                    />
                </View>
                <CopyRight />
            </View>
        </ScrollbarWrapper>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        textInputWidth: {width: '100%', },
        textInputText: {color: theme.tabIconSelected, fontFamily: FONT_FAMILY_BOLD, letterSpacing: 1, fontSize: FONT_SIZE_L},
        scrollView: { height: '100%' },
        contentContainer: { justifyContent: 'flex-start', height: '67%' },
        footerText2Container: { paddingTop: scaleSize(10) },
        copyrightTextContainer: { flexDirection: 'row', paddingTop: scaleSize(20) },
        copyrightText: { fontSize: FONT_SIZE_XS, color: theme.switch_trackOn },
        footerTextContainer: { justifyContent: 'center', alignItems: 'center', paddingTop: scaleSize(20) },
        footerText1: { fontSize: FONT_SIZE_XS, color: theme.switch_trackOn },
        footerText2: { fontSize: FONT_SIZE_XS, color: theme.text_positionsCount },
        buttonContainer: {
            flexDirection: 'row',
            width: '100%',
            paddingVertical: scaleSize(40),
            justifyContent: 'flex-end',
            height: '20%',
        },
        headerTextContainer: {
            paddingVertical: scaleSize(40),
            justifyContent: 'center',
            alignItems: 'center',
        },
        headerText: {
            fontSize: FONT_SIZE_S,
            color: theme.switch_trackOn,
            justifyContent: 'center',
            alignItems: 'center',
        },
        depositTextContainer: {
            flexDirection: 'row',
            paddingBottom: scaleSize(40),
            justifyContent: 'center',
            alignItems: 'center',
        },
        depositText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        container: {
            alignItems: 'center',
            flex: 1,
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            paddingTop: scaleSize(7),
            paddingHorizontal: scaleSize(17),
        },
    });
