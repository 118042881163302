import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { scaleSize } from '~/constants/scale';

type Props = {
    iconUrl: string;
};

export default function InstrumentIcon({ iconUrl }: Props) {
    const styles = useStyles(stylesGenerator);

    return (
        <View style={styles.container}>
            {iconUrl && iconUrl !== '' && iconUrl.indexOf(',') < 0 ? (
                <Image
                    source={{
                        uri: iconUrl,
                    }}
                    style={[styles.instrumentIcon, styles.instrumentIconBorder]}
                />
            ) : null}
            {iconUrl && iconUrl !== '' && iconUrl.indexOf(',') > 0 ? (
                <>
                    <Image
                        source={{
                            uri: iconUrl.substring(iconUrl.indexOf(',') + 1, iconUrl.length),
                        }}
                        style={[styles.instrumentIconCurrency2, styles.instrumentIconBorder]}
                    />

                    <Image
                        source={{
                            uri: iconUrl.substring(0, iconUrl.indexOf(',')),
                        }}
                        style={[styles.instrumentIconCurrency1, styles.instrumentIconBorder]}
                    />
                </>
            ) : null}
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        container: { height: scaleSize(30), justifyContent: 'center' },
        instrumentIconBorder: {
            borderRadius: scaleSize(15),
            borderWidth: scaleSize(1),
            borderColor: theme.iconBorder,
            backgroundColor: theme.iconBorder,
        },
        instrumentIcon: {
            height: scaleSize(30),
            width: scaleSize(30),
        },
        instrumentIconCurrency1: { position: 'absolute', height: scaleSize(30), width: scaleSize(30) },
        instrumentIconCurrency2: {
            position: 'absolute',
            left: scaleSize(18),
            height: scaleSize(30),
            width: scaleSize(30),
        },
    });
