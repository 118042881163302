import React from 'react';
import { NavigationContainer } from '@react-navigation/native';

import AuthStackNavigator from './AuthStackNavigator';
import RootStackNavigator from './RootStackNavigator';
import LoadingScreen from '~/components/screens/LoadingScreen';
import { navigationRef } from '../../navigation';
import { useAppSelector } from '~/hooks';
import { Platform, useWindowDimensions } from 'react-native';
import DesktopStackNavigator from './DesktopStackNavigator';
import { global } from '~/constants/global';
import { appSettingsSelector } from '~/state/selectors';
import { StatusBar } from 'expo-status-bar';
import { useInitialDataLoad } from '~/hooks/useInitialDataLoad';

export default function Navigation() {
    const { isLoading, isLoggedIn, ...props } = useInitialDataLoad();
    const dimensions = useWindowDimensions();
    const { colorScheme } = useAppSelector(appSettingsSelector);


    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <>
        <NavigationContainer ref={navigationRef} {...props}>
            {isLoggedIn ? (
                Platform.OS === 'web' && dimensions.width > global.DESKTOP_MIN_WIDTH ? (
                    <DesktopStackNavigator />
                ) : (
                    <RootStackNavigator />

                )
            ) : (
                <AuthStackNavigator />
            )}
        </NavigationContainer><StatusBar style={colorScheme === 'dark' ? 'light' : 'dark'} hidden={true}/>
        </>

    );
}
