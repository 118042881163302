import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { formatTime } from '~/lib/format';
import { scaleSize } from '~/constants/scale';
import { FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import { appSettingsSelector } from '~/state/selectors';
import { Message } from '~/api-services/CoreDataService/types';

type Props = {
    message: Message;
};

export default function NotificationsRow({ message }: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);

    return (
        <View
            style={[
                styles.positionGroupContainer,
                styles.positionGroupClose,
                colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
            ]}
            key={'notificationsContainer' + message.id}
        >
            <View style={styles.titleContainer}>
                <Text fontType="LATO_REGULAR" style={styles.notificationTitleText}>
                    {message.titleTranslated}
                </Text>
                {!message.read ? <View style={styles.readPoint} /> : null}
            </View>
            <View>
                <Text fontType="LATO_REGULAR" style={styles.notificationBodyText}>
                    {message.bodyTranslated.replace(/<\/?[^>]+(>|$)/g, '')}
                </Text>
            </View>
            <View style={styles.notificationTimeContainer}>
                <Text fontType="LATO_REGULAR" style={styles.notificationBodyText}>
                    {formatTime(message.timestamp)}
                </Text>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        readPoint: {
            backgroundColor: theme.switch_trackOn,
            width: scaleSize(10),
            height: scaleSize(10),
            borderRadius: scaleSize(5),
        },
        positionGroupClose: { borderRadius: scaleSize(5), marginTop: scaleSize(7) },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },
        positionGroupContainer: {
            borderWidth: 1,
            width: '100%',
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            backgroundColor: theme.backgroundSecondary,
            paddingHorizontal: scaleSize(10),
        },
        notificationTimeContainer: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingVertical: scaleSize(5),
        },
        notificationBodyText: { fontSize: FONT_SIZE_XS, color: theme.text_pendingOrder },
        notificationTitleText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        titleContainer: {
            paddingVertical: scaleSize(5),
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    });
