import React, { useState } from 'react';
import { StyleSheet, Platform, View, Linking } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import FromToSelector from '../Result/FromToSelector';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { Button } from '~/components/core';
import config from '~/config';
import { appSettingsSelector, userAuthDataSelector } from '~/state/selectors';

const now = new Date();
const startPeriod = new Date(now.getTime() - 1 * 24 * 3600 * 1000);
startPeriod.setHours(0);
startPeriod.setMinutes(0);
startPeriod.setSeconds(0);
startPeriod.setMilliseconds(0);

export default function ClientReport() {
    const [from, setFrom] = useState(startPeriod);
    const [to, setTo] = useState(now.getTime());
    const styles = useStyles(stylesGenerator);
    const { sessionId } = useAppSelector(userAuthDataSelector);
    const { language } = useAppSelector(appSettingsSelector);



    function downloadReport() {

        const startPeriod = new Date(from.getTime());
        startPeriod.setHours(0);
        startPeriod.setMinutes(0);
        startPeriod.setSeconds(0);
        startPeriod.setMilliseconds(0);

        var url = config.PLATFORM_BASE_URL + 'reports/dailyReport?sessionId=' + sessionId + '&from=' + startPeriod.getTime() + '&language=' + language;

        Linking.openURL(url);

    }

    return (
        <>
            <View style={styles.ordersContainer}>
                <Button title="Download" onPress={() => downloadReport()} disabled={from === null} />
            </View>
            <FromToSelector setFromButton={setFrom} setToButton={setTo} prevPeriod={startPeriod} hideTo={true} />
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        ordersContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
        },
        button: { width: '100%' },
    });
