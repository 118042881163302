import standard from './standard';
import standardFXMERIDIAN from './standardFXMERIDIAN';


export const themes = {
    standard, standardFXMERIDIAN
};

export type Themes = keyof typeof themes;

export * from './type';
