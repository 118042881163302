import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Image, Platform, Pressable } from 'react-native';
import { SvgUri } from 'react-native-svg';
import { PaymentConfig } from '~/api-services/PaymentConverterService/types';
import { Text } from '~/components/core';
import config from '~/config';
import { FONT_SIZE_S } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { useAddFunds } from '~/hooks/useAddFunds';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import { Theme } from '~/theme';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';
import ResolveCountryService from '~/api-services/ResolveCountryService';
import { isEUCountry } from '~/lib/utils';
type Props = {
    setActiveRightTab?: Function;
    currentAmount?: string;
    setProvider?: Function;
    setMethod?: Function;
};

export default function AddFundsCategory({ setActiveRightTab, currentAmount, setProvider, setMethod }: Props) {
    
    const styles = useStyles(stylesGenerator);
    const route = useRoute();
    const amount = currentAmount ? currentAmount : (route.params as any).amount;
    const { configData } = useAddFunds(amount!='' ? Number(amount) : 0);
    const [enableSkrillLogo, setEnableSkrillLogo] = useState(false);

    useEffect(() => {
        (async () => {
                const { status, data } = await ResolveCountryService.resolveCountry();
                if (status === 200 && data.status) {
                    let clientCountry = data.returnData;
                    if (!isEUCountry(clientCountry) && clientCountry !== 'GB') {
                        setEnableSkrillLogo(true);
                    }
                }
            
        })();
    }, []);

    function openPaymentCategory(cProvider: string, cMethod: string) {
        if (setProvider) {
            setProvider(cProvider);
        }
        if (setMethod) {
            setMethod(cMethod);
        }
        if (setActiveRightTab) {
            setActiveRightTab('add-money-card');
        }
    }

    return (
        <>
            <DesktopScreenHeader goToScreenName="add-money" title="add-money" setActiveRightTab={setActiveRightTab} />
            <View style={styles.container}>
                {configData ? (
                    <View style={styles.content}>
                        <View style={styles.addFundsTitleContainer}>
                            <Text fontType="LATO_BOLD" style={styles.addFundsTitleText}>
                                {'select-prefered-payment-method'}
                            </Text>
                        </View>
                        {configData.card && configData.card.length > 0
                            ? configData.card.map((paymentConfig: PaymentConfig, key) => (
                                  <Pressable
                                      key={key}
                                      style={styles.row}
                                      onPress={() => {
                                          isDesktop() && setActiveRightTab
                                              ? openPaymentCategory(paymentConfig.provider, paymentConfig.method)
                                              : linkTo(
                                                    paths.addFundsCard
                                                        .replace(':provider', paymentConfig.provider)
                                                        .replace(':method', paymentConfig.method)
                                                        .replace(':amount', amount)
                                                );
                                      }}
                                  >
                                      <View style={styles.paymentCategoryBox}>
                                          <Image
                                              source={
                                                paymentConfig.provider === 'PAYTIKO' ? 
                                                ( enableSkrillLogo ? 
                                                    require('../../../../assets/files/mobileplatform/Paytiko/crypto_skrill_visa.gif') : 
                                                    require('../../../../assets/files/mobileplatform/Paytiko/visa_master_crypto.gif'))
                                                : require('../../../../assets/files/mobileplatform/images/payments/visa_mastercard.gif')
                                            }
                                              style={styles.paymentCategory}
                                          />
                                      </View>
                                      <Text fontType="LATO_REGULAR" style={styles.descriptionText}>
                                          {'credit-and-debit-cards-' + paymentConfig.provider}
                                      </Text>
                                  </Pressable>
                              ))
                            : null}
                        {configData.alternative && configData.alternative.length > 0 ? (
                            <Pressable
                                style={styles.row}
                                onPress={() => {
                                    isDesktop() && setActiveRightTab
                                        ? setActiveRightTab('add-money-alternative')
                                        : linkTo(paths.addFundsAlternative.replace(':amount', amount));
                                }}
                            >
                                <View style={styles.paymentCategoryBox}>
                                    {Platform.OS === 'web' ? (
                                        <Image
                                            source={require('../../../../assets/files/mobileplatform/images/payments/Only_alternativepayment.svg')}
                                            style={styles.paymentCategory}
                                        />
                                    ) : (
                                        <SvgUri
                                            uri={
                                                'https://' +
                                                config.EXTERNAL_SOURCES_URL +
                                                'mobileplatform/images/payments/Only_alternativepayment.svg'
                                            }
                                            style={styles.paymentCategory}
                                        />
                                    )}
                                </View>
                                <Text fontType="LATO_REGULAR" style={styles.descriptionText}>
                                    {'alternative-payment-method'}
                                </Text>
                            </Pressable>
                        ) : null}
                        <Pressable
                            style={styles.row}
                            onPress={() => {
                                isDesktop() && setActiveRightTab
                                    ? setActiveRightTab('add-money-bank')
                                    : linkTo(paths.addFundsBank.replace(':amount', amount));
                            }}
                        >
                            <View style={styles.paymentCategoryBox}>
                                {Platform.OS === 'web' ? (
                                    <Image
                                        source={require('../../../../assets/files/mobileplatform/images/payments/Only_WireTransfer.svg')}
                                        style={styles.paymentCategory}
                                    />
                                ) : (
                                    <SvgUri
                                        uri={
                                            'https://' +
                                            config.EXTERNAL_SOURCES_URL +
                                            'mobileplatform/images/payments/Only_WireTransfer.svg'
                                        }
                                        style={styles.paymentCategory}
                                    />
                                )}
                            </View>
                            <Text fontType="LATO_REGULAR" style={styles.descriptionText}>
                                {'secure-wire-transfer'}
                            </Text>
                        </Pressable>
                    </View>
                ) : null}
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        paymentCategoryBox: {
            justifyContent: 'center',
            alignItems: 'center',
            width: scaleSize(60),
            height: scaleSize(30),
            backgroundColor: theme.white,
            borderColor: theme.border_2,
            borderRadius: scaleSize(5),
            borderWidth: scaleSize(1),
            marginRight: 10,
        },
        descriptionText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        addFundsTitleContainer: {
            paddingVertical: scaleSize(40),
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
            marginHorizontal: scaleSize(10),
        },
        addFundsTitleText: { fontSize: FONT_SIZE_S, color: theme.greenLineDesktop },
        content: { width: '100%' },
        paymentCategory: {
            width: scaleSize(50),
            height: scaleSize(18),
        },
        row: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: scaleSize(10),
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
            marginHorizontal: scaleSize(10),
        },
        container: {
            alignItems: 'center',
            flex: 1,
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
            paddingTop: scaleSize(7),
            paddingHorizontal: scaleSize(17),
        },
    });
