import dateFormat from "dateformat";


export const formatCurrency = (num: number | undefined | null, decimal: number, currency: string = ''): string => {
    if (num === undefined || num === null) {
        return '';
    }
    let formattedNumber = num.toFixed(decimal);
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(formattedNumber))
        formattedNumber = formattedNumber.replace(pattern, "$1,$2")
    return currency === 'USD'
        ? `$ ${formattedNumber}`
        : currency === 'EUR'
        ? `€ ${formattedNumber}`
        : currency === 'GBP'
        ? `£ ${formattedNumber}`
        : currency === 'JPY'
        ? `¥ ${formattedNumber}`
        : `${formattedNumber} ${currency}`;
};

export const formatCurrencyWithFormatetdNumber = (num: string | undefined | null, currency: string = ''): string => {
    if (num === undefined || num === null) {
        return '';
    }
    return currency === 'USD'
        ? `$ ${num}`
        : currency === 'EUR'
        ? `€ ${num}`
        : currency === 'GBP'
        ? `£ ${num}`
        : currency === 'JPY'
        ? `¥ ${num}`
        : `${num} ${currency}`;
};

export const getCurrencySign = (currency: string): string | null => {
    return currency === 'USD'
        ? '$'
        : currency === 'EUR'
        ? '€'
        : currency === 'GBP'
        ? '£'
        : currency === 'JPY'
        ? '¥'
        : currency;
};

export const formatNumber = (num: number | undefined | null, decimal: number): string => {
    if (num === undefined || num === null) {
        return '';
    }
    var multiplier = Math.pow(10, decimal);
    num = Math.round(num * multiplier) / multiplier;
    return num.toFixed(decimal).toLocaleString();
};

export const formatStringToNumber = (num: string | undefined | null, decimal: number): string => {
    if (num === undefined || num === null) {
        return '';
    }
    let numNumber = Number(num);
    var multiplier = Math.pow(10, decimal);
    numNumber = Math.round(numNumber * multiplier) / multiplier;
    return numNumber.toFixed(decimal).toLocaleString();
};

export const formatNumberWithPercent = (num: number | undefined | null, decimal: number, sign: boolean): string => {
    if (num === undefined || num === null) {
        return '';
    }
    return num > 0
        ? (sign ? '+' : '') + num.toFixed(decimal).toLocaleString() + ' %'
        : num.toFixed(decimal).toLocaleString() + ' %';
};

export const formatDate = (timestamp: number): string => {
    if (!timestamp) {
        return '';
    }
    let date = new Date(timestamp);
    return dateFormat(date, 'dd.mm.yyyy / HH:MM:ss');
};

export const formatDateShort = (timestamp: number): string => {
    if (!timestamp) {
        return '';
    }
    let date = new Date(timestamp);
    return dateFormat(date, 'dd.mm.yyyy');
};

export const formatTime = (timestamp: number): string => {
    if (!timestamp) {
        return '';
    }
    let date = new Date(timestamp);
    return dateFormat(date, 'HH:MM:ss');
};

export const formatDateToday = (timestamp: number): string => {
    if (!timestamp) {
        return '';
    }
    let now: number = new Date().getTime();
    let today = dateFormat(now, 'dd.mm.yyyy');
    let yesterday = dateFormat(now - 11 * 3600 * 1000, 'dd.mm.yyyy');
    let date = new Date(timestamp);
    let formatedDate = dateFormat(date, 'dd.mm.yyyy');
    if (today === formatedDate) {
        return 'TODAY';
    }
    if (yesterday === formatedDate) {
        return 'YESTERDAY';
    }
    return formatedDate;
};
