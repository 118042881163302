import axios from '../baseClient';

import {
    GetCandlesBatchResponse,
    GetNCandlesResponse,
    PriceChanges24hResponse,
    PriceChangesList24hRequest,
    PriceRangeRequest,
    PriceRangeResponse,
} from './types';

class ChartDataService {
    private static domain = '/charts/rest';

    getCandles(symbols: string[]) {
        return axios.post<GetCandlesBatchResponse>(`${ChartDataService.domain}/getNCandlesBatch`, {
            period: 15,
            size: 32,
            demoWeekendTrader: true,
            symbols: symbols,
        });
    }

    getNCandles(symbol: string, period: number, size: number) {
        return axios.post<GetNCandlesResponse>(`${ChartDataService.domain}/getNCandles`, {
            askPrice: false,
            demoWeekendTrader: false,
            period: period,
            symbol: symbol,
            size: size,
        });
    }

    getNCandlesBatchOpen(symbols: string[]) {
        return axios.post<GetCandlesBatchResponse>(`${ChartDataService.domain}/getNCandlesBatchOpen`, {
            period: 15,
            size: 32,
            demoWeekendTrader: true,
            symbols: symbols,
        });
    }

    getPriceChangesList24h(priceChangesList24hRequest: PriceChangesList24hRequest) {
        return axios.post<PriceChanges24hResponse>(
            `${ChartDataService.domain}/getPriceChangesList24h`,
            priceChangesList24hRequest
        );
    }

    getPriceRange(priceRangeRequest: PriceRangeRequest) {
        return axios.post<PriceRangeResponse>(`${ChartDataService.domain}/getPriceRange`, priceRangeRequest);
    }
}

export default new ChartDataService();
