import React, { useState, useEffect } from 'react';
import { View, StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';

import { isDesktop, scaleSize } from '~/constants/scale';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useStyles } from '~/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Theme } from '~/theme';
import { CustomerDataResponse } from '~/api-services/VerificationService/types';
import { Button, ErrorMessage, Switch, Text, TextInput } from '~/components/core';
import { FONT_SIZE_M, FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import VerificationService from '~/api-services/VerificationService';
import i18n from '~/i18n';
import { Picker } from '@react-native-picker/picker';
import { countries as globalCountries } from '~/constants/global';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';


type Props = {
    customerData: CustomerDataResponse;
    goToNextStep: Function;
    goToPrevStep: Function;
    setLoading: Function;
};

export default function FinancialInformation({ customerData, goToNextStep, goToPrevStep, setLoading }: Props) {
    const styles = useStyles(stylesGenerator);
    const [taxCountry, setTaxCountry] = useState('');
    const [averageAnnualIncome, setAverageAnnualIncome] = useState('');
    const [breakdownWealth, setBreakdownWealth] = useState('');
    const [dividendsInterestsFromOwnCompany, setDividendsInterestsFromOwnCompany] = useState(false);
    const [dividendsInterestsFromOwnCompanyIncome, setDividendsInterestsFromOwnCompanyIncome] = useState('');
    const [dividendsInterestsFromStocksBonds, setDividendsInterestsFromStocksBonds] = useState(false);
    const [dividendsInterestsFromStocksBondsIncome, setDividendsInterestsFromStocksBondsIncome] = useState('');
    const [intendToDepositNext12Months, setIntendToDepositNext12Months] = useState('');
    const [mainSourceIncome, setMainSourceIncome] = useState('');
    const [otherTax, setOtherTax] = useState(false);
    const [otherTaxCountry, setOtherTaxCountry] = useState('');
    const [otherTaxNumber, setOtherTaxNumber] = useState('');
    const [others, setOthers] = useState(false);
    const [othersDescription, setOthersDescription] = useState('');
    const [othersIncome, setOthersIncome] = useState('');
    const [royalties, setRoyalties] = useState(false);
    const [royaltiesIncome, setRoyaltiesIncome] = useState('');
    const [secondaryIncomeAbove10000, setSecondaryIncomeAbove10000] = useState(false);
    const [taxNumber, setTaxNumber] = useState('');
    const [usReportablePerson, setUsReportablePerson] = useState(false);
    const [usTaxId, setUsTaxId] = useState('');
    const [resident, setResident] = useState(false);

    const [generalError, setGeneralError] = useState('');
    const [showTaxCountryValidationMessage, setShowTaxCountryValidationMessage] = useState(false);
    const [showTaxNumberValidationMessage, setShowTaxNumberValidationMessage] = useState(false);
    const [showUsTaxIdValidationMessage, setShowUsTaxIdValidationMessage] = useState(false);
    const [showIntendToDepositNext12MonthsValidationMessage, setShowIntendToDepositNext12MonthsValidationMessage] = useState(false);
    const [showOtherTaxCountryValidationMessage, setShowOtherTaxCountryValidationMessage] = useState(false);
    const [showOtherTaxNumberValidationMessage, setShowOtherTaxNumberValidationMessage] = useState(false);
    const [showBreakdownWealthValidationMessage, setShowBreakdownWealthValidationMessage] = useState(false);
    const [showMainSourceIncomeValidationMessage, setShowMainSourceIncomeValidationMessage] = useState(false);
    const [showAverageAnnualIncomeValidationMessage, setShowAverageAnnualIncomeValidationMessage] = useState(false);

    const [showOthersDescriptionValidationMessage, setShowOthersDescriptionValidationMessage] = useState(false);
    const [showOthersIncomeValidationMessage, setShowOthersIncomeValidationMessage] = useState(false);
    const [showDividendsInterestsFromStocksBondsIncomeValidationMessage, setShowDividendsInterestsFromStocksBondsIncomeValidationMessage] = useState(false);
    const [showRoyaltiesIncomeValidationMessage, setShowRoyaltiesIncomeValidationMessage] = useState(false);
    const [showDividendsInterestsFromOwnCompanyIncomeValidationMessage, setShowDividendsInterestsFromOwnCompanyIncomeValidationMessage] = useState(false);


    useEffect(() => {
        if (customerData) {
            if (customerData.taxCountry) {
                setTaxCountry(customerData.taxCountry);
            }
            if (customerData.taxNumber) {
                setTaxNumber(customerData.taxNumber);
            }
            if (customerData.averageAnnualIncome) {
                setAverageAnnualIncome(customerData.averageAnnualIncome);
            }
            if (customerData.breakdownWealth) {
                setBreakdownWealth(customerData.breakdownWealth);
            }
            if (customerData.dividendsInterestsFromOwnCompany) {
                setDividendsInterestsFromOwnCompany(customerData.dividendsInterestsFromOwnCompany);
            }
            if (customerData.dividendsInterestsFromOwnCompanyIncome) {
                setDividendsInterestsFromOwnCompanyIncome(customerData.dividendsInterestsFromOwnCompanyIncome);
            }
            if (customerData.dividendsInterestsFromStocksBonds) {
                setDividendsInterestsFromStocksBonds(customerData.dividendsInterestsFromStocksBonds);
            }
            if (customerData.dividendsInterestsFromStocksBondsIncome) {
                setDividendsInterestsFromStocksBondsIncome(customerData.dividendsInterestsFromStocksBondsIncome);
            }
            if (customerData.intendToDepositNext12Months) {
                setIntendToDepositNext12Months(customerData.intendToDepositNext12Months);
            }
            if (customerData.mainSourceIncome) {
                setMainSourceIncome(customerData.mainSourceIncome);
            }
            if (customerData.otherTax) {
                setOtherTax(customerData.otherTax);
            }
            if (customerData.otherTaxCountry && customerData.otherTaxCountry !== '') {
                setOtherTaxCountry(customerData.otherTaxCountry);
                setResident(true);
            }
            if (customerData.otherTaxNumber) {
                setOtherTaxNumber(customerData.otherTaxNumber);
            }
            if (customerData.otherTaxNumber) {
                setOtherTaxNumber(customerData.otherTaxNumber);
            }
            if (customerData.others) {
                setOthers(customerData.others);
            }
            if (customerData.othersDescription) {
                setOthersDescription(customerData.othersDescription);
            }
            if (customerData.othersIncome) {
                setOthersIncome(customerData.othersIncome);
            }
            if (customerData.royalties) {
                setRoyalties(customerData.royalties);
            }
            if (customerData.royaltiesIncome) {
                setRoyaltiesIncome(customerData.royaltiesIncome);
            }
            if (customerData.secondaryIncomeAbove10000) {
                setSecondaryIncomeAbove10000(customerData.secondaryIncomeAbove10000);
            }
            if (customerData.usReportablePerson) {
                setUsReportablePerson(customerData.usReportablePerson);
                if (customerData.usTaxId) {
                    setUsTaxId(customerData.usTaxId);
                }
            }

        }
    }, [customerData]);

    function validateForm(): boolean {
        let formValid: boolean = true;
        setGeneralError('');
        setShowTaxCountryValidationMessage(false);
        setShowTaxNumberValidationMessage(false);
        setShowUsTaxIdValidationMessage(false);
        setShowIntendToDepositNext12MonthsValidationMessage(false);
        setShowOtherTaxCountryValidationMessage(false);
        setShowOtherTaxNumberValidationMessage(false);
        setShowBreakdownWealthValidationMessage(false);
        setShowMainSourceIncomeValidationMessage(false);
        setShowAverageAnnualIncomeValidationMessage(false);
        setShowOthersDescriptionValidationMessage(false);
        setShowOthersIncomeValidationMessage(false);
        setShowDividendsInterestsFromStocksBondsIncomeValidationMessage(false);
        setShowRoyaltiesIncomeValidationMessage(false);
        setShowDividendsInterestsFromOwnCompanyIncomeValidationMessage(false);


        if (taxCountry === '') {
            formValid = false;
            setShowTaxCountryValidationMessage(true);
        }
        if (taxNumber === '') {
            formValid = false;
            setShowTaxNumberValidationMessage(true);
        }
        if (usReportablePerson && usTaxId === '') {
            formValid = false;
            setShowUsTaxIdValidationMessage(true);
        }
        if (intendToDepositNext12Months === '') {
            formValid = false;
            setShowIntendToDepositNext12MonthsValidationMessage(true);
        }
        if (resident && otherTaxCountry === '') {
            formValid = false;
            setShowOtherTaxCountryValidationMessage(true);
        }
        if (resident && otherTaxNumber === '') {
            formValid = false;
            setShowOtherTaxNumberValidationMessage(true);
        }
        if (breakdownWealth === '') {
            formValid = false;
            setShowBreakdownWealthValidationMessage(true);
        }
        if (mainSourceIncome === '') {
            formValid = false;
            setShowMainSourceIncomeValidationMessage(true);
        }
        if (averageAnnualIncome === '') {
            formValid = false;
            setShowAverageAnnualIncomeValidationMessage(true);
        }
        if (secondaryIncomeAbove10000) {
            if (dividendsInterestsFromOwnCompany && dividendsInterestsFromOwnCompanyIncome === '') {
                formValid = false;
                setShowDividendsInterestsFromOwnCompanyIncomeValidationMessage(true);
            }
            if (royalties && royaltiesIncome === '') {
                formValid = false;
                setShowRoyaltiesIncomeValidationMessage(true);
            }
            if (dividendsInterestsFromStocksBonds && dividendsInterestsFromStocksBondsIncome === '') {
                formValid = false;
                setShowDividendsInterestsFromStocksBondsIncomeValidationMessage(true);
            }
            if (others && othersDescription === '') {
                formValid = false;
                setShowOthersDescriptionValidationMessage(true);
            }
            if (others && othersIncome === '') {
                formValid = false;
                setShowOthersIncomeValidationMessage(true);
            }
        }
        return formValid;
    }

    async function setFinancialInformation() {
        if (validateForm()) {
            setLoading(true);
            const { status, data } = await VerificationService.setFinancialInformation(
                {
                    averageAnnualIncome: averageAnnualIncome,
                    breakdownWealth: breakdownWealth,
                    dividendsInterestsFromOwnCompany: dividendsInterestsFromOwnCompany,
                    dividendsInterestsFromOwnCompanyIncome: dividendsInterestsFromOwnCompanyIncome,
                    dividendsInterestsFromStocksBonds: dividendsInterestsFromStocksBonds,
                    dividendsInterestsFromStocksBondsIncome: dividendsInterestsFromStocksBondsIncome,
                    intendToDepositNext12Months: intendToDepositNext12Months,
                    mainSourceIncome: mainSourceIncome,
                    otherTax: otherTax,
                    otherTaxCountry: resident ? otherTaxCountry : '',
                    otherTaxNumber: otherTaxNumber,
                    others: others,
                    othersDescription: othersDescription,
                    othersIncome: othersIncome,
                    royalties: royalties,
                    royaltiesIncome: royaltiesIncome,
                    secondaryIncomeAbove10000: secondaryIncomeAbove10000,
                    taxCountry: taxCountry,
                    taxNumber: taxNumber,
                    usReportablePerson: usReportablePerson,
                    usTaxId: usReportablePerson ? usTaxId : ''
                }
            );
            if (status === 200) {
                if (data.status === true) {
                    goToNextStep();
                }
                else {
                    if (data.errorCode) {
                        if (data.errorCode === 'ArgumentRequiredException') {
                            if (data.errorContext && data.errorContext.field) {
                                if (data.errorContext.field) {
                                    setGeneralError(i18n.t('please-fill-all-required-fields') + ': ' + data.errorContext.field);
                                }
                                else {
                                    setGeneralError(data.errorCode);
                                }
                            }
                            else {
                                setGeneralError(data.errorCode);
                            }
                        }
                        else {
                            setGeneralError(data.errorCode);
                        }
                    }
                }
            }
            else {
                setGeneralError('Error');
            }
            setLoading(false);
        }
    }


    return (
        <View style={styles.safeAreaStyle}>
            <View style={styles.vcTitleContainer}>
                <Text fontType="LATO_BOLD" style={styles.vcTitleText}>
                    financial-information
                </Text>
            </View>
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                tax-country
            </Text>
            <Picker
                style={styles.pickerStyle}
                itemStyle={styles.pickerRow}
                selectedValue={taxCountry}
                onValueChange={(itemValue) => setTaxCountry(itemValue)}
            >
                <Picker.Item
                    label={i18n.t('please-select-country')}
                    value={''}
                />
                {globalCountries.map((countryMap, index) => (
                    <Picker.Item
                        label={countryMap.label}
                        value={countryMap.value}
                        key={'CountryPicker' + index}
                    />
                ))}
            </Picker>
            <ErrorMessage
                text={'please-select-country'}
                visible={taxCountry === '' && showTaxCountryValidationMessage}
            />
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                tax-number
            </Text>
            <TextInput
                containerStyle={styles.inputContainer}
                placeholder='enter-tax-identification-number'
                style={styles.textInputStyle}
                autoCapitalize="none"
                autoFocus={false}
                value={taxNumber}
                onChangeText={(text) => setTaxNumber(text)}
                onBlur={() => setShowTaxNumberValidationMessage(true)}
                returnKeyType='next' />
            <ErrorMessage
                text={'enter-tax-identification-number'}
                visible={taxNumber === '' && showTaxNumberValidationMessage} />
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                us-reportable-person
            </Text>
            <View style={styles.yesNoContainer}>
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {'no'}
                </Text>
                <Switch
                    value={usReportablePerson}
                    onValueChange={() => {
                        setUsReportablePerson(!usReportablePerson);
                    }}
                />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {'yes'}
                </Text>
            </View>
            {usReportablePerson ?
                <>
                    <TextInput
                        containerStyle={styles.inputContainer}
                        style={styles.textInputStyle}
                        placeholder="us-tax-id"
                        autoCapitalize="none"
                        autoFocus={false}
                        value={usTaxId}
                        onChangeText={(text) => setUsTaxId(text)}
                        onBlur={() => setShowUsTaxIdValidationMessage(true)}
                        returnKeyType='next' />
                    <ErrorMessage
                        text={'please-enter-us-tax-id'}
                        visible={usTaxId === '' && showUsTaxIdValidationMessage} />
                </>
                : null}
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                intend-to-deposit-next-12-months-text
            </Text>
            <TextInput
                containerStyle={styles.inputContainer}
                style={styles.textInputStyle}
                autoCapitalize="none"
                autoFocus={false}
                value={intendToDepositNext12Months}
                onChangeText={(text) => setIntendToDepositNext12Months(text)}
                onBlur={() => setShowIntendToDepositNext12MonthsValidationMessage(true)}
                returnKeyType='next' />
            <ErrorMessage
                text={'please-enter-an-answer'}
                visible={intendToDepositNext12Months === '' && showIntendToDepositNext12MonthsValidationMessage} />
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                other-tax-country-text
            </Text>
            <View style={styles.yesNoContainer}>
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {'no'}
                </Text>
                <Switch
                    value={resident}
                    onValueChange={() => {
                        setResident(!resident);
                    }}
                />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {'yes'}
                </Text>
            </View>
            {resident ?
                <>
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        other-tax-country
                    </Text>
                    <Picker
                        style={styles.pickerStyle}
                        itemStyle={styles.pickerRow}
                        selectedValue={otherTaxCountry}
                        onValueChange={(itemValue) => setOtherTaxCountry(itemValue)}
                    >
                        <Picker.Item
                            label={i18n.t('please-select-country')}
                            value={''} />
                        {globalCountries.map((countryMap, index) => (
                            <Picker.Item
                                label={countryMap.label}
                                value={countryMap.value}
                                key={'CountryPicker' + index} />
                        ))}
                    </Picker>
                    <ErrorMessage
                        text={'please-select-country'}
                        visible={otherTaxCountry === '' && showOtherTaxCountryValidationMessage} />

                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        tax-identification-number
                    </Text>
                    <TextInput
                        containerStyle={styles.inputContainer}
                        placeholder='enter-tax-identification-number'
                        style={styles.textInputStyle}
                        autoCapitalize="none"
                        autoFocus={false}
                        value={otherTaxNumber}
                        onChangeText={(text) => setOtherTaxNumber(text)}
                        onBlur={() => setShowOtherTaxNumberValidationMessage(true)}
                        returnKeyType='next' />
                    <ErrorMessage
                        text={'enter-tax-identification-number'}
                        visible={otherTaxNumber === '' && showOtherTaxNumberValidationMessage} />

                </>
                : null}
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                breakdown-wealth-text
            </Text>
            <Picker
                style={styles.pickerStyle}
                itemStyle={styles.pickerRow}
                selectedValue={breakdownWealth}
                onValueChange={(itemValue) => setBreakdownWealth(itemValue)}
            >
                <Picker.Item
                    label={i18n.t('please-select-dots')}
                    value={''} />
                <Picker.Item
                    label={i18n.t('cash-assets')}
                    value={'Cash assets'} />
                <Picker.Item
                    label={i18n.t('securities-etc')}
                    value={'Securities (equities, bonds, investment fund units, etc.)'} />
                <Picker.Item
                    label={i18n.t('others')}
                    value={'Others'} />
                <Picker.Item
                    label={i18n.t('real-estates')}
                    value={'Real estates'} />
                <Picker.Item
                    label={i18n.t('industrial-assets')}
                    value={'Industrial assets(e.g. company)'} />

            </Picker>
            <ErrorMessage
                text={'please-select-breakdown-wealth'}
                visible={breakdownWealth === '' && showBreakdownWealthValidationMessage} />
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                source-income
            </Text>
            <Picker
                style={styles.pickerStyle}
                itemStyle={styles.pickerRow}
                selectedValue={mainSourceIncome}
                onValueChange={(itemValue) => setMainSourceIncome(itemValue)}
            >
                <Picker.Item
                    label={i18n.t('please-select-dots')}
                    value={''} />
                <Picker.Item
                    label={i18n.t('employment')}
                    value={'Employment'} />
                <Picker.Item
                    label={i18n.t('business-activities')}
                    value={'Business Activities (self-employed)'} />
                <Picker.Item
                    label={i18n.t('saving')}
                    value={'Saving'} />
                <Picker.Item
                    label={i18n.t('partner-parent-family')}
                    value={'Partner/Parent/Family'} />
                <Picker.Item
                    label={i18n.t('benefits-borrowing')}
                    value={'Benefits/Borrowing'} />
                <Picker.Item
                    label={i18n.t('private-pension')}
                    value={'Private Pension'} />
                <Picker.Item
                    label={i18n.t('investments-vc')}
                    value={'Investments'} />
                <Picker.Item
                    label={i18n.t('other')}
                    value={'Other'} />
            </Picker>
            <ErrorMessage
                text={'enter-source-income'}
                visible={mainSourceIncome === '' && showMainSourceIncomeValidationMessage} />

            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                anual-income
            </Text>
            <Picker
                style={styles.pickerStyle}
                itemStyle={styles.pickerRow}
                selectedValue={averageAnnualIncome}
                onValueChange={(itemValue) => setAverageAnnualIncome(itemValue)}
            >
                <Picker.Item
                    label={i18n.t('please-select-dots')}
                    value={''} />
                <Picker.Item
                    label={i18n.t('under') + ' $25,000'}
                    value={'Under $25,000'} />
                <Picker.Item
                    label={i18n.t('$25,001-$50,000')}
                    value={'$25,001-$50,000'} />
                <Picker.Item
                    label={i18n.t('$50,001-$100,000')}
                    value={'$50,001-$100,000'} />
                <Picker.Item
                    label={i18n.t('$100,001-$250,000')}
                    value={'$100,001-$250,000'} />
                <Picker.Item
                    label={i18n.t('over') + ' $250,000'}
                    value={'Over $250,000'} />

            </Picker>
            <ErrorMessage
                text={'enter-source-income'}
                visible={averageAnnualIncome === '' && showAverageAnnualIncomeValidationMessage} />

            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                secondary-income-above-10000
            </Text>
            <View style={styles.yesNoContainer}>
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {'no'}
                </Text>
                <Switch
                    value={secondaryIncomeAbove10000}
                    onValueChange={() => {
                        setSecondaryIncomeAbove10000(!secondaryIncomeAbove10000);
                    }}
                />
                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                    {'yes'}
                </Text>
            </View>
            {secondaryIncomeAbove10000 ?
                <>
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        secondary-income-applicable
                    </Text>
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        dividents-interests-own-company
                    </Text>
                    <View style={styles.yesNoContainer}>
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {'no'}
                        </Text>
                        <Switch
                            value={dividendsInterestsFromOwnCompany}
                            onValueChange={() => {
                                setDividendsInterestsFromOwnCompany(!dividendsInterestsFromOwnCompany);
                            }}
                        />
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {'yes'}
                        </Text>
                    </View>
                    {dividendsInterestsFromOwnCompany ?
                        <><Picker
                            style={styles.pickerStyle}
                            itemStyle={styles.pickerRow}
                            selectedValue={dividendsInterestsFromOwnCompanyIncome}
                            onValueChange={(itemValue) => {
                                setDividendsInterestsFromOwnCompanyIncome(itemValue);
                                setShowDividendsInterestsFromOwnCompanyIncomeValidationMessage(true);
                            }}
                        >
                            <Picker.Item
                                label={i18n.t('please-specify-the-amount-dots')}
                                value={''} />
                            <Picker.Item
                                label={i18n.t('less-than') + ' 15 000 EUR'}
                                value={'Less than 15 000 EUR'} />
                            <Picker.Item
                                label={i18n.t('15 000 - 50 000 EUR')}
                                value={'15 000 - 50 000 EUR'} />
                            <Picker.Item
                                label={i18n.t('50 001 - 200 000 EUR')}
                                value={'50 001 - 200 000 EUR'} />
                            <Picker.Item
                                label={i18n.t('200 001 - 500 000 EUR')}
                                value={'200 001 - 500 000 EUR'} />
                            <Picker.Item
                                label={i18n.t('more-than') + ' 500 001 EUR'}
                                value={'More than 500 001 EUR'} />

                        </Picker>
                            <ErrorMessage
                                text={'please-specify-the-amount-dots'}
                                visible={dividendsInterestsFromOwnCompanyIncome === '' && showDividendsInterestsFromOwnCompanyIncomeValidationMessage} /></>
                        : null
                    }
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        royalties
                    </Text>
                    <View style={styles.yesNoContainer}>
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {'no'}
                        </Text>
                        <Switch
                            value={royalties}
                            onValueChange={() => {
                                setRoyalties(!royalties);
                            }}
                        />
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {'yes'}
                        </Text>
                    </View>
                    {royalties ?
                        <>
                            <Picker
                                style={styles.pickerStyle}
                                itemStyle={styles.pickerRow}
                                selectedValue={royaltiesIncome}
                                onValueChange={(itemValue) => {
                                    setRoyaltiesIncome(itemValue);
                                    setShowRoyaltiesIncomeValidationMessage(true);
                                }}
                            >
                                <Picker.Item
                                    label={i18n.t('please-specify-the-amount-dots')}
                                    value={''} />
                                <Picker.Item
                                    label={i18n.t('less-than') + ' 15 000 EUR'}
                                    value={'Less than 15 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('15 000 - 50 000 EUR')}
                                    value={'15 000 - 50 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('50 001 - 200 000 EUR')}
                                    value={'50 001 - 200 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('200 001 - 500 000 EUR')}
                                    value={'200 001 - 500 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('more-than') + ' 500 001 EUR'}
                                    value={'More than 500 001 EUR'} />

                            </Picker>
                            <ErrorMessage
                                text={'please-specify-the-amount-dots'}
                                visible={royaltiesIncome === '' && showRoyaltiesIncomeValidationMessage} />
                        </>
                        : null
                    }
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        dividents-interests-stocks-bonds
                    </Text>
                    <View style={styles.yesNoContainer}>
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {'no'}
                        </Text>
                        <Switch
                            value={dividendsInterestsFromStocksBonds}
                            onValueChange={() => {
                                setDividendsInterestsFromStocksBonds(!dividendsInterestsFromStocksBonds);
                            }}
                        />
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {'yes'}
                        </Text>
                    </View>
                    {dividendsInterestsFromStocksBonds ?
                        <>
                            <Picker
                                style={styles.pickerStyle}
                                itemStyle={styles.pickerRow}
                                selectedValue={dividendsInterestsFromStocksBondsIncome}
                                onValueChange={(itemValue) => {
                                    setDividendsInterestsFromStocksBondsIncome(itemValue);
                                    setShowDividendsInterestsFromStocksBondsIncomeValidationMessage(true);
                                }}
                            >
                                <Picker.Item
                                    label={i18n.t('please-specify-the-amount-dots')}
                                    value={''} />
                                <Picker.Item
                                    label={i18n.t('less-than') + ' 15 000 EUR'}
                                    value={'Less than 15 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('15 000 - 50 000 EUR')}
                                    value={'15 000 - 50 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('50 001 - 200 000 EUR')}
                                    value={'50 001 - 200 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('200 001 - 500 000 EUR')}
                                    value={'200 001 - 500 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('more-than') + ' 500 001 EUR'}
                                    value={'More than 500 001 EUR'} />

                            </Picker>
                            <ErrorMessage
                                text={'please-specify-the-amount-dots'}
                                visible={dividendsInterestsFromStocksBondsIncome === '' && showDividendsInterestsFromStocksBondsIncomeValidationMessage} />
                        </>

                        : null
                    }
                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                        others
                    </Text>
                    <View style={styles.yesNoContainer}>
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {'no'}
                        </Text>
                        <Switch
                            value={others}
                            onValueChange={() => {
                                setOthers(!others);
                            }}
                        />
                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                            {'yes'}
                        </Text>
                    </View>
                    {others ?
                        <>
                            <TextInput
                                containerStyle={styles.inputContainer}
                                style={styles.textInputStyle}
                                autoCapitalize="none"
                                placeholder='please-specify-type-of-income'
                                autoFocus={false}
                                value={othersDescription}
                                onChangeText={(text) => setOthersDescription(text)}
                                onBlur={() => setShowOthersDescriptionValidationMessage(true)}
                                returnKeyType='next' />
                            <ErrorMessage
                                text={'please-specify-type-of-income'}
                                visible={othersDescription === '' && showOthersDescriptionValidationMessage} />

                            <Picker
                                style={styles.pickerStyle}
                                itemStyle={styles.pickerRow}
                                selectedValue={othersIncome}
                                onValueChange={(itemValue) => {
                                    setOthersIncome(itemValue);
                                    setShowOthersIncomeValidationMessage(true)
                                }
                                }
                            >
                                <Picker.Item
                                    label={i18n.t('please-specify-the-amount-dots')}
                                    value={''} />
                                <Picker.Item
                                    label={i18n.t('less-than') + '15 000 EUR'}
                                    value={'Less than 15 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('15 000 - 50 000 EUR')}
                                    value={'15 000 - 50 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('50 001 - 200 000 EUR')}
                                    value={'50 001 - 200 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('200 001 - 500 000 EUR')}
                                    value={'200 001 - 500 000 EUR'} />
                                <Picker.Item
                                    label={i18n.t('more-than') + ' 500 001 EUR'}
                                    value={'More than 500 001 EUR'} />
                            </Picker>
                            <ErrorMessage
                                text={'please-specify-the-amount-dots'}
                                visible={othersIncome === '' && showOthersIncomeValidationMessage} />
                        </> : null
                    }

                </>
                : null}

            <ErrorMessage
                text={generalError}
                visible={generalError !== ''}
            />
            <View style={styles.buttonsContainer}>
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={() => goToPrevStep()}
                        title="back"
                        backgroundColor={styles.backButtonStyle}
                    />

                </View>
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={() => setFinancialInformation()}
                        title="next"
                    />
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        safeAreaStyle: { flex: 1, paddingHorizontal: scaleSize(Platform.OS !== 'web' ? 0 : 12), },
        backButtonStyle: { backgroundColor: theme.tabIconDefault },
        yesNoContainer: { flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: '25%' },
        buttonsContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: scaleSize(20),
        },
        buttonContainer: {
            width: scaleSize(150),
            height: scaleSize(40),
        },
        pickerRow: {
            width: '100%',
            height: scaleSize(40),
            fontSize: scaleSize(FONT_SIZE_S),
            color: theme.text
        },
        pickerStyle: {
            width: '100%',
            height: scaleSize(40),
            fontSize: scaleSize(FONT_SIZE_M),
            backgroundColor: theme.backgroundSecondary,
            color: theme.text_pendingOrder,
            borderRadius: scaleSize(5),
            padding: 0,
            marginBottom: scaleSize(10)
        },
        textInputStyle: {
            fontSize: scaleSize(FONT_SIZE_M),
            color: theme.text_pendingOrder,
            width: '100%'
        },
        labelText: { fontSize: FONT_SIZE_XS, color: theme.text_symbol, paddingVertical: scaleSize(10) },
        bdateContainer: { flexDirection: 'row', justifyContent: 'space-between' },
        bdatePickerContainer: { width: '31%' },
        vcTitleContainer: {
            justifyContent: 'center',
            paddingVertical: scaleSize(20),
        },
        vcTitleText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        inputContainer: {
            marginBottom: scaleSize(10),
        },
        keyBoard: { width: '100%' },
        viewContainer: { justifyContent: 'space-between', height: '80%', paddingBottom: scaleSize(10) },
        scrollView: { maxHeight: '100%', height: '100%' },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
            width: '100%',
        },
    });
