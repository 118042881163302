import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { CoreDataService } from '~/api-services';

import { Button, Text, TextInput } from '~/components/core';
import { FONT_SIZE_S, FONT_SIZE_XS, spacing } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
import ConfirmationMessage from '../ConfirmationMessage';
import Header from '../More/Header';
import { Theme } from '~/theme';
import { useStyles } from '~/hooks';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';

type Props = {
    setActiveRightTab?: Function;
};

export default function ChangePassword({ setActiveRightTab }: Props) {
    const styles = useStyles(stylesGenerator);

    const [oldPasswordVisibility, setOldPasswordVisibility] = useState(true);
    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const [passwordConfirmVisibility, setPasswordConfirmVisibility] = useState(true);

    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [confirmationVisible, setConfirmationVisible] = useState(false);

    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });

    function validateForm(): boolean {
        if (passwordConfirm !== password) {
            setConfirmationProps({ text: 'passwords-not-match', error: true });
            setConfirmationVisible(true);
            return false;
        }
        return true;
    }

    async function changePassword() {
        if (validateForm()) {
            const { status, data } = await CoreDataService.changePassword({
                oldPassword: oldPassword,
                newPassword: password,
            });
            if (status !== 200) {
                setConfirmationProps({ text: 'Error', error: true });
                setConfirmationVisible(true);
            } else {
                if (data.status) {
                    setConfirmationProps({ text: 'successfuly-change-password', error: false });
                    setConfirmationVisible(true);
                } else {
                    if (data.errorCode === 'InvalidCredentials') {
                        setConfirmationProps({ text: 'invalid-credentials', error: true });
                    } else {
                        setConfirmationProps({ text: data.errorCode ? data.errorCode : 'Error', error: true });
                    }
                    setConfirmationVisible(true);
                }
            }
        }
    }

    return (
        <>
            {!isDesktop() ? <Header /> : null}
            <DesktopScreenHeader
                goToScreenName="settings"
                title="change-password"
                setActiveRightTab={setActiveRightTab}
            />
            <ScrollbarWrapper>
                <View style={styles.container}>
                    <View style={styles.viewContainer}>
                        <View>
                            <View style={styles.titleContainer}>
                                <Text fontType="LATO_BOLD" style={styles.titleText}>
                                    change-password-title
                                </Text>
                            </View>
                            <View style={styles.infoTextContainer}>
                                <Text fontType="LATO_REGULAR" style={styles.infoText}>
                                    enter-current-password
                                </Text>
                            </View>
                            <TextInput
                                containerStyle={styles.inputContainer}
                                leftIcon="lock"
                                placeholder="registration-input-old-password"
                                autoCapitalize="none"
                                autoCorrect={false}
                                secureTextEntry={oldPasswordVisibility}
                                textContentType="password"
                                rightIcon={oldPasswordVisibility ? 'eye' : 'eye-off'}
                                value={oldPassword}
                                onChangeText={(text) => setOldPassword(text)}
                                onRightIconPress={() => setOldPasswordVisibility(!oldPasswordVisibility)}
                            />
                            <View style={styles.infoTextContainer}>
                                <Text fontType="LATO_REGULAR" style={styles.infoText}>
                                    enter-new-password
                                </Text>
                            </View>
                            <TextInput
                                containerStyle={styles.inputContainer}
                                leftIcon="lock"
                                placeholder="change-password-new"
                                autoCapitalize="none"
                                autoCorrect={false}
                                secureTextEntry={passwordVisibility}
                                textContentType="password"
                                rightIcon={passwordVisibility ? 'eye' : 'eye-off'}
                                value={password}
                                onChangeText={(text) => setPassword(text)}
                                onRightIconPress={() => setPasswordVisibility(!passwordVisibility)}
                            />
                            <View style={styles.infoTextContainer}>
                                <Text fontType="LATO_REGULAR" style={styles.infoText}>
                                    confirm-new-password
                                </Text>
                            </View>
                            <TextInput
                                containerStyle={styles.inputContainer}
                                leftIcon="lock"
                                placeholder="registration-input-confirm-password"
                                autoCapitalize="none"
                                autoCorrect={false}
                                secureTextEntry={passwordConfirmVisibility}
                                textContentType="password"
                                rightIcon={passwordConfirmVisibility ? 'eye' : 'eye-off'}
                                value={passwordConfirm}
                                onChangeText={(text) => setPasswordConfirm(text)}
                                onRightIconPress={() => setPasswordConfirmVisibility(!passwordConfirmVisibility)}
                            />
                        </View>
                        <View style={styles.buttonContainer}>
                            <Button
                                title="save-favorites-button-text"
                                onPress={() => changePassword()}
                                disabled={password === '' || oldPassword === '' || passwordConfirm === ''}
                            />
                        </View>
                        <ConfirmationMessage
                            text={confirmationProps.text}
                            error={confirmationProps.error}
                            visible={confirmationVisible}
                            setVisible={setConfirmationVisible}
                        />
                    </View>
                </View>
            </ScrollbarWrapper>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        buttonContainer: { paddingBottom: scaleSize(20) },
        infoTextContainer: { paddingBottom: scaleSize(10) },
        infoText: { fontSize: FONT_SIZE_XS, color: theme.text_symbol },
        titleContainer: { paddingVertical: scaleSize(20) },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
        },
        titleText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        viewContainer: { height: '90%', justifyContent: 'space-between' },
        inputContainer: {
            marginBottom: scaleSize(spacing.LARGE),
        },
    });
