import React from 'react';
import { StyleSheet, View } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import BigChart from '../BigChart';

type Props = { symbol: string };

export default function BasicChart({ symbol }: Props) {
    const styles = useStyles(stylesGenerator);

    return (
        <View style={styles.chartContainer}>
            <BigChart chartSymbol={symbol} mode={'light'} />
        </View>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        chartContainer: {
            paddingHorizontal: scaleSize(7),
            height: scaleSize(360),
            paddingTop: scaleSize(10),
        },
    });
