import * as React from 'react';
import { Entypo } from '@expo/vector-icons';
import { isDesktop, scaleSize } from '~/constants/scale';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { Text } from '../../core';
import { FONT_SIZE_L, FONT_SIZE_S } from '~/constants';

type Props = {
    title: string;
    goToScreenName: string;
    setActiveRightTab: Function;
};

export default function DesktopScreenHeader({ title, goToScreenName, setActiveRightTab }: Props) {
    const styles = useStyles(stylesGenerator);
    if (isDesktop()) {
        return (
            <View style={styles.container}>
                <Pressable onPress={() => setActiveRightTab(goToScreenName)} style={styles.backContainer}>
                    <Entypo size={scaleSize(20)} name="chevron-left" style={styles.backArrow} />
                </Pressable>
                <View style={styles.headerTitle}>
                    <Text fontType="LATO_REGULAR" style={styles.headerText}>
                        {title}
                    </Text>
                </View>
            </View>
        );
    } else {
        return null;
    }
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            width: '100%',
            paddingBottom: scaleSize(5),
            backgroundColor: theme.backgroundSecondary,
        },
        backArrow: { color: theme.tabIconDefault },
        backContainer: {
            width: '15%',
            height: scaleSize(28),
            paddingLeft: scaleSize(5),
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        headerTitle: { width: '70%', justifyContent: 'center', alignItems: 'center' },
        headerText: { color: theme.tabIconDefault, fontSize: FONT_SIZE_S },
    });
