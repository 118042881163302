import { useEffect, useState } from 'react';
import WebsiteService from '~/api-services/WebsiteService';
import config from '~/config';
import { formatTime } from '~/lib/format';
import i18n from '~/i18n';


export const useTradingHours = (code: string, dealer: string, instrumentType: string, marketId: number) => {
    const [tradingHours, setTradingHours] = useState('');
    const [interestShortPosition, setInterestShortPosition] = useState('');
    const [interestLongPosition, setInterestLongPosition] = useState('');

    useEffect(() => {
        (async () => {
            console.log("marketId", marketId);
            if((marketId === 17 && (instrumentType === 'FOREX' || instrumentType === 'COMMODITIES')) || marketId === 18) {
                instrumentType = 'FUTURES';
            }
            const { status, data } = await WebsiteService.getTradingHours({
                code: code,
                dealer: dealer,
                website: config.WEBSITE,
                instrumentType: instrumentType
            });
            if (status === 200 && data.status && data.returnData[0]) {
                let tradingHours = '';
                if(instrumentType === 'FOREX') {
                    tradingHours = i18n.t('mon-fri');
                }
                if(marketId === 1) {
                    tradingHours = tradingHours + '00:00:00 - 00:00:00';
                }
                else {
                    tradingHours = tradingHours +  formatTime(data.returnData[0].openTime) + ' - ' + formatTime(data.returnData[0].closeTime);
                }
                setTradingHours(tradingHours);
                setInterestShortPosition(data.returnData[0].interestShortPosition);
                setInterestLongPosition(data.returnData[0].interestLongPosition);
            }
            else if(status === 200 && data.status && data.returnData && data.returnData.length === 0) {
                setTradingHours('00:00:00 - 00:00:00');
            }
        })();
    }, [code, dealer, instrumentType]);

    return {
        tradingHours,
        interestShortPosition,
        interestLongPosition,
    };
};
