import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import Commissions from '../features/Commissions';

export default function CommissionsScreen() {
    return (
        <ScreenWrapper>
            <Commissions />
        </ScreenWrapper>
    );
}
