import React from 'react';
import { View, StyleSheet } from 'react-native';

import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { Text } from '~/components/core';
import { Foundation } from '@expo/vector-icons';
import { FONT_SIZE_XS } from '~/constants';


export default function MandatoryHeader() {
    const styles = useStyles(stylesGenerator);



    return (
        <View style={styles.container}>
            <Foundation name={'asterisk'} size={scaleSize(10)} style={styles.iconStyle} />
            <Text fontType="LATO_BOLD" style={styles.text}>
                vc-mandatory-header
            </Text>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        iconStyle: { color: theme.white, paddingRight: scaleSize(10) },
        text: { fontSize: FONT_SIZE_XS, color: theme.white },
        container: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: scaleSize(30),
            backgroundColor: theme.background_positionsCount
        },
    });
