import axios from '../baseClient';
import { WatchlistItem } from '../LoadService/types';

class WatchlistService {
    private static domain = '/api/favoriteslistservice';

    saveWatchlist(request: WatchlistItem) {
        return axios.put<boolean>(`${WatchlistService.domain}/watchlist/${request.id}`, request);
    }
}

export default new WatchlistService();
