import React, { useState } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { Button, Switch, Text, TextInput } from '~/components/core';

import { FONT_SIZE_S, FONT_SIZE_XS, spacing } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAppSelector, useStyles } from '~/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { accountBalanceSelector, dealerSelector } from '~/state/selectors';
import Header from '../More/Header';
import { Theme } from '~/theme';
import config from '~/config';
import WithdrawService from '~/api-services/WithdrawService';
import ConfirmationMessage from '../ConfirmationMessage';
import CopyRight from './CopyRight';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';
import { CoreDataService } from '~/api-services';
import t from '~/i18n';
import { formatCurrencyWithFormatetdNumber } from '~/lib/format';

type Props = {
    setActiveRightTab?: Function;
};

export default function WithdrawFunds({ setActiveRightTab }: Props) {
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';
    const styles = useStyles(stylesGenerator);
    const [amount, setAmount] = useState('');
    const [showComment, setShowComment] = useState(false);
    const [comment, setComment] = useState('');
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const dealer = useAppSelector(dealerSelector);

    function onChangedAmount(text: string) {
        var newText = '';
        var numbers = '0123456789.';
        text = text.replace(',', '.');
        if (text.length < 1) {
            setAmount('');
        }
        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        setAmount(newText);
    }

    async function withdrawFundsRequest() {

        const { status, data } = await CoreDataService.checkWithdrawAmountLimit(Number(amount) * -1);

        if (status === 200 && data.status) {
            if (data.returnData.status) {
                const { status, data } = await WithdrawService.withdraw({
                    amount: Number(amount),
                    currency: currency,
                    instructions: comment,
                    website: config.WEBSITE,
                    dealer: dealer
                });
                if (status === 200 && data.status) {
                    setConfirmationProps({ text: 'withdraw-success', error: false });
                    setConfirmationVisible(true);
                    setAmount('');
                    setComment('');
                } else {
                    setConfirmationProps({ text: 'withdraw-error', error: true });
                    setConfirmationVisible(true);
                }
            }
            else {
                if(dealer === 'EVER') {
                    var messageContext: any = {};
                    var error = 'dont-have-wthdrawable-funds';
                    var errorMessage=data.returnData.error;
                    if(errorMessage.indexOf('not enough free cash [')>=0) {
                        var freeCash = errorMessage.substring(errorMessage.indexOf('not enough free cash [')+'not enough free cash ['.length, errorMessage.indexOf(']'));
                        messageContext.amount = formatCurrencyWithFormatetdNumber(freeCash,currency) + '!';
                        error = t.t('withral-InsufficientFunds-ever-message', messageContext);
                    }
                    setConfirmationProps({ text: error, error: true });
                }
                else {
                    setConfirmationProps({ text: 'dont-have-wthdrawable-funds', error: true });
                }
                setConfirmationVisible(true);
            }
        }
        else {
            setConfirmationProps({ text: 'withdraw-error', error: true });
            setConfirmationVisible(true);
        }
        console.log(data)

    }

    return (
        <>
            {!isDesktop() ? <Header /> : null}
            <DesktopScreenHeader goToScreenName="more" title="withdraw-funds" setActiveRightTab={setActiveRightTab} />
            <View style={styles.container}>
                <ScrollbarWrapper>
                    <View style={styles.accountWithdrawTitleContainer}>
                        <Text fontType="LATO_BOLD" style={styles.accountWithdrawTitleText}>
                            account-withdraw-title
                        </Text>
                        <Text fontType="LATO_BOLD" style={styles.accountWithdrawTitleText}>
                            {' ('}
                        </Text>
                        <Text fontType="LATO_BOLD" style={styles.accountWithdrawTitleText}>
                            {currency}
                        </Text>
                        <Text fontType="LATO_BOLD" style={styles.accountWithdrawTitleText}>
                            {' )'}
                        </Text>
                    </View>
                    <TextInput
                        containerStyle={styles.inputContainer}
                        placeholder="enter-amount"
                        autoCapitalize="none"
                        autoCorrect={false}
                        value={amount}
                        onChangeText={(text) => onChangedAmount(text)}
                        keyboardType="decimal-pad"
                        maxLength={10}
                        placeholderTextColor={'gray'}
                    />
                    <View style={styles.showCommentContainer}>
                        <Text fontType="LATO_REGULAR" style={styles.showCommentText}>
                            withdraw-comment
                        </Text>
                        <Switch
                            value={showComment}
                            style={styles.switchButton}
                            onValueChange={() => {
                                setShowComment(!showComment);
                            }}
                        />
                    </View>
                    {showComment ? (
                        <TextInput
                            containerStyle={styles.inputContainer}
                            autoCapitalize="none"
                            autoCorrect={false}
                            value={comment}
                            onChangeText={(text) => setComment(text)}
                            multiline={true}
                            style={styles.comment}
                            placeholderTextColor={'gray'}
                        />
                    ) : null}
                    <View style={styles.logosContainer}>
                        <Image
                            source={require('../../../../assets/files/mobileplatform/images/payments/mastercard.png')}
                            style={styles.mastercardLogo}
                        />
                        <Image
                            source={require('../../../../assets/files/mobileplatform/images/payments/visa.png')}
                            style={styles.visaLogo}
                        />
                    </View>

                    <View style={styles.withdrawInfoContainer}>
                        <Text fontType="LATO_REGULAR" style={styles.withdrawInfoText}>
                            withdraw-info
                        </Text>
                    </View>
                    <CopyRight />
                </ScrollbarWrapper>
                <View style={styles.requestButtonContainer}>
                    <Button title="send-request" onPress={() => withdrawFundsRequest()} disabled={amount === ''} />
                </View>
            </View>
            <ConfirmationMessage
                text={confirmationProps.text}
                error={confirmationProps.error}
                visible={confirmationVisible}
                setVisible={setConfirmationVisible}
            />
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        mastercardLogo: { width: scaleSize(34), height: scaleSize(20) },
        visaLogo: { width: scaleSize(58), height: scaleSize(20) },
        requestButtonContainer: { height: '12%' },
        withdrawInfoContainer: {},
        withdrawInfoText: { fontSize: FONT_SIZE_XS, color: theme.text_pendingOrder },
        logosContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: scaleSize(20),
            paddingTop: scaleSize(8),
        },
        comment: { height: scaleSize(56), width: '100%', color: theme.text_pendingOrder },
        showCommentContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: scaleSize(4),
            paddingBottom: scaleSize(20),
            alignItems: 'center',
        },
        showCommentText: { fontSize: FONT_SIZE_XS, color: theme.tabIconSelected },
        switchButton: {
            justifyContent: 'flex-end',
        },
        accountWithdrawTitleContainer: {
            paddingVertical: scaleSize(30),
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        accountWithdrawTitleText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
        },
        inputContainer: {
            marginBottom: spacing.LARGE,
        },
    });
