import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '~/components/core';
import { FONT_SIZE_S } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import { formatNumberWithPercent } from '~/lib/format';
import { appSettingsSelector } from '~/state/selectors';
import { Theme } from '~/theme';

type Props = {
    title: string;
    color: string;
    percent: number;
};

export default function InvestorPortfolioInstrumentGroupDetails({ title, color, percent }: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);

    return (
        <View
            style={[
                styles.positionGroupContainer,
                colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
            ]}
            key={'InvestorPortfolioInstrumentGroupDetails' + title}
        >
            <View style={[styles.colorElement, { backgroundColor: color }]} />
            <View style={styles.elementTitle}>
                <Text fontType="LATO_BOLD" style={styles.text}>
                    {title}
                </Text>
            </View>
            <View style={styles.elementPercent}>
                <Text fontType="LATO_BOLD" style={styles.text}>
                    {formatNumberWithPercent(percent, 2, false)}
                </Text>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        text: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        elementPercent: {
            width: scaleSize(140),
            justifyContent: 'center',
            paddingRight: scaleSize(10),
            alignItems: 'flex-end',
        },
        elementTitle: { paddingLeft: scaleSize(10), justifyContent: 'center', width: scaleSize(200) },
        colorElement: {
            width: scaleSize(20),
            height: '100%',
            borderBottomLeftRadius: scaleSize(5),
            borderTopLeftRadius: scaleSize(5),
        },
        positionGroupContainer: {
            flexDirection: 'row',
            borderWidth: 1,
            width: '100%',
            height: scaleSize(50),
            backgroundColor: theme.backgroundSecondary,
            borderRadius: scaleSize(5),
            marginTop: scaleSize(7),
        },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },
    });
