import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import MarketHeader from '../features/Market/MarketHeader';
import EditOpenPositionScreen from '../screens/EditOpenPositionScreen';
import CustomHeader from './CustomHeader';
import DealsTopTabsNavigator from './DealsTopTabsNavigator';
import { useStyles } from '~/hooks';

const Stack = createNativeStackNavigator();

export default function DealsStackNavigator() {
    const styles = useStyles(stylesGenerator);

    return (
        <SafeAreaView style={styles.container}>
            <Stack.Navigator initialRouteName="DealsTopTabs">
                <Stack.Screen
                    name="DealsTopTabs"
                    component={DealsTopTabsNavigator}
                    options={{
                        headerShown: true,
                        header: MarketHeader,
                        orientation: 'portrait',
                    }}
                />
                <Stack.Screen
                    name="EditOpenPosition"
                    key={'EditOpenPosition'}
                    component={EditOpenPositionScreen}
                    options={{
                        header: () => <CustomHeader title={'edit-position'} />,
                    }}
                />
                <Stack.Screen
                    name="EditPendingOrder"
                    key={'EditPendingOrder'}
                    component={EditOpenPositionScreen}
                    options={{
                        header: () => <CustomHeader title={'edit-pending-order'} />,
                    }}
                />
            </Stack.Navigator>
        </SafeAreaView>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
    });
