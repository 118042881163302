import React, { useState } from 'react';
import { View, StyleSheet, Platform, SafeAreaView } from 'react-native';
import { Button, Text } from '~/components/core';

import { FONT_SIZE_S, FONT_SIZE_XS, spacing } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useStyles } from '~/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Header from '../More/Header';
import { Theme } from '~/theme';
import ConfirmationMessage from '../ConfirmationMessage';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';
import * as DocumentPicker from 'expo-document-picker';
import { useAppSelector } from '~/hooks';
import { userAuthDataSelector } from '~/state/selectors';
import config from '~/config';
import axios from 'axios';
import Loader from '~/components/core/Loader';
import CopyRight from '../WithdrawFunds/CopyRight';
import mime from "mime";

type Props = {
    setActiveRightTab?: Function;
};

export default function UploadDocuments({ setActiveRightTab }: Props) {
    const styles = useStyles(stylesGenerator);
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [singleFile, setSingleFile] = useState(null);
    const { sessionId } = useAppSelector(userAuthDataSelector);
    const [loading, setLoading] = useState(false);

    const uploadImage = async () => {
        setConfirmationVisible(false);
        // Check if any file is selected or not
        if (singleFile != null && singleFile.assets && singleFile.assets.length > 0) {
            setLoading(true);
            // If file selected then create FormData
            for (let i = 0; i < singleFile.assets.length; i++) {
                try {
                    const data = new FormData();

                    data.append('sessionId', sessionId);
                    //data.append("OTHER", { uri: singleFile.assets[i].uri, filename: singleFile.assets[i].name, type: singleFile.assets[i].mimeType })

                    if (Platform.OS === 'web') {
                        data.append('OTHER', singleFile.output[i]);
                    }
                    else if(Platform.OS === 'ios') {
                        data.append('OTHER', singleFile.assets[i]);
                    } else if(Platform.OS === 'android') {
                        const newImageUri =  "file:///" + singleFile.assets[i].uri.split("file:/").join("");
                        data.append("OTHER", 
                        { 
                            uri: newImageUri, 
                            name: newImageUri.split("/").pop(), 
                            type: mime.getType(newImageUri)
                        });
                        
                    }

                    let res = await axios.post(config.PLATFORM_BASE_URL + 'userdocs/upload', data, {
                        headers: {
                            Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    setLoading(false);
                    //let result = await res.
                    console.log('res', res);
                    if (res.data !== 'OK') {
                        setConfirmationProps({ text: res.data + '', error: true });
                        setConfirmationVisible(true);
                        setSingleFile(null);
                    }
                    else {
                        setConfirmationProps({ text: 'File: ' + singleFile.assets[i].name + ' Upload Successful', error: false });
                        setConfirmationVisible(true);
                        setSingleFile(null);
                    }
                } catch (error) {
                    console.log(error)
                    setConfirmationProps({ text: 'Error Upload: ' + error, error: true });
                    setConfirmationVisible(true);
                    setSingleFile(null);
                    setLoading(false);
                }
            }
        } else {
            setConfirmationProps({ text: 'Please Select File first', error: true });
            setConfirmationVisible(true);
            setLoading(false);
        }
    };

    async function selectFile() {
        try {
            const result = await DocumentPicker.getDocumentAsync({ multiple: false, copyToCacheDirectory: true });
            console.log(result)
            // Printing the log realted to the file
            //console.log('res : ' + JSON.stringify(result));
            // Setting the state to show single file attributes
            if (result && result.assets) {
                setSingleFile(result);
            }
        } catch (err) {
            setSingleFile(null);
            console.warn(err);
            return false;
        }
    }
    return (
        <SafeAreaView style={styles.safeArea}>
            {!isDesktop() ? <Header /> : null}
            <DesktopScreenHeader goToScreenName="more" title="upload-documents" setActiveRightTab={setActiveRightTab} />
            <View style={styles.container}>
                <Loader loading={loading} />
                <ScrollbarWrapper>
                    {singleFile && singleFile.assets && singleFile.assets.length > 0 ? (

                        singleFile.assets.map((item, key) => (
                            <View style={styles.selectFileButtonStype} key={key}>
                                <Text fontType="LATO_REGULAR" style={styles.textStyle} >
                                    {'File Name: ' + (item.name ? item.name : '')}
                                </Text>
                                <Text fontType="LATO_REGULAR" style={styles.textStyle} >
                                    {'Type: ' + (item.mimeType ? item.mimeType : '')}
                                </Text>
                                <Text fontType="LATO_REGULAR" style={styles.textStyle} >
                                    {'File Size: ' + (item.size ? (Math.round(item.size / 1024)) + ' kb.' : '')}
                                </Text>
                            </View>
                        ))

                    ) : null}

                    <View style={styles.selectFileButtonStype}>
                        <Button title="Select File" onPress={() => selectFile()} disabled={singleFile !== null} />
                    </View>
                    <Button title="Upload" onPress={() => uploadImage()} disabled={singleFile === null} />

                    <CopyRight />
                </ScrollbarWrapper>
            </View>
            <ConfirmationMessage
                text={confirmationProps.text}
                error={confirmationProps.error}
                visible={confirmationVisible}
                setVisible={setConfirmationVisible}
            />
        </SafeAreaView>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        safeArea: { flex: 1 },
        selectFileButtonStype: { paddingVertical: scaleSize(30) },
        textStyle: {
            marginTop: scaleSize(10),
            textAlign: 'center',
            fontSize: FONT_SIZE_S,
            color: theme.text_symbol
        },
        mastercardLogo: { width: scaleSize(34), height: scaleSize(20) },
        visaLogo: { width: scaleSize(58), height: scaleSize(20) },
        requestButtonContainer: { height: '12%' },
        withdrawInfoContainer: {},
        withdrawInfoText: { fontSize: FONT_SIZE_XS, color: theme.text_pendingOrder },
        logosContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: scaleSize(20),
            paddingTop: scaleSize(8),
        },
        comment: { height: scaleSize(56), width: '100%', color: theme.text_pendingOrder },
        showCommentContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: scaleSize(4),
            paddingBottom: scaleSize(20),
            alignItems: 'center',
        },
        showCommentText: { fontSize: FONT_SIZE_XS, color: theme.tabIconSelected },
        switchButton: {
            justifyContent: 'flex-end',
        },
        accountWithdrawTitleContainer: {
            paddingVertical: scaleSize(30),
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        accountWithdrawTitleText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
        },
        inputContainer: {
            marginBottom: spacing.LARGE,
        },
    });
