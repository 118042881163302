import React, {useState} from 'react';
import { View, Modal, StyleSheet, Pressable, SafeAreaView } from 'react-native';
import { Button, Switch, Text } from '~/components/core';
import ScreenWrapper from '../Theme/ScreenWrapper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useAppDispatch, useAppSelector, useStyles } from '~/hooks';
import { BalanceValues } from '~/api-services/LoadService/types';
import { Theme } from '~/theme';
import { formatCurrency, formatNumberWithPercent } from '~/lib/format';
import { isDesktop, scaleSize } from '~/constants/scale';
import { saveAppSettings } from '~/state/reducers/appSettingsSlice';
import { appSettingsSelector } from '~/state/selectors';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';

type AccountBalanceDialogProps = {
    isModalVisible: boolean;
    balanceValues: BalanceValues | undefined;
    setAccountBalanceVisible: Function;
    setActiveRightTab?: Function;
};

const AccountBalanceDialog = ({
    isModalVisible,
    balanceValues,
    setAccountBalanceVisible,
    setActiveRightTab,
}: AccountBalanceDialogProps) => {
    const styles = useStyles(stylesGenerator);
    const { selectedBalance } = useAppSelector(appSettingsSelector);
    const dispatch = useAppDispatch();
    const [touchY, setTouchY] = useState(0);


    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={isModalVisible}
            onRequestClose={() => {
                setAccountBalanceVisible(false);
            }}
            statusBarTranslucent={false}
        >
            
            <Pressable
                style={[styles.closeContainer]}
                onPress={() => setAccountBalanceVisible(false)}
            >
                <ScrollbarWrapper>
                    <SafeAreaView style={styles.container}>
                        <Pressable>
                            <ScreenWrapper style={styles.screen}>
                                <View style={styles.content}  onTouchStart={e=> {setTouchY(e.nativeEvent.pageY); console.log( e.nativeEvent.pageY)}}
                                onTouchEnd={e => {
                                    if (touchY - e.nativeEvent.pageY > 60 || (touchY > 0 && touchY - e.nativeEvent.pageY < -60)) {
                                        setAccountBalanceVisible(false);
                                    }
                                }}>
                                    <Pressable onPress={() => setAccountBalanceVisible(false)}>
                                        <View style={styles.closeButton}>
                                            <MaterialCommunityIcons
                                                style={styles.closeIcon}
                                                name="close"
                                                size={scaleSize(30)}
                                            />
                                        </View>
                                    </Pressable>
                                    <ScrollbarWrapper style={styles.scrollView}>
                                        <View style={styles.header}>
                                            <Text>account-balance-title</Text>
                                            <View style={styles.rowValue}>
                                                <Text>
                                                    {formatCurrency(balanceValues?.accountBalance, 2, balanceValues?.currency)}
                                                </Text>
                                                <View style={styles.switchContainer}>
                                                    <Switch
                                                        value={selectedBalance === 'balance'}
                                                        onValueChange={() => {
                                                            dispatch(
                                                                saveAppSettings({
                                                                    selectedBalance: 'balance',
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={styles.rowLabel}>
                                                <Text>account-balance-equity</Text>
                                            </View>
                                            <View style={styles.rowValue}>
                                                <Text>
                                                    {formatCurrency(balanceValues?.equity, 2, balanceValues?.currency)}
                                                </Text>
                                                <View style={styles.switchContainer}>
                                                    <Switch
                                                        value={selectedBalance === 'equity'}
                                                        onValueChange={() => {
                                                            dispatch(
                                                                saveAppSettings({
                                                                    selectedBalance: 'equity',
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={styles.rowLabel}>
                                                <Text>necessary-margin</Text>
                                                <Text>:</Text>
                                            </View>
                                            <View style={styles.rowValue}>
                                                <Text>
                                                    {formatCurrency(balanceValues?.usedMargin, 2, balanceValues?.currency)}
                                                </Text>
                                                <View style={styles.switchContainer}>
                                                    <Switch
                                                        value={selectedBalance === 'margin'}
                                                        onValueChange={() => {
                                                            dispatch(
                                                                saveAppSettings({
                                                                    selectedBalance: 'margin',
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={styles.rowLabel}>
                                                <Text>account-balance-margin-level</Text>
                                            </View>
                                            <View style={styles.rowValue}>
                                                <Text>
                                                    {balanceValues?.level === 100000 ? '∞' : formatNumberWithPercent(balanceValues?.level, 2, false)}
                                                </Text>
                                                <View style={styles.switchContainer}>
                                                    <Switch
                                                        value={selectedBalance === 'marginLevel'}
                                                        onValueChange={() => {
                                                            dispatch(
                                                                saveAppSettings({
                                                                    selectedBalance: 'marginLevel',
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={styles.rowLabel}>
                                                <Text>account-balance-free-margin</Text>
                                            </View>
                                            <View style={styles.rowValue}>
                                                <Text>
                                                    {balanceValues?.equity && balanceValues?.usedMargin
                                                        ? formatCurrency(
                                                            balanceValues?.equity - balanceValues?.usedMargin,
                                                                2,
                                                                balanceValues?.currency
                                                            )
                                                        : formatCurrency(0, 2, balanceValues?.currency)}
                                                </Text>
                                                <View style={styles.switchContainer}>
                                                    <Switch
                                                        value={selectedBalance === 'freeMargin'}
                                                        onValueChange={() => {
                                                            dispatch(
                                                                saveAppSettings({
                                                                    selectedBalance: 'freeMargin',
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.row}>
                                            <View style={styles.rowLabel}>
                                                <Text>account-balance-result</Text>
                                            </View>
                                            <View style={styles.rowValue}>
                                                <Text>{formatCurrency(balanceValues?.potentialProfitLoss, 2, balanceValues?.currency)}</Text>
                                                <View style={styles.switchContainer}>
                                                    <Switch
                                                        value={selectedBalance === 'ppl'}
                                                        onValueChange={() => {
                                                            dispatch(
                                                                saveAppSettings({
                                                                    selectedBalance: 'ppl',
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                        <View style={[styles.row]}>
                                            <Button
                                                title={'account-balance-add-funds'}
                                                onPress={() => {
                                                    setAccountBalanceVisible(false);
                                                    if (isDesktop()) {
                                                        setActiveRightTab ? setActiveRightTab('add-money') : null;
                                                    } else {
                                                        linkTo(paths.addFunds);
                                                    }
                                                }}
                                            />
                                        </View>
                                    </ScrollbarWrapper>
                                </View>
                            </ScreenWrapper>
                        </Pressable>
                    </SafeAreaView>
                </ScrollbarWrapper>
            </Pressable>
        </Modal>
    );
};

export default AccountBalanceDialog;

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        closeContainer: { width: '100%', height: '300%'},
        scrollView: { maxHeight: '100%' },
        closeButton: {
            alignItems: 'flex-end',
            marginTop: scaleSize(10),
            marginRight: scaleSize(20),
        },
        closeIcon: {
            color: theme.text_positionId,
        },
        header: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        screen: {
            width: '100%',
            borderRadius: scaleSize(8),
        },
        container: {
            borderRadius: scaleSize(8),
            width: '100%',
            flex: 1
        },
        content: {
            width: '100%',
            flex: 1,
        },
        row: {
            flexDirection: 'row',
            width: '90%',
            justifyContent: 'space-between',
            padding: scaleSize(10),
            borderBottomWidth: 1,
            borderBottomColor: theme.border_2,
            marginHorizontal: '5%',
        },
        rowValue: {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        rowLabel: { justifyContent: 'center', flexDirection: 'row' },
        switchContainer: { paddingLeft: scaleSize(4) },
    });
