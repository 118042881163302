export default {
    white: '#ffffff',
    black: '#000000',
    red_2: '#cc4b37',
    red_3: '#ed3225',
    pink_2: '#dd6e6e',
    green_1: '#05c168',
    green_2: '#90ee90',
    green_3: '#77cbc1',
    blue_1: '#1779ba',
    blue_2: '#2f95dc',
    blue_3: '#5D6D84',
    teal_1: '#cae9e9',
    teal_3: '#0abab5',
    orange: '#ffae00',
    gray_2: '#D5D5D5',
    gray_4: '#929292',
    gray_5: '#17181C',
    gray_6: '#F1F3F8',
    gray_7: '#c7c7cc',
    gray_8: '#e6e6e6',
    gray_9: '#D7D7D7',
    gray_10: '#CED5DD',
    gray_13: '#333333',
    gray_14: '#666666',
    gray_15: '#767676',
    gray_16: '#8a8a8a',
    gray_17: '#878787',
    gray_18: '#231f20',
    gray_20: '#2f2f2a',
    backgroud_dark_theme: '#020303',
};
