import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { accountBalanceSelector, appSettingsSelector } from '~/state/selectors';
import { FONT_SIZE_XS } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { Cash } from '~/api-services/ReportsService/types';
import { formatCurrencyWithFormatetdNumber, formatTime } from '~/lib/format';

type Props = {
    cash: Cash;
};

export default function CashRow({ cash }: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';

    return (
        <View
            style={[
                styles.positionGroupContainer,
                colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
            ]}
            key={'instrumentContainer' + cash.id}
        >
            <View style={styles.typeContainer}>
                <Text fontType="LATO_REGULAR" style={styles.typeText}>
                    {cash.typeTranslated}
                </Text>
            </View>
            <View style={styles.timeContainer}>
                <Text fontType="LATO_REGULAR" style={styles.timeText}>
                    {formatTime(cash.timestamp)}
                </Text>
            </View>
            <View style={styles.amountContainer}>
                <Text
                    fontType="LATO_BOLD"
                    style={[
                        styles.amountText,
                        cash.sideTranslated === 'Deposit' ? styles.positiveResult : styles.negativeResult,
                    ]}
                >
                    {formatCurrencyWithFormatetdNumber(cash.amountFormatted, currency)}
                </Text>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        timeText: { fontSize: FONT_SIZE_XS, color: theme.text_pendingOrder },
        amountText: { fontSize: FONT_SIZE_XS, letterSpacing: 0.5 },
        typeText: { fontSize: FONT_SIZE_XS, color: theme.text_pendingOrder, letterSpacing: 0.5 },
        typeContainer: { flexWrap: 'wrap', width: '34%', paddingLeft: '2%', justifyContent: 'center' },
        timeContainer: { width: '33%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        amountContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '33%' },
        positionGroupContainer: {
            flexDirection: 'row',
            borderWidth: 1,
            width: '100%',
            height: scaleSize(50),
            backgroundColor: theme.backgroundSecondary,
            borderRadius: scaleSize(5),
            marginTop: scaleSize(7),
        },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },
        positiveResult: { fontSize: FONT_SIZE_XS, color: theme.text_upPrice, letterSpacing: 0.5 },
        negativeResult: { fontSize: FONT_SIZE_XS, color: theme.text_downPrice, letterSpacing: 0.5 },
    });
