import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Order } from '~/api-services/ReportsService/types';
import { useAppSelector, useStyles } from '~/hooks';
import { appSettingsSelector } from '~/state/selectors';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { FONT_SIZE_XXS } from '~/constants';
import { EditPositionDetail } from '../EditOpenPosition';
import PositionDetail from '../EditOpenPosition/PositionDetail';
import { formatDate } from '~/lib/format';

type Props = {
    order: Order;
};

export default function OrderDetails({ order }: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);

    const positionDetails: EditPositionDetail[] = [
        // Left Side
        {
            label: 'position-direction',
            value: order.side.charAt(0).toUpperCase() + order.side.substring(1, order.side.length),
            valueStyle: styles.textSpacing,
        },
        {
            label: 'order-instrument',
            value: order.symbolNameTranslated,
            valueStyle: styles.textSpacing,
        },
        {
            label: 'currency',
            value: order.currency,
            valueStyle: styles.textSpacing,
        },
        {
            label: 'position-volume',
            value: order.volumeFormatted,
            valueStyle: styles.numberSpacing,
        },
        {
            label: 'order-price',
            value: order.orderPriceFormatted,
            valueStyle: styles.numberSpacing,
        },
        {
            label: 'execution-price',
            value: order.executionPriceFormatted,
            valueStyle: styles.numberSpacing,
        },
        // Right Side
        {
            label: 'order-number',
            value: order.orderNumber,
            valueStyle: styles.textSpacing,
        },
        {
            label: 'position-order-time',
            value: formatDate(order.timestamp),
            valueStyle: styles.numberSpacing,
        },
        {
            label: 'position-modif-time',
            value: formatDate(order.modificationTimestamp),
            valueStyle: styles.numberSpacing,
        },
        {
            label: 'order-type',
            value: order.typeTranslated,
            valueStyle: styles.textSpacing,
        },
        {
            label: 'order-status',
            value: order.statusTranslated,
            valueStyle: styles.textSpacing,
        },
        {
            label: 'investor-name',
            value: order.investorName,
            valueStyle: styles.textSpacing,
        },
    ];

    return (
        <View
            key={order.id}
            style={[
                styles.orderDetailsContainer,
                colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
            ]}
        >
            <View style={styles.positionDetailsContainer}>
                <View style={styles.positionDetailsColumn}>
                    {positionDetails.slice(0, 6).map((positionDetail: EditPositionDetail, index) => (
                        <PositionDetail
                            editPositionDetail={positionDetail}
                            rowStyle={[styles.positionDetailsRow, styles.positionDetailsRowLeft]}
                            key={index}
                        />
                    ))}
                </View>
                <View style={styles.positionDetailsColumn}>
                    {positionDetails
                        .slice(6, positionDetails.length)
                        .map((positionDetail: EditPositionDetail, index) => (
                            <PositionDetail
                                editPositionDetail={positionDetail}
                                rowStyle={[styles.positionDetailsRow, styles.positionDetailsRowRight]}
                                key={index}
                            />
                        ))}
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        numberSpacing: { letterSpacing: 0.5 },
        textSpacing: { letterSpacing: 0 },
        positionDetailsColumn: { width: '50%', maxWidth: '50%' },
        positionDetailsContainer: {
            width: '100%',
            flexDirection: 'row',
            marginVertical: scaleSize(6),
        },
        positionDetailsRow: {
            flexDirection: 'row',
            width: '100%',
            paddingVertical: scaleSize(4),
            justifyContent: 'space-between',
        },
        positionDetailsRowLeft: {
            paddingLeft: scaleSize(10),
            paddingRight: scaleSize(0),
        },
        positionDetailsRowRight: {
            paddingRight: scaleSize(10),
            paddingLeft: scaleSize(5),
        },
        left: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
        },
        right: {
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'row',
        },
        detailsText: { fontSize: FONT_SIZE_XXS, color: theme.text_pendingOrder },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },
        orderDetailsContainer: {
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            backgroundColor: theme.backgroundSecondary,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderLeftWidth: 1,
            borderBottomLeftRadius: scaleSize(5),
            borderBottomRightRadius: scaleSize(5),
        },
    });
