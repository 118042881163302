import React from 'react';
import { View, StyleSheet } from 'react-native';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { useNotifications } from '~/hooks/useNotifications';
import DateRow, { getDateRow } from '../Result/DateRow';
import NotificationsRow from './NotificationsRow';

const now = new Date();
const prevPeriod = new Date(now);
prevPeriod.setMonth(now.getMonth() - 1);

export default function Notifications() {
    const { messageArray } = useNotifications();
    const styles = useStyles(stylesGenerator);

    return (
        <>
            <ScrollbarWrapper>
                <View style={styles.newsContainer}>
                    {messageArray.length !== 0 &&
                        messageArray.map((message, key) => (
                            <View style={styles.container} key={key}>
                                {getDateRow(message.timestamp, key) !== '' ? (
                                    <DateRow timestamp={message.timestamp} />
                                ) : null}
                                <NotificationsRow message={message} />
                            </View>
                        ))}
                </View>
            </ScrollbarWrapper>
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        newsContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingHorizontal: scaleSize(7),
        },
        container: { width: '100%' },
    });
