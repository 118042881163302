import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { DataSymbol } from '~/api-services/LoadService/types';
import { FONT_SIZE_M, FONT_SIZE_S } from '~/constants/typography';
import { useAppSelector, useStyles } from '~/hooks';
import { Foundation } from '@expo/vector-icons';
import { Text } from '~/components/core';
import { symbolsSelector, userDataSelector } from '~/state/selectors';
import { Theme } from '~/theme';
import { formatNumber } from '~/lib/format';
import { scaleSize } from '~/constants/scale';
import BuySellPosition from '../../BuySellPosition';
import MarketOrder from '../../MarketOrder';
import { useTheme } from '~/hooks/useTheme';

type Props = {
    symbol: string;
    setActiveChart?: Function;
};

const extractDailyChange = (s: DataSymbol) => {
    if (s) {
        const d1 = s.deviation.d1;
        const bid = s.price.bid;
        const dailyChange = d1 && bid ? ((bid - d1) / d1) * 100 : 0;
        return dailyChange;
    }
    return 0;
};

const formatDailyChange = (n: number) => (n < 0 ? `${n.toFixed(3)}%` : `+${n.toFixed(3)}%`);

const getPriceBackgroundColor = (theme: Theme, isMarketOpen: boolean, hasIncreased?: boolean) => {
    if (isMarketOpen === false) {
        return '';
    }
    switch (hasIncreased) {
        case true:
            return theme.text_upPrice;
        case false:
            return theme.text_downPrice;
        default:
            return '';
    }
};

const colorPriceTime = 1000;

const getPriceColor = (
    theme: Theme,
    lastPriceBackgroundColor: string,
    isMarketOpen: boolean,
    hasIncreased?: boolean
) => {
    if (isMarketOpen === false) {
        return theme.equityValue;
    }
    switch (hasIncreased) {
        case true:
            return theme.text_priceUp;
        case false:
            return theme.text_priceDown;
        default:
            return lastPriceBackgroundColor;
    }
};

export default function MarketChartCardDesktop({ symbol, setActiveChart }: Props) {
    const theme = useTheme();
    const data = useAppSelector((state) => symbolsSelector(state)[symbol]);
    const openMarket =
        data && data.marketId
            ? useAppSelector(userDataSelector)?.data?.account.marketToOpeningMap[data.marketId]
            : false;
    const now = new Date().getTime();
    const isMarketOpen = openMarket === 0 || now > openMarket;
    const dailyChange = extractDailyChange(data);
    const chartColor = dailyChange > 0 ? theme.text_upPrice : theme.text_downPrice;
    const [lastPriceBackgroundColorAsk, setLastPriceBackgroundColorAsk] = useState<string | undefined>(undefined);
    const [lastPriceBackgroundColorBid, setLastPriceBackgroundColorBid] = useState<string | undefined>(undefined);
    const [priceBackgroundColorAsk, setPriceBackgroundColorAsk] = useState('');
    const [priceBackgroundColorBid, setPriceBackgroundColorBid] = useState('');
    const [priceColorAsk, setPriceColorAsk] = useState('');
    const [priceColorBid, setPriceColorBid] = useState('');
    const [isOpenBuySell, setIsOpenBuySell] = useState(false);
    const [openBuySellType, setOpenBuySellType] = useState('');
    const styles = useStyles(stylesGenerator, { isOpenBuySell, openBuySellType });

    useEffect(() => {
        if(data) {
            let bcolor = getPriceBackgroundColor(theme, isMarketOpen, data ? data.askPriceHasIncreased : undefined);
            setPriceBackgroundColorAsk(bcolor);
            setPriceColorAsk(
                getPriceColor(
                    theme,
                    lastPriceBackgroundColorAsk,
                    isMarketOpen,
                    data ? data.askPriceHasIncreased : undefined
                )
            );
            if (bcolor !== '' && bcolor !== theme.text_symbol) {
                setLastPriceBackgroundColorAsk(bcolor);
                if (data.askPriceHasIncreased === true || data.askPriceHasIncreased === false) {
                    setTimeout(() => {
                        setPriceBackgroundColorAsk('');
                        setPriceColorAsk(bcolor);
                    }, colorPriceTime);
                }
            }
        }
    }, [data, isMarketOpen, lastPriceBackgroundColorAsk, theme]);

    useEffect(() => {
        if(data) {
            let bcolor = getPriceBackgroundColor(theme, isMarketOpen, data ? data.bidPriceHasIncreased : undefined);
            setPriceBackgroundColorBid(bcolor);
            setPriceColorBid(
                getPriceColor(
                    theme,
                    lastPriceBackgroundColorBid,
                    isMarketOpen,
                    data ? data.bidPriceHasIncreased : undefined
                )
            );
            if (bcolor !== '' && bcolor !== theme.text_symbol) {
                setLastPriceBackgroundColorBid(bcolor);
                if (data.bidPriceHasIncreased === true || data.bidPriceHasIncreased === false) {
                    setTimeout(() => {
                        setPriceBackgroundColorBid('');
                        setPriceColorBid(bcolor);
                    }, colorPriceTime);
                }
            }
        }
    }, [data, isMarketOpen, lastPriceBackgroundColorBid, theme]);

    if (data === undefined) {
        return <View />;
    }

    return (
        <>
            <Pressable
                style={[styles.container, { backgroundColor: theme.backgroundSecondary }]}
                onPress={() => {
                    setActiveChart ? setActiveChart(data.symbol) : null;
                    setIsOpenBuySell(!isOpenBuySell);
                    setOpenBuySellType('');
                }}
            >
                <View style={styles.toolbar}>
                    <View style={styles.symbolContainer}>
                        <Text
                            fontType="LATO_BOLD"
                            style={[styles.mediumText, { color: isMarketOpen ? theme.text_symbol : theme.equityValue }]}
                        >
                            {data.nameTranslated}
                        </Text>
                        <View style={styles.dailyChangeContainer}>
                            <Text
                                fontType="LATO_BOLD"
                                style={[
                                    styles.dailyChangeSmallText,
                                    {
                                        color: chartColor,
                                    },
                                ]}
                            >
                                {formatDailyChange(dailyChange)}
                            </Text>
                            <View style={styles.arrowContainer}>
                                <Foundation
                                    name={dailyChange > 0 ? 'arrow-up' : 'arrow-down'}
                                    size={12}
                                    style={{ color: chartColor }}
                                />
                            </View>
                        </View>
                    </View>
                    <View style={styles.rightAligned}>
                        <View
                            style={[
                                styles.priceContainer,
                                isMarketOpen && priceBackgroundColorBid !== ''
                                    ? { backgroundColor: priceBackgroundColorBid }
                                    : null,
                            ]}
                        >
                            <Text
                                fontType="LATO_BOLD"
                                style={[
                                    styles.priceText,
                                    {
                                        color: priceColorBid,
                                    },
                                ]}
                            >
                                {formatNumber(data.price.bid, data.digits)}
                            </Text>
                        </View>
                        <View style={styles.arrowContainer}>
                            {isMarketOpen ? (
                                <Foundation
                                    name={
                                        (data.askPriceHasIncreased === true || data.bidPriceHasIncreased === true) &&
                                        data.askPriceHasIncreased !== false &&
                                        data.bidPriceHasIncreased !== false
                                            ? 'arrow-up'
                                            : 'arrow-down'
                                    }
                                    size={12}
                                    style={{
                                        color:
                                            (data.askPriceHasIncreased === true ||
                                                data.bidPriceHasIncreased === true) &&
                                            data.askPriceHasIncreased !== false &&
                                            data.bidPriceHasIncreased !== false
                                                ? lastPriceBackgroundColorAsk
                                                : (data.askPriceHasIncreased === false ||
                                                      data.bidPriceHasIncreased === false) &&
                                                  data.askPriceHasIncreased !== true &&
                                                  data.bidPriceHasIncreased !== true &&
                                                  lastPriceBackgroundColorAsk
                                                ? lastPriceBackgroundColorBid
                                                    ? lastPriceBackgroundColorBid
                                                    : theme.backgroundSecondary
                                                : theme.backgroundSecondary,
                                    }}
                                />
                            ) : null}
                        </View>
                        <View
                            style={[
                                styles.priceContainer,
                                isMarketOpen && priceBackgroundColorAsk !== ''
                                    ? { backgroundColor: priceBackgroundColorAsk }
                                    : null,
                            ]}
                        >
                            <Text
                                fontType="LATO_BOLD"
                                style={[
                                    styles.priceText,
                                    {
                                        color: priceColorAsk,
                                    },
                                ]}
                            >
                                {formatNumber(data.price.ask, data.digits)}
                            </Text>
                        </View>
                    </View>
                </View>
            </Pressable>
            {isOpenBuySell && openBuySellType === '' ? (
                <View style={styles.buySellContainer}>
                    <BuySellPosition selectedSymbol={data.symbol} setOpenBuySellType={setOpenBuySellType} />
                </View>
            ) : null}
            {openBuySellType !== '' ? (
                <View style={styles.buySellTypeContainer}>
                    <MarketOrder
                        selectedSymbol={data.symbol}
                        selectedSide={openBuySellType}
                        setOpenBuySellType={setOpenBuySellType}
                    />
                </View>
            ) : null}
        </>
    );
}

const stylesGenerator = (isOpenBuySell: any, openBuySellType: any) =>
    StyleSheet.create({
        buySellTypeContainer: { marginHorizontal: scaleSize(-7) },
        buySellContainer: { marginHorizontal: scaleSize(-7) },
        dailyChangeContainer: { flexDirection: 'row' },
        arrowContainer: { marginHorizontal: 10 },
        container: {
            height: 50,
            alignItems: 'center',
            padding: scaleSize(10),
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            borderBottomLeftRadius: isOpenBuySell || openBuySellType !== '' ? 0 : scaleSize(5),
            borderBottomRightRadius: isOpenBuySell || openBuySellType !== '' ? 0 : scaleSize(5),
            marginTop: scaleSize(7),
            marginRight: scaleSize(7),
            width: '98.13%',
        },
        mediumText: {
            fontSize: FONT_SIZE_M,
        },
        priceText: {
            fontSize: FONT_SIZE_S,
            letterSpacing: 0.5,
        },
        dailyChangeSmallText: {
            fontSize: FONT_SIZE_S,
            letterSpacing: 0.5,
        },
        toolbar: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        rightAligned: {
            alignItems: 'flex-end',
            flexDirection: 'row',
        },
        priceContainer: { borderRadius: 5, width: 80, height: 20, justifyContent: 'center', alignItems: 'center' },
        symbolContainer: { height: '100%' },
    });
