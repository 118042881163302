import React from 'react';
import { StyleSheet, ViewProps } from 'react-native';

import ThemedView from '~/components/core/ThemedView';

export default function ScreenWrapper({ style, ...rest }: ViewProps) {
    return <ThemedView style={[style, styles.container]} {...rest} />;
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
    },
});
