import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';

import { Text } from '~/components/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAppSelector, useLogout, useStyles } from '~/hooks';
import { scaleSize } from '~/constants/scale';
import { Ionicons } from '@expo/vector-icons';
import { Theme } from '~/theme';
import { FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { userAuthDataSelector, userDataSelector } from '~/state/selectors';

export default function Header() {
    const styles = useStyles(stylesGenerator);
    const logout = useLogout();
    const name = useAppSelector(userDataSelector)?.data?.accountDetails.name;
    const id = useAppSelector(userDataSelector)?.data?.accountDetails.id;
    const { type } = useAppSelector(userAuthDataSelector);

    return (
        <View style={styles.headerContainer}>
            <View style={styles.profileWithNameContainer}>
                <View style={styles.profileContainer}>
                    <Ionicons name="person-circle-outline" style={styles.profileIconColor} size={scaleSize(22)} />
                </View>
                <View style={styles.nameContainer}>
                    <Text fontType="LATO_BOLD" style={styles.nameText} numberOfLines={1} ellipsizeMode="tail">
                        {name ? name + '/' + id  : ''}
                    </Text>
                </View>
            </View>
            <Pressable style={styles.background_logoutButton} onPress={logout}>
                <Text fontType="LATO_BOLD" style={[styles.background_logoutButtonText]}>
                    {'logout-label'}
                </Text>
            </Pressable>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        nameContainer: { height: scaleSize(25), width: scaleSize(225), justifyContent: 'center' },
        headerContainer: {
            paddingHorizontal: scaleSize(14),
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: '10%',
            alignItems: 'center',
            width: '100%',
            backgroundColor: theme.backgroundSecondary,
        },
        nameText: { fontSize: FONT_SIZE_S, color: theme.text_pendingOrder },
        background_logoutButton: {
            width: scaleSize(80),
            justifyContent: 'center',
            alignItems: 'center',
            height: scaleSize(22),
            borderRadius: scaleSize(5),
            borderColor: theme.background_closeTradeButton,
            borderWidth: 1,
            backgroundColor: theme.background_closeTradeButtonPressed,
        },
        background_logoutButtonText: { color: theme.background_closeTradeButton, fontSize: FONT_SIZE_XS },
        profileWithNameContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        profileContainer: {
            backgroundColor: theme.tabIconSelected,
            height: scaleSize(24),
            width: scaleSize(24),
            borderRadius: scaleSize(12),
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: scaleSize(6),
        },
        profileIconColor: {
            color: theme.white,
        },
    });
