import React, {useState, useEffect} from 'react';
import BigChart from '../features/BigChart';
import ScreenWrapper from '../features/Theme/ScreenWrapper';
import * as ScreenOrientation from "expo-screen-orientation";
import { Platform } from 'react-native';



export default function BigChartScreen() {

    const [orientation, setOrientation] = useState(6);
    useEffect(() => {
        lockOrientation();
    }, [orientation]);

    const lockOrientation = async () => {
        if(Platform.OS !== 'web') {
            await ScreenOrientation.lockAsync(
                ScreenOrientation.OrientationLock.LANDSCAPE_LEFT
            );
            const o = await ScreenOrientation.getOrientationAsync();
            setOrientation(o);
        }
      };

    return (
        <ScreenWrapper>
            <BigChart />
        </ScreenWrapper>
    );
}
