import React from 'react';

import RegistrationForm from '~/components/features/Authentication/RegistrationForm';
import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function RegistrationScreen() {
    return (
        <ScreenWrapper>
            <RegistrationForm />
        </ScreenWrapper>
    );
}
