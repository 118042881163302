import { useEffect, useState } from 'react';
import ExchangeService from '~/api-services/ExchangeService';
import { ExchangeRates } from '~/api-services/ExchangeService/types';

export const useExchangeRates = (symbol: string) => {
    const [exchangeRates, setExchangeRates] = useState<ExchangeRates>();

    useEffect(() => {
        (async () => {
            if (symbol) {
                const { status, data } = await ExchangeService.getProfitLossExchangeRates(symbol);
                if (status === 200 && data.status) {
                    setExchangeRates(data.returnData);
                }
            }
        })();
    }, []);

    return {
        exchangeRates,
    };
};
