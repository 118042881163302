import axios from '../baseClient';
import { TradingHoursRequest, TradingHoursResponse } from './types';

class WebsiteService {
    private static domain = '/api/24yieldwebservice';

    getTradingHours(tradingHoursRequest: TradingHoursRequest) {
        return axios.post<TradingHoursResponse>(`${WebsiteService.domain}/getInstrumentInfo`, tradingHoursRequest);
    }
}

export default new WebsiteService();
