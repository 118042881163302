import i18n from 'i18n-js';

import languages from './languages';

i18n.translations = languages;
i18n.defaultLocale = 'en';
i18n.locale = 'en';
i18n.fallbacks = true;
i18n.missingTranslation = (scope) => scope;

export default i18n;
