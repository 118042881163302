import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { isDesktop, scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { SafeAreaView } from 'react-native-safe-area-context';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { goBack, linkTo } from '~/navigation';
import { Platform } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { useAppSelector, useStyles } from '~/hooks';
import { appSettingsSelector, userAuthDataSelector } from '~/state/selectors';
import paths from '~/navigation/paths';
import { Ionicons } from '@expo/vector-icons';
import config from '~/config';
import { StatusBar } from 'expo-status-bar';


type Props = {
    chartSymbol?: string;
    mode?: 'light' | 'full';
};

export default function BigChart({ chartSymbol, mode }: Props) {
    const { WebView } = Platform.OS === 'web' ? require('react-native-web-webview') : require('react-native-webview');
    const uri = '://' + config.EXTERNAL_SOURCES_URL + 'tradingview/index.html';
    const protocol = 'https';
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const route: any = useRoute();
    const symbol = chartSymbol ? chartSymbol : (route.params.symbol as string); //TODO
    const styles = useStyles(stylesGenerator);
    const user = useAppSelector(userAuthDataSelector);

    function getView() {
        return (
            <View style={styles.chartContainerLight}>
                {isDesktop() || mode === 'light' ? null : (
                    <Pressable
                        style={styles.closeIcon}
                        onPress={() => {
                            goBack();
                        }}
                    >
                        <MaterialCommunityIcons style={styles.closeButton} name="close" size={30} />
                    </Pressable>
                )}
                {mode === 'light' ? (
                    <View style={[styles.periodsContainer, { right: scaleSize(5), height: scaleSize(50), }]}>
                        <Pressable onPress={() => linkTo(paths.chartingLibrary.replace(':symbol', symbol))}>
                            <Ionicons style={styles.chartTypeIcon} name="expand" size={28} />
                        </Pressable>
                    </View>
                ) : null}
                <View style={isDesktop() || mode === 'light' ? styles.chartContainerLight : [styles.chartContainer, { width: '100%', height: '100%', }]}>
                    <WebView
                        cacheEnabled={true}
                        originWhitelist={['*']}
                        usesCleartextTraffic={true}
                        source={{
                            uri: `${protocol + uri}?theme=${colorScheme}&symbol=${symbol}&s=${user.sessionId
                                }&mode=${mode}`,
                        }}
                    />
                </View>
                <StatusBar style={colorScheme === 'dark' ? 'light' : 'dark'} hidden={true} />
            </View>
        );
    }

    if (mode === 'light') {
        return <>{getView()}</>;
    } else {
        return <SafeAreaView style={styles.safeArea}>{getView()}</SafeAreaView>;
    }
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        chartTypeIcon: { color: theme.text_chartNumbers },
        periodsContainer: {
            position: 'absolute',
            zIndex: 2,
            top: 5,
            flexDirection: 'row',
        },
        safeArea: { flex: 1 },
        closeButton: { color: theme.text_pendingOrder },
        chartContainer: {
            flexDirection: 'row'
        },
        chartContainerLight: { flexDirection: 'row', height: '100%', width: '100%' },
        closeIcon: {
            alignItems: 'flex-end',
            right: 5,
            top: 0,
            position: 'absolute',
            zIndex: 100,
        },
    });
