import React, { useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { useInterestSwap } from '~/hooks/useInterestSwap';
import InterestRow from './InterestRow';
import DateRow, { getDateRow } from '../Result/DateRow';
import FromToSelector from '../Result/FromToSelector';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import Loader from '~/components/core/Loader';

const now = new Date();
const prevPeriod = new Date(now.getTime() - 30 * 24 * 3600 * 1000);
prevPeriod.setHours(0);
prevPeriod.setMinutes(0);
prevPeriod.setSeconds(0);

export default function InterestSwap() {
    const [fromButton, setFromButton] = useState(prevPeriod);
    const [toButton, setToButton] = useState(now);
    const { interests, totalFormatted, totalDirection, loading } = useInterestSwap(fromButton, toButton);
    const styles = useStyles(stylesGenerator);

    return (
        <>
            <Loader loading={loading} />
            <ScrollbarWrapper>
                <View style={styles.ordersContainer}>
                    {interests.length !== 0 &&
                        interests.map((interest, key) => (
                            <View style={styles.button} key={key}>
                                {getDateRow(interest.timestamp, key) !== '' ? (
                                    <DateRow timestamp={interest.timestamp} />
                                ) : null}
                                <InterestRow interest={interest} />
                            </View>
                        ))}
                </View>
            </ScrollbarWrapper>
            <FromToSelector
                setFromButton={setFromButton}
                setToButton={setToButton}
                prevPeriod={prevPeriod}
                totalFormatted={totalFormatted}
                totalDirection={totalDirection}
            />
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        ordersContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
        },
        button: { width: '100%' },
    });
