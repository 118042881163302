import { useEffect, useRef, useState, useCallback } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { useIsScreenActive } from './useIsScreenActive';
import { loadUserTradesData } from '~/state/reducers/userDataSlice';
import { accountPositionsSelector } from '~/state/selectors';
import { useSubscribeQuote } from './useSubscribeQuote';

export const useOpenTradesData = () => {
    const dispatch = useAppDispatch();
    const isScreenActive = useIsScreenActive();
    const openTradesDataPollRef = useRef<NodeJS.Timer>();
    const positions = useAppSelector(accountPositionsSelector);
    const [openSymbol, setOpenSymbol] = useState<string | null>(null);
    const loadTradesData = useCallback(() => dispatch(loadUserTradesData()), [dispatch]);
    useSubscribeQuote(openSymbol);

    useEffect(() => {
        if (isScreenActive) {
            openTradesDataPollRef.current = setInterval(loadTradesData, 5000);
            loadTradesData();
        } else {
            openTradesDataPollRef.current && clearInterval(openTradesDataPollRef.current);
        }
        return () => {
            openTradesDataPollRef.current && clearInterval(openTradesDataPollRef.current);
        };
    }, [loadTradesData, isScreenActive]);

    return {
        positions,
        openSymbol,
        setOpenSymbol,
    };
};
