import { useFonts } from 'expo-font';

export const useLoadFonts = () => {
    const [fontsLoaded] = useFonts({
        'Montserrat-Bold': require('~/../assets/fonts/Montserrat-Bold.ttf'),
        'Montserrat-Regular': require('~/../assets/fonts/Montserrat-Regular.ttf'),
        'Lato-Bold': require('~/../assets/fonts/Lato-Bold.ttf'),
        'Lato-Regular': require('~/../assets/fonts/Lato-Regular.ttf'),
        'Lato-Black': require('~/../assets/fonts/Lato-Black.ttf'),
        'Lato-Italic': require('~/../assets/fonts/Lato-Italic.ttf'),
        'Lato-Italic-Bold': require('~/../assets/fonts/Lato-BoldItalic.ttf'),
    });

    return fontsLoaded;
};
