import React from 'react';
import { Platform, StyleSheet, Switch as RNSwitch, SwitchProps } from 'react-native';
import { scaleSize } from '~/constants/scale';

import { useStyles } from '~/hooks';
import { useTheme } from '~/hooks/useTheme';

const Switch = (props: SwitchProps) => {
    const theme = useTheme();
    const styles = useStyles(stylesGenerator);

    return (
        <RNSwitch
            trackColor={{ false: theme.switch_trackOff, true: theme.text_upPrice }}
            thumbColor={props.value ? theme.switch_tumbOn : theme.switch_tumbOff}
            // @ts-ignore
            activeThumbColor={props.value ? theme.switch_tumbOn : theme.switch_tumbOff}
            ios_backgroundColor={theme.switch_trackOff}
            {...props}
            style={[styles.switch, Platform.OS !== 'ios' ? {height: scaleSize(30),} : null]}
        />
    );
};

export default Switch;

const stylesGenerator = () =>
    StyleSheet.create({
        switch: {
            
            transform: Platform.OS === 'ios' ? [{ scaleX: 0.75 }, { scaleY: 0.75 }] : [{ scaleX: 1 }, { scaleY: 1 }],
        },
    });
