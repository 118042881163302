import axios from '../baseClient';
import { ConfigRequest, ConfigResponse, RatesAndCUrrenciesRequest, RatesAndCUrrenciesResponse } from './types';

class PaymentConverterService {
    private static domain = '/api/convertrateservice';

    getConfig(configRequest: ConfigRequest) {
        return axios.post<ConfigResponse>(`${PaymentConverterService.domain}/config`, configRequest);
    }

    getRatesAndCUrrencies(ratesAndCUrrenciesRequest: RatesAndCUrrenciesRequest) {
        return axios.post<RatesAndCUrrenciesResponse>(
            `${PaymentConverterService.domain}/rates`,
            ratesAndCUrrenciesRequest
        );
    }
}

export default new PaymentConverterService();
