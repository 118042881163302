import React from 'react';
import { View, StyleSheet, useWindowDimensions, Linking } from 'react-native';
import { Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { formatDate } from '~/lib/format';
import { InstrumentNews } from '~/api-services/NewsService/types';
import { scaleSize } from '~/constants/scale';
import { FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import { appSettingsSelector } from '~/state/selectors';
import RenderHTML from 'react-native-render-html';
import { useTheme } from '~/hooks/useTheme';

type Props = {
    news: InstrumentNews;
    isOpen: boolean;
};

export default function NewsRow({ news, isOpen }: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const theme = useTheme();
    const { width } = useWindowDimensions();

    return (
        <>
            <View
                style={[
                    styles.positionGroupContainer,
                    styles.positionGroup,
                    colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                ]}
                key={'instrumentContainer' + news.id}
            >
                <View style={styles.titleContainer}>
                    <Text fontType="LATO_REGULAR" style={styles.newsTitleText}>
                        {news.title}
                    </Text>
                </View>
                {isOpen ? (
                    <>
                        <RenderHTML
                            contentWidth={width}
                            source={{
                                html: '<!DOCTYPE html><html><body style="font-size: ' +
                                    FONT_SIZE_S +
                                    'px; color: ' +
                                    theme.text_pendingOrder +
                                    ' ">' +
                                    news.description +
                                    '</body></html>',
                            }} />
                        <Text style={styles.readMoreText} fontType='LATO_REGULAR' onPress={() => {if(news.link) {Linking.openURL(news.link)}}} >read-more</Text>
                    </>
                ) : null}
                <View style={styles.newsSourceContainer}>
                    <Text fontType="LATO_REGULAR" style={styles.newsBodyText}>
                        {news.source}
                    </Text>
                    <Text fontType="LATO_REGULAR" style={styles.newsBodyText}>
                        {formatDate(news.date)}
                    </Text>
                </View>
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        readMoreText: {fontSize: FONT_SIZE_S, color: theme.text_upPrice},
        positionGroup: { borderRadius: scaleSize(5), marginTop: scaleSize(7) },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },
        positionGroupContainer: {
            borderWidth: 1,
            width: '100%',
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            backgroundColor: theme.backgroundSecondary,
            paddingHorizontal: scaleSize(10),
        },
        newsSourceContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: scaleSize(5),
        },
        newsBodyText: { fontSize: FONT_SIZE_XS, color: theme.text_pendingOrder },
        newsTitleText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        titleContainer: { paddingVertical: scaleSize(5) },
        newsContainer: {
            marginVertical: scaleSize(7),
            marginHorizontal: scaleSize(17),
            paddingHorizontal: scaleSize(10),
            borderRadius: scaleSize(5),
            backgroundColor: theme.backgroundSecondary,
        },
    });
