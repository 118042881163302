import React, { useState, useEffect } from 'react';
import { View, StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';

import { scaleSize } from '~/constants/scale';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useStyles } from '~/hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Theme } from '~/theme';
import { CustomerDataResponse } from '~/api-services/VerificationService/types';
import { Button, ErrorMessage, Text, TextInput } from '~/components/core';
import { FONT_SIZE_M, FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import VerificationService from '~/api-services/VerificationService';
import i18n from '~/i18n';

type Props = {
    customerData: CustomerDataResponse;
    goToNextStep: Function;
    goToPrevStep: Function;
    setLoading: Function;
};

export default function OtherQuestions({ customerData, goToNextStep, goToPrevStep, setLoading }: Props) {
    const styles = useStyles(stylesGenerator);
    const [generalError, setGeneralError] = useState('');
    const [assetManagement, setAssetManagement] = useState('');
    const [businessAndProfessionalActivity, setBusinessAndProfessionalActivity] = useState('');
    const [frequentCashPayments, setFrequentCashPayments] = useState('');
    const [servicesToUse, setServicesToUse] = useState('');

    const [showAssetManagementValidationMessage, setShowAssetManagementValidationMessage] = useState(false);
    const [showBusinessAndProfessionalActivityValidationMessage, setShowBusinessAndProfessionalActivityValidationMessage] = useState(false);
    const [showServicesToUseValidationMessage, setShowServicesToUseValidationMessage] = useState(false);

    useEffect(() => {
        if (customerData) {
            if (customerData.assetManagement) {
                setAssetManagement(customerData.assetManagement);
            }
            if (customerData.businessAndProfessionalActivity) {
                setBusinessAndProfessionalActivity(customerData.businessAndProfessionalActivity);
            }
            if (customerData.frequentCashPayments) {
                setFrequentCashPayments(customerData.frequentCashPayments);
            }
            if (customerData.servicesToUse) {
                setServicesToUse(customerData.servicesToUse);
            }
        }
    }, [customerData]);

    function validateForm(): boolean {
        let formValid: boolean = true;
        setGeneralError('');
        setShowAssetManagementValidationMessage(false);
        setShowBusinessAndProfessionalActivityValidationMessage(false);
        setShowServicesToUseValidationMessage(false);
        if (assetManagement === '') {
            formValid = false;
            setShowAssetManagementValidationMessage(true);
        }
        if (businessAndProfessionalActivity === '') {
            formValid = false;
            setShowBusinessAndProfessionalActivityValidationMessage(true);
        }
        if (servicesToUse === '') {
            formValid = false;
            setShowServicesToUseValidationMessage(true);
        }
        return formValid;
    }

    async function setOtherQuestions() {
        if (validateForm()) {
            setLoading(true);
            const { status, data } = await VerificationService.setOtherQuestions(
                {
                    assetManagement: assetManagement,
                    businessAndProfessionalActivity: businessAndProfessionalActivity,
                    frequentCashPayments: frequentCashPayments,
                    servicesToUse: servicesToUse
                }
            );
            if (status === 200) {
                if (data.status === true) {
                    goToNextStep();
                }
                else {
                    if (data.errorCode) {
                        if (data.errorCode === 'ArgumentRequiredException') {
                            if (data.errorContext && data.errorContext.field) {
                                if (data.errorContext.field) {
                                    setGeneralError(i18n.t('please-fill-all-required-fields') + ': ' + data.errorContext.field);
                                }
                                else {
                                    setGeneralError(data.errorCode);
                                }
                            }
                            else {
                                setGeneralError(data.errorCode);
                            }
                        }
                        else {
                            setGeneralError(data.errorCode);
                        }
                    }
                }
            }
            else {
                setLoading(false);
                setGeneralError('Error');
            }
            setLoading(false);
        }
    }


    return (
        <View style={styles.safeAreaStyle}>
            <View style={styles.vcTitleContainer}>
                <Text fontType="LATO_BOLD" style={styles.vcTitleText}>
                    other-questions
                </Text>
            </View>
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                profesional-activity
            </Text>
            <TextInput
                containerStyle={styles.inputContainer}
                style={styles.textInputStyle}
                autoCapitalize="none"
                autoFocus={false}
                value={businessAndProfessionalActivity}
                onChangeText={(text) => setBusinessAndProfessionalActivity(text)}
                onBlur={() => setShowBusinessAndProfessionalActivityValidationMessage(true)}
                returnKeyType='next' />
            <ErrorMessage
                text={'please-enter-value'}
                visible={businessAndProfessionalActivity === '' && showBusinessAndProfessionalActivityValidationMessage} />
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                frequent-cash-payments
            </Text>
            <TextInput
                containerStyle={styles.inputContainer}
                style={styles.textInputStyle}
                autoCapitalize="none"
                autoFocus={false}
                value={frequentCashPayments}
                onChangeText={(text) => setFrequentCashPayments(text)}
                returnKeyType='next' />
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                purpose-of-registering
            </Text>
            <TextInput
                containerStyle={styles.inputContainer}
                style={styles.textInputStyle}
                autoCapitalize="none"
                autoFocus={false}
                value={servicesToUse}
                onChangeText={(text) => setServicesToUse(text)}
                onBlur={() => setShowServicesToUseValidationMessage(true)}
                returnKeyType='next' />
            <ErrorMessage
                text={'please-enter-value'}
                visible={servicesToUse === '' && showServicesToUseValidationMessage} />
            <Text fontType="LATO_REGULAR" style={styles.labelText}>
                asset-managment
            </Text>
            <TextInput
                containerStyle={styles.inputContainer}
                style={styles.textInputStyle}
                autoCapitalize="none"
                autoFocus={false}
                value={assetManagement}
                onChangeText={(text) => setAssetManagement(text)}
                onBlur={() => setShowAssetManagementValidationMessage(true)}
                returnKeyType='next' />
            <ErrorMessage
                text={'please-enter-value'}
                visible={assetManagement === '' && showAssetManagementValidationMessage} />
            <ErrorMessage
                text={generalError}
                visible={generalError !== ''}
            />
            <View style={styles.buttonsContainer}>
                <View style={styles.buttonContainer} />
                <View style={styles.buttonContainer}>
                    <Button
                        onPress={() => setOtherQuestions()}
                        title="finish"
                    />
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        safeAreaStyle: { flex: 1, paddingHorizontal: scaleSize(Platform.OS !== 'web' ? 0 : 12), },
        backButtonStyle: { backgroundColor: theme.tabIconDefault },
        yesNoContainer: { flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: '25%' },
        buttonsContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: scaleSize(20),
        },
        buttonContainer: {
            width: scaleSize(150),
            height: scaleSize(40),
        },
        pickerRow: {
            width: '100%',
            height: scaleSize(40),
            fontSize: scaleSize(FONT_SIZE_S),
            color: theme.text
        },
        pickerStyle: {
            width: '100%',
            height: scaleSize(40),
            fontSize: scaleSize(FONT_SIZE_M),
            backgroundColor: theme.backgroundSecondary,
            color: theme.text_pendingOrder,
            borderRadius: scaleSize(5),
            padding: 0,
            marginBottom: scaleSize(10)
        },
        textInputStyle: {
            fontSize: scaleSize(FONT_SIZE_M),
            color: theme.text_pendingOrder,
            width: '100%'
        },
        labelText: { fontSize: FONT_SIZE_XS, color: theme.text_symbol, paddingVertical: scaleSize(10) },
        bdateContainer: { flexDirection: 'row', justifyContent: 'space-between' },
        bdatePickerContainer: { width: '31%' },
        vcTitleContainer: {
            justifyContent: 'center',
            paddingVertical: scaleSize(20),
        },
        vcTitleText: { fontSize: FONT_SIZE_S, color: theme.text_symbol },
        inputContainer: {
            marginBottom: scaleSize(10),
        },
        keyBoard: { width: '100%' },
        viewContainer: { justifyContent: 'space-between', height: '80%', paddingBottom: scaleSize(10) },
        scrollView: { maxHeight: '100%', height: '100%' },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
            width: '100%',
        },
    });
