import { useEffect, useState } from 'react';
import PaymentConverterService from '~/api-services/PaymentConverterService';
import { RatesAndCUrrenciesType } from '~/api-services/PaymentConverterService/types';

export const useRates = (provider: string, method: string) => {
    const [ratesAndCurrencies, setRatesAndCurrencies] = useState<RatesAndCUrrenciesType>();

    useEffect(() => {
        (async () => {
            const { status, data } = await PaymentConverterService.getRatesAndCUrrencies({
                provider: provider,
                method: method,
            });
            if (status === 200 && data.status) {
                setRatesAndCurrencies(data.returnData);
            }
        })();
    }, [method, provider]);

    return {
        ratesAndCurrencies,
    };
};
