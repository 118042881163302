import { useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { View, StyleSheet, Pressable, KeyboardAvoidingView } from 'react-native';
import { Button, Text } from '~/components/core';
import { FONT_SIZE_XXXL, FONT_SIZE_XS, FONT_SIZE_LS } from '~/constants';
import { formatCurrency, formatDate, formatNumber, formatNumberWithPercent } from '~/lib/format';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useAppDispatch, useAppSelector, useOpenTradesData, useStyles } from '~/hooks';
import { FontAwesome5 } from '@expo/vector-icons';
import {
    accountBalanceSelector,
    positionBy2IdSelector,
    symbolsSelector,
    watchlistDataSelector,
} from '~/state/selectors';
import { useSubscribeQuote } from '~/hooks/useSubscribeQuote';
import { Position } from '~/api-services/LoadService/types';
import PositionDetail from './PositionDetail';
import PendingOrder from '../PendingOrderComponent';
import { CoreDataService } from '~/api-services';
import { linkTo } from '~/navigation';
import { Theme } from '~/theme';
import ConfirmationMessage from '../ConfirmationMessage';
import BuySellHeader from '../BuySellHeader';
import AlertService from '~/api-services/AlertService';
import paths from '~/navigation/paths';
import { useMarketOrder } from '~/hooks/useMarketOrder';
import Loader from '~/components/core/Loader';
import { loadUserKeepALiveData } from '~/state/reducers/userDataSlice';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { SafeAreaView } from 'react-native-safe-area-context';

type EditPositionProps = {
    position: Position;
    setConfirmationVisible: Function;
    setConfirmationProps: Function;
    setOpenEditPositionId: Function;
};

export type EditPositionDetail = {
    label: string;
    value: string;
    valueStyle: any;
};

export function EditPosition({
    position,
    setConfirmationVisible,
    setConfirmationProps,
    setOpenEditPositionId,
}: EditPositionProps) {
    const styles = useStyles(stylesGenerator);
    const [isActiveConfirmButton, setIsActiveConfirmButton] = useState(false);
    const [isActiveCloseButton, setIsActiveCloseButton] = useState(true);
    const dataSymbol = useAppSelector((state) => symbolsSelector(state)[position.symbol]);
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';
    const [isOpenTakeProfit, setIsOpenTakeProfit] = useState(position.limitProfit !== null);
    const [isOpenStopLoss, setIsOpenStopLoss] = useState(position.stopLoss !== null);
    const [pendingOrderPrice, setPendingOrderPrice] = useState(position.openPrice);
    const [limitProfitPrice, setLimitProfitPrice] = useState(
        position.limitProfit
            ? position.limitProfit
            : position.side === 'buy'
            ? position.pending
                ? pendingOrderPrice * 1.005
                : position.marketPrice * 1.005
            : position.pending
            ? pendingOrderPrice * 0.995
            : position.marketPrice * 0.995
    );
    const [stopLossPrice, setStopLossPrice] = useState(
        position.stopLoss
            ? position.stopLoss
            : position.side === 'buy'
            ? position.pending
                ? pendingOrderPrice * 0.995
                : position.marketPrice * 0.995
            : position.pending
            ? pendingOrderPrice * 1.005
            : position.marketPrice * 1.005
    );

    useSubscribeQuote(position.symbol);

    const isPending = position.pending;

    const marketPrice = position.side === 'buy' && !isPending ? dataSymbol.price.bid : dataSymbol.price.ask;

    const { maxPendingOrderPrice, minPendingOrderPrice, exchangeRates } = useMarketOrder(
        position.symbol,
        position.side
    );

    const positionDetails: EditPositionDetail[] = [
        // Left Side
        {
            label: 'position-volume',
            value: formatNumber(position.volume, dataSymbol.volumePrecision),
            valueStyle: styles.numberSpacing,
        },
        {
            label: 'position-open-price',
            value: formatNumber(position.openPrice, dataSymbol.digits),
            valueStyle: styles.numberSpacing,
        },
        {
            label: 'position-market-price',
            value:
                position.side === 'buy'
                    ? formatNumber(dataSymbol.price.bid, dataSymbol.digits)
                    : formatNumber(dataSymbol.price.ask, dataSymbol.digits),
            valueStyle: styles.numberSpacing,
        },
        {
            label: 'T/P',
            value: position.limitProfit ? formatNumber(position.limitProfit, dataSymbol.digits) : 'not-set',
            valueStyle: position.limitProfit ? styles.numberSpacing : styles.textSpacing,
        },
        {
            label: 'S/L',
            value: position.stopLoss ? formatNumber(position.stopLoss, dataSymbol.digits) : 'not-set',
            valueStyle: position.stopLoss ? styles.numberSpacing : styles.textSpacing,
        }, // Right Side
        {
            label: 'position-direction',
            value: position.side.charAt(0).toUpperCase() + position.side.substring(1, position.side.length),
            valueStyle: styles.textSpacing,
        },
        {
            label: 'position-order-time',
            value: formatDate(position.timestamp),
            valueStyle: styles.textSpacing,
        },
        {
            label: 'position-margin',
            value: formatCurrency(position.margin, 2, currency),
            valueStyle: styles.numberSpacing,
        },
        {
            label: 'position-daily-swap',
            value: formatNumberWithPercent(position.interestPercentage, dataSymbol.type === 'FOREX' ? 6 : 4, true),
            valueStyle: styles.numberSpacing,
        },
    ];
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    function changePendingOrderPrice(newPrice: number) {
        setPendingOrderPrice(newPrice);
        setIsActiveConfirmButton(true);
    }

    async function editPosition() {
        setIsActiveConfirmButton(false);
        setLoading(true);
        if (position.pending) {
            const { status, data } = await CoreDataService.editPendingOrder({
                limitProfit: isOpenTakeProfit ? limitProfitPrice : null,
                stopLoss: isOpenStopLoss ? stopLossPrice : null,
                orderId: position.id,
                openPrice: pendingOrderPrice,
            });
            setLoading(false);
            if (status !== 200) {
                setConfirmationProps({ text: 'Error', error: true });
                setConfirmationVisible(true);
            }
            if (data.status) {
                if (data.returnData && data.returnData.order) {
                    setConfirmationProps({ text: 'pending-order-confirmed', error: false });
                    setConfirmationVisible(true);
                    let newId = data.returnData.order.id;
                    await dispatch(loadUserKeepALiveData());
                    setOpenEditPositionId(newId);
                }
            } else {
                setIsActiveConfirmButton(true);
                setConfirmationProps({ text: AlertService.getErrorResponse(data).message, error: true });
                setConfirmationVisible(true);
            }
        } else {
            try {
                const { status, data } = await CoreDataService.editPosition({
                    limitProfit: isOpenTakeProfit ? limitProfitPrice : null,
                    stopLoss: isOpenStopLoss ? stopLossPrice : null,
                    positionId: position.id,
                });
                setLoading(false);
                if (status !== 200) {
                    setConfirmationProps({ text: 'Error', error: true });
                    setConfirmationVisible(true);
                }
                if (data.status) {
                    setConfirmationProps({ text: 'pending-order-confirmed', error: false });
                    setConfirmationVisible(true);
                } else {
                    setIsActiveConfirmButton(true);
                    setConfirmationProps({ text: AlertService.getErrorResponse(data).message, error: true });
                    setConfirmationVisible(true);
                }
            } catch (e) {
                setLoading(false);
                setIsActiveConfirmButton(true);
                setConfirmationProps({ text: 'Error', error: true });
                setConfirmationVisible(true);
            }
        }
    }

    async function closePosition() {
        setIsActiveCloseButton(false);
        setLoading(true);
        if (position.pending) {
            const { status, data } = await CoreDataService.deletePendingOrder({
                orderId: position.id,
            });
            setIsActiveCloseButton(true);
            setLoading(false);
            if (status !== 200) {
                setConfirmationProps({ text: 'Error', error: true });
                setConfirmationVisible(true);
            }
            if (data.status) {
                setConfirmationProps({ text: 'pending-order-canceled', error: false });
                setConfirmationVisible(true);
            } else {
                setConfirmationProps({ text: AlertService.getErrorResponse(data).message, error: true });
                setConfirmationVisible(true);
            }
        } else {
            try {
                const { status, data } = await CoreDataService.closePosition({
                    closePrice: position.marketPrice,
                    positionId: position.id,
                });
                setIsActiveCloseButton(true);
                setLoading(false);
                if (status !== 200) {
                    setConfirmationProps({ text: 'Error', error: true });
                    setConfirmationVisible(true);
                }
                if (data.status) {
                    setConfirmationProps({ text: 'close-position-success', error: false });
                    setConfirmationVisible(true);
                } else {
                    setConfirmationProps({ text: AlertService.getErrorResponse(data).message, error: true });
                    setConfirmationVisible(true);
                }
            } catch (e) {
                setIsActiveCloseButton(true);
                setLoading(false);
                setConfirmationProps({ text: 'Error', error: true });
                setConfirmationVisible(true);
            }
        }
    }

    return (
            <KeyboardAvoidingView behavior={'height'}  style={styles.keyBoard}>
                <ScrollbarWrapper>
                    <SafeAreaView style={styles.safeAreaStyle}>
                <View style={styles.container}>
                    <Loader loading={loading} />
                    <BuySellHeader side={position.side} name={dataSymbol.nameTranslated} />
                    <View style={[styles.positionIdContainer]}>
                        <View style={styles.left}>
                            <Text fontType="LATO_REGULAR" style={[styles.text_positionId, styles.textSpacing]}>
                                {'position-position-id'}
                            </Text>
                            <Text fontType="LATO_REGULAR" style={[styles.text_positionId, styles.textSpacing]}>
                                {': '}
                            </Text>
                        </View>
                        <View style={styles.right}>
                            <Text fontType="LATO_REGULAR" style={[styles.text_positionId, styles.textSpacing]}>
                                {position.orderNumber + (position.investorUid ? ' CT' : '')}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.positionDetailsContainer}>
                        <View style={styles.positionDetailsColumn}>
                            {positionDetails.slice(0, 5).map((positionDetail: EditPositionDetail, index) => (
                                <PositionDetail
                                    editPositionDetail={positionDetail}
                                    rowStyle={[styles.positionDetailsRow, styles.positionDetailsRowLeft]}
                                    key={index}
                                />
                            ))}
                        </View>
                        <View style={styles.positionDetailsColumn}>
                            {positionDetails.slice(5, 9).map((positionDetail: EditPositionDetail, index) => (
                                <PositionDetail
                                    editPositionDetail={positionDetail}
                                    rowStyle={[styles.positionDetailsRow, styles.positionDetailsRowRight]}
                                    key={index}
                                />
                            ))}
                        </View>
                    </View>
                    <View style={styles.separator} />
                    {isPending ? (
                        <View style={styles.pendingOrderContainer}>
                            <PendingOrder
                                label={position.side === 'buy' ? 'buy-when-rate-is' : 'sell-when-rate-is'}
                                value={pendingOrderPrice}
                                setValue={changePendingOrderPrice}
                                marketPrice={marketPrice}
                                dataSymbol={dataSymbol}
                                side={position.side}
                                volume={position.volume}
                                isOpen={true}
                                setIsOpen={() => true}
                                showEstresult={false}
                                max={maxPendingOrderPrice}
                                min={minPendingOrderPrice}
                                pending={isPending}
                                exchangeRates={exchangeRates}
                            />
                        </View>
                    ) : null}
                    <View style={styles.pendingOrderContainer}>
                        <PendingOrder
                            label="position-take-profit"
                            value={limitProfitPrice}
                            setValue={setLimitProfitPrice}
                            setIsActiveConfirmButton={setIsActiveConfirmButton}
                            marketPrice={isPending ? pendingOrderPrice : position.openPrice}
                            dataSymbol={dataSymbol}
                            side={position.side}
                            volume={position.volume}
                            isOpen={isOpenTakeProfit}
                            setIsOpen={setIsOpenTakeProfit}
                            exchangeRates={exchangeRates}
                        />
                        <PendingOrder
                            label="position-stop-loss"
                            value={stopLossPrice}
                            setValue={setStopLossPrice}
                            setIsActiveConfirmButton={setIsActiveConfirmButton}
                            marketPrice={isPending ? pendingOrderPrice : position.openPrice}
                            dataSymbol={dataSymbol}
                            side={position.side}
                            volume={position.volume}
                            isOpen={isOpenStopLoss}
                            setIsOpen={setIsOpenStopLoss}
                            exchangeRates={exchangeRates}
                        />
                    </View>
                    <View style={styles.confirmContainer}>
                        <Button
                            onPress={() => editPosition()}
                            disabled={!isActiveConfirmButton}
                            removeOpacity={true}
                            title="position-confirm"
                            backgroundColor={
                                isActiveConfirmButton ? styles.confirmButtonActiveColor : styles.confirmButtonUnactiveColor
                            }
                            titleSize={FONT_SIZE_XXXL}
                        />
                    </View>
                    <View style={styles.closeTradeContainer}>
                        <Pressable
                            style={() => [
                                styles.background_closeTradeButton,
                                isActiveCloseButton
                                    ? styles.background_closeTradeButtonUnPressed
                                    : styles.background_closeTradeButtonPressed,
                                isPending ? styles.cancelPendingButtonWidth : styles.closeTradeButtonWidth,
                            ]}
                            onPress={() => closePosition()}
                            disabled={!isActiveCloseButton}
                        >
                            <Text
                                fontType="LATO_BOLD"
                                style={[
                                    isActiveCloseButton
                                        ? styles.background_closeTradeButtonText
                                        : styles.background_closeTradeButtonTextPressed,
                                ]}
                            >
                                {isPending ? 'cancel-pending-order' : 'edit-position-close-trade'}
                            </Text>
                        </Pressable>
                    </View>
                </View>
                </SafeAreaView>
            </ScrollbarWrapper>
        </KeyboardAvoidingView>
    );
}

export function EmptyScreen() {
    const styles = useStyles(stylesGenerator);
    const watchlist = useAppSelector(watchlistDataSelector);
    return (
        <View style={styles.emptyContainer}>
            <Pressable onPress={() => linkTo(paths.watchlistItem(watchlist[0].id))} style={styles.messageContainer}>
                <View style={styles.background_message}>
                    <View style={styles.messageIcon}>
                        <FontAwesome5 name="exclamation" style={styles.messageIconColor} size={scaleSize(15)} />
                    </View>
                    <Text style={styles.messageText} fontType="LATO_BOLD">
                        missing-edit-position
                    </Text>
                </View>
            </Pressable>
            <View style={styles.buttonContainer}>
                <View style={styles.buttonPosition}>
                    <Button
                        title={'explore-markets'}
                        titleSize={22}
                        onPress={() => linkTo(paths.watchlistItem(watchlist[0].id))}
                    />
                </View>
            </View>
        </View>
    );
}

type Props = {
    selectedId?: string;
};

export default function EditOpenPosition({ selectedId }: Props) {
    const route = useRoute();
    useOpenTradesData();
    const id = selectedId ? selectedId : (route.params as any).id;
    const [oldId, setOldId] = useState(id);
    const [newId, setNewId] = useState(id);
    const position = useAppSelector((state) => positionBy2IdSelector(state, oldId, newId));

    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });

    function changePositionId(newPositionId: string) {
        setOldId(newId);
        setNewId(newPositionId);
    }

    function changeConfirmationVisible(show: boolean) {
        setConfirmationVisible(show);
    }

    return (
        <>
            {position ? (
                <EditPosition
                    position={position}
                    setConfirmationVisible={changeConfirmationVisible}
                    setConfirmationProps={setConfirmationProps}
                    setOpenEditPositionId={changePositionId}
                />
            ) : !isDesktop() ? (
                <EmptyScreen />
            ) : null}
            <ConfirmationMessage
                text={confirmationProps.text}
                error={confirmationProps.error}
                visible={confirmationVisible}
                setVisible={(show: boolean) => changeConfirmationVisible(show)}
            />
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        keyBoard: { width: '100%', height: '100%' },
        safeAreaStyle: { flex: 1 },
        buttonPosition: {
            width: '91%',
            justifyContent: 'center',
            alignItems: 'center',
            marginHorizontal: '4.5%',
        },
        messageIconColor: { color: theme.background_message },
        buttonContainer: {
            width: '100%',
            height: '50%',
            paddingBottom: scaleSize(17),
            justifyContent: 'flex-end',
        },
        messageText: { fontSize: FONT_SIZE_LS, color: theme.white },
        messageIcon: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: scaleSize(6),
            backgroundColor: theme.white,
            height: scaleSize(26),
            width: scaleSize(26),
            borderRadius: scaleSize(13),
        },
        background_message: {
            height: scaleSize(80),
            backgroundColor: theme.background_message,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        messageContainer: {
            height: '50%',
            width: '100%',
            justifyContent: 'flex-end',
        },
        emptyContainer: { height: '100%', width: '100%', flex: 1, alignItems: 'center' },
        separator: {
            marginHorizontal: scaleSize(17),
            height: scaleSize(1),
            backgroundColor: theme.border_2,
        },
        pendingOrderContainer: { width: '100%' },
        confirmButtonActiveColor: { backgroundColor: theme.text_upPrice },
        confirmButtonUnactiveColor: { backgroundColor: theme.background_buttonInactive },
        confirmContainer: {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: scaleSize(18),
            paddingHorizontal: scaleSize(17),
        },
        closeTradeContainer: {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: scaleSize(18),
        },
        background_closeTradeButtonUnPressed: {
            backgroundColor: theme.background_closeTradeButtonPressed,
        },
        background_closeTradeButtonPressed: {
            backgroundColor: theme.background_closeTradeButton,
        },
        closeTradeButtonWidth: { width: scaleSize(100) },
        cancelPendingButtonWidth: { width: scaleSize(200) },
        background_closeTradeButton: {
            justifyContent: 'center',
            alignItems: 'center',
            height: scaleSize(25),
            borderRadius: scaleSize(2),
            borderColor: theme.background_closeTradeButton,
            borderWidth: 1,
        },
        background_closeTradeButtonText: {
            color: theme.background_closeTradeButton,
        },
        background_closeTradeButtonTextPressed: {
            color: theme.background_closeTradeButtonPressed,
        },
        container: { width: '100%' },
        positionDetailsContainer: { width: '100%', flexDirection: 'row', marginBottom: scaleSize(10) },
        text_positionId: { color: theme.text_positionId, fontSize: FONT_SIZE_XS },
        positionIdContainer: {
            width: '100%',
            height: scaleSize(20),
            backgroundColor: theme.background_positionId,
            flexDirection: 'row',
            justifyContent: 'center',
            paddingHorizontal: scaleSize(17),
            marginBottom: scaleSize(10),
        },
        positionDetailsRow: {
            flexDirection: 'row',
            width: '100%',
            paddingVertical: scaleSize(4),
            justifyContent: 'space-between',
        },
        positionDetailsRowLeft: {
            paddingLeft: scaleSize(17),
            paddingRight: scaleSize(8.5),
        },
        positionDetailsRowRight: {
            paddingRight: scaleSize(17),
            paddingLeft: scaleSize(8.5),
        },
        left: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
        },
        right: {
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'row',
        },

        positionDetailsColumn: { width: '50%' },
        numberSpacing: { letterSpacing: 0.5 },
        textSpacing: { letterSpacing: 0 },
    });
