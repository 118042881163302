import React from 'react';
import { StyleSheet, View, TextInput, Pressable } from 'react-native';
import { Theme } from '~/theme';
import { isDesktop, scaleSize } from '~/constants/scale';
import i18n from '~/i18n';
import { useStyles } from '~/hooks';
import { Text } from '~/components/core';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { DataSymbol } from '~/api-services/LoadService/types';


type Props = {
    searchTerm: string;
    setSearchTerm: any;
    screen: string;
    watchListId: number;
    setOpenEditFavorites?: Function;
    saveFavorites?: Function;
    data?: DataSymbol[];
    editedList?: boolean;
};

export const SearchHeader = ({ searchTerm, setSearchTerm, screen, watchListId, setOpenEditFavorites, saveFavorites, data, editedList }: Props) => {
    const styles = useStyles(stylesGenerator);

    return (
        <View style={styles.searchHeaderStyle}>
            <View style={styles.searchInputContainer}>
                <View style={styles.searchIconContainer}>
                    <FontAwesome name="search" style={styles.searchIcon} size={scaleSize(14)} />
                </View>
                <TextInput
                    autoCapitalize="none"
                    autoFocus={false}
                    onChangeText={setSearchTerm}
                    placeholder={i18n.t('add-favorites-search-placeholder')}
                    value={searchTerm}
                    style={styles.inputContainer}
                    placeholderTextColor={'gray'}
                />
                <Pressable onPress={() => setSearchTerm('')}>
                    <MaterialCommunityIcons name={'close'} size={scaleSize(20)} style={styles.rightIcon} />
                </Pressable>
            </View>
            <Pressable style={[styles.buttonContainer, searchTerm === '' && editedList === true ? styles.saveButtonColorActive : styles.saveButtonColorUnactive]} onPress={() => {
                if (screen !== 'EditFavoritesList' || searchTerm !== '') {
                    setSearchTerm('');
                    if (isDesktop()) {
                        setOpenEditFavorites(true);
                    }
                    else {
                        linkTo(paths.editFavoritesList.replace(':id', watchListId.toString()));
                    }
                }
                else {
                    setSearchTerm('');
                    if (editedList) {
                        saveFavorites(data);
                    }
                    else {
                        if (isDesktop()) {
                            setOpenEditFavorites(false);
                        }
                        else {
                            linkTo(paths.watchlistItem(watchListId));
                        }
                    }
                }
            }}>

                <Text fontType='LATO_REGULAR' style={styles.buttonText}>{screen === 'EditFavoritesList' && searchTerm === '' ? editedList === true ? 'save-favorites-button-text' : 'close-favorites-button-text' : 'edit-favorites-button-text'}</Text>
                <FontAwesome name="list-ul" style={styles.buttonIcon} size={scaleSize(12)} />
            </Pressable>
        </View>
    );
};


const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        rightIcon: {
            alignSelf: 'center',
            marginHorizontal: scaleSize(2),
            color: theme.search_header_gray,
        },
        searchIconContainer: { justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: scaleSize(5), borderBottomLeftRadius: scaleSize(5), backgroundColor: theme.search_header_gray, height: scaleSize(25), width: scaleSize(25) },
        buttonIcon: { color: theme.white, paddingLeft: scaleSize(5) },
        searchIcon: { color: theme.white },
        buttonText: { color: theme.white },
        saveButtonColorUnactive: {backgroundColor: theme.search_header_gray},
        saveButtonColorActive: {backgroundColor: theme.tabIconSelected},
        buttonContainer: {
            flexDirection: 'row',
            width: scaleSize(70),
            height: scaleSize(25),
            
            borderRadius: scaleSize(5),
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: scaleSize(7),
        },
        inputContainer: {
            width: isDesktop() ? scaleSize(224) : scaleSize(231),
            height: '100%',
            marginVertical: scaleSize(4),
            color: theme.text_pendingOrder,
            borderWidth: 0,
            borderColor: theme.search_header_gray,
            borderTopColor: theme.search_header_gray,
            borderBottomColor: theme.search_header_gray,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            paddingLeft: scaleSize(7),

        },
        searchHeaderStyle: {
            paddingTop: scaleSize(7),
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            marginHorizontal: scaleSize(7)
        },
        textSearch: { color: theme.tabIconSelected, paddingLeft: scaleSize(7) },
        searchInputContainer: {
            flexDirection: 'row',
            width: isDesktop() ? scaleSize(274) : scaleSize(281),
            borderRadius: scaleSize(5),
            borderBottomRightRadius: scaleSize(5),
            borderColor: theme.search_header_gray,
            borderWidth: 1,
            height: scaleSize(25),
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 2,
            marginLeft: 2
        },
    });
