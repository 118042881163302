// TODO: fix faq.dev+test@gmail.com

import { Platform } from "react-native";
import config from "~/config";
import { isDesktop } from "~/constants/scale";

// export const validateEmail = (email: string) => {
export const validateEmail = (email: string) => {
    var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
};

export const validatePasswordStrength = (password: string): boolean => {
    var regex = new RegExp(/^[0-9a-zA-Z\~\!\@\#\$\%\^\&\*\(\)\-\_\=\+]{6,20}$/);
    if (!regex.test(password)) {
        return false;
    }
    var smallLetters = new RegExp(/[a-z]+/);
    var capitalLetters = new RegExp(/[A-Z]+/);
    var numeric = new RegExp(/[0-9]+/);
    var symbols = new RegExp(/[\~\!\@\#\$\%\^\&\*\(\)\-\_\=\+]+/);
    var hasSmallLetters = smallLetters.test(password);
    var hasCapitalLetters = capitalLetters.test(password);
    var hasNumeric = numeric.test(password);
    var hasSymbols = symbols.test(password);
    var strength: number = 0;
    if (hasSmallLetters) {
        strength = strength + 1;
    }
    if (hasCapitalLetters) {
        strength = strength + 1;
    }
    if (hasNumeric) {
        strength = strength + 1;
    }
    if (hasSymbols) {
        strength = strength + 1;
    }
    if (strength > 1) {
        return true;
    }
    return false;
};

export const isEUCountry = (country: string): boolean => {
    if (country === 'AT') return true;
    if (country === 'BE') return true;
    if (country === 'BG') return true;
    if (country === 'HR') return true;
    if (country === 'CY') return true;
    if (country === 'CZ') return true;
    if (country === 'DK') return true;
    if (country === 'EE') return true;
    if (country === 'FI') return true;
    if (country === 'FR') return true;
    if (country === 'DE') return true;
    if (country === 'GR') return true;
    if (country === 'HU') return true;
    if (country === 'IE') return true;
    if (country === 'IT') return true;
    if (country === 'LV') return true;
    if (country === 'LT') return true;
    if (country === 'LU') return true;
    if (country === 'MT') return true;
    if (country === 'NL') return true;
    if (country === 'PL') return true;
    if (country === 'PT') return true;
    if (country === 'RO') return true;
    if (country === 'SK') return true;
    if (country === 'SI') return true;
    if (country === 'ES') return true;
    if (country === 'SE') return true;
    return false;
}

export const enableRegistrationButton = (): boolean => {
    if (config.WEBSITE === '24YIELD' && !isDesktop()) {
        return false;
    }
    if (config.WEBSITE === 'INVESTINGFF' && !isDesktop()) {
        return false;
    }
    if (config.WEBSITE === 'STEPONEFX') {
        return false;
    }
    return true;
}

export const enableForgotPasswordButton = (): boolean => {
    if (config.WEBSITE === 'STEPONEFX') {
        return false;
    }
    return true;
}
