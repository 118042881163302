import * as React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import HomeTabsNavigator from './HomeTabsNavigator';
import LoadingScreen from '~/components/screens/LoadingScreen';
import BigChartScreen from '../screens/BigChartScreen';

const Stack = createNativeStackNavigator();

export default function RootStackNavigator() {
    return (
        <Stack.Navigator initialRouteName="HomeTabs">
            <Stack.Screen
                name="HomeTabs"
                component={HomeTabsNavigator}
                options={{ headerShown: false, orientation: 'portrait' }}
            />
            <Stack.Screen
                name="Loading"
                component={LoadingScreen}
                options={{ headerShown: false, orientation: 'portrait' }}
            />
            <Stack.Screen
                name="Chart"
                component={BigChartScreen}
                options={{ headerShown: false, orientation: 'landscape' }}
            />
        </Stack.Navigator>
    );
}
