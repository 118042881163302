import React, { useState } from 'react';
import { View, StyleSheet, Platform, Pressable, Image } from 'react-native';
import { useAppSelector, useStyles } from '~/hooks';
import { Button, Text } from '~/components/core';
import { scaleSize, isDesktop } from '~/constants/scale';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import Header from '../More/Header';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';
import { appSettingsSelector } from '~/state/selectors';
import { Entypo } from '@expo/vector-icons';
import { FONT_SIZE_S } from '~/constants';
import { Theme } from '~/theme';
import LanguagePicker from '../Desktop/LanguagePicker';

type Props = {
    setActiveRightTab?: Function;
};
export default function Settings({ setActiveRightTab }: Props) {
    const styles = useStyles(stylesGenerator);
    const { language } = useAppSelector(appSettingsSelector);
    const [openLanguageMenu, setOpenLanguageMenu] = useState(false);


    return (
        <>
            {!isDesktop() ? <Header /> : null}
            <DesktopScreenHeader goToScreenName="more" title="settings" setActiveRightTab={setActiveRightTab} />
            <View style={styles.container}>

                <View style={styles.viewContainer}>
                    <Pressable style={styles.languageContainer} onPress={() => setOpenLanguageMenu(!openLanguageMenu)}>
                        {language === 'de' ? (
                            <Image
                                source={require('../../../../assets/images/flags/de.png')}
                                style={styles.languageFlag}
                            />
                        ) : language === 'fr' ? (
                            <Image
                                source={require('../../../../assets/images/flags/fr.png')}
                                style={styles.languageFlag}
                            />
                        ) : language === 'ru' ? (
                            <Image
                                source={require('../../../../assets/images/flags/ru.png')}
                                style={styles.languageFlag}
                            />
                        ) : language === 'es' ? (
                            <Image
                                source={require('../../../../assets/images/flags/es.png')}
                                style={styles.languageFlag}
                            />
                        ) : (
                            <Image
                                source={require('../../../../assets/images/flags/en.png')}
                                style={styles.languageFlag}
                            />
                        )}
                        <Text fontType="LATO_REGULAR" style={styles.languageText}>
                            {'language-' + language}
                        </Text>
                        <Entypo name="chevron-down" size={14} style={styles.languagePicker} />
                    </Pressable>
                    {openLanguageMenu ?
                        <LanguagePicker setOpenLanguageMenu={setOpenLanguageMenu} /> : null
                    }
                    <View style={styles.buttonContainer}>
                        <Button
                            title="change-password"
                            onPress={() =>
                                isDesktop() && setActiveRightTab
                                    ? setActiveRightTab('change-password')
                                    : linkTo(paths.changePassword)
                            }
                        />
                    </View>
                    {Platform.OS !== 'web' ? (
                        <View style={styles.buttonContainer}>
                            <Button
                                title="notifications-label"
                                onPress={() =>
                                    isDesktop() && setActiveRightTab
                                        ? setActiveRightTab('notifications-settings')
                                        : linkTo(paths.notificationsSettings)
                                }
                            />
                        </View>
                    ) : null}

                </View>
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        languagePicker: { color: theme.languagePicker, marginLeft: 5 },
        languageText: { fontSize: FONT_SIZE_S, color: theme.desktopHeaderTextColor },
        languageFlag: { width: 30, height: 20, marginRight: 10 },
        languageContainer: { paddingVertical: scaleSize(10), flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        buttonContainer: { paddingBottom: scaleSize(10) },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
        },
        viewContainer: { height: '90%', justifyContent: 'center' },
    });
