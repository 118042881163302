import * as React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import DesktopScreen from '~/components/screens/DesktopScreen';
import config from '~/config';

const Stack = createNativeStackNavigator();

export default function DesktopStackNavigator() {
    return (
        <Stack.Navigator initialRouteName={'Desktop'}>
            <Stack.Screen
                name="Desktop"
                component={DesktopScreen}
                options={{ headerShown: false, title: config.BRAND_NAME + ' ' + config.PLATFORM_NAME }}
            />
        </Stack.Navigator>
    );
}
