import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import InterestSwap from '../features/InterestSwap';

export default function InterestSwapScreen() {
    return (
        <ScreenWrapper>
            <InterestSwap />
        </ScreenWrapper>
    );
}
