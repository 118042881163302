import axios from '../baseClient';
import { WithdrawRequest, WithdrawResponse } from './types';

class WithdrawService {
    private static domain = '/api/24yieldservice';

    withdraw(withdrawRequest: WithdrawRequest) {
        return axios.post<WithdrawResponse>(`${WithdrawService.domain}/requiresession/withdraw`, withdrawRequest);
    }
}

export default new WithdrawService();
