import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import VerificationCenter from '../features/VerificationCenter';

type Props = {
    setActiveRightTab?: Function;
};

export default function VerificationCenterScreen({ setActiveRightTab }: Props) {
    return (
        <ScreenWrapper>
            <VerificationCenter setActiveRightTab={setActiveRightTab} />
        </ScreenWrapper>
    );
}
