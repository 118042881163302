import { useEffect, useState, useRef } from 'react';
import { CoreDataService } from '~/api-services';
import { Message } from '~/api-services/CoreDataService/types';
import { userIsLoggedInSelector } from '~/state/selectors';
import { useAppSelector } from './useAppSelector';
import { useIsAppActive } from './useIsAppActive';

export const useNotifications = () => {
    const [messageArray, setMessageArray] = useState<Message[]>([]);
    const reloadDataPollRef = useRef<NodeJS.Timer>();
    const isAppActive = useIsAppActive();
    const isLoggedIn = useAppSelector(userIsLoggedInSelector);

    useEffect(() => {
        if (isAppActive && isLoggedIn) {
            reloadDataPollRef.current = setInterval(() => {
                loadData();
            }, 60000); // refetch 60 seconds
            loadData();
        } else {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        }
    }, [isLoggedIn, isAppActive]);

    async function loadData() {
        const { status, data } = await CoreDataService.getAllMessages();
        if (status === 200 && data.status) {
            setMessageArray(data.returnData);
            let messageIds: string[] = data.returnData.map((message) => message.id);

            CoreDataService.markMessagesAsRead(messageIds);
        }
    }

    return {
        messageArray,
    };
};
