import i18n from '~/i18n';
import dateFormat from "dateformat";


export type AlertMessage = {
    title: string;
    message: string;
};

class AlertService {
    getErrorResponse(response: any): AlertMessage {
        var errorMessageTranslated = response.errorMessageTranslated;
        if (errorMessageTranslated) {
            return { title: '', message: errorMessageTranslated };
        }

        var errorCode = response.errorCode;
        var titleKey = 'error-' + errorCode + '-title';
        var messageKey = 'error-' + errorCode;
        var messageContext = response.errorContext || {};

        if (errorCode === 'INVALID_SESSION') {
        } else if (errorCode === 'MinQuantityExceeded') {
            title = '';

            messageContext.min = messageContext.min;
            var message = i18n.t(messageKey, messageContext);

            return { title: title, message: message };
        } else if (errorCode === 'MaxBuyQuantityExceeded' || errorCode === 'MaxSellQuantityExceeded') {
            var titleKey = 'error-MaxQuantityExceeded-title';
            var title = i18n.t(titleKey);
            if (title === titleKey) {
                title = '';
            }

            messageContext.max = messageContext.max;
            var message = i18n.t(messageKey, messageContext);

            return { title: title, message: message };
        } else if (errorCode === 'Favorites') {
            var title = i18n.t(titleKey);
            if (title === titleKey) {
                title = '';
            }

            messageContext.max = messageContext.max;
            var message = i18n.t(messageKey, messageContext);

            return { title: title, message: message };
        } else if (this.startsWith(errorCode, 'OpenPrice')) {
            messageContext = {};
            var message = i18n.t(messageKey, messageContext);

            return { title: '', message: message };
        } else if (this.startsWith(errorCode, 'TakeProfit')) {
            messageContext = {};
            var message = i18n.t(messageKey, messageContext);

            return { title: '', message: message };
        } else if (this.startsWith(errorCode, 'StopLoss')) {
            messageContext = {};
            var message = i18n.t(messageKey, messageContext);

            return { title: '', message: message };
        } else if (
            errorCode === 'MarketStillNotOpen' ||
            errorCode === 'MarketOpenToday' ||
            errorCode === 'MarketOpenNextDays'
        ) {
            var opens = messageContext.opens;
            var opensDate = new Date(opens);
            messageContext.time = dateFormat(opensDate, 'HH:MM');
            messageContext.date = dateFormat(opensDate, 'yyyy.mm.dd');
            var now = new Date();
            var miliseconds = opensDate.getTime() - now.getTime();
            var seconds = miliseconds / 1000;
            var minutes = seconds / 60;
            var hours = minutes / 60;
            minutes = Math.round(minutes % 60);
            hours = Math.round(hours);
            messageContext.minutes = minutes;
            messageContext.hours = hours;
            var message = i18n.t(messageKey, messageContext);
            return { title: '', message: message };
        } else if (errorCode === 'MarketClosedAtWeekend') {
            var title = '';
            var weekendStart = new Date(messageContext.weekendStart);
            var weekendEnd = new Date(messageContext.weekendEnd);
            messageContext.weekendStart = dateFormat(weekendStart, 'yyyy.mm.dd HH:MM');
            messageContext.weekendEnd = dateFormat(weekendEnd, 'yyyy.mm.dd HH:MM');
            var message = i18n.t(messageKey, messageContext);
            return { title: title, message: message };
        } else if (errorCode === 'CantTrade') {
            var title = '';
            var defaultMessageKey = messageKey + '-default';
            var newMessageKey = messageKey + '-' + messageContext.cantTradeReason;
            var message = i18n.t(newMessageKey, messageContext);
            var messageTest = i18n.t('close-at-profit');
            if (message === newMessageKey) {
                message = i18n.t(defaultMessageKey, messageContext);
            }
            return { title: title, message: message };
        } else {
            var title = i18n.t(titleKey);
            if (title === titleKey || title === undefined) {
                title = '';
            }

            var errorMessageDebug = ' >> ' + response.errorMessage + ' ' + response.errorCode + ' << ';
            messageContext.debug = errorMessageDebug;
            //messageContext["debug"] = "";
            var message = i18n.t(messageKey, messageContext);
            if (message === messageKey) {
                //default message
                message = i18n.t('error-default', messageContext);
            }
            return { title: title, message: message };
        }
        return { title: 'Unknown Error', message: 'Error' };
    }

    startsWith(source: string, searchString: string) {
        return source.substring(0, searchString.length) === searchString;
    }
}

export default new AlertService();
