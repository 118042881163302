import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '~/components/core';
import { FONT_SIZE_M, FONT_SIZE_XXS } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { useLiveStatistic } from '~/hooks/useLiveStatistic';
import { formatNumberWithPercent } from '~/lib/format';
import { Theme } from '~/theme';
type Props = {
    symbol: string;
    averagePrice: number;
};

export default function LiveStatistic({ symbol, averagePrice }: Props) {
    const styles = useStyles(stylesGenerator);

    const { liveStat5M, liveStat1H, liveStat24H } = useLiveStatistic(symbol, averagePrice);

    return (
        <View style={styles.sectionContainer}>
            <Text fontType="LATO_BOLD" style={styles.sectionTitleText}>
                live-statistic
            </Text>
            <Text fontType="LATO_BOLD" style={styles.liveStatSubTitle}>
                24h-change
            </Text>
            <View style={styles.liveStatDataContainer}>
                <View style={styles.liveStatDetail}>
                    <Text
                        fontType="LATO_REGULAR"
                        style={[
                            styles.liveStatDetailText,
                            styles.letterSpacing05,
                            liveStat5M && liveStat5M < 0 ? styles.redPrice : styles.greenPrice,
                        ]}
                    >
                        {liveStat5M ? formatNumberWithPercent(liveStat5M, 2, true) : ''}
                    </Text>
                </View>
                <View style={styles.liveStatDetail}>
                    <Text
                        fontType="LATO_REGULAR"
                        style={[
                            styles.liveStatDetailText,
                            styles.letterSpacing05,
                            liveStat1H && liveStat1H < 0 ? styles.redPrice : styles.greenPrice,
                        ]}
                    >
                        {liveStat1H ? formatNumberWithPercent(liveStat1H, 2, true) : ''}
                    </Text>
                </View>
                <View style={styles.liveStatDetail}>
                    <Text
                        fontType="LATO_REGULAR"
                        style={[
                            styles.liveStatDetailText,
                            styles.letterSpacing05,
                            liveStat24H && liveStat24H < 0 ? styles.redPrice : styles.greenPrice,
                        ]}
                    >
                        {liveStat24H ? formatNumberWithPercent(liveStat24H, 2, true) : ''}
                    </Text>
                </View>
            </View>
            <View style={styles.liveStatDataContainer}>
                <View style={styles.liveStatDetail}>
                    <Text fontType="LATO_BOLD" style={[styles.liveStatDetailText, styles.symbolColor]}>
                        5-minutes
                    </Text>
                </View>
                <View style={styles.liveStatDetail}>
                    <Text fontType="LATO_BOLD" style={[styles.liveStatDetailText, styles.symbolColor]}>
                        1-hour
                    </Text>
                </View>
                <View style={styles.liveStatDetail}>
                    <Text fontType="LATO_BOLD" style={[styles.liveStatDetailText, styles.symbolColor]}>
                        24-hours
                    </Text>
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        greenPrice: { color: theme.text_upPrice },
        redPrice: { color: theme.text_downPrice },
        liveStatDetailText: { fontSize: FONT_SIZE_XXS },
        liveStatDetail: { justifyContent: 'center', alignItems: 'center', width: '33.33%' },
        liveStatDataContainer: { paddingTop: scaleSize(10), flexDirection: 'row', justifyContent: 'space-between' },
        symbolColor: { color: theme.text_symbol },
        letterSpacing05: { letterSpacing: 0.5 },
        sectionContainer: { marginHorizontal: scaleSize(10), marginTop: scaleSize(20) },
        sectionTitleText: { fontSize: FONT_SIZE_M, color: theme.text_symbol },
        liveStatSubTitle: { fontSize: FONT_SIZE_XXS, color: theme.text_symbol, paddingTop: scaleSize(10) },
    });
