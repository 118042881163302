import {
    createNavigationContainerRef,
    getActionFromState,
    getStateFromPath,
    NavigationAction,
    StackActions,
    CommonActions,
} from '@react-navigation/native';

import { getLinking } from './linking';

type ActionType = 'NAVIGATE' | 'PUSH' | 'RESET' | 'REPLACE';

export const navigationRef = createNavigationContainerRef();

export function dispatch(action: NavigationAction) {
    navigationRef.current?.dispatch(action);
}

export function popBack(screensCount = 1) {
    navigationRef.current?.dispatch(StackActions.pop(screensCount));
}

export function goBack() {
    navigationRef.current?.dispatch(CommonActions.goBack());
}

export function linkTo(path: string, type: ActionType = 'NAVIGATE') {
    const state = getStateFromPath(path, getLinking().config);
    if (!state) {
        return;
    }

    const action = getActionFromState(state);
    if (!action) {
        return;
    }

    // @ts-ignore
    action.type = type;

    dispatch(action);
}
