import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import WithdrawFunds from '../features/WithdrawFunds';

type Props = {
    setActiveRightTab?: Function;
};

export default function WithdrawFundsScreen({ setActiveRightTab }: Props) {
    return (
        <ScreenWrapper>
            <WithdrawFunds setActiveRightTab={setActiveRightTab} />
        </ScreenWrapper>
    );
}
