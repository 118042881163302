import { useEffect, useState } from 'react';
import { useIsScreenActive } from './useIsScreenActive';
import StreamingService from '~/api-services/StreamingService';
import { userAuthDataSelector } from '~/state/selectors';
import { useAppSelector } from './useAppSelector';

export const useSubscribeQuote = (symbol: string) => {
    const isScreenActive = useIsScreenActive();
    const { sessionId } = useAppSelector(userAuthDataSelector);
    const [lastSessionId, setLastSessionId] = useState('');

    useEffect(() => {
        if (isScreenActive) {
            StreamingService.subscribeQuotes([symbol]);
        } else {
            StreamingService.unsubscribeQuotes([symbol]);
        }
        return () => {
            StreamingService.unsubscribeQuotes([symbol]);
        };
    }, [isScreenActive, symbol]);

    useEffect(() => {
        if (isScreenActive) {
            if (lastSessionId !== '' && lastSessionId !== sessionId) {
                StreamingService.unsubscribeQuotes([symbol]);
                StreamingService.closeConnection();
                StreamingService.initConnection(sessionId);
                StreamingService.subscribeQuotes([symbol]);
            }
            setLastSessionId(sessionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, isScreenActive, lastSessionId]);
};
