import React from 'react';
import { StyleSheet, View, Modal, ActivityIndicator } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';

type Props = {
    loading: boolean;
};

const Loader = ({ loading }: Props) => {
    const styles = useStyles(stylesGenerator);

    return (
        <Modal transparent={true} animationType={'none'} visible={loading} onRequestClose={() => {}}>
            <View style={styles.modalBackground}>
                <View style={styles.activityIndicatorWrapper}>
                    <ActivityIndicator animating={true} color="#000000" size="large" style={styles.activityIndicator} />
                </View>
            </View>
        </Modal>
    );
};

export default Loader;

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        modalBackground: {
            flex: 1,
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
            backgroundColor: 'rgba(0,0,0,0.5)',
        },
        activityIndicatorWrapper: {
            backgroundColor: theme.backgroundSecondary,
            height: scaleSize(100),
            width: scaleSize(100),
            borderRadius: scaleSize(10),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
        activityIndicator: {
            alignItems: 'center',
            height: scaleSize(80),
        },
    });
