import React from 'react';
import Notifications from '../features/Notifications';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function NotificationsScreen() {
    return (
        <ScreenWrapper>
            <Notifications />
        </ScreenWrapper>
    );
}
