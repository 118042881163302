import { ActionReducerMapBuilder, AsyncThunk } from '@reduxjs/toolkit';

import type { ThunkAPI } from '../../store';

type GenericState = ResponseMetadata & {
    data: any;
};

export const thunkGenericApiReducerBuilder = (
    builder: ActionReducerMapBuilder<GenericState>,
    thunkReducer: AsyncThunk<any, any, ThunkAPI>,
    mutateStateOnFullfil = <S extends GenericState>(state: S, payload: any) => {
        state.data = payload;
    },
    defaultErrorMessage = 'Unknown error.'
) => {
    builder.addCase(thunkReducer.fulfilled, (state, { payload }) => {
        mutateStateOnFullfil(state, payload);
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;
        state.errorMessage = '';

        return state;
    });

    builder.addCase(thunkReducer.rejected, (state, { payload = defaultErrorMessage }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.errorMessage = payload;

        return state;
    });

    builder.addCase(thunkReducer.pending, (state) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.errorMessage = '';

        return state;
    });
};
