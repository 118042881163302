import React from 'react';
import { StyleSheet, Pressable, PressableProps, StyleProp, ViewStyle } from 'react-native';
import { FONT_SIZE_L } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import Text from '../Text';

type Props = PressableProps & {
    title: string;
    titleSize?: number;
    buttonHeight?: number;
    containerStyle?: StyleProp<ViewStyle>;
    removeOpacity?: boolean;
    backgroundColor?: { backgroundColor: string };
    maxWidth?: number;
};

const Button = ({
    title,
    titleSize = FONT_SIZE_L,
    buttonHeight = 40,
    containerStyle,
    backgroundColor,
    maxWidth,
    disabled,
    removeOpacity,
    onPress,
    ...rest
}: Props) => {
    const styles = useStyles(stylesGenerator, { titleSize, buttonHeight, maxWidth });

    return (
        <Pressable
            disabled={disabled}
            onPress={disabled ? null : onPress}
            style={(args) => [
                styles.base,
                {
                    opacity: args.pressed || (disabled && !removeOpacity) ? 0.5 : 1,
                },
                containerStyle,
                backgroundColor ? backgroundColor : styles.backgroundColor,
            ]}
            {...rest}
        >
            <Text fontType="LATO_BOLD" style={[styles.text, styles.textColor]}>
                {title}
            </Text>
        </Pressable>
    );
};

const stylesGenerator = (theme: Theme, custom?: { titleSize: number; buttonHeight: number; maxWidth: number }) =>
    StyleSheet.create({
        text: {
            fontWeight: 'bold',
            fontSize: custom?.titleSize || FONT_SIZE_L,
        },
        textColor: { color: theme.white },
        base: {
            alignItems: 'center',
            justifyContent: 'center',
            height: custom?.buttonHeight
                ? scaleSize(custom?.buttonHeight, custom?.maxWidth)
                : scaleSize(40, custom?.maxWidth),
            borderRadius: scaleSize(5, custom?.maxWidth),
            width: '100%',
        },
        backgroundColor: { backgroundColor: theme.text_upPrice },
    });

export default Button;
