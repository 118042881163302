import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ChartDataService } from '~/api-services';
import { Text } from '~/components/core';
import { FONT_SIZE_M, FONT_SIZE_S } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { formatNumber } from '~/lib/format';
import { Theme } from '~/theme';
import PriceRangeTab from './PriceRangeTab';

type Props = { averagePrice: number; digits: number; symbol: string };

export default function PriceRange({ averagePrice, digits, symbol }: Props) {
    const styles = useStyles(stylesGenerator);
    const [priceRangeSelected, setPriceRangeSelected] = useState<
        'price-range-daily' | 'price-range-weekly' | 'price-range-monthly' | 'price-range-yearly'
    >('price-range-weekly');
    const priceRangeLineWidth = 341;
    const [markerPosition, setMarkerPosition] = useState(priceRangeLineWidth / 2);
    const [priceRangeStart, setPriceRangeStart] = useState<number | null>(null);
    const [priceRangeEnd, setPriceRangeEnd] = useState<number | null>(null);

    useEffect(() => {
        async function fetchData() {
            const { status, data } = await ChartDataService.getPriceRange({
                symbol: symbol,
                type: priceRangeSelected,
            });
            if (status === 200 && data.status) {
                let low: number = data.returnData.low;
                let high: number = data.returnData.high;
                setPriceRangeStart(low);
                setPriceRangeEnd(high);
            }
        }
        fetchData();
    }, [priceRangeSelected, symbol]);

    useEffect(() => {
        if (priceRangeStart && priceRangeEnd && averagePrice) {
            setMarkerPosition(
                ((averagePrice - priceRangeStart) / (priceRangeEnd - priceRangeStart)) * priceRangeLineWidth
            );
        }
    }, [averagePrice, priceRangeEnd, priceRangeStart]);

    useEffect(() => {
        if (priceRangeStart && priceRangeStart > averagePrice) {
            setPriceRangeStart(averagePrice);
        }
        if (priceRangeEnd && priceRangeEnd < averagePrice) {
            setPriceRangeEnd(averagePrice);
        }
    }, [averagePrice, priceRangeEnd, priceRangeStart]);

    return (
        <View style={styles.priceRangeContainer}>
            <View style={styles.priceRangeTitleContainer}>
                <Text fontType="LATO_BOLD" style={styles.priceRangeText}>
                    price-range
                </Text>
            </View>
            <View style={[styles.priceRangeDataContainer]}>
                <View style={styles.priceRangeTabs}>
                    <PriceRangeTab
                        setSelected={setPriceRangeSelected}
                        labelKey="price-range-daily"
                        position="left"
                        selected={priceRangeSelected}
                    />
                    <PriceRangeTab
                        setSelected={setPriceRangeSelected}
                        labelKey="price-range-weekly"
                        position="center"
                        selected={priceRangeSelected}
                    />
                    <PriceRangeTab
                        setSelected={setPriceRangeSelected}
                        labelKey="price-range-monthly"
                        position="center"
                        selected={priceRangeSelected}
                    />
                    <PriceRangeTab
                        setSelected={setPriceRangeSelected}
                        labelKey="price-range-yearly"
                        position="right"
                        selected={priceRangeSelected}
                    />
                </View>
                <View style={[styles.priceRangeLine, { width: scaleSize(priceRangeLineWidth) }]} />
                <View style={[styles.priceRangeMarker, { left: scaleSize(markerPosition) }]} />
                <View style={styles.priceRangeNumbers}>
                    <Text fontType="LATO_BOLD" style={styles.priceRangeNumberText}>
                        {formatNumber(priceRangeStart, digits)}
                    </Text>
                    <Text fontType="LATO_BOLD" style={styles.priceRangeNumberText}>
                        {formatNumber(priceRangeEnd, digits)}
                    </Text>
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        priceRangeContainer: { marginHorizontal: scaleSize(7), marginTop: scaleSize(20) },
        priceRangeTitleContainer: { paddingBottom: scaleSize(10), paddingLeft: scaleSize(5) },
        priceRangeText: { fontSize: FONT_SIZE_M },
        priceRangeDataContainer: {
            backgroundColor: theme.backgroundSecondary,
            borderRadius: scaleSize(5),
            height: scaleSize(70),
        },
        priceRangeTabs: { flexDirection: 'row' },
        priceRangeLine: {
            backgroundColor: theme.border_2,
            height: scaleSize(2),
            marginHorizontal: scaleSize(10),
            marginTop: scaleSize(20),
            borderRadius: scaleSize(1),
        },
        priceRangeMarker: {
            width: scaleSize(2),
            height: scaleSize(8),
            backgroundColor: theme.background_positionId,
            borderRadius: scaleSize(1),
            position: 'absolute',
            marginLeft: scaleSize(10),
            top: scaleSize(37),
        },
        priceRangeNumbers: {
            marginTop: scaleSize(6),
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginHorizontal: scaleSize(10),
        },
        priceRangeNumberText: { fontSize: FONT_SIZE_S, color: theme.text_positionId },
    });
