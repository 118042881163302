import React, { useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { useNews } from '~/hooks/useNews';
import DateRow, { getDateRow } from '../Result/DateRow';
import NewsRow from './NewsRow';

const now = new Date();
const prevPeriod = new Date(now);
prevPeriod.setMonth(now.getMonth() - 1);

export default function News() {
    const { newsArray } = useNews();
    const styles = useStyles(stylesGenerator);
    const [openNews, setOpenNews] = useState('');

    return (
        <>
            <ScrollbarWrapper>
                <View style={styles.newsContainer}>
                    {newsArray.length !== 0 &&
                        newsArray.map((news, key) => (
                            <Pressable
                                style={styles.container}
                                key={key}
                                onPress={() => setOpenNews(openNews === news.id ? '' : news.id)}
                            >
                                {getDateRow(news.date, key) !== '' ? <DateRow timestamp={news.date} /> : null}
                                <NewsRow news={news} isOpen={openNews === news.id} />
                            </Pressable>
                        ))}
                </View>
            </ScrollbarWrapper>
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        newsContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingHorizontal: scaleSize(7),
        },
        container: { width: '100%' },
    });
