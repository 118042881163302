import { useEffect } from 'react';
import { Platform } from 'react-native';
import { appSettingsLanguageSelector } from '~/state/selectors';
import { useAppDispatch, useAppSelector } from '~/hooks';
import * as qs from 'query-string';
import { saveAppSettings } from '~/state/reducers/appSettingsSlice';


export const useBrowserLanguage = () => {
    const language = useAppSelector(appSettingsLanguageSelector);
    const dispatch = useAppDispatch();
    function loadLanguage() {
        if (Platform.OS === 'web' && language) {
            let urlLang = language;
            let params = qs.parse(location.search);
            if (params && params.lang) {
                if (Array.isArray(params.lang)) {
                    urlLang = params.lang[0];
                } else {
                    urlLang = params.lang;
                }
                if(urlLang !== language && (urlLang === 'en' || urlLang === 'es' || urlLang === 'ru' || urlLang === 'fr' || urlLang === 'de')) {
                    dispatch(saveAppSettings({language: urlLang}));
                }
            }
        }
        return false;
    }

    useEffect(() => {
        loadLanguage();
    }, [language]);

    return {}
};
