import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import RegistrationFormInvesting from '../features/Authentication/RegistrationFormInvesting';

export default function RegistrationInvestingScreen() {
    return (
        <ScreenWrapper>
            <RegistrationFormInvesting />
        </ScreenWrapper>
    );
}
