import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import AllOrders from '../features/AllOrders';

export default function AllOrdersScreen() {
    return (
        <ScreenWrapper>
            <AllOrders />
        </ScreenWrapper>
    );
}
