import axios from '../baseClient';
import { ProfitLossExchangeRatesResponse } from './types';

class ExchangeService {
    private static domain = '/api/backend';

    getProfitLossExchangeRates(symbol: string) {
        return axios.post<ProfitLossExchangeRatesResponse>(`${ExchangeService.domain}/getProfitLossExchangeRates`, {symbol: symbol});
    }
}

export default new ExchangeService();
