import { Dimensions, Platform } from 'react-native';
import { global } from '~/constants/global';

export const scaleSize = (num: number, maxWidth?: number) => {
    return isDesktop()
        ? num
        : maxWidth
        ? (Math.min(maxWidth, Dimensions.get('window').width) / 375) * num
        : (Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / 375) * num;
};

export const scaleSizeMobile = (num: number, maxWidth?: number) => {
    return maxWidth
        ? (Math.min(maxWidth, Dimensions.get('window').width) / 375) * num
        : (Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / 375) * num;
};

export const isDesktop = () => {
    if (Platform.OS === 'web' && Dimensions.get('window').width > global.DESKTOP_MIN_WIDTH) {
        return true;
    }
    return false;
};
