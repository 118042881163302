import React from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import { scaleSize } from '~/constants/scale';

import Text from '../Text';
import { useAppSelector } from '~/hooks';
import { appSettingsSelector } from '~/state/selectors';

type Props = {
    data?: number[];
    fromNumber?: number;
    chartColor: string;
    isLarge: boolean;
};

export default React.memo(function BasicLineChart({ data, fromNumber, chartColor, isLarge }: Props) {
    const dimensions = useWindowDimensions();
    const height = isLarge ? scaleSize(167) : scaleSize(65);
    const width = dimensions.width * (isLarge ? 1.024 : 0.488);
    const { colorScheme } = useAppSelector(appSettingsSelector);

    const chartConfig: LineChart['props']['chartConfig'] = {
        fillShadowGradientOpacity: 0.7,
        color: (opacity = 1) =>
            colorScheme === 'dark' ? `rgba(23, 24, 28, ${opacity})` : `rgba(255, 255, 255, ${opacity})`,
        decimalPlaces: 4, // optional, defaults to 2dp
    };

    if (!data || data.length === 0) {
        return (
            <View style={[styles.emptyChart, { height: 0.8 * height }]}>
                <Text children={data ? 'No available chart data' : 'Loading chart data...'} />
            </View>
        );
    }

    return (
        <LineChart
            bezier
            transparent
            fromNumber={fromNumber}
            data={{
                labels: [],
                datasets: [
                    {
                        data,
                    },
                ],
            }}
            height={height}
            width={width}
            withVerticalLabels={false}
            withHorizontalLabels={false}
            withInnerLines={false}
            withOuterLines={false}
            withVerticalLines={false}
            withHorizontalLines={false}
            withDots={false}
            chartConfig={{ ...chartConfig, fillShadowGradient: chartColor }}
            style={{
                ...StyleSheet.flatten(styles.chart),
                paddingLeft: isLarge ? 0 : width * 0.032,
                paddingBottom: -height * 0.27,
            }}
        />
    );
});

const styles = StyleSheet.create({
    chart: {
        overflow: 'hidden',
        paddingTop: 0,
        paddingRight: 0,
    },
    emptyChart: {
        justifyContent: 'center',
    },
});
