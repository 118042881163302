import React from 'react';
import { StyleSheet, View, TextInput, Pressable } from 'react-native';
import { Theme } from '~/theme';
import { scaleSize } from '~/constants/scale';
import i18n from '~/i18n';
import { useStyles } from '~/hooks';
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';


type Props = {
    searchTerm: string;
    setSearchTerm: any;
    investorHook: any;
    setloading: Function;
};

export const InvestorSearchHeader = ({ searchTerm, setSearchTerm, investorHook, setloading }: Props) => {
    const styles = useStyles(stylesGenerator);

    return (
        <View style={styles.searchHeaderStyle}>
            <View style={styles.searchInputContainer}>
                <View style={styles.searchIconContainer}>
                    <FontAwesome name="search" style={styles.searchIcon} size={scaleSize(14)} />
                </View>
                <TextInput
                    autoCapitalize="none"
                    autoFocus={false}
                    onChangeText={async (text) => {
                        setSearchTerm(text);
                        await investorHook.getTopInvestitors(text);
                    }}
                    placeholder={i18n.t('add-favorites-search-placeholder')}
                    value={searchTerm}
                    style={styles.inputContainer}
                    placeholderTextColor={'gray'}
                />
                <Pressable onPress={async () => {
                    setSearchTerm('');
                    await investorHook.getTopInvestitors('');
                }}>
                    <MaterialCommunityIcons name={'close'} size={scaleSize(20)} style={styles.rightIcon} />
                </Pressable>
            </View>
        </View>
    );
};


const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        rightIcon: {
            alignSelf: 'center',
            marginHorizontal: scaleSize(2),
            color: theme.search_header_gray,
        },
        searchIconContainer: { justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: scaleSize(5), borderBottomLeftRadius: scaleSize(5), backgroundColor: theme.search_header_gray, height: scaleSize(25), width: scaleSize(25) },
        buttonIcon: { color: theme.white, paddingLeft: scaleSize(5) },
        searchIcon: { color: theme.white },
        buttonText: { color: theme.white },
        saveButtonColorUnactive: { backgroundColor: theme.search_header_gray },
        saveButtonColorActive: { backgroundColor: theme.tabIconSelected },
        buttonContainer: {
            flexDirection: 'row',
            width: scaleSize(70),
            height: scaleSize(25),

            borderRadius: scaleSize(5),
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: scaleSize(7),
        },
        inputContainer: {
            width: '100%',
            height: '100%',
            marginVertical: scaleSize(4),
            color: theme.text_pendingOrder,
            borderWidth: 0,
            borderColor: theme.search_header_gray,
            borderTopColor: theme.search_header_gray,
            borderBottomColor: theme.search_header_gray,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            paddingLeft: scaleSize(7),

        },
        searchHeaderStyle: {
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
        },
        textSearch: { color: theme.tabIconSelected, paddingLeft: scaleSize(7) },
        searchInputContainer: {
            flexDirection: 'row',
            width: '100%',
            borderRadius: scaleSize(5),
            borderBottomRightRadius: scaleSize(5),
            borderColor: theme.search_header_gray,
            borderWidth: 1,
            height: scaleSize(25),
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: scaleSize(2),
        },
    });
