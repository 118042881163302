import React, { useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { FONT_SIZE_S, FONT_SIZE_XS } from '~/constants/typography';
import CashRow from './CashRow';
import { useCash } from '~/hooks/useCash';
import FromToSelector from '../Result/FromToSelector';
import DateRow, { getDateRow } from '../Result/DateRow';
import { Theme } from '~/theme';
import { Text } from '~/components/core';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import Loader from '~/components/core/Loader';

const now = new Date();
const prevPeriod = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
prevPeriod.setHours(0);
prevPeriod.setMinutes(0);
prevPeriod.setSeconds(0);

export default function Cash() {
    const [fromButton, setFromButton] = useState(prevPeriod);
    const [toButton, setToButton] = useState(now);
    const { cashArray, totalDirection, totalFormatted, loading } = useCash(fromButton, toButton);
    const styles = useStyles(stylesGenerator);

    return (
        <>
            <Loader loading={loading} />
            <View style={styles.cashHeaderContainer}>
                <Text style={styles.cashHeaderText} fontType="LATO_BOLD">
                    details-label
                </Text>
                <Text style={styles.cashHeaderText} fontType="LATO_BOLD">
                    time-label
                </Text>
                <Text style={styles.cashHeaderText} fontType="LATO_BOLD">
                    amount-label
                </Text>
            </View>
            <ScrollbarWrapper>
                <View style={styles.ordersContainer}>
                    {cashArray.length !== 0 &&
                        cashArray.map((cash, key) => (
                            <View style={styles.button} key={key}>
                                {getDateRow(cash.timestamp, key) !== '' ? <DateRow timestamp={cash.timestamp} /> : null}
                                <CashRow cash={cash} />
                            </View>
                        ))}
                </View>
            </ScrollbarWrapper>
            <FromToSelector
                setFromButton={setFromButton}
                setToButton={setToButton}
                prevPeriod={prevPeriod}
                totalFormatted={totalFormatted}
                totalDirection={totalDirection}
            />
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        cashHeaderText: { fontSize: FONT_SIZE_XS, color: theme.card },
        cashHeaderContainer: {
            flexDirection: 'row',
            width: '100%',
            height: scaleSize(30),
            backgroundColor: theme.background_positionId,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: scaleSize(30),
        },
        ordersContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
        },
        button: { width: '100%' },
        datePickerContainer: {
            flexDirection: 'row',
        },
        datePickerItem: {
            flex: 1,
            padding: scaleSize(5),
        },
        datePickerLabel: {
            fontSize: FONT_SIZE_S,
        },
    });
