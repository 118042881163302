import config from '~/config';
import { io, Socket } from 'socket.io-client';
import { ClientToServerEvents, ServerToClientEvents } from './types';
import { store } from '~/state/store';
import { updateSymbolPrices } from '~/state/reducers/symbolsSlice';

class StreamingService {
    socket?: Socket<ServerToClientEvents, ClientToServerEvents>;
    unsubQuotesIntervals: { [key: string]: NodeJS.Timer } = {};
    subQuotesIntervals: { [key: string]: NodeJS.Timer } = {};
    lastSessionId: string = '';

    initConnection(sessionId: string) {
        if (!this.socket || sessionId !== this.lastSessionId) {
            this.lastSessionId = sessionId;
            this.socket = io(config.STREAMING_API_BASE_URL, {
                autoConnect: false,
                query: {
                    sessionId,
                },
                path: '/socketserverv4/socket.io',
            });
            this.setupQuotesListener();
        }
        this.socket.connect();
    }

    setupQuotesListener() {
        if (this.socket && !this.socket.hasListeners('quote')) {
            this.socket.on('quote', (symbol) => {
                store.dispatch(updateSymbolPrices({ [symbol.symbol]: symbol }));
            });
        }
    }

    closeConnection() {
        this.socket?.disconnect();
    }

    subscribeQuotes(symbols: string[]) {
        for (const s of symbols) {
            setTimeout(() => {
                if (this.unsubQuotesIntervals[s]) {
                    clearInterval(this.unsubQuotesIntervals[s]);
                    delete this.unsubQuotesIntervals[s];
                }
                this.socket?.emit('join', `/quotes/real/${s}`);
            }, 100); // join faster
        }
    }

    unsubscribeQuotes(symbols: string[]) {
        for (const s of symbols) {
            if (this.unsubQuotesIntervals[s]) {
                clearInterval(this.unsubQuotesIntervals[s]);
            }
            this.unsubQuotesIntervals[s] = setTimeout(() => {
                this.socket?.emit('leave', `/quotes/real/${s}`);
                delete this.unsubQuotesIntervals[s];
            }, 1000); // leave slower
        }
    }

    subscribeMaxVolumes(symbol: string) {
        this.socket?.emit('join', `/maxVolumes/${symbol}`);
    }

    subscribeProfitLossExchangeRates(accountCurrency: string) {
        this.socket?.emit('join', `/profitLossExchangeRates/${accountCurrency}`);
    }

    subscribeNews(language: string) {
        this.socket?.emit('join', `/news/${language}`);
    }
}

export default new StreamingService();
