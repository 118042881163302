import React from 'react';
import { View, ViewProps, StyleSheet } from 'react-native';

import { useStyles } from '~/hooks';
import { Theme } from '~/theme';

export default function ThemedView({ style, ...rest }: ViewProps) {
    const styles = useStyles(stylesGenerator);

    return <View style={[style, styles.container]} {...rest} />;
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: theme.background,
        },
    });
