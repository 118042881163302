import React from 'react';
import Desktop from '../features/Desktop';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function DesktopScreen() {
    return (
        <ScreenWrapper>
            <Desktop />
        </ScreenWrapper>
    );
}
