import React from 'react';
import { StyleSheet, Pressable } from 'react-native';
import { Text } from '~/components/core';
import { FONT_SIZE_XS } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';

type Props = {
    labelKey: string;
    setSelected: Function;
    selected: string;
    position: 'left' | 'right' | 'center';
};

export default function PriceRangeTab({ labelKey, setSelected, selected, position }: Props) {
    const styles = useStyles(stylesGenerator);
    return (
        <Pressable
            onPress={() => setSelected(labelKey)}
            style={[
                styles.priceRangeTabContainer,
                selected === labelKey ? styles.priceRangeActiveTab : styles.priceRangeUnactiveTab,
                position === 'left' ? styles.leftTab : position === 'right' ? styles.rightTab : null,
            ]}
        >
            <Text
                fontType="LATO_BOLD"
                style={[
                    styles.priceRangeTabText,
                    selected === labelKey ? styles.priceRangeActiveTabText : styles.priceRangeUnactiveTabText,
                ]}
            >
                {labelKey}
            </Text>
        </Pressable>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        leftTab: { borderTopLeftRadius: scaleSize(5) },
        rightTab: { borderTopRightRadius: scaleSize(5) },
        priceRangeActiveTabText: { color: theme.white },
        priceRangeUnactiveTabText: { color: theme.tabIconDefault },
        priceRangeActiveTab: { backgroundColor: theme.background_positionsCount },
        priceRangeUnactiveTab: {},
        priceRangeTabContainer: { width: '25%', justifyContent: 'center', alignItems: 'center', height: scaleSize(20) },
        priceRangeTabText: { fontSize: FONT_SIZE_XS },
    });
