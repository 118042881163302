export const FONT_FAMILY_REGULAR = 'Montserrat-Regular';
export const FONT_FAMILY_BOLD = 'Montserrat-Bold';

export const FONT_FAMILIES = {
    LATO_BOLD: 'Lato-Bold',
    LATO_BLACK: 'Lato-Black',
    LATO_ITALIC: 'Lato-Italic',
    LATO_REGULAR: 'Lato-Regular',
    LATO_ITALIC_BOLD: 'Lato-Italic-Bold',
};

export const FONT_WEIGHT_REGULAR = '400';
export const FONT_WEIGHT_BOLD = '700';

export const FONT_SIZE_XXXXL = 24;
export const FONT_SIZE_XXXL = 22;
export const FONT_SIZE_XXL = 20;
export const FONT_SIZE_XL = 18;
export const FONT_SIZE_L = 16;
export const FONT_SIZE_M = 14;
export const FONT_SIZE_S = 12;
export const FONT_SIZE_LS = 11;
export const FONT_SIZE_XS = 10;
export const FONT_SIZE_XXS = 9;
export const FONT_SIZE_XXXS = 8;
export const FONT_SIZE_XXXXS = 7;

export const LINE_HEIGHT_LARGE = 24;
export const LINE_HEIGHT_MEDIUM = 20;
export const LINE_HEIGHT_SMALL = 16;
