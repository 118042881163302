import axios from '../baseClient';
import { CustomerInfoServiceResponse } from './types';

class CustomerInfoService {
    private static emerchantDomain = '/api/emerchantpayservice';
    private static trustpaymentsDomain = '/api/trustpaymentsservice';
    private static paytikoDomain = '/api/paytikorequester';
    private static arenamoneyDomain = '/api/arenamoneyrequester';

    getCustomerInfo(provider: string) {
        if (provider === 'TRUSTPAYMENTS') {
            return axios.post<CustomerInfoServiceResponse>(
                `${CustomerInfoService.trustpaymentsDomain}/getCustomerInfo`,
                {}
            );
        }
        // EMERCHANTPAYFORM
        return axios.post<CustomerInfoServiceResponse>(`${CustomerInfoService.emerchantDomain}/getCustomerInfo`, {});
    }

    getCustomerInfoIdeal() {
        return axios.post<CustomerInfoServiceResponse>(
            `${CustomerInfoService.trustpaymentsDomain}/getCustomerInfoIdeal`,
            {}
        );
    }

    getCustomerInfoPaytikoPayForm() {
        return axios.post<CustomerInfoServiceResponse>(
            `${CustomerInfoService.paytikoDomain}/getCustomerInfo`,
            {}
        );
    }

    getCustomerInfoArenaMoneyPayForm() {
        return axios.post<CustomerInfoServiceResponse>(
            `${CustomerInfoService.arenamoneyDomain}/getCustomerInfo`,
            {}
        );
    }
}

export default new CustomerInfoService();
