import { useEffect, useState } from 'react';
import ReportsService from '~/api-services/ReportsService';
import { Cash, ReportOrdersRequest } from '~/api-services/ReportsService/types';
import { useAppSelector } from './useAppSelector';
import { appSettingsSelector } from '~/state/selectors';

export const useCash = (from: Date, to: Date) => {
    const [cashArray, setCashArray] = useState<Cash[]>([]);
    const [totalFormatted, setTotalFormatted] = useState<string>('');
    const [totalDirection, setTotalDirection] = useState<'positive' | 'negative'>('positive');
    const [loading, setloading] = useState(true);
    const { language } = useAppSelector(appSettingsSelector);

    useEffect(() => {
        (async () => {
            if (from && to) {
                from.setHours(0);
                from.setMinutes(0);
                from.setSeconds(0);
                from.setMilliseconds(0);
                to.setHours(0);
                to.setMinutes(0);
                to.setSeconds(0);
                to.setMilliseconds(0);
                let request: ReportOrdersRequest = {
                    from: from.getTime(),
                    language: language.toUpperCase(),
                    page: 1,
                    pageSize: 10000,
                    sort: 'timestamp',
                    sortDirection: 'DESC',
                    to: to.getTime(),
                };
                setloading(true);
                const { status, data } = await ReportsService.getReportCash(request);
                if (status === 200 && data.status) {
                    setCashArray(data.returnData.data);
                    setTotalFormatted(data.returnData.totalFormatted);
                    setTotalDirection(data.returnData.totalDirection);
                }
                setloading(false);
            }
        })();
    }, [from, to]);

    return {
        cashArray,
        totalFormatted,
        totalDirection,
        loading
    };
};
