import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '~/components/core';
import { useStyles } from '~/hooks';
import { FONT_SIZE_S } from '~/constants';
import { Theme } from '~/theme';
import { formatDateToday } from '~/lib/format';
import { scaleSize } from '~/constants/scale';

type Props = {
    timestamp: number;
};

let lastDate = '';

export function getDateRow(timestamp: number, key: number) {
    const cDate = formatDateToday(timestamp);
    if (cDate !== lastDate || key === 0) {
        lastDate = cDate;
        return cDate;
    } else {
        return '';
    }
}

export default function DateRow({ timestamp }: Props) {
    const styles = useStyles(stylesGenerator);
    const cDate = formatDateToday(timestamp);
    return (
        <View style={styles.dateContainer} key={'dateContainer' + timestamp}>
            <Text fontType="LATO_BOLD" style={styles.dateText}>
                {cDate}
            </Text>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        dateContainer: {
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            paddingTop: scaleSize(14),
            paddingBottom: scaleSize(10),
            paddingLeft: scaleSize(10),
        },
        dateText: { fontSize: FONT_SIZE_S, color: theme.text_positionsCount },
    });
