import { useTheme } from './useTheme';

import type { Theme } from '~/theme';

export const useStyles = <M, T extends (theme: Theme, custom?: M) => any>(styleFunc: T, custom?: M) => {
    const theme = useTheme();
    const styles: ReturnType<T> = styleFunc(theme, custom);

    return styles;
};
