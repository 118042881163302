import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '~/components/core';
import { FONT_SIZE_XXS } from '~/constants';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { EditPositionDetail } from '.';

type Props = {
    editPositionDetail: EditPositionDetail;
    rowStyle: any[];
};

export default function PositionDetail({ editPositionDetail, rowStyle }: Props) {
    const styles = useStyles(stylesGenerator);
    if (editPositionDetail.value) {
        return (
            <View style={rowStyle}>
                <View style={styles.left}>
                    <Text fontType="LATO_REGULAR" style={[styles.detailsText, styles.textSpacing]}>
                        {editPositionDetail.label}
                    </Text>
                    <Text fontType="LATO_REGULAR" style={[styles.detailsText, styles.textSpacing]}>
                        :
                    </Text>
                </View>
                <View style={styles.right}>
                    <Text fontType="LATO_REGULAR" style={[styles.detailsText, editPositionDetail.valueStyle]} numberOfLines={2}>
                        {editPositionDetail.value}
                    </Text>
                </View>
            </View>
        );
    }
    else {
        return null;
    }
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        left: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
        },
        right: {
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'row',
            maxWidth: '98%'
        },
        detailsText: { fontSize: FONT_SIZE_XXS, color: theme.text_pendingOrder },
        textSpacing: { letterSpacing: 0 },
    });
