import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import Cash from '../features/Cash';

export default function CashScreen() {
    return (
        <ScreenWrapper>
            <Cash />
        </ScreenWrapper>
    );
}
