import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { accountBalanceSelector, appSettingsSelector } from '~/state/selectors';
import { FONT_SIZE_XS, FONT_SIZE_S } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { Interest } from '~/api-services/ReportsService/types';
import { formatCurrencyWithFormatetdNumber, formatTime } from '~/lib/format';

type Props = {
    interest: Interest;
};

export default function InterestRow({ interest }: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';

    return (
        <>
            <View
                style={[
                    styles.positionGroupContainer,
                    styles.positionGroupClose,
                    colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                ]}
                key={'instrumentContainer' + interest.symbol}
            >
                <View
                    style={[
                        styles.positionItemSide,
                        colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                    ]}
                >
                    <View style={[styles.rotateTextWrapper, styles.buySell]}>
                        <Text fontType="LATO_BLACK" style={interest.side === 'buy' ? styles.buyText : styles.sellText}>
                            {interest.side.toUpperCase()}
                        </Text>
                    </View>
                </View>
                <View style={styles.instrumentName}>
                    <View>
                        <Text fontType="LATO_BOLD" style={styles.instrumentNameText}>
                            {interest.symbolNameTranslated}
                        </Text>
                    </View>
                    <View style={styles.positionItemVolume}>
                        <View style={styles.volumeBackground}>
                            <Text fontType="LATO_BOLD" style={styles.volumeText}>
                                {interest.side === 'buy'
                                    ? '+' + interest.volumeFormatted
                                    : '-' + interest.volumeFormatted}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.timeContainer}>
                    {interest.typeTranslated === 'Rollover' || interest.typeTranslated === 'Ролловер' || interest.typeTranslated === 'Ролловер' ? 
                    <Text style={[styles.positionItemPriceText]}>Rollover</Text>
                    : 
                    <Text style={[styles.positionItemPriceText]}>time-label</Text>
                    }
                    <Text style={[styles.positionItemPriceText]}>: </Text>
                    <Text style={[styles.positionItemPriceText]}>{formatTime(interest.timestamp)}</Text>
                </View>
                <View style={styles.positionItemPrice}>
                    <Text
                        style={[
                            styles.positionItemPriceText,
                            interest.amount >= 0 ? styles.positiveResult : styles.negativeResult,
                        ]}
                    >
                        {formatCurrencyWithFormatetdNumber(interest.amountFormatted, currency)}
                    </Text>
                </View>
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        positiveResult: { color: theme.text_upPrice },
        negativeResult: { color: theme.text_downPrice },
        positionItemVolume: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: '1%',
            paddingTop: scaleSize(5),
        },
        positionItemSide: {
            width: '5%',
            borderRightWidth: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        instrumentName: { flexWrap: 'wrap', width: '34%', paddingLeft: '2%', justifyContent: 'center' },
        timeContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '33%' },
        positionItemPrice: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '33%' },

        positionItemPriceText: { fontSize: FONT_SIZE_S, color: theme.text_pendingOrder, paddingRight: scaleSize(7) },

        buyText: {
            fontSize: FONT_SIZE_S,
            color: theme.text_upPrice,
        },
        sellText: {
            fontSize: FONT_SIZE_S,
            color: theme.text_downPrice,
        },
        buySell: { width: scaleSize(50), alignItems: 'center' },
        rotateTextWrapper: { transform: [{ rotate: '-90deg' }] },

        positionGroupContainer: {
            flexDirection: 'row',
            borderWidth: 1,
            width: '100%',
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            height: scaleSize(50),
            backgroundColor: theme.backgroundSecondary,
        },
        positionGroupClose: { borderRadius: scaleSize(5), marginTop: scaleSize(7) },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },

        instrumentNameText: { fontSize: FONT_SIZE_S, letterSpacing: 0, color: theme.text_symbol },
        volumeBackground: {
            backgroundColor: theme.background_message,
            borderRadius: scaleSize(2),
            flexDirection: 'row',
            paddingHorizontal: scaleSize(5),
            paddingVertical: scaleSize(3),
        },
        volumeText: { color: theme.white, fontSize: FONT_SIZE_XS, letterSpacing: 0.5 },
    });
