import config from '~/config';
import axios from '../baseClient';

import {
    GetKeepALiveResponse,
    GetAllSymbolNamesResponse,
    GetPositionsResponse,
    GetPreferencesResponse,
    ClosePositionRequest,
    ClosePositionResponse,
    DeletePendingOrderRequest,
    DeletePendingOrderResponse,
    EditPositionRequest,
    EditPositionResponse,
    EditPendingOrderRequest,
    EditPendingOrderResponse,
    ExecuteMarketOrderResponse,
    AddPendingOrderResponse,
    OrderRequest,
    GetTradeMarginRequest,
    GetTradeMarginResponse,
    ChangePasswordRequest,
    ChangePasswordResponse,
    GetAllMessagesResponse,
    InvestorOpenedPositionsResponse,
    CheckWithdrawAmountLimitResponse,
} from './types';

class CoreDataService {
    private static domain = '/api/backend';

    getTrades() {
        return axios.post<GetPositionsResponse>(`${CoreDataService.domain}/openpositions`, {});
    }

    getKeepALive() {
        return axios.post<GetKeepALiveResponse>(`${CoreDataService.domain}/getbalance`, {});
    }

    checkWithdrawAmountLimit(amount: number ) {
        return axios.post<CheckWithdrawAmountLimitResponse>(`${CoreDataService.domain}/checkWithdrawAmountLimit/` + amount, {});
    }

    getAllSymbolNames() {
        return axios.post<GetAllSymbolNamesResponse>(`${CoreDataService.domain}/getinstruments`, {});
    }

    closePosition(closePositionRequest: ClosePositionRequest) {
        return axios.post<ClosePositionResponse>(`${CoreDataService.domain}/closePosition`, closePositionRequest);
    }

    deletePendingOrder(deletePendingOrderRequest: DeletePendingOrderRequest) {
        return axios.post<DeletePendingOrderResponse>(
            `${CoreDataService.domain}/deletePendingOrder`,
            deletePendingOrderRequest
        );
    }

    editPosition(editPositionRequest: EditPositionRequest) {
        return axios.post<EditPositionResponse>(`${CoreDataService.domain}/editPosition`, editPositionRequest);
    }

    editPendingOrder(editPendingOrderRequest: EditPendingOrderRequest) {
        return axios.post<EditPendingOrderResponse>(
            `${CoreDataService.domain}/editPendingOrder`,
            editPendingOrderRequest
        );
    }

    executeMarketOrder(executeMarketOrderRequest: OrderRequest) {
        return axios.post<ExecuteMarketOrderResponse>(
            `${CoreDataService.domain}/newmarketorder`,
            executeMarketOrderRequest
        );
    }

    addPendingOrder(addPendingOrderRequest: OrderRequest) {
        return axios.post<AddPendingOrderResponse>(`${CoreDataService.domain}/newpendingorder`, addPendingOrderRequest);
    }

    getTradeMargin(getTradeMarginRequest: GetTradeMarginRequest) {
        return axios.post<GetTradeMarginResponse>(`${CoreDataService.domain}/getmargin`, getTradeMarginRequest);
    }

    changePassword(changePasswordRequest: ChangePasswordRequest) {
        return axios.post<ChangePasswordResponse>(`${CoreDataService.domain}/changePassword`, changePasswordRequest);
    }

    getPreferences(key: string) {
        return axios.post<GetPreferencesResponse>(`${CoreDataService.domain}/getPreferences`, {
            key: key,
        });
    }

    getAllMessages() {
        return axios.post<GetAllMessagesResponse>(`${CoreDataService.domain}/getnotifications`, {});
    }

    markMessagesAsRead(messageIds: string[]) {
        axios.post<GetAllMessagesResponse>(`${CoreDataService.domain}/markasread`, { messageIds: messageIds });
    }

    getInvestorOpenedPositions(investorUid: string) {
        return axios.post<InvestorOpenedPositionsResponse>(`${CoreDataService.domain}/getInvestorOpenedPositions`, {investorUid: investorUid, website: config.WEBSITE});
    }
}

export default new CoreDataService();
