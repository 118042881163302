import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import ChangePassword from '../features/ChangePassword';

type Props = {
    setActiveRightTab?: Function;
};

export default function ChangePasswordScreen({ setActiveRightTab }: Props) {
    return (
        <ScreenWrapper>
            <ChangePassword setActiveRightTab={setActiveRightTab} />
        </ScreenWrapper>
    );
}
