import React from 'react';
import ConfirmationForm from '../features/Authentication/ConfirmationForm';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function ConfirmationScreen() {
    return (
        <ScreenWrapper>
            <ConfirmationForm />
        </ScreenWrapper>
    );
}
