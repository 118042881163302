import React from 'react';

import LoginForm from '~/components/features/Authentication/LoginForm';
import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function LoginScreen() {
    return (
        <ScreenWrapper>
            <LoginForm />
        </ScreenWrapper>
    );
}
