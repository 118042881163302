import React from 'react';
import ScreenWrapper from '../features/Theme/ScreenWrapper';
import AddFundsAlternativePayment from '../features/AddFundsAlternativePayment';

type Props = {
    provider?: string;
    currentAmount?: string;
    method?: string;
    setActiveRightTab?: Function;
};

export default function AddFundsAlternativePaymentScreen({ provider, method, currentAmount, setActiveRightTab }: Props) {
    return (
        <ScreenWrapper>
            <AddFundsAlternativePayment
                provider={provider}
                currentAmount={currentAmount}
                method={method}
                setActiveRightTab={setActiveRightTab}
            />
        </ScreenWrapper>
    );
}
