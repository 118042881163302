import { useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { useInvestments } from '~/hooks/useInvestments';
import InvestorMenu from './InvestorMenu';
import Portfolio from './Portfolio';
import InvestorOpenTrades from './InvestorOpenTrades';
import InvestorTradingHistory from './InvestorTradingHistory';
import ConfirmationMessage from '../../ConfirmationMessage';
import ConfirmationCopyTrading from '../ConfirmationCopyTrading';
import { Theme } from '~/theme';
import { Platform, StyleSheet, useWindowDimensions, View } from 'react-native';
import { useStyles } from '~/hooks';
import YesNoMessage from '../../YesNoMessage';
import { scaleSize } from '~/constants/scale';
import Loader from '~/components/core/Loader';


type Props = {
    selectedId?: string;
    setActiveWatchlist?: Function;
};

export default function Investments({ selectedId, setActiveWatchlist }: Props) {
    const route = useRoute();
    const styles = useStyles(stylesGenerator);
    const investorUid = selectedId ? selectedId : (route.params as any).id;
    const dimensions = useWindowDimensions();

    const {
        getResponse,
        investorResultResponse,
        addFollower,
        removeFollower,
        investorOpenedPositions,
        confirmationVisible,
        setConfirmationVisible,
        confirmationProps,
        loading
    } = useInvestments(investorUid);
    const [selectedMenu, setSelectedMenu] = useState('portfolio');
    const [confirmation, setConfirmation] = useState(false);
    const [yesNoVisible, setYesNoVisible] = useState(false);
    const yesNoText = getResponse && getResponse.investorInfo ? 'By unfollowing "' + getResponse.investorInfo.name + '" (this Investor), I confirm that all open positions in my account by this Investor will be closed.' : '';
    const unfollowInvestorUID = getResponse && getResponse.investorInfo ? getResponse.investorInfo.uid: '';

    function unfollowInvestor() {
        removeFollower(unfollowInvestorUID);
        setYesNoVisible(false);
    }

    function pressUnfollow() {
        setYesNoVisible(true);
    }

    return (
        <>
            <Loader loading={loading} />
            {getResponse ? (
                <InvestorMenu
                    setConfirmation={setConfirmation}
                    selectedMenu={selectedMenu}
                    setSelectedMenu={setSelectedMenu}
                    addFollower={addFollower}
                    unfollowInvestor={pressUnfollow}
                    investorInfo={getResponse.investorInfo}
                    setActiveWatchlist={setActiveWatchlist}
                />
            ) : null}
            {confirmation ? (
                <ConfirmationCopyTrading setConfirmation={setConfirmation} followInvestorUID={getResponse.investorInfo.uid} addFollower={addFollower} />
            ) : (
                <>
                    {selectedMenu === 'portfolio' ? <Portfolio getResponse={getResponse} /> : null}
                    {selectedMenu === 'open-trades' ? <InvestorOpenTrades positions={investorOpenedPositions} /> : null}
                    {selectedMenu === 'trading-history' ? (
                        <InvestorTradingHistory investorResult={investorResultResponse} />
                    ) : null}
                </>
            )}
            <ConfirmationMessage
                text={confirmationProps.text}
                error={confirmationProps.error}
                visible={confirmationVisible}
                setVisible={setConfirmationVisible}
            />
            {yesNoVisible ? (
            <View
                style={[styles.blurScreen, { height: dimensions.height }]}
            />
            ) : null}
            <YesNoMessage visible={yesNoVisible} text={yesNoText} setVisible={setYesNoVisible} yesFunction={unfollowInvestor}/>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        blurScreen: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: theme.background,
            opacity: 0.75,
        },
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
            paddingBottom: scaleSize(7),
        },
    });
