import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, Platform, Keyboard, KeyboardAvoidingView } from 'react-native';
import { Button, TextInput, Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { FONT_SIZE_S, spacing } from '~/constants';
import { scaleSize, scaleSizeMobile } from '~/constants/scale';
import { Theme } from '~/theme';
import config from '~/config';
import { appSettingsSelector } from '~/state/selectors';
import { SvgUri } from 'react-native-svg';
import RequestResetPasswordService from '~/api-services/RequestResetPasswordService';
import t from '~/i18n';
import ConfirmationMessage from '../../ConfirmationMessage';
import paths from '~/navigation/paths';
import { linkTo } from '~/navigation';
import { useBrowserLanguage } from '~/hooks/useBrowserLanguage';
import { enableForgotPasswordButton, enableRegistrationButton } from '~/lib/utils';

export default function ForgotPasswordForm() {
    const { lastEmail, colorScheme } = useAppSelector(appSettingsSelector);
    const [email, setEmail] = useState(lastEmail);
    const maxWidth: number = 600;
    const styles = useStyles(stylesGenerator, { maxWidth });
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    useBrowserLanguage();

    useEffect(() => {
        setTimeout(() => {
            setEmail(lastEmail);
        }, 1000);
    }, [lastEmail]);

    async function resetPassword() {
        const { status, data } = await RequestResetPasswordService.requestResetPassword({
            website: config.WEBSITE,
            email: email,
            source: Platform.OS === 'web' ? 'WEB' : 'MOBILE',
            apiVersion: Number(config.API_VERSION),
            sourceType: Platform.OS.toUpperCase(),
            sourceVersion: config.SOURCE_VERSION,
        });
        if (status === 200 && data.status) {
            setConfirmationProps({ text: t.t('reset-pass-success') + email, error: false });
            setConfirmationVisible(true);
        } else {
            if (data.errorCode === 'EmailNotFound') {
                setConfirmationProps({ text: 'reset-pass-not-found', error: true });
                setConfirmationVisible(true);
            } else {
                setConfirmationProps({ text: 'reset-pass-error', error: true });
                setConfirmationVisible(true);
            }
        }
    }

    return (
        <KeyboardAvoidingView behavior={'height'} style={styles.container} >
            <View style={styles.logoContainer}>
                {Platform.OS === 'web' ? (
                    <Image
                        source={{
                            uri: 'https://' +
                                config.EXTERNAL_SOURCES_URL +
                                'mobileplatform/images/brand/' +
                                config.BRAND_NAME +
                                '/home_logo_' +
                                colorScheme +
                                '.svg?1'
                        }}
                        style={styles.logoStyle}
                        resizeMode="contain"
                    />
                ) : (
                    <SvgUri
                        width={'60%'}
                        style={styles.logoStyle}
                        uri={
                            'https://' +
                            config.EXTERNAL_SOURCES_URL +
                            'mobileplatform/images/brand/' +
                            config.BRAND_NAME +
                            '/home_logo_' +
                            colorScheme +
                            '.svg?1'
                        }
                    />
                )}
            </View>
            {!confirmationVisible ? (
                <TextInput
                    containerStyle={styles.inputContainer}
                    leftIcon="email"
                    placeholder="auth-input-enter-email"
                    autoCapitalize="none"
                    keyboardType="email-address"
                    textContentType="emailAddress"
                    autoFocus={false}
                    value={email}
                    onChangeText={(text) => setEmail(text)}
                    maxWidth={maxWidth}
                    returnKeyType="done"
                    onSubmitEditing={() => {
                        if (email && email !== '') {
                            Keyboard.dismiss;
                            resetPassword();
                            Keyboard.dismiss;
                        }
                    }}
                />
            ) : null}
            {!confirmationVisible ? (
                <Button
                    disabled={!email}
                    onPress={() => resetPassword()}
                    title="auth-button-reset"
                    containerStyle={styles.loginButton}
                    maxWidth={maxWidth}
                />
            ) : null}
            <View style={styles.footerContainer}>
                {enableForgotPasswordButton() ?
                    <Text
                        fontType="LATO_BOLD"
                        style={styles.footerText}
                        onPress={() => linkTo(paths.login)}
                        maxWidth={maxWidth}
                    >
                        auth-title-login
                    </Text> : null}
                {enableRegistrationButton() ?
                    <>
                        <View style={styles.footerSeparator} />
                        <Text
                            fontType="LATO_BOLD"
                            style={styles.footerText}
                            onPress={() => linkTo(paths.registration)}
                            maxWidth={maxWidth}
                        >
                            auth-button-go-to-signup
                        </Text>
                    </> : null}
            </View>
            <ConfirmationMessage
                text={confirmationProps.text}
                error={confirmationProps.error}
                visible={confirmationVisible}
                setVisible={setConfirmationVisible}
                timeout={30000}
            />
        </KeyboardAvoidingView>
    );
}

const stylesGenerator = (theme: Theme, custom?: { maxWidth: number }) =>
    StyleSheet.create({
        footerText: { color: theme.switch_trackOn, fontSize: FONT_SIZE_S },
        footerSeparator: {
            width: 1,
            height: scaleSize(48, custom?.maxWidth),
            backgroundColor: theme.background_buttonInactive,
        },
        footerContainer: { flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', paddingBottom: scaleSize(10) },
        logoStyle: { width: config.HOME_LOGO_SIZE_IN_PERCENT, height: '100%', maxWidth: scaleSizeMobile(custom?.maxWidth), maxHeight: scaleSizeMobile(custom?.maxWidth) }, logoContainer: { height: '50%', justifyContent: 'center', alignItems: 'center' },
        container: {
            flex: 1,
            justifyContent: 'space-between',
            alignSelf: 'center',
            maxWidth: custom?.maxWidth,
            paddingHorizontal: scaleSize(12, custom?.maxWidth),
            width: '100%',
            height: '100%',
            maxHeight: '100%',
        },
        inputContainer: {
            marginBottom: scaleSize(spacing.LARGE, custom?.maxWidth),
        },
        loginButton: {
            marginBottom: scaleSize(100, custom?.maxWidth),
        },
    });
