import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import AutoLoginForm from '../features/Authentication/AutoLoginForm';

export default function AutoLoginScreen() {
    return (
        <ScreenWrapper>
            <AutoLoginForm />
        </ScreenWrapper>
    );
}
