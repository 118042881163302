import React, { useState } from 'react';
import { View, StyleSheet, Pressable, Platform } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { useAllOrders } from '~/hooks/useAllOrders';
import OrderRow from './OrderRow';
import FromToSelector from '../Result/FromToSelector';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import Loader from '~/components/core/Loader';

const now = new Date();
const prevPeriod = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
prevPeriod.setHours(0);
prevPeriod.setMinutes(0);
prevPeriod.setSeconds(0);

export default function AllOrders() {
    const [from, setFrom] = useState(prevPeriod);
    const [to, setTo] = useState(now);
    const { orders, loading } = useAllOrders(from, to);
    const [openOrder, setOpenOrder] = useState('');
    const styles = useStyles(stylesGenerator);

    return (
        <>
            <Loader loading={loading} />
            <ScrollbarWrapper>
                <View style={styles.ordersContainer}>
                    {orders.length !== 0 &&
                        orders.map((order, key) => (
                            <Pressable
                                onPress={() => setOpenOrder(openOrder === order.id ? '' : order.id)}
                                style={styles.button}
                                key={key}
                            >
                                <OrderRow order={order} isOpen={openOrder === order.id} />
                            </Pressable>
                        ))}
                </View>
            </ScrollbarWrapper>
            <FromToSelector setFromButton={setFrom} setToButton={setTo} prevPeriod={prevPeriod} />
        </>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        ordersContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
        },
        button: { width: '100%' },
    });
