import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { accountBalanceSelector, appSettingsSelector } from '~/state/selectors';
import { FONT_SIZE_XS, FONT_SIZE_S } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { Result } from '~/api-services/ReportsService/types';
import { formatCurrencyWithFormatetdNumber, formatTime } from '~/lib/format';
import ResultDetails from './ResultDetails';

type Props = {
    result: Result;
    isOpen: boolean;
};

export default function ResultRow({ result, isOpen }: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';

    return (
        <>
            <View
                style={[
                    styles.positionGroupContainer,
                    isOpen ? styles.positionGroupOpen : styles.positionGroupClose,
                    colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                ]}
                key={'instrumentContainer' + result.symbol}
            >
                <View
                    style={[
                        styles.positionItemSide,
                        colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                    ]}
                >
                    <View style={[styles.rotateTextWrapper, styles.buySell]}>
                        <Text fontType="LATO_BLACK" style={result.side === 'buy' ? styles.buyText : styles.sellText}>
                            {result.side.toUpperCase()}
                        </Text>
                    </View>
                </View>
                <View style={styles.instrumentName}>
                    <View>
                        <Text fontType="LATO_BOLD" style={styles.instrumentNameText}>
                            {result.symbolNameTranslated}
                        </Text>
                        {
                            result.investorName ?
                                <View style={styles.copyTradingSymbolContainer}>
                                    <Text fontType="LATO_BLACK" style={styles.copyTradingSymbol}>
                                        CT
                                    </Text>
                                </View> : null
                        }
                    </View>
                    <View style={styles.positionItemVolume}>
                        <View style={styles.volumeBackground}>
                            <Text fontType="LATO_BOLD" style={styles.volumeText}>
                                {result.side === 'buy' ? '+' + result.volumeFormatted : '-' + result.volumeFormatted}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.positionGroupPrice}>
                    <View style={styles.openClosePriceContainer}>
                        <Text style={[styles.positionItemPriceText]}>position-open</Text>
                        <Text style={[styles.positionItemPriceText]}>: </Text>
                        <Text style={[styles.positionItemPriceText]}>{result.openPriceFormatted}</Text>
                    </View>
                    <View style={styles.openClosePriceContainer}>
                        <Text style={[styles.positionItemPriceText]}>position-close</Text>
                        <Text style={[styles.positionItemPriceText]}>: </Text>
                        <Text style={[styles.positionItemPriceText]}>{result.closePriceFormatted}</Text>
                    </View>
                </View>
                <View style={styles.positionGroupResult}>
                    <View style={styles.timeContainer}>
                        <Text style={[styles.positionItemPriceText]}>{formatTime(result.timestamp)}</Text>
                    </View>
                    <Text
                        style={[
                            styles.positionItemPriceText,
                            result.result >= 0 ? styles.positiveResult : styles.negativeResult,
                        ]}
                    >
                        {formatCurrencyWithFormatetdNumber(result.resultFormatted, currency)}
                    </Text>
                </View>
            </View>
            {isOpen ? <ResultDetails result={result} /> : null}
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        positionGroupClose: { borderRadius: scaleSize(5), marginTop: scaleSize(7) },
        positionGroupOpen: {
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            marginTop: scaleSize(7),
        },
        copyTradingSymbolContainer: { marginTop: scaleSize(3), borderRadius: scaleSize(2), width: scaleSize(18), height: scaleSize(13), backgroundColor: theme.ctInfo, justifyContent: 'center', alignItems: 'center' },
        copyTradingSymbol: { fontSize: FONT_SIZE_XS, color: theme.ctTextColor },
        positionItemSide: {
            width: '5%',
            borderRightWidth: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        instrumentName: { flexWrap: 'wrap', width: '32%', justifyContent: 'center', paddingLeft: scaleSize(10) },
        positionGroupPrice: { width: '32%', justifyContent: 'center', alignItems: 'center' },
        positionGroupResult: {
            justifyContent: 'center',
            alignItems: 'flex-end',
            width: '31%',
            paddingRight: scaleSize(10),
        },

        timeContainer: { paddingBottom: scaleSize(10) },
        openClosePriceContainer: { flexDirection: 'row', paddingVertical: scaleSize(5) },
        positionItemPriceText: { fontSize: FONT_SIZE_XS, color: theme.text_pendingOrder },
        buyText: {
            fontSize: FONT_SIZE_S,
            color: theme.text_upPrice,
        },
        positiveResult: { color: theme.text_upPrice },
        negativeResult: { color: theme.text_downPrice },
        sellText: {
            fontSize: FONT_SIZE_S,
            color: theme.text_downPrice,
        },
        buySell: { width: scaleSize(50), alignItems: 'center' },
        rotateTextWrapper: { transform: [{ rotate: '-90deg' }] },
        positionGroupContainer: {
            flexDirection: 'row',
            borderWidth: 1,
            width: '100%',
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            height: scaleSize(50),
            backgroundColor: theme.backgroundSecondary,
        },

        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },

        instrumentNameText: { fontSize: FONT_SIZE_S, letterSpacing: 0, color: theme.text_symbol },
        volumeBackground: {
            backgroundColor: theme.background_message,
            borderRadius: scaleSize(2),
            flexDirection: 'row',
            paddingHorizontal: scaleSize(5),
            paddingVertical: scaleSize(3),
        },
        volumeText: { color: theme.white, fontSize: FONT_SIZE_XS, letterSpacing: 0.5 },
        positionItemVolume: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: '1%',
            paddingTop: scaleSize(5),
        },
    });
