import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import ClientReport from '../features/ClientReport';

export default function ClientReportScreen() {
    return (
        <ScreenWrapper>
            <ClientReport/>
        </ScreenWrapper>
    );
}
