import React from 'react';
import More from '../features/More';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
type Props = {
    setActiveRightTab?: Function;
};
export default function MoreScreen({ setActiveRightTab }: Props) {
    return (
        <ScreenWrapper>
            <More setActiveRightTab={setActiveRightTab} />
        </ScreenWrapper>
    );
}
