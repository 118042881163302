import * as React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import i18n from '~/i18n';

import OpenTradesScreen from '../screens/OpenTradesScreen';
import AllOrdersScreen from '../screens/AllOrdersScreen';
import ResultScreen from '../screens/ResultScreen';
import CashScreen from '../screens/CashScreen';
import { useStyles } from '~/hooks';
import { Platform, StyleSheet } from 'react-native';
import { FONT_FAMILIES, FONT_SIZE_S } from '~/constants';
import { scaleSize } from '~/constants/scale';
import CommissionsScreen from '../screens/CommissionsScreen';
import InterestSwapScreen from '../screens/InterestSwapScreen';
import { useTheme } from '~/hooks/useTheme';

const Tab = createMaterialTopTabNavigator();

export default function DealsTopTabsNavigator() {
    const theme = useTheme();
    const styles = useStyles(stylesGenerator);
    return (
        <Tab.Navigator
            screenOptions={{
                tabBarScrollEnabled: true,
                lazy: true,
                tabBarActiveTintColor: theme.tabIconSelected,
                tabBarInactiveTintColor: theme.tabIconDefault,
                tabBarIndicatorStyle: { backgroundColor: theme.tabIconSelected },
                ...styles,
            }}
        >
            <Tab.Screen
                name="OpenTrades"
                key="OpenTrades"
                component={OpenTradesScreen}
                options={{ title: i18n.t('open-trades') }}
            />
            <Tab.Screen name="Result" key="Result" component={ResultScreen} options={{ title: i18n.t('result') }} />
            <Tab.Screen name="Cash" key="Cash" component={CashScreen} options={{ title: i18n.t('cash') }} />
            <Tab.Screen
                name="AllOrders"
                key="AllOrders"
                component={AllOrdersScreen}
                options={{ title: i18n.t('all-orders') }}
            />
            <Tab.Screen
                name="Commissions"
                key={'Commissions'}
                component={CommissionsScreen}
                options={{ title: i18n.t('commissions-screen') }}
            />
            <Tab.Screen
                name="InterestSwap"
                key={'InterestSwap'}
                component={InterestSwapScreen}
                options={{ title: i18n.t('interest-swap-screen') }}
            />
        </Tab.Navigator>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        tabBarLabelStyle: {
            fontFamily: FONT_FAMILIES.LATO_BOLD,
            fontWeight: Platform.OS === 'web' ? 'bold' : 'regular',
            fontSize: scaleSize(FONT_SIZE_S),
            flex: 1,
        },
        tabBarStyle: {
            alignItems: 'stretch',
        },
        tabBarIconStyle: {
            minHeight: scaleSize(24),
            flex: 1,
        },
        tabBarItemStyle: {
            paddingHorizontal: scaleSize(20),
            width: 'auto',
        },
    });
