import axios from '../baseClient';
import { InstrumentNewsResponse, News2Request, NewsResponse } from './types';

class NewsService {
    private static domain = '/api/24yieldnews';

    getAllNews2(allNews2Request: News2Request) {
        return axios.post<NewsResponse>(`${NewsService.domain}/getAllNews2`, allNews2Request);
    }

    getInstrumentNews(instrumentCode: string) {
        return axios.post<InstrumentNewsResponse>(`${NewsService.domain}/getInstrumentNews`, {instrumentCode: instrumentCode});
    }
}

export default new NewsService();
