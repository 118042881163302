import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import AddFundsBank from '../features/AddFundsBank';

type Props = {
    setActiveRightTab?: Function;
    currentAmount?: string;
};

export default function AddFundsBankScreen({ setActiveRightTab, currentAmount }: Props) {
    return (
        <ScreenWrapper>
            <AddFundsBank setActiveRightTab={setActiveRightTab} currentAmount={currentAmount} />
        </ScreenWrapper>
    );
}
