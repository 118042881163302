import React from 'react';
import { View, StyleSheet, Image, Pressable } from 'react-native';
import { Investor } from '~/api-services/CopyTradingService/types';
import { Text } from '~/components/core';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';
import { appSettingsSelector, dealerSelector } from '~/state/selectors';
import { Theme } from '~/theme';
import { Entypo, Feather } from '@expo/vector-icons';
import { FONT_SIZE_S, FONT_SIZE_XXL } from '~/constants';
import { formatNumberWithPercent } from '~/lib/format';

type Props = {
    investor: Investor;
    addFollower: Function;
    setActiveWatchlist: Function;
    setSelectedId: Function;
    returnOptions: string;
    allInvestors: boolean;
    setConfirmation: Function;
    setYesNoVisible: Function;
    setYesNoText: Function;
    setUnfollowInvestorUID: Function;
    setFollowInvestorUID: Function;
};

export default function InvestorCard({
    investor,
    addFollower,
    setActiveWatchlist,
    setSelectedId,
    returnOptions,
    allInvestors,
    setConfirmation,
    setYesNoVisible,
    setYesNoText,
    setUnfollowInvestorUID,
    setFollowInvestorUID
}: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const dealer = useAppSelector(dealerSelector);


    function showInvestments() {
        if (dealer !== 'EVER') {
            if (isDesktop()) {
                setSelectedId(investor.uid);
                setActiveWatchlist(-2);
            } else {
                linkTo(paths.investments.replace(':id', investor.uid));
            }
        }
    }

    async function pressFollower() {
        if (dealer !== 'EVER') {
            if (investor.followed === false) {
                let result: string = await addFollower(investor.uid, investor.name);
                if (result === 'CopyTradingNotConfirmed') {
                    setFollowInvestorUID(investor.uid);
                    setConfirmation(true);
                }
            } else {
                setYesNoText('By unfollowing "' + investor.name + '" (this Investor), I confirm that all open positions in my account by this Investor will be closed.');
                setUnfollowInvestorUID(investor.uid);
                setYesNoVisible(true);
            }
        }
    }

    function getRiskColor(risk: number): string {
        if (risk > 9) {
            return '#ed3225';
        }
        if (risk > 8) {
            return '#ef562f';
        }
        if (risk > 7) {
            return '#f17537';
        }
        if (risk > 6) {
            return '#f3933f';
        }
        if (risk > 5) {
            return '#f7b13c';
        }
        if (risk > 4) {
            return '#fbd03a';
        }
        if (risk > 3) {
            return '#ffe837';
        }
        if (risk > 2) {
            return '#ebeb41';
        }
        if (risk > 1) {
            return '#a6dc65';
        }
        return '#0abab5';
    }
    if (allInvestors || investor.followed) {
        return (
            <>
                <View
                    style={[
                        styles.positionGroupContainer,
                        colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                    ]}
                    key={'InvestorContainer' + investor.uid}
                >
                    <Pressable onPress={() => showInvestments()} style={styles.avatarContainer}>
                        <Image
                            source={{
                                uri: investor.avatarUrl,
                            }}
                            style={styles.avatarStyle} />
                    </Pressable>
                    <Pressable onPress={() => showInvestments()} style={styles.nameContainer}>
                        <Text fontType="LATO_BLACK" style={styles.investorNameText}>
                            {investor.name && investor.name.length > 15
                                ? investor.name.substring(0, 15) + '...'
                                : investor.name}
                        </Text>
                    </Pressable>
                    <Pressable onPress={() => showInvestments()} style={styles.returnContainer}>
                        <Text
                            fontType="LATO_BLACK"
                            style={[
                                styles.returnText,
                                (returnOptions === '1m' && investor.profit_30d >= 0) ||
                                    (returnOptions === '12m' && investor.profit_12m >= 0) ||
                                    (returnOptions === '7d' && investor.profit_7d >= 0)
                                    ? styles.returnTextColorPositive
                                    : styles.returnTextColorNegative,
                            ]}
                        >
                            {formatNumberWithPercent(
                                returnOptions === '1m'
                                    ? investor.profit_30d
                                    : returnOptions === '12m'
                                        ? investor.profit_12m
                                        : investor.profit_7d,
                                2,
                                false
                            )}
                        </Text>
                    </Pressable>
                    <Pressable onPress={() => showInvestments()} style={styles.riskContainer}>
                        <View style={[styles.riskDiv, { borderColor: getRiskColor(investor.avg_risk_12m) }]}>
                            <Text
                                fontType="LATO_BLACK"
                                style={[styles.riskText, { color: getRiskColor(investor.avg_risk_12m) }]}
                            >
                                {investor.avg_risk_12m < 1
                                    ? '1'
                                    : investor.avg_risk_12m > 10
                                        ? '10'
                                        : Math.floor(investor.avg_risk_12m).toString()}
                            </Text>
                        </View>
                    </Pressable>
                    <View style={styles.followContainer}>
                        <Pressable
                            onPress={() => {
                                pressFollower();
                            } }
                            style={investor.followed && allInvestors ? styles.followButton : styles.unfollowButton}
                        >
                            {allInvestors ? (
                                <Entypo
                                    name="check"
                                    style={investor.followed ? styles.buttonWhite : styles.buttonDark}
                                    size={scaleSize(14)} />
                            ) : (
                                <Feather name="plus" style={styles.forgetIcon} size={scaleSize(14)} />
                            )}
                        </Pressable>
                    </View>
                </View>
            </>
        );
    } else {
        return null;
    }
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        
        buttonWhite: { color: theme.white },
        buttonDark: { color: theme.background_message },
        investorNameText: { fontSize: FONT_SIZE_S },
        returnText: { fontSize: FONT_SIZE_S },
        returnTextColorPositive: { color: theme.switch_trackOn },
        returnTextColorNegative: { color: theme.text_downPrice },
        forgetIcon: { color: theme.background_message, transform: [{ rotate: '45deg' }] },
        riskText: { fontSize: FONT_SIZE_XXL },
        riskDiv: {
            alignItems: 'center',
            justifyContent: 'center',
            width: scaleSize(30),
            height: scaleSize(30),
            borderRadius: scaleSize(5),
            borderWidth: scaleSize(1),
        },
        unfollowButton: {
            alignItems: 'center',
            justifyContent: 'center',
            width: scaleSize(22),
            height: scaleSize(22),
            borderRadius: scaleSize(11),
            borderWidth: scaleSize(1),
            borderColor: theme.background_message,
        },
        followButton: {
            alignItems: 'center',
            justifyContent: 'center',
            width: scaleSize(22),
            height: scaleSize(22),
            borderRadius: scaleSize(11),
            backgroundColor: theme.background_message,
        },
        followContainer: { justifyContent: 'center', alignItems: 'flex-start', width: scaleSize(20) },
        returnContainer: { justifyContent: 'center', alignItems: 'flex-start', width: scaleSize(85) },
        riskContainer: { justifyContent: 'center', alignItems: 'flex-start', width: scaleSize(70) },
        nameContainer: { justifyContent: 'center', alignItems: 'flex-start', width: scaleSize(110) },
        avatarContainer: { justifyContent: 'center', alignItems: 'flex-start', paddingRight: scaleSize(10) },
        positionGroupContainer: {
            flexDirection: 'row',
            borderWidth: 1,
            width: '100%',
            height: scaleSize(50),
            backgroundColor: theme.backgroundSecondary,
            borderRadius: scaleSize(5),
            marginTop: scaleSize(7),
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },
        avatarStyle: {
            width: scaleSize(48),
            height: scaleSize(48),
            borderTopLeftRadius: scaleSize(4.8),
            borderBottomLeftRadius: scaleSize(4.8),
        },
    });
