import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import News from '../features/News';

export default function NewsScreen() {
    return (
        <ScreenWrapper>
            <News />
        </ScreenWrapper>
    );
}
