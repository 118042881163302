import React from 'react';
import Investors from '../features/CopyTrading/Investors';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

type Props = {
    setSelectedId?: Function;
    setActiveWatchlist?: Function;
};

export default function InvestorsScreen({ setSelectedId, setActiveWatchlist }: Props) {
    return (
        <ScreenWrapper>
            <Investors setSelectedId={setSelectedId} setActiveWatchlist={setActiveWatchlist} />
        </ScreenWrapper>
    );
}
