import React from 'react';
import ForgotPasswordForm from '../features/Authentication/ForgotPasswordForm';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function ForgotPasswordScreen() {
    return (
        <ScreenWrapper>
            <ForgotPasswordForm />
        </ScreenWrapper>
    );
}
