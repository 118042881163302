import axios from '../baseClient';
import { CustomerDataResponse, FinancialInformationRequest, InvestmentKnowledgeRequest, OtherQuestionsRequest, PersonalInformationRequest,  TradingExperiance1Request,  TradingExperiance2Request,  TradingExperiance3Request,  TradingExperianceRequest,  VerificationCenterResponse} from './types';

class VerificationService {
    private static domain = '/api/registration/customer/profile';

    getCustomerData() {
        return axios.post<CustomerDataResponse>(`${VerificationService.domain}/getCustomerData`, {});
    }

    setPersonalInformation(personalInformationRequest: PersonalInformationRequest) {
        return axios.post<VerificationCenterResponse>(`${VerificationService.domain}/setPersonalInformation`, personalInformationRequest);
    }

    setFinancialInformation(financialInformationRequest: FinancialInformationRequest) {
        return axios.post<VerificationCenterResponse>(`${VerificationService.domain}/setFinancialInformation`, financialInformationRequest);
    }

    setTradingExperiance(tradingExperianceRequest: TradingExperianceRequest) {
        return axios.post<VerificationCenterResponse>(`${VerificationService.domain}/setTradingExperiance`, tradingExperianceRequest);
    }

    setTradingExperiance1(tradingExperiance1Request: TradingExperiance1Request) {
        return axios.post<VerificationCenterResponse>(`${VerificationService.domain}/setTradingExperiance1`, tradingExperiance1Request);
    }

    setTradingExperiance2(tradingExperiance2Request: TradingExperiance2Request) {
        return axios.post<VerificationCenterResponse>(`${VerificationService.domain}/setTradingExperiance2`, tradingExperiance2Request);
    }

    setTradingExperiance3(tradingExperiance3Request: TradingExperiance3Request) {
        return axios.post<VerificationCenterResponse>(`${VerificationService.domain}/setTradingExperiance3`, tradingExperiance3Request);
    }

    setInvestmentKnowledge(investmentKnowledgeRequest: InvestmentKnowledgeRequest) {
        return axios.post<VerificationCenterResponse>(`${VerificationService.domain}/setInvestmentKnowledgeNew`, investmentKnowledgeRequest);
    }

    setOtherQuestions(otherQuestionsRequest: OtherQuestionsRequest) {
        return axios.post<VerificationCenterResponse>(`${VerificationService.domain}/setOtherQuestionsNew`, otherQuestionsRequest);
    }
    
}

export default new VerificationService();
