import React from 'react';
import Result from '../features/Result';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function ResultScreen() {
    return (
        <ScreenWrapper>
            <Result />
        </ScreenWrapper>
    );
}
