import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { type Themes } from '../../theme';
import config from '~/config';

export interface ThemeState {
    value: Themes;
}

console.log('config.THEME: ' + config.THEME);

const initialState: ThemeState = {
    value: config.THEME == "standardFXMERIDIAN" ? "standardFXMERIDIAN" : "standard"
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        changeTheme: (state, action: PayloadAction<Themes>) => {
            state.value = action.payload;
        },
    },
});

export const { changeTheme } = themeSlice.actions;

export default themeSlice.reducer;
