import React from 'react';
import { View, StyleSheet } from 'react-native';
import { InvestorOpenedPosition } from '~/api-services/CoreDataService/types';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import Position from './Position';

type Props = {
    positions: InvestorOpenedPosition[];
};

export default function InvestorOpenTrades({ positions }: Props) {
    const styles = useStyles(stylesGenerator);
    return (
        <ScrollbarWrapper>
            <View style={styles.openTradesContainer}>
                {positions.map((item, key) => (
                    <View style={styles.button} key={key}>
                        <Position position={item} />
                    </View>
                ))}
            </View>
        </ScrollbarWrapper>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        buttonContainer: {
            width: '100%',
            height: '50%',
            paddingBottom: scaleSize(17),
            justifyContent: 'flex-end',
        },
        button: { width: '100%' },
        openTradesContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
        },
    });
