import React, {useState, useEffect} from 'react';
import { ActivityIndicator, Platform, StyleSheet, Text } from 'react-native';
import { useStyles } from '~/hooks';
import ScreenWrapper from '../features/Theme/ScreenWrapper';
import * as ScreenOrientation from "expo-screen-orientation";


export default function LoadingScreen() {
    const styles = useStyles(stylesGenerator);

    const [orientation, setOrientation] = useState(3);
    useEffect(() => {
        lockOrientation();
    }, [orientation]);

    const lockOrientation = async () => {
        if(Platform.OS !== 'web') {
            await ScreenOrientation.lockAsync(
                ScreenOrientation.OrientationLock.PORTRAIT_UP
            );
            const o = await ScreenOrientation.getOrientationAsync();
            setOrientation(o);
        }
      };

    return (
        <ScreenWrapper style={styles.container}>
            <ActivityIndicator size="large" />
            <Text>Loading ...</Text>
        </ScreenWrapper>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        container: {
            alignItems: 'center',
            justifyContent: 'center',
        },
    });
