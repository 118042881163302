import React, { useEffect } from 'react';
import { View, Modal, StyleSheet, Pressable, useWindowDimensions } from 'react-native';
import { Text } from '~/components/core';
import ScreenWrapper from '../Theme/ScreenWrapper';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { isDesktop, scaleSize } from '~/constants/scale';
import { global } from '~/constants/global';
import { FONT_SIZE_LS, FONT_SIZE_S } from '~/constants';
import { Octicons, Ionicons } from '@expo/vector-icons';

import { linkTo } from '~/navigation';
import paths from '~/navigation/paths';

export type VerificationCenterMessageProps = {
    visible: boolean;
    setVisible: Function;
};

const VerificationCenterMessage = ({ visible, setVisible }: VerificationCenterMessageProps) => {
    const styles = useStyles(stylesGenerator);
    const dimensions = useWindowDimensions();


    return (
        <Modal
            statusBarTranslucent={true}
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={() => {
                setVisible(false);
            }}
        >
            <Pressable onPress={() => setVisible(false)} style={styles.fullScreen}>
                <View
                    style={[
                        styles.container,
                        {
                            top:
                                dimensions.height -
                                scaleSize(global.CONFIRMATION_MESSAGE_HEIGHT + 20) -
                                scaleSize(isDesktop() ? global.HOME_TABS_HEIGHT_DESKTOP : global.HOME_TABS_HEIGHT),
                            height: scaleSize(global.CONFIRMATION_MESSAGE_HEIGHT + 20),
                        },
                    ]}
                >
                    <ScreenWrapper style={styles.screen}>
                        <View style={styles.content}>
                            <View style={styles.closeButton}>
                                <Ionicons name="close" size={scaleSize(14)} color={'white'} />
                            </View>
                            <View style={styles.messageContainer}>
                                <View style={[styles.checkContainer, styles.checkContainerSuccess]}>
                                    <Pressable
                                        style={styles.linkContainer}
                                        onPress={() => {
                                            setVisible(false);
                                            linkTo(paths.verificationCenter);
                                        }
                                        }
                                    >
                                        <View style={styles.iconCircle}>
                                            <Octicons name="check" size={scaleSize(12)} style={styles.iconColor} />
                                        </View>
                                        <Text fontType="LATO_REGULAR" style={styles.linkText}>
                                            verification-center
                                        </Text>
                                    </Pressable>

                                </View>
                                <Text style={styles.messageText} fontType="LATO_BOLD">
                                    complete-questionary
                                </Text>
                            </View>
                        </View>
                    </ScreenWrapper>
                </View>
            </Pressable>
        </Modal>
    );
};

export default VerificationCenterMessage;

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        iconCircle: {
            marginRight: scaleSize(10),
            height: scaleSize(22),
            width: scaleSize(22),
            borderRadius: scaleSize(11),
            borderWidth: 1,
            borderColor: 'gray',
            justifyContent: 'center',
            alignItems: 'center',
        },
        linkText: { fontSize: FONT_SIZE_S, color: theme.tabIconSelected },
        iconColor: { color: theme.tabIconSelected },
        linkContainer: {
            padding: scaleSize(10),
            flexDirection: 'row',
            alignItems: 'center',
            width: 'auto'
        },
        messageIconColorSuccess: { color: theme.tabIconSelected },
        messageIconColor: { color: theme.background_message },
        fullScreen: { width: '100%', height: '100%' },
        closeButton: {
            alignItems: 'flex-end',
            marginTop: scaleSize(5),
            marginRight: scaleSize(5),
        },
        checkContainer: {
            height: scaleSize(40),
            borderRadius: scaleSize(20),
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: scaleSize(12),
        },
        checkContainerSuccess: { backgroundColor: theme.white },
        checkContainerError: { backgroundColor: 'red' },
        messageContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingHorizontal: scaleSize(7),
        },
        messageText: {
            fontSize: FONT_SIZE_LS,
            color: theme.white,
            textAlign: 'center',
        },
        screen: {
            width: '100%',
        },
        container: {
            borderTopLeftRadius: scaleSize(5),
            borderTopEndRadius: scaleSize(5),
            left: 0,
            position: 'absolute',
            width: '100%',
        },
        content: {
            width: '100%',
            flex: 1,
            borderTopLeftRadius: scaleSize(5),
            borderTopEndRadius: scaleSize(5),
            backgroundColor: theme.background_message,
        },
    });
