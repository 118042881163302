import React, { useEffect } from 'react';
import { View, Modal, StyleSheet, Pressable, useWindowDimensions } from 'react-native';
import { Button, Text } from '~/components/core';
import ScreenWrapper from '../Theme/ScreenWrapper';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { isDesktop, scaleSize } from '~/constants/scale';
import { global } from '~/constants/global';
import { FONT_SIZE_LS, FONT_SIZE_S } from '~/constants';
import { Entypo, FontAwesome5, Ionicons } from '@expo/vector-icons';

export type Props = {
    visible: boolean;
    text: string;
    setVisible: Function;
    yesFunction: Function;
};

const YesNoMessage = ({ visible, text, setVisible, yesFunction }: Props) => {
    const styles = useStyles(stylesGenerator);
    const dimensions = useWindowDimensions();

    
    return (
        <Modal
            statusBarTranslucent={true}
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={() => {
                setVisible(false);
            }}
        >
            <View style={styles.fullScreen}>
                <View
                    style={[
                        styles.container,
                        {
                            top:
                                dimensions.height -
                                scaleSize(190 + (!isDesktop() && text.length > 140 ? 20 : 0)) -
                                scaleSize(isDesktop() ? global.HOME_TABS_HEIGHT_DESKTOP : global.HOME_TABS_HEIGHT),
                            height: scaleSize(190+ (!isDesktop() && text.length > 140 ? 20 : 0)),
                        },
                    ]}
                >
                    <ScreenWrapper style={styles.screen}>
                        <View style={styles.content}>
                            <Pressable style={styles.closeButton} onPress={() => setVisible(false)}>
                                <Ionicons name="close" size={scaleSize(14)} color={'white'} />
                            </Pressable>
                            <View style={styles.messageContainer}>
                                <View style={[styles.checkContainer, styles.checkContainerSuccess]}>
                                    <FontAwesome5
                                        name="exclamation"
                                        style={styles.messageIconColor}
                                        size={scaleSize(15)}
                                    />
                                </View>
                                <Text style={styles.confirmationText} fontType="LATO_BOLD">
                                    confirmation-copy-trading
                                </Text>
                                <Text style={styles.messageText} fontType="LATO_BOLD">
                                    {text}
                                </Text>
                            </View>
                            <View style={styles.yesNoContainer}>
                                <View style={styles.buttonsContainer}>
                                    <Pressable onPress={() => setVisible(false)} style={[styles.buttonContainer, styles.noButton]}>
                                        <Text style={styles.noText} fontType="LATO_BOLD">position-close</Text>
                                    </Pressable>
                                </View>
                                <View style={styles.buttonsContainer}>
                                    <Pressable onPress={() => yesFunction()} style={[styles.buttonContainer, styles.yesButton]}>
                                        <Text style={styles.yesText} fontType="LATO_BOLD">confirm</Text>
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    </ScreenWrapper>
                </View>
            </View>
        </Modal>
    );
};

export default YesNoMessage;

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        buttonContainer: {width: scaleSize(100), height: scaleSize(25), borderRadius: scaleSize(5), justifyContent: 'center', alignItems: 'center'},
        noButton: {borderWidth: 1, borderColor: theme.white},
        yesButton: {backgroundColor: theme.white},
        noText: {fontSize: FONT_SIZE_S, color: theme.white},
        yesText: {fontSize: FONT_SIZE_S, color: theme.background_message},
        messageIconColorSuccess: { color: theme.tabIconSelected },
        messageIconColor: { color: theme.background_message },
        fullScreen: { width: '100%', height: '100%' },
        buttonsContainer: {paddingHorizontal: scaleSize(30)},
        closeButton: {
            alignItems: 'flex-end',
            marginTop: scaleSize(5),
            marginRight: scaleSize(5),
        },
        checkContainer: {
            height: scaleSize(40),
            width: scaleSize(40),
            borderRadius: scaleSize(20),
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: scaleSize(12),
        },
        checkContainerSuccess: { backgroundColor: theme.white },
        checkContainerError: { backgroundColor: 'red' },
        yesNoContainer: {flexDirection: 'row', justifyContent: 'center', paddingBottom: scaleSize(20),},
        messageContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingHorizontal: scaleSize(7),
        },
        confirmationText: {
            fontSize: FONT_SIZE_LS,
            color: theme.white,
            paddingBottom: scaleSize(16),
        },
        messageText: {
            fontSize: FONT_SIZE_LS,
            color: theme.white,
            textAlign: 'center',
            paddingBottom: scaleSize(20),
        },
        screen: {
            width: '100%',
        },
        container: {
            borderTopLeftRadius: scaleSize(5),
            borderTopEndRadius: scaleSize(5),
            left: 0,
            position: 'absolute',
            width: '100%',
        },
        content: {
            width: '100%',
            flex: 1,
            borderTopLeftRadius: scaleSize(5),
            borderTopEndRadius: scaleSize(5),
            backgroundColor: theme.background_message,
        },
    });
