import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { InvestorInfo } from '~/api-services/CopyTradingService/types';
import { Text } from '~/components/core';
import InvestorHeader from '~/components/navigators/InvestorHeader';
import { FONT_SIZE_L } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import { traderIdSelector } from '~/state/selectors';
import { Theme } from '~/theme';

type Props = {
    investorInfo: InvestorInfo;
    addFollower: Function;
    unfollowInvestor: Function;
    setActiveWatchlist: Function;
    selectedMenu: string;
    setSelectedMenu: Function;
    setConfirmation: Function;
};

export default function InvestorMenu({
    investorInfo,
    addFollower,
    unfollowInvestor,
    setActiveWatchlist,
    selectedMenu,
    setSelectedMenu,
    setConfirmation,
}: Props) {
    const styles = useStyles(stylesGenerator);
    const traderId = useAppSelector(traderIdSelector);

    return (
        <>
            <InvestorHeader
                setConfirmation={setConfirmation}
                investorInfo={investorInfo}
                addFollower={addFollower}
                unfollowInvestor={unfollowInvestor}
                setActiveWatchlist={setActiveWatchlist}
            />
            <View style={styles.container}>
                <Pressable
                    onPress={() => {
                        setSelectedMenu('portfolio');
                    }}
                >
                    <Text
                        fontType="LATO_BOLD"
                        style={[
                            styles.menuText,
                            selectedMenu === 'portfolio' ? styles.activeMenu : styles.unactiveMenu,
                        ]}
                    >
                        portfolio
                    </Text>
                </Pressable>
                {traderId < 331359 ?
                    <Pressable
                        onPress={() => {
                            setSelectedMenu('open-trades');
                        }}
                    >
                        <Text
                            fontType="LATO_BOLD"
                            style={[
                                styles.menuText,
                                selectedMenu === 'open-trades' ? styles.activeMenu : styles.unactiveMenu,
                            ]}
                        >
                            open-trades
                        </Text>
                    </Pressable> : null}
                <Pressable
                    onPress={() => {
                        setSelectedMenu('trading-history');
                    }}
                >
                    <Text
                        fontType="LATO_BOLD"
                        style={[
                            styles.menuText,
                            selectedMenu === 'trading-history' ? styles.activeMenu : styles.unactiveMenu,
                        ]}
                    >
                        trading-history
                    </Text>
                </Pressable>
            </View>
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        menuText: { fontSize: FONT_SIZE_L },
        activeMenu: { color: theme.tabIconSelected },
        unactiveMenu: { color: theme.tabIconDefault },
        container: {
            height: scaleSize(50),
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-around',
            backgroundColor: theme.backgroundSecondary,
        },
    });
