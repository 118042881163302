import React from 'react';
import { StyleSheet, } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SafeAreaView } from 'react-native-safe-area-context';
import MarketTopTabsNavigator from './MarketTopTabsNavigator';
import MarketHeader from '../features/Market/MarketHeader';
import EditFavoritesScreen from '../screens/EditFavoritesScreen';
import CustomHeader from './CustomHeader';
import { useStyles } from '~/hooks';
import BuySellPositionScreen from '../screens/BuySellPositionScreen';
import { useRoute } from '@react-navigation/native';
import MarketOrderScreen from '../screens/MarketOrderScreen';
import InvestmentsScreen from '../screens/InvestmentsScreen';

const Stack = createNativeStackNavigator();

export default function MarketStackNavigator() {
    const styles = useStyles(stylesGenerator);
    const route: any = useRoute();
    const side: 'buy' | 'sell' =
        route.params && route.params.params && (route.params.params as any).side
            ? (route.params.params as any).side
            : '';

    return (
            <>
            <SafeAreaView style={styles.container}>
                <Stack.Navigator initialRouteName="MarketTopTabs">
                    <Stack.Screen
                        name="MarketTopTabs"
                        component={MarketTopTabsNavigator}
                        options={{
                            headerShown: true,
                            header: MarketHeader,
                            orientation: 'portrait',
                        }} />
                    <Stack.Screen
                        name="EditFavoritesList"
                        key={'EditFavoritesList'}
                        options={{
                            header: () => <CustomHeader title={'edit-list'} />,
                        }}
                        component={EditFavoritesScreen} />
                    <Stack.Screen
                        name="BuySellPosition"
                        key={'BuySellPosition'}
                        component={BuySellPositionScreen}
                        options={{
                            orientation: 'portrait',
                            header: () => <CustomHeader title={'buy-sell-position'} />,
                        }} />
                    <Stack.Screen
                        name="MarketOrder"
                        key={'MarketOrder'}
                        component={MarketOrderScreen}
                        options={{
                            header: () => <CustomHeader title={side === 'buy' ? 'buy-section' : 'sell-section'} />,
                        }} />
                    <Stack.Screen
                        name="Investments"
                        key={'Investments'}
                        component={InvestmentsScreen}
                        options={{
                            headerShown: false,
                        }} />
                </Stack.Navigator>
            </SafeAreaView>
        </>
            
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
    });
