import { useEffect, useState, useRef } from 'react';
import ReportsService from '~/api-services/ReportsService';
import { Order, ReportOrdersRequest } from '~/api-services/ReportsService/types';
import { appSettingsSelector, userIsLoggedInSelector } from '~/state/selectors';
import { useAppSelector } from './useAppSelector';
import { useIsAppActive } from './useIsAppActive';

export const useAllOrders = (from: Date, to: Date) => {
    const [orders, setOrders] = useState<Order[]>([]);
    const reloadDataPollRef = useRef<NodeJS.Timer>();
    const isAppActive = useIsAppActive();
    const isLoggedIn = useAppSelector(userIsLoggedInSelector);
    const [loading, setloading] = useState(true);
    const { language } = useAppSelector(appSettingsSelector);


    useEffect(() => {
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        from.setMilliseconds(0);
        to.setHours(0);
        to.setMinutes(0);
        to.setSeconds(0);
        to.setMilliseconds(0);
        let request: ReportOrdersRequest = {
            from: from.getTime(),
            language: language.toUpperCase(),
            page: 1,
            pageSize: 10000,
            sort: 'timestamp',
            sortDirection: 'DESC',
            to: to.getTime(),
        };
        if (isAppActive && isLoggedIn) {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
            reloadDataPollRef.current = setInterval(() => {
                loadData(request);
            }, 60000); // refetch 60 seconds
            loadData(request);
        } else {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        }
    }, [isLoggedIn, isAppActive, from, to]);

    async function loadData(request: ReportOrdersRequest) {
        setloading(true);
        const { status, data } = await ReportsService.getReportOrders(request);
        if (status === 200 && data.status) {
            setOrders(data.returnData.data);
        }
        setloading(false);
    }

    return {
        orders,
        loading
    };
};
