import en from './en.json';
import de from './de.json';
import fr from './fr.json';
import ru from './ru.json';
import es from './es.json';


export default {
    en,
    de,
    fr,
    ru,
    es
};
