import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Order, Result } from '~/api-services/ReportsService/types';
import { useAppSelector, useStyles } from '~/hooks';
import { appSettingsSelector } from '~/state/selectors';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { FONT_SIZE_XXS } from '~/constants';
import { EditPositionDetail } from '../EditOpenPosition';
import PositionDetail from '../EditOpenPosition/PositionDetail';

type Props = {
    result: Result;
};

export default function ResultDetails({ result }: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);

    const resultDetails: EditPositionDetail[] = [
        // Left Side
        {
            label: 'investor-name',
            value: result.investorName,
            valueStyle: styles.textSpacing,
        },
    ];

    return (
        <View
            key={result.id}
            style={[
                styles.orderDetailsContainer,
                colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
            ]}
        >
            <View style={styles.positionDetailsContainer}>
                <View style={styles.positionDetailsColumn}>
                    {resultDetails.slice(0, 1).map((positionDetail: EditPositionDetail, index) => (
                        <PositionDetail
                            editPositionDetail={positionDetail}
                            rowStyle={[styles.positionDetailsRow, styles.positionDetailsRowLeft]}
                            key={index}
                        />
                    ))}
                </View>
                <View style={styles.positionDetailsColumn}>
                    
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        numberSpacing: { letterSpacing: 0.5 },
        textSpacing: { letterSpacing: 0 },
        positionDetailsColumn: { width: '50%', maxWidth: '50%' },
        positionDetailsContainer: {
            width: '100%',
            flexDirection: 'row',
            marginVertical: scaleSize(6),
        },
        positionDetailsRow: {
            flexDirection: 'row',
            width: '100%',
            paddingVertical: scaleSize(4),
            justifyContent: 'space-between',
        },
        positionDetailsRowLeft: {
            paddingLeft: scaleSize(10),
            paddingRight: scaleSize(0),
        },
        positionDetailsRowRight: {
            paddingRight: scaleSize(10),
            paddingLeft: scaleSize(5),
        },
        left: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
        },
        right: {
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'row',
        },
        detailsText: { fontSize: FONT_SIZE_XXS, color: theme.text_pendingOrder },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },
        orderDetailsContainer: {
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            backgroundColor: theme.backgroundSecondary,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderLeftWidth: 1,
            borderBottomLeftRadius: scaleSize(5),
            borderBottomRightRadius: scaleSize(5),
        },
    });
