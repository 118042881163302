import { useState, useCallback, useEffect } from 'react';
import { FlatList } from 'react-native';
import { useRoute } from '@react-navigation/native';

import { appSettingsSelector, userAuthDataSelector, watchlistInstrumentsSelectorV2 } from '~/state/selectors';
import { extractWatchlistItemId } from '~/navigation/utilts';
import { useAppSelector } from './useAppSelector';
import { useIsScreenActive } from './useIsScreenActive';
import StreamingService from '~/api-services/StreamingService';

const LIST_LENGTH_INTERVAL = 10;

type Props = {
    watchListId?: number;
};

export const useWatchlistScreenData = ({ watchListId }: Props) => {
    const isScreenActive = useIsScreenActive();
    const route = useRoute();
    const id = watchListId ? watchListId : extractWatchlistItemId(route.name);
    const instrumentCodes = useAppSelector((state) => watchlistInstrumentsSelectorV2(state, id));
    const { watchlistColumns } = useAppSelector(appSettingsSelector);
    const [codes, setCodes] = useState<string[]>(instrumentCodes.slice(0, LIST_LENGTH_INTERVAL));
    const [visibleCodes, setVisibleCodes] = useState<string[]>([]);
    const { sessionId } = useAppSelector(userAuthDataSelector);
    const [lastSessionId, setLastSessionId] = useState('');
    const onEndReached = useCallback(() => {
        if (codes.length === instrumentCodes.length) {
            return;
        }
        setCodes(instrumentCodes.slice(0, codes.length + LIST_LENGTH_INTERVAL));
    }, [codes.length, instrumentCodes]);
    // Sub/unsub based on visible items of the flat list
    const onViewableItemsChanged = useCallback<
        NonNullable<(typeof FlatList)['prototype']['props']['onViewableItemsChanged']>
    >(
        ({ viewableItems, changed }) => {
            setVisibleCodes(viewableItems.map(({ item }) => item));
            const [shownItems, hiddenItems] = changed.reduce(
                (memo, { item, isViewable }) => {
                    memo[isViewable ? 0 : 1].push(item);
                    return memo;
                },
                [[], []] as string[][]
            );
            StreamingService.subscribeQuotes(shownItems);
            StreamingService.unsubscribeQuotes(hiddenItems);
        },
        [setVisibleCodes]
    );
    // Stops/resumes the sockets listeners for the watchlist codes based on screen activity
    // Changing the screen or minimizing the app stops the listeners
    useEffect(() => {
        if (isScreenActive) {
            StreamingService.subscribeQuotes(visibleCodes);
        } else {
            StreamingService.unsubscribeQuotes(visibleCodes);
        }
        setCodes(instrumentCodes.slice(0, codes.length + LIST_LENGTH_INTERVAL));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isScreenActive, instrumentCodes]);

    useEffect(() => {
        if (isScreenActive) {
            if (lastSessionId !== '' && lastSessionId !== sessionId) {
                StreamingService.unsubscribeQuotes(visibleCodes);
                StreamingService.closeConnection();
                StreamingService.initConnection(sessionId);
                StreamingService.subscribeQuotes(visibleCodes);
            }
            setLastSessionId(sessionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, isScreenActive, lastSessionId]);

    return { codes, numColumns: watchlistColumns, onEndReached, onViewableItemsChanged };
};
