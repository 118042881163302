import React, { useState } from 'react';
import { StyleSheet, View, Image, Platform, KeyboardAvoidingView } from 'react-native';
import { Button, TextInput, ErrorMessage, Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { FONT_SIZE_S, FONT_SIZE_XS, spacing } from '~/constants';
import { scaleSize, scaleSizeMobile } from '~/constants/scale';
import { Theme } from '~/theme';
import config from '~/config';
import { appSettingsSelector } from '~/state/selectors';
import { SvgUri } from 'react-native-svg';
import RequestResetPasswordService from '~/api-services/RequestResetPasswordService';
import t from '~/i18n';
import ConfirmationMessage from '../../ConfirmationMessage';
import paths from '~/navigation/paths';
import { linkTo } from '~/navigation';
import { useRoute } from '@react-navigation/native';
import { validatePasswordStrength } from '~/lib/utils';
import { useBrowserLanguage } from '~/hooks/useBrowserLanguage';

export default function ResetPasswordForm() {
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const maxWidth: number = 600;
    const styles = useStyles(stylesGenerator, { maxWidth });
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const [passwordConfirmVisibility, setPasswordConfirmVisibility] = useState(true);

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const route: any = useRoute();
    const token: string = (route.params as any).token;
    const [showPasswordValidationMessage, setShowPasswordValidationMessage] = useState(false);
    useBrowserLanguage();

    function validateForm(): boolean {
        if (passwordConfirm !== password) {
            setConfirmationProps({ text: 'passwords-not-match', error: true });
            setConfirmationVisible(true);
            return false;
        }
        return true;
    }

    async function resetPassword() {
        if (!validatePasswordStrength(password)) {
            setShowPasswordValidationMessage(true);
            return;
        }
        if (validateForm()) {
            const { status, data } = await RequestResetPasswordService.changePassword({
                website: config.WEBSITE,
                id: token,
                password: password,
                source: Platform.OS === 'web' ? 'WEB' : 'MOBILE',
                sourceType: Platform.OS.toUpperCase(),
                apiVersion: Number(config.API_VERSION),
                sourceVersion: config.SOURCE_VERSION,
            });
            if (status === 200 && data.status) {
                setConfirmationProps({ text: t.t('change-pass-success'), error: false });
                setConfirmationVisible(true);
            } else {
                setConfirmationProps({ text: 'change-pass-error', error: true });
                setConfirmationVisible(true);
            }
        }
    }

    return (
        <KeyboardAvoidingView style={styles.container} behavior={'height'}>
            <View style={styles.logoContainer}>
                {Platform.OS === 'web' ? (
                    <Image
                        source={{uri: 'https://' +
                        config.EXTERNAL_SOURCES_URL +
                        'mobileplatform/images/brand/' +
                        config.BRAND_NAME +
                        '/home_logo_' +
                        colorScheme +
                        '.svg?1'}}
                        style={styles.logoStyle}
                        resizeMode="contain"
                    />
                ) : (
                    <SvgUri
                        width={'60%'}
                        style={styles.logoStyle}
                        uri={
                            'https://' +
                            config.EXTERNAL_SOURCES_URL +
                            'mobileplatform/images/brand/' +
                            config.BRAND_NAME +
                            '/home_logo_' +
                            colorScheme +
                            '.svg?1'
                        }
                    />
                )}
            </View>
            <View style={styles.infoTextContainer}>
                <Text fontType="LATO_REGULAR" style={styles.infoText}>
                    fill-new-password
                </Text>
            </View>
            <TextInput
                containerStyle={styles.inputContainer}
                leftIcon="lock"
                placeholder="change-password-new"
                autoCapitalize="none"
                autoCorrect={false}
                secureTextEntry={passwordVisibility}
                textContentType="password"
                rightIcon={passwordVisibility ? 'eye' : 'eye-off'}
                value={password}
                onChangeText={(text) => setPassword(text)}
                onRightIconPress={() => setPasswordVisibility(!passwordVisibility)}
            />
            <ErrorMessage
                text={'signup-password-strength-info'}
                visible={password !== '' && !validatePasswordStrength(password) && showPasswordValidationMessage}
            />
            <TextInput
                containerStyle={styles.inputContainer}
                leftIcon="lock"
                placeholder="registration-input-confirm-password"
                autoCapitalize="none"
                autoCorrect={false}
                secureTextEntry={passwordConfirmVisibility}
                textContentType="password"
                rightIcon={passwordConfirmVisibility ? 'eye' : 'eye-off'}
                value={passwordConfirm}
                onChangeText={(text) => setPasswordConfirm(text)}
                onRightIconPress={() => setPasswordConfirmVisibility(!passwordConfirmVisibility)}
            />
            <Button
                disabled={!password || !passwordConfirm}
                onPress={() => resetPassword()}
                title="save-changes"
                containerStyle={styles.loginButton}
                maxWidth={maxWidth}
            />
            <View style={styles.footerContainer}>
                <Text
                    fontType="LATO_BOLD"
                    style={styles.footerText}
                    onPress={() => linkTo(paths.login)}
                    maxWidth={maxWidth}
                >
                    back-to-login-page
                </Text>
            </View>
            <ConfirmationMessage
                text={confirmationProps.text}
                error={confirmationProps.error}
                visible={confirmationVisible}
                setVisible={setConfirmationVisible}
                timeout={60000}
            />
        </KeyboardAvoidingView>
    );
}

const stylesGenerator = (theme: Theme, custom?: { maxWidth: number }) =>
    StyleSheet.create({
        infoTextContainer: { paddingBottom: scaleSize(10) },
        infoText: { fontSize: FONT_SIZE_XS, color: theme.text_symbol },
        footerText: { color: theme.switch_trackOn, fontSize: FONT_SIZE_S },
        footerSeparator: {
            width: 1,
            height: scaleSize(48, custom?.maxWidth),
            backgroundColor: theme.background_buttonInactive,
        },
        footerContainer: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: scaleSize(30),
        },
        logoStyle: { width: config.HOME_LOGO_SIZE_IN_PERCENT, height: '100%', maxWidth: scaleSizeMobile(custom?.maxWidth), maxHeight: scaleSizeMobile(custom?.maxWidth) },        logoContainer: { height: '50%', justifyContent: 'center', alignItems: 'center' },
        container: {
            flex: 1,
            justifyContent: 'space-between',
            alignSelf: 'center',
            maxWidth: custom?.maxWidth,
            paddingHorizontal: scaleSize(12, custom?.maxWidth),
            width: '100%',
            height: '100%',
            maxHeight: '100%',
        },
        inputContainer: {
            marginBottom: scaleSize(spacing.LARGE, custom?.maxWidth),
        },
        loginButton: {
            marginBottom: scaleSize(100, custom?.maxWidth),
        },
    });
