import React from 'react';
import OpenTrades from '../features/OpenTrades';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function OpenTradesScreen() {
    return (
        <ScreenWrapper>
            <OpenTrades />
        </ScreenWrapper>
    );
}
