import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { WatchlistItem, InitialData } from '~/api-services/LoadService/types';
import { thunkGenericApiReducerBuilder } from './utils';
import { loadUserData } from './userDataSlice';
import { ThunkAPI } from '../store';
import WatchlistService from '~/api-services/WatchlistService';

export interface WatchlistState extends ResponseMetadata {
    data: WatchlistItem[];
}

const initialState: WatchlistState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    data: [],
};

export type saveWatchListRequest = {
    watchlist: WatchlistItem;
    instrumentCodes: string[];
};

export const saveWatchlist = createAsyncThunk<WatchlistItem, WatchlistItem, ThunkAPI>(
    'watchlist/saveWatchlist',
    async (params, thunkAPI) => {
        try {
            const { status, data } = await WatchlistService.saveWatchlist(params);
            if (status !== 200 || !data) {
                throw new Error('Error');
            }
            return params;
        } catch (e) {
            return thunkAPI.rejectWithValue('Unauthorized');
        }
    }
);

export const watchlistSlice = createSlice({
    name: 'watchlist',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        thunkGenericApiReducerBuilder(builder, loadUserData, (state: WatchlistState, { watchList }: InitialData) => {
            state.data = [...watchList];
        });
        thunkGenericApiReducerBuilder(builder, saveWatchlist, (state: WatchlistState, request: WatchlistItem) => {
            for (let i = 0; i < state.data.length; i++) {
                if (state.data[i].id === request.id) {
                    state.data[i] = request;
                    break;
                }
            }
        });
    },
});

export const { clearState } = watchlistSlice.actions;

export default watchlistSlice.reducer;
