import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import MarketHeader from '../features/Market/MarketHeader';
import NewsTopTabsNavigator from './NewsTopTabsNavigator';
import { useStyles } from '~/hooks';

const Stack = createNativeStackNavigator();

export default function NewsStackNavigator() {
    const styles = useStyles(stylesGenerator);

    return (
        <SafeAreaView style={styles.container}>
            <Stack.Navigator initialRouteName="NewsTopTabs">
                <Stack.Screen
                    name="NewsTopTabs"
                    component={NewsTopTabsNavigator}
                    options={{
                        headerShown: true,
                        header: MarketHeader,
                    }}
                />
            </Stack.Navigator>
        </SafeAreaView>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
    });
