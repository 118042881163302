import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import NotificationSettings from '../features/NotificationSettings';

export default function NotificationsSettingsScreen() {
    return (
        <ScreenWrapper>
            <NotificationSettings />
        </ScreenWrapper>
    );
}
