import axios from '../baseClient';
import { RequestChangePasswordRequest, RequestChangePasswordResponse, RequestResetPasswordRequest, RequestResetPasswordResponse } from './types';

class RequestResetPasswordService {
    private static domain = '/api/24yieldservice';

    requestResetPassword(requestResetPasswordRequest: RequestResetPasswordRequest) {
        return axios.post<RequestResetPasswordResponse>(`${RequestResetPasswordService.domain}/requestResetPassword`, requestResetPasswordRequest);
    }
    changePassword(requestChangePasswordRequest: RequestChangePasswordRequest) {
        return axios.post<RequestChangePasswordResponse>(`${RequestResetPasswordService.domain}/changePassword`, requestChangePasswordRequest);
    }
    
}

export default new RequestResetPasswordService();
