import type { Theme } from '@react-navigation/native';

import { appSettingsSelector } from '~/state/selectors';
import { useAppSelector } from './useAppSelector';
import { useTheme } from './useTheme';

export const useNavigationTheme = () => {
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const { primary, background, card, text, border, notification } = useTheme();

    const navTheme: Theme = {
        dark: colorScheme === 'dark',
        colors: { primary, background, card, text, border, notification },
    };

    return navTheme;
};
