import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import i18n from '~/i18n';
import AddFundsScreen from '../screens/AddFundsScreen';
import ChangePasswordScreen from '../screens/ChangePasswordScreen';
import MoreScreen from '../screens/MoreScreen';
import SettingsScreen from '../screens/SettingsScreen';
import VerificationCenterScreen from '../screens/VerificationCenterScreen';
import WithdrawFundsScreen from '../screens/WithdrawFundsScreen';
import CustomHeader from './CustomHeader';
import NotificationsSettingsScreen from '../screens/NotificationsSettingsScreen';
import { useStyles } from '~/hooks';
import AddFundsCategoryScreen from '../screens/AddFundsCategoryScreen';
import AddFundsAlternativeScreen from '../screens/AddFundsAlternativeScreen';
import AddFundsBankScreen from '../screens/AddFundsBankScreen';
import AddFundsCardScreen from '../screens/AddFundsCardScreen';
import AddFundsAlternativePaymentScreen from '../screens/AddFundsAlternativePaymentScreen';
import UploadDocumentsScreen from '../screens/UploadDocumentsScreen';
import ClientReportScreen from '../screens/ClientReportScreen';
import AccountsScreen from '../screens/AccountsScreen';


const Stack = createNativeStackNavigator();

export default function MoreStackNavigator() {
    const styles = useStyles(stylesGenerator);

    return (
        <SafeAreaView style={styles.container}>
            <Stack.Navigator initialRouteName="More">
                <Stack.Screen
                    name="More"
                    key={'More'}
                    component={MoreScreen}
                    options={{
                        title: i18n.t('more-screen'),
                        header: () => <CustomHeader title={'more-screen'} hideBackButton={true} />,
                    }}
                />
                <Stack.Screen
                    name="Settings"
                    key={'Settings'}
                    component={SettingsScreen}
                    options={{
                        title: i18n.t('settings-screen'),
                        header: () => <CustomHeader title={'settings-screen'} />,
                    }}
                />
                <Stack.Screen
                    name="Accounts"
                    key={'Accounts'}
                    component={AccountsScreen}
                    options={{
                        title: i18n.t('accounts-label'),
                        header: () => <CustomHeader title={'accounts-label'} />,
                    }}
                />
                <Stack.Screen
                    name="ChangePassword"
                    key={'ChangePassword'}
                    component={ChangePasswordScreen}
                    options={{
                        header: () => <CustomHeader title={'change-password'} />,
                    }}
                />
                <Stack.Screen
                    name="NotificationsSettings"
                    key={'NotificationsSettings'}
                    component={NotificationsSettingsScreen}
                    options={{
                        header: () => <CustomHeader title={'notifications-label'} />,
                    }}
                />
                <Stack.Screen
                    name="WithdrawFunds"
                    key={'WithdrawFunds'}
                    component={WithdrawFundsScreen}
                    options={{
                        header: () => <CustomHeader title={'withdraw-funds-screen'} />,
                    }}
                />
                <Stack.Screen
                    name="UploadDocuments"
                    key={'UploadDocuments'}
                    component={UploadDocumentsScreen}
                    options={{
                        header: () => <CustomHeader title={'upload-documents'} />,
                    }}
                />
                <Stack.Screen
                    name="AddFunds"
                    key={'AddFunds'}
                    component={AddFundsScreen}
                    options={{
                        header: () => <CustomHeader title={'add-money-screen'} />,
                    }}
                />
                <Stack.Screen
                    name="AddFundsCategory"
                    key={'AddFundsCategory'}
                    component={AddFundsCategoryScreen}
                    options={{
                        header: () => <CustomHeader title={'add-money-screen'} />,
                    }}
                />
                <Stack.Screen
                    name="AddFundsCard"
                    key={'AddFundsCard'}
                    component={AddFundsCardScreen}
                    options={{
                        header: () => <CustomHeader title={'add-money-screen'} />,
                    }}
                />
                <Stack.Screen
                    name="AddFundsAlternativePayment"
                    key={'AddFundsAlternativePayment'}
                    component={AddFundsAlternativePaymentScreen}
                    options={{
                        header: () => <CustomHeader title={'add-money-screen'} />,
                    }}
                />
                <Stack.Screen
                    name="AddFundsAlternative"
                    key={'AddFundsAlternative'}
                    component={AddFundsAlternativeScreen}
                    options={{
                        header: () => <CustomHeader title={'add-money-screen'} />,
                    }}
                />
                <Stack.Screen
                    name="AddFundsBank"
                    key={'AddFundsBank'}
                    component={AddFundsBankScreen}
                    options={{
                        header: () => <CustomHeader title={'add-money-screen'} />,
                    }}
                />
                <Stack.Screen
                    name="VerificationCenter"
                    key={'VerificationCenter'}
                    component={VerificationCenterScreen}
                    options={{
                        header: () => <CustomHeader title={'verification-center-screen'} />,
                    }}
                />
                <Stack.Screen
                    name="ClientReport"
                    key={'ClientReport'}
                    component={ClientReportScreen}
                    options={{
                        header: () => <CustomHeader title={'client-report'} />,
                        title: i18n.t('client-report')
                    }}
                />
            </Stack.Navigator>
        </SafeAreaView>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
    });
