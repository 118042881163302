import React from 'react';
import {
    View,
    StyleSheet,
    TextInput,
    Pressable,
    TextInputProps,
    PressableProps,
    StyleProp,
    ViewStyle,
} from 'react-native';
import { MaterialCommunityIcons, Ionicons, AntDesign, Entypo } from '@expo/vector-icons';
import type { MaterialIcon, IoniconsIcon, AntDesignIcon, EntypoIcon } from 'app/common-types';
import { useStyles } from '~/hooks';
import i18n from '~/i18n';
import { FONT_FAMILY_REGULAR, FONT_SIZE_M } from '~/constants/typography';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';

type Props = TextInputProps & {
    leftIcon?: MaterialIcon;
    rightIcon?: MaterialIcon;
    containerStyle?: StyleProp<ViewStyle>;
    onRightIconPress?: PressableProps['onPress'];
    ioniconsLeftIcon?: IoniconsIcon;
    antDesignLeftIcon?: AntDesignIcon;
    entypoRightIcon?: EntypoIcon;
    maxWidth?: number;
    ref?: any;
};

const InputField = ({
    leftIcon,
    rightIcon,
    containerStyle,
    onRightIconPress,
    placeholder,
    ioniconsLeftIcon,
    antDesignLeftIcon,
    entypoRightIcon,
    maxWidth,
    ref,
    ...rest
}: Props) => {
    const styles = useStyles(stylesGenerator, maxWidth ? { maxWidth } : undefined);
    return (
        <View style={[styles.container, containerStyle]}>
            {leftIcon ? (
                <View style={styles.leftIconCircle}>
                    <MaterialCommunityIcons
                        name={leftIcon}
                        size={scaleSize(12, maxWidth)}
                        style={styles.customLeftIcon}
                    />
                </View>
            ) : null}
            {ioniconsLeftIcon ? (
                <View style={styles.leftIconCircle}>
                    <Ionicons name={ioniconsLeftIcon} size={scaleSize(12, maxWidth)} style={styles.customLeftIcon} />
                </View>
            ) : null}
            {antDesignLeftIcon ? (
                <View style={styles.leftIconCircle}>
                    <AntDesign name={antDesignLeftIcon} size={scaleSize(12, maxWidth)} style={styles.customLeftIcon} />
                </View>
            ) : null}
            <TextInput
                placeholder={placeholder && i18n.t(placeholder)}
                placeholderTextColor={'#929292'}
                style={[{ fontFamily: FONT_FAMILY_REGULAR }, styles.textStyle, styles.input, rest.style]}
                ref={ref}
                {...rest}
            />
            {rightIcon ? (
                <Pressable onPress={onRightIconPress}>
                    <MaterialCommunityIcons name={rightIcon} size={scaleSize(20, maxWidth)} style={styles.rightIcon} />
                </Pressable>
            ) : null}
            {entypoRightIcon ? (
                <Pressable onPress={onRightIconPress}>
                    <Entypo name={entypoRightIcon} size={scaleSize(20, maxWidth)} style={styles.rightIcon} />
                </Pressable>
            ) : null}
        </View>
    );
};

const stylesGenerator = (theme: Theme, custom?: { maxWidth: number }) =>
    StyleSheet.create({
        leftIconCircle: {
            borderWidth: 1,
            borderRadius: scaleSize(10, custom?.maxWidth),
            borderColor: theme.equityValue,
            height: scaleSize(20, custom?.maxWidth),
            width: scaleSize(20, custom?.maxWidth),
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: scaleSize(5, custom?.maxWidth),
        },
        textStyle: { color: theme.desktopHeaderTextColor },
        container: {
            borderRadius: scaleSize(4, custom?.maxWidth),
            borderColor: theme.equityValue,
            borderWidth: 1,
            flexDirection: 'row',
            padding: scaleSize(12, custom?.maxWidth),
            backgroundColor: theme.backgroundSecondary,
        },
        customLeftIcon: {
            color: theme.equityValue,
        },
        input: {
            flex: 1,
            width: '100%',
            fontSize: FONT_SIZE_M,
        },
        rightIcon: {
            alignSelf: 'center',
            marginLeft: scaleSize(10, custom?.maxWidth),
            color: theme.equityValue,
        },
    });

export default InputField;
