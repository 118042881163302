import * as React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import i18n from '~/i18n';
import WatchlistItemScreen from '../screens/WatchlistItemScreen';
import { useAppSelector, useStyles } from '~/hooks';
import { dealerSelector, ptSelector, watchlistDataSelector } from '~/state/selectors';
import { generateWatchlistItemId } from '~/navigation/utilts';
import { Platform, StyleSheet } from 'react-native';
import { scaleSize } from '~/constants/scale';
import { FONT_FAMILIES, FONT_SIZE_S } from '~/constants';
import InvestorsScreen from '../screens/InvestorsScreen';
import { useTheme } from '~/hooks/useTheme';

const Tab = createMaterialTopTabNavigator();

export default function MarketTopTabsNavigator() {
    const watchlist = useAppSelector(watchlistDataSelector);
    const styles = useStyles(stylesGenerator);
    const theme = useTheme();
    const dealer = useAppSelector(dealerSelector);
    const pt = useAppSelector(ptSelector);



    return (
        <Tab.Navigator
            screenOptions={{
                tabBarScrollEnabled: true,
                lazy: true,
                tabBarActiveTintColor: theme.tabIconSelected,
                tabBarInactiveTintColor: theme.tabIconDefault,
                tabBarIndicatorStyle: { backgroundColor: theme.tabIconSelected },
                ...styles,
            }}
        >
            {watchlist.slice(0, 1).map((item) => (
                <Tab.Screen
                    name={generateWatchlistItemId(item.id)}
                    key={item.id}
                    component={WatchlistItemScreen}
                    options={{ title: item.custom && item.name ? item.name : i18n.t(item.code || '') }}
                />
            ))}
            {dealer !== 'EVER' && pt !== true ?
                <Tab.Screen
                    name={'CopyTrading'}
                    key={'CopyTradingKey'}
                    component={InvestorsScreen}
                    options={{ title: i18n.t('copy-trading') }}
                /> : null}
            {watchlist.slice(1, watchlist.length).map((item) => (
                <Tab.Screen
                    name={generateWatchlistItemId(item.id)}
                    key={item.id}
                    component={WatchlistItemScreen}
                    options={{ title: item.custom && item.name ? item.name : i18n.t(item.code || '') }}
                />
            ))}
        </Tab.Navigator>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        tabBarLabelStyle: {
            fontFamily: FONT_FAMILIES.LATO_BOLD,
            fontWeight: Platform.OS === 'web' ? 'bold' : 'regular',
            fontSize: scaleSize(FONT_SIZE_S),
            flex: 1,
        },
        tabBarStyle: {
            alignItems: 'stretch',
        },
        tabBarIconStyle: {
            minHeight: scaleSize(24),
            flex: 1,
        },
        tabBarItemStyle: {
            paddingHorizontal: scaleSize(20),
            width: 'auto',
        },
    });
