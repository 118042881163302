import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { Text } from '~/components/core';
import { FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import { formatNumberWithPercent } from '~/lib/format';
import InvestorPortfolioInstrumentGroupDetails from './InvestorPortfolioInstrumentGroupDetails';
import PieChart from 'react-native-pie-chart';
import { getData } from '~/api-services/CopyTradingService/types';

type Props = {
    getResponse: getData;
};

export default function Portfolio({ getResponse }: Props) {
    const styles = useStyles(stylesGenerator);
    const widthAndHeight = 200;
    const [series, setSeries] = useState<number[]>([]);
    const [sliceColor, setSliceColor] = useState<string[]>([]);

    useEffect(() => {
        if (getResponse && getResponse.instrumentsResults) {
            setSliceColor(['#7101c7', '#f7941d', '#0189d1', '#a00076', '#b4d06a', '#ea547a']);
            setSeries([
                getResponse.instrumentsResults.Indexes,
                getResponse.instrumentsResults.Commodities,
                getResponse.instrumentsResults.Stocks,
                getResponse.instrumentsResults.Currencies,
                getResponse.instrumentsResults.ETFs,
                getResponse.instrumentsResults.CryptoCurrencies,
            ]);
        }
    }, [getResponse]);

    return (
        <ScrollbarWrapper>
            <View style={styles.container}>
                <View style={styles.headerDiv}>
                    <Text fontType="LATO_BOLD" style={styles.headerTitleText}>
                        pie-chart-overview
                    </Text>
                    {getResponse &&
                    getResponse.instrumentsResults &&
                    series.length > 0 &&
                    (getResponse.instrumentsResults.Indexes > 0 ||
                        getResponse.instrumentsResults.Commodities > 0 ||
                        getResponse.instrumentsResults.Stocks > 0 ||
                        getResponse.instrumentsResults.Currencies > 0 ||
                        getResponse.instrumentsResults.ETFs > 0 ||
                        getResponse.instrumentsResults.CryptoCurrencies > 0) ? (
                        <PieChart widthAndHeight={widthAndHeight} series={series} sliceColor={sliceColor} />
                    ) : null}
                </View>
                <View style={styles.pieChartLegend}>
                    <View style={styles.legendElementDiv}>
                        <View style={[styles.point, styles.indexesColor]} />
                        <Text fontType="LATO_REGULAR" style={styles.legendText}>
                            Indexes
                        </Text>
                    </View>
                    <View style={styles.legendElementDiv}>
                        <View style={[styles.point, styles.comoditiesColor]} />
                        <Text fontType="LATO_REGULAR" style={styles.legendText}>
                            Commodities
                        </Text>
                    </View>
                    <View style={styles.legendElementDiv}>
                        <View style={[styles.point, styles.sharesColor]} />
                        <Text fontType="LATO_REGULAR" style={styles.legendText}>
                            Shares
                        </Text>
                    </View>
                    <View style={styles.legendElementDiv}>
                        <View style={[styles.point, styles.currenciesColor]} />
                        <Text fontType="LATO_REGULAR" style={styles.legendText}>
                            Currencies
                        </Text>
                    </View>
                    <View style={styles.legendElementDiv}>
                        <View style={[styles.point, styles.etfsColor]} />
                        <Text fontType="LATO_REGULAR" style={styles.legendText}>
                            ETFs
                        </Text>
                    </View>
                    <View style={styles.legendElementDiv}>
                        <View style={[styles.point, styles.otherColor]} />
                        <Text fontType="LATO_REGULAR" style={styles.legendText}>
                            Other
                        </Text>
                    </View>
                </View>
                {getResponse ? (
                    <>
                        <View style={styles.investorResult}>
                            <View style={styles.investorResultDetail}>
                                <Text fontType="LATO_REGULAR" style={styles.investorResultDetailLabel}>
                                    profit-30-days
                                </Text>
                                <Text fontType="LATO_BOLD" style={getResponse.investorInfo.profit_30d < 0 ? styles.investorResultDetailValueNegative : styles.investorResultDetailValue}>
                                    {formatNumberWithPercent(getResponse.investorInfo.profit_30d, 2, false)}
                                </Text>
                            </View>
                            <View style={styles.investorResultDetail}>
                                <Text fontType="LATO_REGULAR" style={styles.investorResultDetailLabel}>
                                    profit-1-year
                                </Text>
                                <Text fontType="LATO_BOLD" style={getResponse.investorInfo.profit_12m < 0 ? styles.investorResultDetailValueNegative : styles.investorResultDetailValue }>
                                    {formatNumberWithPercent(getResponse.investorInfo.profit_12m, 2, false)}
                                </Text>
                            </View>
                        </View>
                        <InvestorPortfolioInstrumentGroupDetails
                            title="Indexes"
                            color="#7101c7"
                            percent={getResponse.instrumentsResults.Indexes}
                        />
                        <InvestorPortfolioInstrumentGroupDetails
                            title="Commodities"
                            color="#f7941d"
                            percent={getResponse.instrumentsResults.Commodities}
                        />
                        <InvestorPortfolioInstrumentGroupDetails
                            title="Shares"
                            color="#0189d1"
                            percent={getResponse.instrumentsResults.Stocks}
                        />
                        <InvestorPortfolioInstrumentGroupDetails
                            title="Currencies"
                            color="#a00076"
                            percent={getResponse.instrumentsResults.Currencies}
                        />
                        <InvestorPortfolioInstrumentGroupDetails
                            title="ETFs"
                            color="#b4d06a"
                            percent={getResponse.instrumentsResults.ETFs}
                        />
                        <InvestorPortfolioInstrumentGroupDetails
                            title="Other"
                            color="#ea547a"
                            percent={getResponse.instrumentsResults.CryptoCurrencies}
                        />
                    </>
                ) : null}
            </View>
        </ScrollbarWrapper>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        investorResultDetailValueNegative: { fontSize: FONT_SIZE_S, color: theme.text_downPrice, paddingLeft: scaleSize(5) },
        investorResultDetailValue: { fontSize: FONT_SIZE_S, color: theme.switch_trackOn, paddingLeft: scaleSize(5) },
        investorResultDetailLabel: { fontSize: FONT_SIZE_S, color: theme.text_pendingOrder },
        investorResultDetail: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '50%' },
        investorResult: {
            width: '100%',
            flexDirection: 'row',
            paddingTop: scaleSize(30),
            paddingBottom: scaleSize(20),
        },
        legendElementDiv: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        legendText: { color: theme.text_pendingOrder, fontSize: FONT_SIZE_XS, paddingLeft: scaleSize(5) },
        point: { width: scaleSize(7), height: scaleSize(7), borderRadius: scaleSize(3.5) },
        indexesColor: { backgroundColor: '#7101c7' },
        comoditiesColor: { backgroundColor: '#f7941d' },
        sharesColor: { backgroundColor: '#0189d1' },
        currenciesColor: { backgroundColor: '#a00076' },
        etfsColor: { backgroundColor: '#b4d06a' },
        otherColor: { backgroundColor: '#ea547a' },
        pieChartLegend: {
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: scaleSize(7),
        },
        headerDiv: { paddingVertical: scaleSize(20), justifyContent: 'center', alignItems: 'center' },
        headerTitleText: { color: theme.text_positionsCount, fontSize: FONT_SIZE_S, paddingBottom: scaleSize(20) },
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
            paddingBottom: scaleSize(7),
        },
    });
