import AsyncStorage from '@react-native-async-storage/async-storage';
import { InitialState } from '@react-navigation/native';
import type { LoginResponseReturnData } from '~/api-services/AuthDataService/types';
import type { AppSettings } from './reducers/appSettingsSlice';

class LocalStorageSlice<T> {
    constructor(private key: string) {}

    public async set(data: T) {
        await AsyncStorage.setItem(this.key, JSON.stringify(data));
        return data;
    }

    public async get() {
        const data = await AsyncStorage.getItem(this.key);

        if (!data) {
            return null;
        }

        const parsedData: T = JSON.parse(data);

        return parsedData;
    }

    public async clear() {
        await AsyncStorage.removeItem(this.key);
    }
}

export default {
    userAuthData: new LocalStorageSlice<LoginResponseReturnData>('user_auth_data'),
    appSettings: new LocalStorageSlice<AppSettings>('app_settings'),
    initialNavigationState: new LocalStorageSlice<InitialState>('navigation_state'),
};
