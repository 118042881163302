import * as React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import i18n from '~/i18n';
import { Theme } from '~/theme';
import { useAppSelector, useStyles } from '~/hooks';
import { Platform, StyleSheet, View } from 'react-native';
import { FONT_FAMILIES, FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import { scaleSize } from '~/constants/scale';
import NewsScreen from '../screens/NewsScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import { pendingMessagesCountSelector } from '~/state/selectors';
import { Text } from '../core';
import { useTheme } from '~/hooks/useTheme';

const Tab = createMaterialTopTabNavigator();

export default function NewsTopTabsNavigator() {
    const theme = useTheme();
    const styles = useStyles(stylesGenerator);
    const pendingMessagesCount = useAppSelector(pendingMessagesCountSelector);
    return (
        <Tab.Navigator
            screenOptions={{
                tabBarScrollEnabled: true,
                lazy: true,
                tabBarActiveTintColor: theme.tabIconSelected,
                tabBarInactiveTintColor: theme.tabIconDefault,
                tabBarIndicatorStyle: { backgroundColor: theme.tabIconSelected },
                ...styles,
            }}
        >
            <Tab.Screen name="News" key="News" component={NewsScreen} options={{ title: i18n.t('bottom-tab-news') }} />
            <Tab.Screen
                name="Notifications"
                key="Notifications"
                component={NotificationsScreen}
                options={{
                    tabBarLabel: i18n.t('notifications-label'),
                    tabBarBadge: () => {
                        if (pendingMessagesCount) {
                            return (
                                <View style={styles.badgeContainer}>
                                    <Text fontType="LATO_REGULAR" style={styles.badgeText}>
                                        {pendingMessagesCount > 9 ? '9+' : pendingMessagesCount.toString()}
                                    </Text>
                                </View>
                            );
                        } else {
                            return null;
                        }
                    },
                }}
            />
        </Tab.Navigator>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        badgeText: { fontSize: scaleSize(FONT_SIZE_XS), color: theme.white },
        badgeContainer: {
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.tabIconSelected,
            top: scaleSize(10),
            right: scaleSize(7),
            width: scaleSize(16),
            height: scaleSize(16),
            borderRadius: scaleSize(8),
        },
        tabBarLabelStyle: {
            fontFamily: FONT_FAMILIES.LATO_BOLD,
            fontWeight: Platform.OS === 'web' ? 'bold' : 'regular',
            fontSize: scaleSize(FONT_SIZE_S),
            flex: 1,
            minHeight: scaleSize(12),
        },
    });
