import React from 'react';
import BuySellPosition from '../features/BuySellPosition';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function BuySellPositionScreen() {
    return (
        <ScreenWrapper>
            <BuySellPosition />
        </ScreenWrapper>
    );
}
