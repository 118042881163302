import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '~/components/core';
import { FONT_SIZE_XXS } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';

type Props = {
    labelKey: string;
    value: string;
    spacing?: boolean;
    lastRow?: boolean;
};

export default function RowData({ labelKey, value, spacing, lastRow }: Props) {
    const styles = useStyles(stylesGenerator);
    return (
        <View style={[styles.rowDataContainer, lastRow ? styles.lastRowPadding : null]}>
            <Text fontType="LATO_REGULAR" style={[styles.liveStatDetailText, styles.symbolColor]}>
                {labelKey}
            </Text>
            <Text
                fontType="LATO_REGULAR"
                style={[styles.liveStatDetailText, styles.symbolColor, spacing ? styles.letterSpacing05 : null]}
            >
                {value}
            </Text>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        liveStatDetailText: { fontSize: FONT_SIZE_XXS },
        rowDataContainer: { flexDirection: 'row', justifyContent: 'space-between', paddingTop: scaleSize(10) },
        symbolColor: { color: theme.text_symbol },
        lastRowPadding: { paddingBottom: scaleSize(20) },
        letterSpacing05: { letterSpacing: 0.5 },
    });
