import React from 'react';
import EditOpenPosition from '../features/EditOpenPosition';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function EditOpenPositionScreen() {
    return (
        <ScreenWrapper>
            <EditOpenPosition />
        </ScreenWrapper>
    );
}
