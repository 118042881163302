import React, { useEffect, useState } from 'react';
import { View, Modal, StyleSheet, SafeAreaView, Pressable } from 'react-native';
import { useAppDispatch, useAppSelector, useStyles } from '~/hooks';
import { Theme } from '~/theme';
import { scaleSize } from '~/constants/scale';
import { FONT_SIZE_XS } from '~/constants';
import { appSettingsSelector, watchlistDataSelector } from '~/state/selectors';
import { saveAppSettings } from '~/state/reducers/appSettingsSlice';
import { Switch, Text } from '~/components/core';

type WalletProps = {
    isModalVisible: boolean;
    setVisible: Function;
};

const Wallet = ({ isModalVisible, setVisible }: WalletProps) => {
    const styles = useStyles(stylesGenerator);
    const { watchlistColumns, colorScheme, hideBalance } = useAppSelector(appSettingsSelector);
    const dispatch = useAppDispatch();
    const [watchlistColumnsSwitch, setWatchlistColumnsSwitch] = useState(watchlistColumns);
    const [colorSchemeSwitch, setColorSchemeSwitch] = useState<'light' | 'dark' | undefined>(colorScheme);
    const [hideBalanceSwitch, setHideBalanceSwitch] = useState(hideBalance);

    useEffect(() => {
        setWatchlistColumnsSwitch(watchlistColumns);
        setColorSchemeSwitch(colorScheme);
        setHideBalanceSwitch(hideBalance);
    }, [watchlistColumns, colorScheme, hideBalance]);

    return (
        <Modal
            animationType="none"
            transparent={true}
            visible={isModalVisible}
            onRequestClose={() => {
                setVisible(false);
            }}
            statusBarTranslucent={false}
        >
            <SafeAreaView style={styles.closeContainer}>
                <Pressable
                    style={[styles.closeContainer]}
                    onPress={() => {
                        setVisible(false);
                    }}
                >
                    <View
                        style={[
                            styles.container,
                            {
                                top: scaleSize(0),
                            },
                        ]}
                    >
                        <View style={styles.content}>
                            <View style={styles.switchContainer}>
                                <View style={styles.pressableArea}>
                                    <View style={styles.textPadding}>
                                        <Text fontType="LATO_REGULAR" style={styles.swithText}>
                                            change-view
                                        </Text>
                                    </View>
                                    <Switch
                                        value={watchlistColumnsSwitch === 1}
                                        style={styles.switchButton}
                                        onValueChange={(value) => {
                                            setWatchlistColumnsSwitch(value ? 1 : 2);
                                            dispatch(saveAppSettings({ watchlistColumns: value ? 1 : 2 }));
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={styles.switchContainer}>
                                <View style={styles.pressableArea}>
                                    <View style={styles.textPadding}>
                                        <Text fontType="LATO_REGULAR" style={styles.swithText}>
                                            hide-balance
                                        </Text>
                                    </View>
                                    <Switch
                                        value={hideBalanceSwitch}
                                        style={styles.switchButton}
                                        onValueChange={(value) => {
                                            setHideBalanceSwitch(value);
                                            dispatch(saveAppSettings({ hideBalance: value }));
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={styles.switchContainer}>
                                <View style={styles.pressableArea}>
                                    <View style={styles.textPadding}>
                                        <Text fontType="LATO_REGULAR" style={styles.swithText}>
                                            switch-theme
                                        </Text>
                                    </View>
                                    <Switch
                                        value={colorSchemeSwitch === 'dark'}
                                        style={styles.switchButton}
                                        onValueChange={(value) => {
                                            setColorSchemeSwitch(value ? 'dark' : 'light');
                                            dispatch(saveAppSettings({ colorScheme: value ? 'dark' : 'light' }));
                                        }}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </Pressable>
            </SafeAreaView>
        </Modal>
    );
};

export default Wallet;

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        textPadding: { paddingRight: scaleSize(6), width: scaleSize(90) },
        pressableArea: {
            width: '100%',
            height: scaleSize(26),
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        switchContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: scaleSize(10),
            justifyContent: 'space-between',
        },
        switchButton: {
            justifyContent: 'flex-end',
        },
        swithText: { fontSize: FONT_SIZE_XS, color: theme.wallet_text, justifyContent: 'flex-start' },
        screen: {
            width: '100%',
        },
        closeContainer: { flex: 1, width: '100%', height: '100%' },
        container: {
            borderTopLeftRadius: scaleSize(5),
            borderBottomLeftRadius: scaleSize(5),
            position: 'absolute',
            width: scaleSize(188),
            left: scaleSize(187),
            borderColor: theme.border_wallet,
            borderWidth: scaleSize(1),
        },
        content: {
            width: '100%',
            flex: 1,
            borderTopLeftRadius: scaleSize(5),
            borderBottomLeftRadius: scaleSize(5),
            backgroundColor: theme.background_wallet,
            padding: scaleSize(20),
        },
    });
