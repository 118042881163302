import React, { useState, useEffect } from 'react';
import { View, StyleSheet, SafeAreaView, KeyboardAvoidingView, Platform } from 'react-native';

import { isDesktop, scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import Header from '../More/Header';
import { Theme } from '~/theme';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';
import Loader from '~/components/core/Loader';
import { Text } from '~/components/core';
import { FONT_SIZE_XS } from '~/constants';
import PersonalInformation from './PersonalInformation';
import FinancialInformation from './FinancialInformation';
import TradingExperiance from './TradingExperiance';
import InvestmentKnowledge from './InvestmentKnowledge';
import OtherQuestions from './OtherQuestions';
import Finished from './Finished';
import MandatoryHeader from './MandatoryHeader';
import { CustomerDataResponse } from '~/api-services/VerificationService/types';
import VerificationService from '~/api-services/VerificationService';

type Props = {
    setActiveRightTab?: Function;
};

export default function VerificationCenter({ setActiveRightTab }: Props) {
    const styles = useStyles(stylesGenerator);
    const [currentStep, setCurrentStep] = useState(-1);
    const [finishedStep, setFinishedStep] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [customerData, setCustomerData] = useState<CustomerDataResponse>();
    const [tradingExperianceAgreement, setTradingExperianceAgreement] = useState(false);
    const [tradingExperianceStep, setTradingExperianceStep] = useState(1);

    console.log('customerData.howManyTransactions', customerData ? customerData.howManyTransactions : undefined);
    console.log('tradingExperianceStep', tradingExperianceStep);

    useEffect(() => {
        loadCustomerData();
    }, []);

    async function loadCustomerData() {
        const { status, data } = await VerificationService.getCustomerData();
        if (status === 200 && data.status) {
            setCustomerData(data.returnData);
        }
    }

    useEffect(() => {
        if (customerData && currentStep === -1) {
            var cstep = getCurrentStep();
            setCurrentStep(cstep);
            setFinishedStep(cstep - 1);
        }
    }, [currentStep, customerData]);

    function goToNextStep() {
        if (finishedStep <= currentStep) {
            setFinishedStep(currentStep);
        }
        if (currentStep === 3 && tradingExperianceStep < 3) {
            setTradingExperianceStep(tradingExperianceStep + 1);
        }
        else {
            var nextStep = currentStep + 1;
            if (currentStep === 2) {
                if (finishedStep === 3) {
                    nextStep = 4;
                }
                if (finishedStep === 4) {
                    nextStep = 5;
                }
                if (finishedStep === 5) {
                    nextStep = 6;
                }
            }
            setCurrentStep(nextStep);

        }
        loadCustomerData();
    }

    function goToPrevStep() {
        if (currentStep === 3 && tradingExperianceStep > 1) {
            setTradingExperianceStep(tradingExperianceStep - 1);
        }
        else {
            setCurrentStep(currentStep - 1);
        }
    }

    function goToFirstStep() {
        setCurrentStep(1);
    }

    console.log(customerData);

    function getCurrentStep(): number {
        var step = 1;
        if (customerData.firstname === null || customerData.lastname === null || customerData.phone.phone === null ||
            customerData.address.details === null || customerData.address.postalCode === null || customerData.address.city === null ||
            customerData.address.country.code === null || customerData.nationality === null ||
            customerData.politicallyExposedPerson === null || customerData.birthday === null
            || customerData.connectedWithPoliticallyExposedPerson === null
        ) {
            step = 1;
        }
        else if (customerData.taxCountry === null || customerData.taxNumber === null || customerData.otherTax === null ||
            customerData.mainSourceIncome === null || customerData.averageAnnualIncome === null ||
            (customerData.otherTax == true && (customerData.otherTaxCountry === null || customerData.otherTaxNumber === null))
            || customerData.breakdownWealth === null ||
            customerData.secondaryIncomeAbove10000 === null ||
            customerData.intendToDepositNext12Months === null


        ) {
            step = 2;
        }
        else if (
            customerData.tradingExperianceAgreement === null || customerData.tradingExperianceAgreement === false ||//tradingExperianceStep === 1
            customerData.levelOfEducationAndQualification === null ||
            customerData.experienceWithForexLeveragedCFDs === null ||
            customerData.whereGatherTradingExperienceForexLeveragedCFDs === null ||
            customerData.financialInstrumentsTraded === null ||
            (customerData.financialInstrumentsTraded === 'Other (*Please specify)' && customerData.financialInstrumentsTradedDescription === null) ||
            customerData.howTradedInstruments === null ||
            customerData.believeInKnowledge === null ||
            customerData.howCharacterizeKnowledge === null ||// tradingExperianceStep === 2
            customerData.howManyTransactions === null // tradingExperianceStep === 3
        ) {
            step = 3;
        }
        else if (customerData.educationFinancialServicesPast3Years === null || customerData.depositLeverageQuestion === null ||
            customerData.tradedUsingLeverageMagnifiesPotentialProfitsLossesText === null || customerData.realDeliveryAsset === null ||
            customerData.automaticallyClosedPosition === null || customerData.whatHappenStopLossOrder === null) {
            step = 4;
        }
        else if (customerData.businessAndProfessionalActivity === null || customerData.servicesToUse === null || customerData.assetManagement === null) {
            step = 5;
        }
        else if (customerData.businessAndProfessionalActivity != null && customerData.servicesToUse != null && customerData.assetManagement != null) {
            step = 6;
        }
        return step;
    }


    return (
        <KeyboardAvoidingView behavior='height' style={styles.keyBoard}>

            <SafeAreaView style={styles.safeAreaStyle}>
                {!isDesktop() ? <Header /> : null}
                <DesktopScreenHeader goToScreenName="more" title="verification-center" setActiveRightTab={setActiveRightTab} />
                <Loader loading={loading} />
                <View style={styles.stepsContainer}>
                    <View style={styles.stepsLine}></View>
                    <View style={[styles.stepPosition, currentStep === 1 ? styles.stepPositionBackgroundActive : finishedStep >= 1 ? styles.stepPositionBackgroundComplate : styles.stepPositionBackgroundPassive]}>
                        <Text fontType='LATO_BLACK' style={styles.stepText}>1</Text>
                    </View>
                    <View style={[styles.stepPosition, currentStep === 2 ? styles.stepPositionBackgroundActive : finishedStep >= 2 ? styles.stepPositionBackgroundComplate : styles.stepPositionBackgroundPassive]}>
                        <Text fontType='LATO_BLACK' style={styles.stepText}>2</Text>
                    </View>
                    <View style={[styles.stepPosition, currentStep === 3 ? styles.stepPositionBackgroundActive : finishedStep >= 3 ? styles.stepPositionBackgroundComplate : styles.stepPositionBackgroundPassive]}>
                        <Text fontType='LATO_BLACK' style={styles.stepText}>3</Text>
                    </View>
                    <View style={[styles.stepPosition, currentStep === 4 ? styles.stepPositionBackgroundActive : finishedStep >= 4 ? styles.stepPositionBackgroundComplate : styles.stepPositionBackgroundPassive]}>
                        <Text fontType='LATO_BLACK' style={styles.stepText}>4</Text>
                    </View>
                    <View style={[styles.stepPosition, currentStep === 5 ? styles.stepPositionBackgroundActive : finishedStep >= 5 ? styles.stepPositionBackgroundComplate : styles.stepPositionBackgroundPassive]}>
                        <Text fontType='LATO_BLACK' style={styles.stepText}>5</Text>
                    </View>
                </View>
                {currentStep < 6 ?
                    <>
                        <MandatoryHeader />
                        <ScrollbarWrapper style={styles.contentContainer}>
                            {currentStep === 1 ? <PersonalInformation setLoading={setLoading} customerData={customerData} goToNextStep={goToNextStep} /> : null}
                            {currentStep === 2 ? <FinancialInformation setLoading={setLoading} customerData={customerData} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} /> : null}
                            {currentStep === 3 ? <TradingExperiance tradingExperianceStep={tradingExperianceStep} setTradingExperianceStep={setTradingExperianceStep} setLoading={setLoading} tradingExperianceAgreement={tradingExperianceAgreement} setTradingExperianceAgreement={setTradingExperianceAgreement} customerData={customerData} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} /> : null}
                            {currentStep === 4 ? <InvestmentKnowledge setLoading={setLoading} customerData={customerData} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} /> : null}
                            {currentStep === 5 ? <OtherQuestions setLoading={setLoading} customerData={customerData} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} /> : null}
                        </ScrollbarWrapper>

                    </> : null}
                {currentStep > 5 ? <Finished goToFirstStep={goToFirstStep} setActiveRightTab={setActiveRightTab} /> : null}
            </SafeAreaView>
        </KeyboardAvoidingView>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        keyBoard: { width: '100%', height: '100%' },
        safeAreaStyle: { flex: 1 },
        stepText: { fontSize: FONT_SIZE_XS, color: theme.white },
        stepsLine: { backgroundColor: theme.tabIconDefault, width: '90%', position: 'absolute', top: scaleSize(20), height: scaleSize(2) },
        stepPositionBackgroundPassive: { backgroundColor: theme.tabIconDefault },
        stepPositionBackgroundActive: { backgroundColor: theme.priceRangeActiveTab },
        stepPositionBackgroundComplate: { backgroundColor: theme.compleateVCStep },
        safeArea: { flex: 1 },
        stepPosition: { width: scaleSize(20), height: scaleSize(20), borderRadius: scaleSize(10), justifyContent: 'center', alignItems: 'center' },
        stepsContainer: { justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', paddingHorizontal: scaleSize(17), paddingVertical: scaleSize(10) },
        contentContainer: { paddingHorizontal: scaleSize(17) },
        container: {
            flex: 1,
            height: '100%',
            alignSelf: 'center',
            width: isDesktop() ? scaleSize(390) : '100%',
        },
    });
