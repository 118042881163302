import React from 'react';
import { StyleSheet, Pressable, View, PressableProps, StyleProp, ViewStyle } from 'react-native';
import { Text } from '~/components/core';
import { FONT_SIZE_M, FONT_SIZE_XL } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';

type Props = {
    label: string;
    onPress: PressableProps['onPress'];
    price: string;
    style: StyleProp<ViewStyle>;
};

export default function BuySellButton({ label, onPress, price, style }: Props) {
    const styles = useStyles(stylesGenerator);
    return (
        <View style={styles.buySellButtonContainer}>
            <Pressable onPress={onPress} style={[styles.buySellButtonSize, style]}>
                <Text fontType="LATO_BOLD" style={styles.buySellText}>
                    {label}
                </Text>
                <Text fontType="LATO_BOLD" style={styles.priceText}>
                    {price}
                </Text>
            </Pressable>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        buySellText: { color: theme.white, fontSize: FONT_SIZE_M },
        priceText: { color: theme.white, fontSize: FONT_SIZE_XL },
        buySellButtonContainer: {
            width: '50%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        buySellButtonSize: {
            borderRadius: scaleSize(5),
            width: scaleSize(165.5),
            height: scaleSize(50),
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
