import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Image, Platform, KeyboardAvoidingView } from 'react-native';
import { ErrorMessage } from '~/components/core';
import { useAppDispatch, useAppSelector, useStyles } from '~/hooks';
import { FONT_SIZE_S, FONT_SIZE_XS, FONT_SIZE_XXXL, FONT_SIZE_XXXXL, spacing } from '~/constants';
import { userAuthSelector } from '~/state/selectors';
import { autoLogin } from '~/state/reducers/userAuthSlice';
import { scaleSize, scaleSizeMobile } from '~/constants/scale';
import { Theme } from '~/theme';
import config from '~/config';
import { appSettingsSelector } from '~/state/selectors';
import { SvgUri } from 'react-native-svg';
import { useBrowserLanguage } from '~/hooks/useBrowserLanguage';
import { useRoute } from '@react-navigation/native';
import { AuthDataService } from '~/api-services';

export default function AutoLoginForm() {
    const route: any = useRoute();
    const autoLoginToken: string = (route.params as any).autoLoginToken;

    const dispatch = useAppDispatch();
    const { errorMessage } = useAppSelector(userAuthSelector);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const maxWidth: number = 600;
    const styles = useStyles(stylesGenerator, { maxWidth });
    useBrowserLanguage();

    useEffect(() => {
        (async () => {
            if (autoLoginToken) {
                dispatch(autoLogin({ autoLoginToken }));
            }
        })();
    }, [autoLoginToken]);

    return (
        <KeyboardAvoidingView style={styles.container} behavior={'height'}>
            <View style={styles.logoContainer}>
                {Platform.OS === 'web' ? (
                    <Image
                        source={{uri: 'https://' +
                        config.EXTERNAL_SOURCES_URL +
                        'mobileplatform/images/brand/' +
                        config.BRAND_NAME +
                        '/home_logo_' +
                        colorScheme +
                        '.svg?1'}}
                        style={styles.logoStyle}
                        resizeMode="contain"
                    />
                ) : (
                    <SvgUri
                        width={'60%'}
                        style={styles.logoStyle}
                        uri={
                            'https://' +
                            config.EXTERNAL_SOURCES_URL +
                            'mobileplatform/images/brand/' +
                            config.BRAND_NAME +
                            '/home_logo_' +
                            colorScheme +
                            '.svg?1'
                        }
                    />
                )}
            </View>
            <ErrorMessage text={errorMessage} visible={!!errorMessage} />
            
        </KeyboardAvoidingView>
    );
}

const stylesGenerator = (theme: Theme, custom?: { maxWidth: number }) =>
    StyleSheet.create({
        footerText: { color: theme.switch_trackOn, fontSize: FONT_SIZE_S },
        footerSeparator: {
            width: 1,
            height: scaleSize(48, custom?.maxWidth),
            backgroundColor: theme.background_buttonInactive,
        },
        footerContainer: { flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' },
        rememberMeText: { color: theme.tabIconSelected },
        rememberMeTextContainer: { paddingLeft: scaleSize(10, custom?.maxWidth) },
        brandNameText: { letterSpacing: 1, color: theme.text_symbol, fontSize: FONT_SIZE_XXXXL },
        brandNameSlogan: { letterSpacing: 1, fontSize: FONT_SIZE_XS, color: theme.switch_trackOn },
        logoStyle: { width: config.HOME_LOGO_SIZE_IN_PERCENT, height: '100%', maxWidth: scaleSizeMobile(custom?.maxWidth), maxHeight: scaleSizeMobile(custom?.maxWidth) },        logoContainer: { height: '50%', justifyContent: 'center', alignItems: 'center' },
        container: {
            flex: 1,
            justifyContent: 'space-between',
            alignSelf: 'center',
            maxWidth: custom?.maxWidth,
            paddingHorizontal: scaleSize(12, custom?.maxWidth),
            width: '100%',
            height: '100%',
            maxHeight: '100%',
        },
        title: {
            fontSize: FONT_SIZE_XXXL,
            fontWeight: '600',
            alignSelf: 'center',
            marginBottom: scaleSize(spacing.LARGE, custom?.maxWidth),
        },
        inputContainer: {
            marginBottom: scaleSize(spacing.LARGE, custom?.maxWidth),
        },
        switchContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: scaleSize(spacing.LARGE, custom?.maxWidth),
        },
        loginButton: {
            marginBottom: scaleSize(spacing.LARGE, custom?.maxWidth),
        },
    });
