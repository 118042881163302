import React, { useMemo } from 'react';
import { Text as RNText, TextProps, StyleSheet } from 'react-native';

import i18n from '~/i18n';
import { useStyles } from '~/hooks';
import { FONT_FAMILIES, FONT_SIZE_S } from '~/constants/typography';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';

type Props = Omit<TextProps, 'children'> & {
    fontType?: keyof typeof FONT_FAMILIES;
    children: string;
    noTranslate?: boolean;
    upperCase?: boolean;
    maxWidth?: number;
};

const DEFAULT_FONT_SIZE = FONT_SIZE_S;
const DEFAULT_FONT_TYPE = 'LATO_REGULAR';

const Text = ({
    fontType = DEFAULT_FONT_TYPE,
    children,
    noTranslate,
    upperCase,
    maxWidth,
    style,
    ...textProps
}: Props) => {
    const styles = useStyles(stylesGenerator);
    const fontStyles = useMemo(
        () => ({
            fontSize: scaleSize((style && StyleSheet.flatten(style).fontSize) || DEFAULT_FONT_SIZE, maxWidth),
            fontFamily: FONT_FAMILIES[fontType],
        }),
        [fontType, style, maxWidth]
    );

    return (
        <RNText
            style={[styles.text, style, fontStyles]}
            children={
                noTranslate
                    ? upperCase
                        ? children.toUpperCase()
                        : children
                    : upperCase
                    ? i18n.t(children).toUpperCase()
                    : i18n.t(children)
            }
            {...textProps}
        />
    );
};

export default Text;

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        text: {
            color: theme.text,
        },
    });
