import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import { scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import DateRow, { getDateRow } from '../../Result/DateRow';
import { InvestorResult } from '~/api-services/ReportsService/types';
import ResultRow from './ResultRow';

type Props = {
    investorResult: InvestorResult[];
};

export default function InvestorTradingHistory({ investorResult }: Props) {
    const styles = useStyles(stylesGenerator);

    return (
        <ScrollbarWrapper>
            <View style={styles.ordersContainer}>
                {investorResult &&
                    investorResult.length !== 0 &&
                    investorResult.map((result, key) => (
                        <View style={styles.container} key={key}>
                            {getDateRow(result.timestamp, key) !== '' ? <DateRow timestamp={result.timestamp} /> : null}
                            <ResultRow result={result} />
                        </View>
                    ))}
            </View>
        </ScrollbarWrapper>
    );
}

const stylesGenerator = () =>
    StyleSheet.create({
        ordersContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingTop: scaleSize(7),
            paddingLeft: scaleSize(7),
            paddingRight: Platform.OS === 'web' ? 0 : scaleSize(7),
            paddingBottom: scaleSize(7),
        },
        container: { width: '100%' },
    });
