import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { appSettingsSelector } from '~/state/selectors';
import { FONT_SIZE_XS, FONT_SIZE_S, FONT_SIZE_LS } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { Order } from '~/api-services/ReportsService/types';
import OrderDetails from './OrderDetails';

type Props = {
    order: Order;
    isOpen: boolean;
};

export default function OrderRow({ order, isOpen }: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    return (
        <>
            <View
                style={[
                    styles.positionGroupContainer,
                    isOpen ? styles.positionGroupOpen : styles.positionGroupClose,
                    colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                ]}
                key={'instrumentContainer' + order.symbol}
            >
                <View
                    style={[
                        styles.positionItemSide,
                        colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                    ]}
                >
                    <View style={[styles.rotateTextWrapper, styles.buySell]}>
                        <Text fontType="LATO_BLACK" style={order.side === 'buy' ? styles.buyText : styles.sellText}>
                            {order.side.toUpperCase()}
                        </Text>
                    </View>
                </View>
                <View style={styles.instrumentName}>
                    <Text fontType="LATO_BOLD" style={styles.instrumentNameText}>
                        {order.symbolNameTranslated}
                    </Text>
                    {
                        order.investorName ? 
                        <View style={styles.copyTradingSymbolContainer}>
                            <Text fontType="LATO_BLACK" style={styles.copyTradingSymbol}>
                                CT
                            </Text>
                        </View> : null
                    }
                </View>
                <View style={styles.positionGroupVolume}>
                    <View style={styles.volumeBackground}>
                        <Text fontType="LATO_BOLD" style={styles.volumeText}>
                            {order.volumeFormatted}
                        </Text>
                    </View>
                </View>
                <View style={styles.atSeparatorMargin}>
                    <Text style={styles.atSeparatorText}>@</Text>
                </View>
                <View style={styles.positionItemPrice}>
                    <Text style={[styles.positionItemPriceText, styles.positionItemPriceRight]}>
                        {order.orderPriceFormatted}
                    </Text>
                </View>
                <View style={styles.positionItemStatus}>
                    <Text style={[styles.positionStatusText, styles.positionItemPriceRight]}>
                        {order.statusTranslated.length > 31 ? order.statusTranslated.substring(0,31) + '...' : order.statusTranslated}
                    </Text>
                </View>
            </View>
            {isOpen ? <OrderDetails order={order} /> : null}
        </>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        copyTradingSymbolContainer: { marginTop: scaleSize(3), borderRadius: scaleSize(2), width: scaleSize(18), height: scaleSize(13), backgroundColor: theme.ctInfo, justifyContent: 'center', alignItems: 'center'},
        copyTradingSymbol: {fontSize: FONT_SIZE_XS, color: theme.ctTextColor},
        positionItemSide: {
            width: '5%',
            borderRightWidth: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        instrumentName: { flexWrap: 'wrap', width: '22%', paddingLeft: '2%', justifyContent: 'center' },
        positionGroupVolume: { width: '21%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        atSeparatorMargin: { width: '9%', justifyContent: 'center', alignItems: 'center' },
        positionItemPrice: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '19%' },
        positionItemStatus: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '22%' },

        text_at: { color: theme.text_at },
        positionItemPriceText: { fontSize: FONT_SIZE_S, color: theme.text_pendingOrder },
        positionStatusText: { fontSize: FONT_SIZE_LS, color: theme.text_pendingOrder },
        positionItemPriceRight: { paddingRight: scaleSize(7) },
        positionItemPriceLeft: { paddingLeft: scaleSize(7) },

        atSeparatorText: { color: theme.text_at, fontSize: FONT_SIZE_S },
        buyText: {
            fontSize: FONT_SIZE_S,
            color: theme.text_upPrice,
        },
        sellText: {
            fontSize: FONT_SIZE_S,
            color: theme.text_downPrice,
        },
        buySell: { width: scaleSize(50), alignItems: 'center' },
        rotateTextWrapper: { transform: [{ rotate: '-90deg' }] },

        positionGroupContainer: {
            flexDirection: 'row',
            borderWidth: 1,
            width: '100%',
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            height: scaleSize(50),
            backgroundColor: theme.backgroundSecondary,
        },
        positionGroupClose: { borderRadius: scaleSize(5), marginTop: scaleSize(7) },
        positionGroupOpen: {
            borderTopLeftRadius: scaleSize(5),
            borderTopRightRadius: scaleSize(5),
            marginTop: scaleSize(7),
        },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },

        instrumentNameText: { fontSize: FONT_SIZE_S, letterSpacing: 0, color: theme.text_symbol },
        volumeBackground: {
            backgroundColor: theme.background_message,
            borderRadius: scaleSize(2),
            flexDirection: 'row',
            paddingHorizontal: scaleSize(5),
            paddingVertical: scaleSize(3),
        },
        volumeText: { color: theme.white, fontSize: FONT_SIZE_XS, letterSpacing: 0.5 },
    });
