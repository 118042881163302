import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadService } from '~/api-services';
import { ChartDataService } from '~/api-services';
import type { DataSymbol, GetSymbolsResponse, InitialData } from '~/api-services/LoadService/types';
import { thunkGenericApiReducerBuilder } from './utils';
import { loadUserData } from './userDataSlice';
import type { ThunkAPI } from '../store';
import { Quote } from '~/api-services/StreamingService/types';

export interface SymbolsState extends ResponseMetadata {
    data: {
        [key: string]: DataSymbol;
    };
    stretchMap?: {
        [key: string]: number;
    };
}

const initialState: SymbolsState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    data: {},
};

const getNormalizedChartData = (candles: number[]) => {
    let data: number[] = (candles || []).map((d) => d);
    let diff = 0;

    if (data.length) {
        const average = data.reduce((m, p) => m + p) / data.length;
        const min = Math.min(...data);
        const max = Math.max(...data);
        diff = max - min;
        data = data.map((p) => p - average);
    }

    return {
        data,
        fromNumber: diff,
    };
};

export const loadSymbols = createAsyncThunk<GetSymbolsResponse, string[], ThunkAPI>(
    'symbols/load',
    async (params, thunkAPI) => {
        try {
            const { status, data } = await LoadService.getSymbols(params);
            if (status === 200 && data.returnData) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data.errorCode || 'Unauthorized');
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Unauthorized');
        }
    }
);

type SymbolsCandlesData = {
    symbol: string;
    chartData: {
        data: number[];
        fromNumber: number;
    };
};

export const loadSymbolsCandles = createAsyncThunk<SymbolsCandlesData[], string[], ThunkAPI>(
    'symbols/load-candles',
    async (params, thunkAPI) => {
        try {
            const { status, data } = await ChartDataService.getNCandlesBatchOpen(params);
            if (status === 200 && data.returnData.responses) {
                const localSymbols = thunkAPI.getState().symbols.data;
                return Object.keys(data.returnData.responses)
                    .filter((key) => !localSymbols[key].chartData)
                    .map((key) => ({
                        symbol: key,
                        chartData: getNormalizedChartData(data.returnData.responses[key]),
                    }));
            } else {
                return thunkAPI.rejectWithValue(data.errorCode || 'Unauthorized');
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Unauthorized');
        }
    }
);

export const symbolsSlice = createSlice({
    name: 'symbols',
    initialState,
    reducers: {
        clearState: () => initialState,
        updateSymbolPrices: (state, { payload }: PayloadAction<{ [key: string]: Quote }>) => {
            for (const symbol in payload) {
                const ls = state.data[symbol]; // local symbol
                const ps = payload[symbol]; // payload symbol
                if (!ls) {
                    continue;
                }
                const stretch = state.stretchMap?.[symbol];
                if (stretch) {
                    ps.bid -= stretch;
                    ps.ask += stretch;
                }
                ls.askPriceHasIncreased = ps.ask === ls.price.ask ? undefined : ps.ask > ls.price.ask;
                ls.bidPriceHasIncreased = ps.bid === ls.price.bid ? undefined : ps.bid > ls.price.bid;
                ls.price = { ...ps };
            }
            return state;
        },
    },
    extraReducers: (builder) => {
        thunkGenericApiReducerBuilder(builder, loadUserData, (state: SymbolsState, { loadData }: InitialData) => {
            if (loadData.stretchMap) {
                for (const symbol in loadData.symbols) {
                    const stretch = loadData.stretchMap[symbol];
                    if (!stretch) {
                        continue;
                    }
                    loadData.symbols[symbol].price.bid -= stretch;
                    loadData.symbols[symbol].price.ask += stretch;
                }
            }
            state.stretchMap = loadData.stretchMap;
            state.data = { ...state.data, ...loadData.symbols };
        });
        thunkGenericApiReducerBuilder(
            builder,
            loadSymbols,
            (state: SymbolsState, { returnData }: GetSymbolsResponse) => {
                for (const symbol in returnData) {
                    state.data[symbol] = returnData[symbol];
                }
            }
        );
        thunkGenericApiReducerBuilder(
            builder,
            loadSymbolsCandles,
            (state: SymbolsState, payload: SymbolsCandlesData[]) => {
                for (const { symbol, chartData } of payload) {
                    state.data[symbol].chartData = chartData;
                }
            }
        );
    },
});

export const { clearState, updateSymbolPrices } = symbolsSlice.actions;

export default symbolsSlice.reducer;
