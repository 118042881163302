import React from 'react';
import { Provider } from 'react-redux';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { store } from '~/state/store';
import Navigation from '~/components/navigators/NavigationContainer';


const App = () => {
 
    return (
        <SafeAreaProvider>
            <Provider store={store}>
                <Navigation />
            </Provider>
        </SafeAreaProvider>
    );
};

export default App;