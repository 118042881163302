import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, Platform, Keyboard, KeyboardAvoidingView } from 'react-native';
import paths from '~/navigation/paths';
import { linkTo } from '~/navigation';
import { Button, TextInput, ErrorMessage, Text } from '~/components/core';
import { useAppDispatch, useAppSelector, useStyles } from '~/hooks';
import { FONT_SIZE_S, FONT_SIZE_XS, FONT_SIZE_XXXL, FONT_SIZE_XXXXL, spacing } from '~/constants';
import { userAuthSelector } from '~/state/selectors';
import { loginUser } from '~/state/reducers/userAuthSlice';
import { scaleSize, scaleSizeMobile } from '~/constants/scale';
import { Theme } from '~/theme';
import config from '~/config';
import Checkbox from '~/components/core/Checkbox';
import { appSettingsSelector } from '~/state/selectors';
import { saveAppSettings } from '~/state/reducers/appSettingsSlice';
import { SvgUri } from 'react-native-svg';
import { useBrowserLanguage } from '~/hooks/useBrowserLanguage';
import { enableForgotPasswordButton, enableRegistrationButton } from '~/lib/utils';


export default function LoginForm() {
    const dispatch = useAppDispatch();
    useBrowserLanguage();
    const { errorMessage } = useAppSelector(userAuthSelector);
    const { lastRememberMe, lastEmail, lastPassword, colorScheme } = useAppSelector(appSettingsSelector);
    const [email, setEmail] = useState(lastEmail);
    const [rememberMe, setRememberMe] = useState(lastRememberMe);
    const [password, setPassword] = useState(lastPassword);
    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const maxWidth: number = 600;
    const styles = useStyles(stylesGenerator, { maxWidth });


    useEffect(() => {
        setTimeout(() => {
            setRememberMe(lastRememberMe);
            setEmail(lastEmail);
            setPassword(lastPassword);
        }, 1000);
    }, [lastRememberMe, lastEmail, lastPassword]);

    function loginFunction() {
        dispatch(loginUser({ email, password, rememberMe }));
        setTimeout(() => {
            dispatch(
                saveAppSettings({
                    lastRememberMe: rememberMe,
                    lastEmail: rememberMe ? email : '',
                    lastPassword: rememberMe ? password : '',
                })
            );
        }, 3000);
    }

    return (
        <KeyboardAvoidingView style={styles.container} behavior={'height'}>
            <View style={styles.logoContainer}>
                {Platform.OS === 'web' ? (
                    <Image
                        source={{
                            uri:
                                'https://' +
                                config.EXTERNAL_SOURCES_URL +
                                'mobileplatform/images/brand/' +
                                config.BRAND_NAME +
                                '/home_logo_' +
                                colorScheme +
                                '.svg?1'
                        }}
                        style={styles.logoStyle}
                        resizeMode="contain"
                    />
                ) : (
                    <SvgUri
                        width={'60%'}
                        style={styles.logoStyle}
                        uri={
                            'https://' +
                            config.EXTERNAL_SOURCES_URL +
                            'mobileplatform/images/brand/' +
                            config.BRAND_NAME +
                            '/home_logo_' +
                            colorScheme +
                            '.svg?1'
                        }
                    />
                )}
            </View>
            <TextInput
                containerStyle={styles.inputContainer}
                leftIcon="email"
                placeholder="auth-input-enter-email"
                autoCapitalize="none"
                keyboardType="email-address"
                textContentType="emailAddress"
                autoFocus={false}
                value={email}
                onChangeText={(text) => setEmail(text)}
                maxWidth={maxWidth}
                returnKeyType="next"
            />
            <TextInput
                containerStyle={styles.inputContainer}
                leftIcon="lock"
                placeholder="auth-input-enter-password"
                autoCapitalize="none"
                autoCorrect={false}
                secureTextEntry={passwordVisibility}
                textContentType="password"
                rightIcon={passwordVisibility ? 'eye' : 'eye-off'}
                value={password}
                onChangeText={(text) => setPassword(text)}
                onRightIconPress={() => setPasswordVisibility(!passwordVisibility)}
                maxWidth={maxWidth}
                returnKeyType="done"
                onSubmitEditing={() => {
                    if (email && password && email !== '' && password !== '') {
                        Keyboard.dismiss;
                        loginFunction();
                        Keyboard.dismiss;
                    }
                }}
            />
            <View style={styles.switchContainer}>
                <Checkbox checked={rememberMe} setChecked={setRememberMe} />
                <View style={styles.rememberMeTextContainer}>
                    <Text style={styles.rememberMeText} maxWidth={maxWidth}>
                        auth-switch-remember-me
                    </Text>
                </View>
            </View>
            <ErrorMessage text={errorMessage} visible={!!errorMessage} />
            <Button
                disabled={!email || !password}
                onPress={() => loginFunction()}
                title="auth-button-login"
                containerStyle={styles.loginButton}
                maxWidth={maxWidth}
            />
            <View style={styles.footerContainer}>
                {enableForgotPasswordButton() ?
                <Text
                    fontType="LATO_BOLD"
                    style={styles.footerText}
                    onPress={() => linkTo(paths.forgotPassword)}
                    maxWidth={maxWidth}
                >
                    forgoten-password
                </Text> : null}
                {enableRegistrationButton() ?
                    <>
                        <View style={styles.footerSeparator} /><Text
                            fontType="LATO_BOLD"
                            style={styles.footerText}
                            onPress={() => linkTo(paths.registration)}
                            maxWidth={maxWidth}
                        >
                            auth-button-go-to-signup
                        </Text>
                    </> : null}
            </View>
        </KeyboardAvoidingView>
    );
}

const stylesGenerator = (theme: Theme, custom?: { maxWidth: number }) =>
    StyleSheet.create({
        footerText: { color: theme.switch_trackOn, fontSize: FONT_SIZE_S },
        footerSeparator: {
            width: 1,
            height: scaleSize(52, custom?.maxWidth),
            backgroundColor: theme.background_buttonInactive,
        },
        footerContainer: { flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', paddingBottom: scaleSize(10) },
        rememberMeText: { color: theme.tabIconSelected },
        rememberMeTextContainer: { paddingLeft: scaleSize(10, custom?.maxWidth) },
        brandNameText: { letterSpacing: 1, color: theme.text_symbol, fontSize: FONT_SIZE_XXXXL },
        brandNameSlogan: { letterSpacing: 1, fontSize: FONT_SIZE_XS, color: theme.switch_trackOn },
        logoStyle: { width: config.HOME_LOGO_SIZE_IN_PERCENT, height: '100%', maxWidth: scaleSizeMobile(custom?.maxWidth), maxHeight: scaleSizeMobile(custom?.maxWidth) },
        logoContainer: { height: '50%', justifyContent: 'center', alignItems: 'center' },
        container: {
            flex: 1,
            justifyContent: 'space-between',
            alignSelf: 'center',
            maxWidth: custom?.maxWidth,
            paddingHorizontal: scaleSize(12, custom?.maxWidth),
            width: '100%',
            height: '100%',
            maxHeight: '100%',
        },
        title: {
            fontSize: FONT_SIZE_XXXL,
            fontWeight: '600',
            alignSelf: 'center',
            marginBottom: scaleSize(spacing.LARGE, custom?.maxWidth),
        },
        inputContainer: {
            marginBottom: scaleSize(spacing.LARGE, custom?.maxWidth),
        },
        switchContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: scaleSize(spacing.LARGE, custom?.maxWidth),
        },
        loginButton: {
            marginBottom: scaleSize(spacing.LARGE, custom?.maxWidth),
        },
    });
