import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import {
    View,
    StyleSheet,
    KeyboardAvoidingView,
    Pressable,
    Linking,
    Dimensions,
    SafeAreaView,
} from 'react-native';
import { PaymentConfig } from '~/api-services/PaymentConverterService/types';
import { Button, ErrorMessage, Text, TextInput } from '~/components/core';
import { FONT_FAMILY_BOLD, FONT_SIZE_L, FONT_SIZE_S, FONT_SIZE_XS } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useAppSelector, useStyles } from '~/hooks';
import { useAddFunds } from '~/hooks/useAddFunds';
import { useRates } from '~/hooks/useRates';
import i18n from '~/i18n';
import { formatNumber } from '~/lib/format';
import { accountBalanceSelector, accountDetailsSelector, appSettingsSelector, dealerSelector } from '~/state/selectors';
import { Theme } from '~/theme';
import Header from '../More/Header';
import CopyRight from '../WithdrawFunds/CopyRight';
import { Picker } from '@react-native-picker/picker';
import { FontAwesome } from '@expo/vector-icons';
import config from '~/config';
import Checkbox from '~/components/core/Checkbox';
import { useCustomerInfo } from '~/hooks/useCustomerInfo';
import { auStates, caStates, countries, usState } from '~/constants/global';
import OrderConvertService from '~/api-services/OrderConvertService';
import { OrderConvert, OrderPaytikoConvert, TrustPayments } from '~/api-services/OrderConvertService/types';
import { generateBDateDayOptions, generateBDateYearOptions, generateMonthOptions, generateYearOptions, validateCardNumber } from './AddFundsUtil';
import { validateEmail } from '~/lib/utils';
import Loader from '~/components/core/Loader';
import ScrollbarWrapper from '~/components/core/ScrollbarWrapper';
import DesktopScreenHeader from '../Desktop/DesktopScreenHeader';

type Props = {
    provider?: string;
    method?: string;
    currentAmount?: string;
    setActiveRightTab?: Function;
};

export default function AddFundsCard({ provider, method, currentAmount, setActiveRightTab }: Props) {
    const route = useRoute();
    const selectedProvider: string = provider ? provider : (route.params as any).provider;
    const selectedMethod: string = method ? method : (route.params as any).method;
    const { ratesAndCurrencies } = useRates(selectedProvider, selectedMethod);
    const { customerInfo } = useCustomerInfo(selectedProvider, selectedMethod);
    const [cardPaymentConfigData, setCardPaymentConfigData] = useState<PaymentConfig | undefined>(undefined);
    const styles = useStyles(stylesGenerator);
    const [cardNumber, setCardNumber] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [bDateYear, setBDateYear] = useState('');
    const [bDateMonth, setBDateMonth] = useState('');
    const [bDateDay, setBDateDay] = useState('');
    const [gender, setGender] = useState('');
    const currency = useAppSelector(accountBalanceSelector)?.currency || '';
    const [amount, setAmount] = useState(currentAmount ? currentAmount : (route.params as any).amount);
    const { configData } = useAddFunds(amount != '' ? Number(amount) : 0);
    const [securityCode, setSecurityCode] = useState('');
    const [selectedCurrencyIndex, setSelectedCurrencyIndex] = useState(0);
    const [showPersonalData, setShowPersonalData] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
    const [state, setState] = useState('');
    const [dob, setDob] = useState('');

    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [phone, setPhone] = useState('');
    const [confirm, setConfirm] = useState(false);
    const { language, colorScheme } = useAppSelector(appSettingsSelector);
    const [showCardNumberValidationMessage, setShowCardNumberValidationMessage] = useState(false);
    const [showMonthValidationMessage, setShowMonthValidationMessage] = useState(false);
    const [showYearValidationMessage, setShowYearValidationMessage] = useState(false);
    const [showExpirationDateValidationMessage, setShowExpirationDateValidationMessage] = useState(false);
    const [showAmountValidationMessage, setShowAmountValidationMessage] = useState(false);
    const [paymentResponseErrorMessage, setPaymentResponseErrorMessage] = useState('');
    const [showMinAmountValidationMessage, setShowMinAmountValidationMessage] = useState(false);
    const [showMaxAmountValidationMessage, setShowMaxAmountValidationMessage] = useState(false);
    const [showAddressValidationMessage, setShowAddressValidationMessage] = useState(false);
    const [showCityValidationMessage, setShowCityValidationMessage] = useState(false);
    const [showRegionValidationMessage, setShowRegionValidationMessage] = useState(false);
    const [showGenderValidationMessage, setShowGenderValidationMessage] = useState(false);
    const [showPhoneValidationMessage, setShowPhoneValidationMessage] = useState(false);
    const [showCountryValidationMessage, setShowCountryValidationMessage] = useState(false);
    const [showEmailValidationMessage, setShowEmailValidationMessage] = useState(false);
    const [showEmailInvalidValidationMessage, setShowEmailInvalidValidationMessage] = useState(false);
    const [showFirstNameValidationMessage, setShowFirstNameValidationMessage] = useState(false);
    const [showLastNameValidationMessage, setShowLastNameValidationMessage] = useState(false);
    const [showPostalValidationMessage, setShowPostalValidationMessage] = useState(false);
    const [showDOBValidationMessage, setShowDOBValidationMessage] = useState(false);
    const [showInvalidDOBValidationMessage, setShowInvalidDOBValidationMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [protocol, setProtocol] = useState('');
    const accountDetails = useAppSelector(accountDetailsSelector);
    const [confirmEmerchant, setConfirmEmerchant] = useState(false);
    const dealer = useAppSelector(dealerSelector);


    useEffect(() => {
        if (protocol === '') {
            Linking.getInitialURL().then((url) => {
                if (url && url.indexOf('https') === 0) {
                    setProtocol('https');
                } else {
                    setProtocol('http');
                }
            });
        }
    }, [protocol]);

    useEffect(() => {
        if (customerInfo !== undefined) {
            setFirstName(customerInfo.firstName);
            setLastName(customerInfo.lastName);
            setAddress(customerInfo.address1);
            setCity(customerInfo.city);
            setPostalCode(customerInfo.postal);
            setCountry(customerInfo.country);
            setEmail(customerInfo.email);
            setPhone(customerInfo.phone);
            setState(customerInfo.state);
            if (selectedProvider === 'PAYTIKO' || selectedProvider === 'ARENAMONEY') {
                setGender(customerInfo.gender_female ? 'true' : 'false');
                setAddress(customerInfo.street);
                setRegion(customerInfo.region);
                setPostalCode(customerInfo.zipCode);
            }
            if (customerInfo.dob && customerInfo.dob.length === 10) {
                setBDateYear(customerInfo.dob.substring(0, 4));
                setBDateMonth(customerInfo.dob.substring(5, 7));
                setBDateDay(customerInfo.dob.substring(8, 10));
            }


            if (selectedProvider === 'EMERCHANTPAYFORM') {
                if (
                    customerInfo.firstName === '' ||
                    customerInfo.lastName === '' ||
                    customerInfo.address1 === '' ||
                    customerInfo.city === '' ||
                    customerInfo.postal === '' ||
                    customerInfo.country === '' ||
                    customerInfo.email === '' ||
                    customerInfo.phone === '' ||
                    (customerInfo.state === '' &&
                        (customerInfo.country === 'US' ||
                            customerInfo.country === 'CA' ||
                            customerInfo.country === 'AU'))
                ) {
                    setShowPersonalData(true);
                } else {
                    setShowPersonalData(false);
                }
            }
            if (selectedProvider === 'PAYTIKO' || selectedProvider === 'ARENAMONEY') {
                if (
                    customerInfo.firstName === '' ||
                    customerInfo.lastName === '' ||
                    customerInfo.email === '' ||
                    customerInfo.country === '' ||
                    customerInfo.phone === '' ||
                    customerInfo.city === '' ||
                    customerInfo.street === '' ||
                    customerInfo.region === '' ||
                    customerInfo.zipCode === '' ||
                    customerInfo.dob === '' ||
                    customerInfo.gender_female === null ||

                    (customerInfo.state === '' &&
                        (customerInfo.country === 'US' ||
                            customerInfo.country === 'CA' ||
                            customerInfo.country === 'AU'))
                ) {
                    setShowPersonalData(true);
                } else {
                    setShowPersonalData(false);
                }
            }
            if (selectedProvider === 'TRUSTPAYMENTS') {
                if (
                    customerInfo.dob === '' ||
                    customerInfo.firstName === '' ||
                    customerInfo.email === '' ||
                    customerInfo.phone === '' ||
                    customerInfo.lastName === '' ||
                    customerInfo.postal === '' ||
                    customerInfo.country === ''
                ) {
                    setShowPersonalData(true);
                } else {
                    setShowPersonalData(false);
                }
            }

        } else {
            setShowPersonalData(true);
        }
    }, [customerInfo, selectedProvider]);

    useEffect(() => {
        if (cardPaymentConfigData === undefined && configData && configData.card) {
            for (let i = 0; i < configData.card.length; i++) {
                let paymentConfig: PaymentConfig = configData.card[i];
                if (paymentConfig.provider === selectedProvider) {
                    setCardPaymentConfigData(paymentConfig);
                    setLoading(false);
                }
            }
        }
    }, [cardPaymentConfigData, configData, selectedProvider]);

    useEffect(() => {
        if (ratesAndCurrencies) {
            for (let i = 0; i < ratesAndCurrencies.rates.length; i++) {
                if (ratesAndCurrencies.rates[i].currency === currency) {
                    setSelectedCurrencyIndex(i);
                    break;
                }
            }
        }
    }, [ratesAndCurrencies]);

    function onSelchangeCurrency(selectedCurrency: string, currencyIndex: number) {
        setSelectedCurrencyIndex(currencyIndex);
    }

    function onChangeSecurityCode(text: string) {
        var newText = '';
        var numbers = '0123456789';
        if (text.length < 1) {
            setSecurityCode('');
        }
        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        setSecurityCode(newText);
    }

    function validateDob(): boolean {
        if (bDateDay === '' && bDateMonth === '' && bDateYear === '') {
            setShowDOBValidationMessage(true);
            setShowInvalidDOBValidationMessage(false);
            return false;
        }
        else if (bDateDay === '' || bDateMonth === '' || bDateYear === '') {
            setShowDOBValidationMessage(false);
            setShowInvalidDOBValidationMessage(true);
            return false;
        }
        else {
            setShowDOBValidationMessage(false);
            setShowInvalidDOBValidationMessage(false);
        }
        return true;
    }

    function onChangeEmail(text: string) {
        setEmail(text);
        if (text === '') {
            setShowEmailValidationMessage(true);
            setShowEmailInvalidValidationMessage(false);
        } else {
            setShowEmailValidationMessage(false);
            if (!validateEmail(text)) {
                setShowEmailInvalidValidationMessage(true);
            } else {
                setShowEmailInvalidValidationMessage(false);
            }
        }
    }

    function validateAmount(vAmount: string): boolean {
        if (vAmount === '') {
            setShowAmountValidationMessage(true);
            return false;
        } else {
            setShowAmountValidationMessage(false);
            if (cardPaymentConfigData) {
                if (Number(vAmount) < cardPaymentConfigData.minAmount) {
                    setShowMinAmountValidationMessage(true);
                    return false;
                } else {
                    setShowMinAmountValidationMessage(false);
                }
                if (Number(vAmount) > cardPaymentConfigData.maxAmount) {
                    setShowMaxAmountValidationMessage(true);
                    return false;
                } else {
                    setShowMaxAmountValidationMessage(false);
                }
            }
        }
        return true;
    }

    function validateExpirationDate(cMonth: string, cYear: string): boolean {
        if (cMonth !== '' && cYear !== '') {
            let curDate = new Date();
            if (Number(cYear) === curDate.getFullYear()) {
                if (Number(cMonth) <= curDate.getMonth()) {
                    setShowExpirationDateValidationMessage(true);
                    return false;
                } else {
                    setShowExpirationDateValidationMessage(false);
                }
            } else {
                setShowExpirationDateValidationMessage(false);
            }
        } else {
            setShowExpirationDateValidationMessage(false);
        }
        return true;
    }

    function validateForm(): boolean {
        let formValid: boolean = true;
        if (selectedProvider !== 'PAYTIKO' && selectedProvider !== 'ARENAMONEY') {
            if (!validateCardNumber(cardNumber)) {
                formValid = false;
                setShowCardNumberValidationMessage(true);
            } else {
                setShowCardNumberValidationMessage(false);
            }
        }

        if (lastName === '') {
            formValid = false;
            setShowPersonalData(true);
            setShowLastNameValidationMessage(true);
        } else {
            setShowLastNameValidationMessage(false);
        }
        if (country === '') {
            formValid = false;
            setShowPersonalData(true);
            setShowCountryValidationMessage(true);
        } else {
            setShowCountryValidationMessage(false);
        }
        if (postalCode === '' || postalCode === null || postalCode === undefined) {
            formValid = false;
            setShowPersonalData(true);
            setShowPostalValidationMessage(true);
        } else {
            setShowPostalValidationMessage(false);
        }
        if (ratesAndCurrencies === undefined) {
            formValid = false;
        }
        if (!validateAmount(amount)) {
            formValid = false;
        }
        if (selectedProvider === 'TRUSTPAYMENTS') {
            if (!validateDob()) {
                formValid = false;
                setShowPersonalData(true);
            }
        }
        if (selectedProvider === 'PAYTIKO' || selectedProvider === 'ARENAMONEY') {
            if (!validateDob()) {
                formValid = false;
                setShowPersonalData(true);
            }
            if (gender === null || gender === '') {
                formValid = false;
                setShowPersonalData(true);
                setShowGenderValidationMessage(true);
            }
            if (firstName === '') {
                formValid = false;
                setShowPersonalData(true);
                setShowFirstNameValidationMessage(true);
            } else {
                setShowFirstNameValidationMessage(false);
            }
            if (email === '') {
                formValid = false;
                setShowPersonalData(true);
                setShowEmailValidationMessage(true);
                setShowEmailInvalidValidationMessage(false);
            } else {
                setShowEmailValidationMessage(false);
                if (!validateEmail(email)) {
                    formValid = false;
                    setShowPersonalData(true);
                    setShowEmailInvalidValidationMessage(true);
                } else {
                    setShowEmailInvalidValidationMessage(false);
                }
            }
            if (address === '' || address === null || address === undefined) {
                formValid = false;
                setShowPersonalData(true);
                setShowAddressValidationMessage(true);
            } else {
                setShowAddressValidationMessage(false);
            }
            if (city === '') {
                formValid = false;
                setShowPersonalData(true);
                setShowCityValidationMessage(true);
            } else {
                setShowCityValidationMessage(false);
            }
            if (phone === '') {
                formValid = false;
                setShowPersonalData(true);
                setShowPhoneValidationMessage(true);
            } else {
                setShowPhoneValidationMessage(false);
            }
            if (region === '') {
                formValid = false;
                setShowPersonalData(true);
                setShowRegionValidationMessage(true);
            } else {
                setShowRegionValidationMessage(false);
            }
        }
        if (selectedProvider === 'EMERCHANTPAYFORM') {
            if (month === '') {
                formValid = false;
                setShowMonthValidationMessage(true);
            } else {
                setShowMonthValidationMessage(false);
            }
            if (year === '') {
                setShowYearValidationMessage(true);
                formValid = false;
            } else {
                setShowYearValidationMessage(false);
            }
            if (!validateExpirationDate(month, year)) {
                formValid = false;
            }

            if (firstName === '') {
                formValid = false;
                setShowPersonalData(true);
                setShowFirstNameValidationMessage(true);
            } else {
                setShowFirstNameValidationMessage(false);
            }
            if (email === '') {
                formValid = false;
                setShowPersonalData(true);
                setShowEmailValidationMessage(true);
                setShowEmailInvalidValidationMessage(false);
            } else {
                setShowEmailValidationMessage(false);
                if (!validateEmail(email)) {
                    formValid = false;
                    setShowPersonalData(true);
                    setShowEmailInvalidValidationMessage(true);
                } else {
                    setShowEmailInvalidValidationMessage(false);
                }
            }
            if (address === '') {
                formValid = false;
                setShowPersonalData(true);
                setShowAddressValidationMessage(true);
            } else {
                setShowAddressValidationMessage(false);
            }
            if (city === '') {
                formValid = false;
                setShowPersonalData(true);
                setShowCityValidationMessage(true);
            } else {
                setShowCityValidationMessage(false);
            }
            if (phone === '') {
                formValid = false;
                setShowPersonalData(true);
                setShowPhoneValidationMessage(true);
            } else {
                setShowPhoneValidationMessage(false);
            }
        }

        return formValid;
    }

    async function payDeposit() {
        setPaymentResponseErrorMessage('');
        if (validateForm()) {
            setLoading(true);
            try {
                if (selectedProvider === 'EMERCHANTPAYFORM') {
                    const { status, data } = await OrderConvertService.createOrderEmerchantPay({
                        cardHolder: firstName + ' ' + lastName,
                        cardNumber: cardNumber.split(' ').join(''),
                        challengeWindowSize: '600x400',
                        colorDepth: 24,
                        convertId: ratesAndCurrencies ? ratesAndCurrencies.convertId : '',
                        customer: {
                            address1: address,
                            city: city,
                            country: country,
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            phone: phone,
                            postal: postalCode,
                            state: state,
                        },
                        cvv: securityCode,
                        depositAmount: Number(amount),
                        depositCurrency: currency,
                        expirationMonth: month,
                        expirationYear: year,
                        flag3dsv21: true,
                        generateRedirectUID: false,
                        language: 'en-EN',
                        method: selectedMethod,
                        moto: false,
                        payAmount: ratesAndCurrencies
                            ? ratesAndCurrencies.rates[selectedCurrencyIndex].rate * amount
                            : 0,
                        payCurrency: ratesAndCurrencies ? ratesAndCurrencies.rates[selectedCurrencyIndex].currency : '',
                        returnErrorUrl:
                            `${config.CARD_PAYMENT_ERROR_URL}` + '?language=' + language + '&theme=' + colorScheme,
                        returnSuccessUrl:
                            `${config.CARD_PAYMENT_SUCCESS_URL}` + '?language=' + language + '&theme=' + colorScheme,
                        screenHeight: Math.round(Dimensions.get('window').height),
                        screenWidth: Math.round(Dimensions.get('window').width),
                        timeZoneOffset: new Date().getTimezoneOffset().toString(),
                    });
                    setLoading(false);
                    if (status === 200 && data.status) {
                        let orderConvert: OrderConvert | undefined = data.returnData;
                        if (orderConvert) {
                            if (orderConvert.status === 'pending_async') {
                                if (orderConvert.redirectUrl !== "") {
                                    Linking.openURL(orderConvert.redirectUrl);
                                } else if (orderConvert.threedsMethodUrl !== "") {
                                    let url = 'https://' + config.EXTERNAL_SOURCES_URL + "mobileplatform/emerchantpayform-3ds-iframe/index.html?threeds_method_url=" + encodeURIComponent(orderConvert.threedsMethodUrl) + "&unique_id=" + encodeURIComponent(orderConvert.uniqueId) + "&signature=" + encodeURIComponent(orderConvert.signature);
                                    Linking.openURL(url);
                                }
                            } else if (orderConvert.status === 'approved') {
                                Linking.openURL(
                                    `${config.CARD_PAYMENT_SUCCESS_URL}` +
                                    '?language=' +
                                    language +
                                    '&theme=' +
                                    colorScheme
                                );
                            } else {
                                if (data.returnData) {
                                    if (data.returnData?.message) {
                                        setPaymentResponseErrorMessage(data.returnData?.message);
                                    } else if (data.returnData?.technicalMessage) {
                                        setPaymentResponseErrorMessage(data.returnData?.technicalMessage);
                                    }
                                }
                            }
                        }
                    } else {
                        if (data.returnData) {
                            setPaymentResponseErrorMessage(data.returnData?.message);
                        } else {
                            setPaymentResponseErrorMessage('Error');
                        }
                    }
                }
                if (selectedProvider === 'PAYTIKO') {
                    const { status, data } = await OrderConvertService.createOrderPaytiko({
                        convertId: ratesAndCurrencies ? ratesAndCurrencies.convertId : '',
                        customer: {
                            city: city,
                            country: country,
                            dob: dob,
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            gender_female: gender === 'true' ? true : false,
                            phone: phone,
                            region: region,
                            street: address,
                            zipCode: postalCode,
                            state: state
                        },
                        depositAmount: Number(amount),
                        depositCurrency: currency,
                        payAmount: ratesAndCurrencies
                            ? ratesAndCurrencies.rates[selectedCurrencyIndex].rate * amount
                            : 0,
                        payCurrency: ratesAndCurrencies ? ratesAndCurrencies.rates[selectedCurrencyIndex].currency : '',
                    });
                    setLoading(false);
                    if (status === 200 && data.status) {
                        let orderConvert: OrderPaytikoConvert | undefined = data.returnData;
                        if (orderConvert) {
                            Linking.openURL('https://' + config.EXTERNAL_SOURCES_URL + 'mobileplatform/Paytiko/index.html?iframeUrl=' +
                                orderConvert.config.iframeUrl.replace('?', '&') +
                                '&amount=' + orderConvert.config.amount + '&orderId=' + orderConvert.config.orderId);
                        }
                    } else {
                        setPaymentResponseErrorMessage('paytiko-payments-error');
                    }
                }
                if (selectedProvider === 'ARENAMONEY') {
                    const { status, data } = await OrderConvertService.createOrderArenaMoney({
                        convertId: ratesAndCurrencies ? ratesAndCurrencies.convertId : '',
                        customer: {
                            city: city,
                            country: country,
                            dob: dob,
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            gender_female: gender === 'true' ? true : false,
                            phone: phone,
                            region: region,
                            street: address,
                            zipCode: postalCode,
                            state: state
                        },
                        depositAmount: Number(amount),
                        depositCurrency: currency,
                        payAmount: ratesAndCurrencies
                            ? ratesAndCurrencies.rates[selectedCurrencyIndex].rate * amount
                            : 0,
                        payCurrency: ratesAndCurrencies ? ratesAndCurrencies.rates[selectedCurrencyIndex].currency : '',
                    });
                    setLoading(false);
                    if (status === 200 && data.status) {
                        let orderConvert: OrderPaytikoConvert | undefined = data.returnData;
                        if (orderConvert) {
                            //OLD Address
                            //'https://' + config.EXTERNAL_SOURCES_URL + 'mobileplatform/Paytiko/index.html?iframeUrl=' +
                            Linking.openURL(orderConvert.config.iframeUrl);
                        }
                    } else {
                        setPaymentResponseErrorMessage('paytiko-payments-error');
                    }
                }
                if (selectedProvider === 'TRUSTPAYMENTS') {
                    const { status, data } = await OrderConvertService.createOrderTrustPayments({
                        cardNumber: cardNumber.split(' ').join(''),
                        convertId: ratesAndCurrencies ? ratesAndCurrencies.convertId : '',
                        customer: {
                            country: country,
                            firstName: firstName,
                            lastName: lastName,
                            postal: postalCode,
                            dob: dob,
                            phone: phone,
                            email: email
                        },
                        depositAmount: Number(amount),
                        depositCurrency: currency,
                        payAmount: ratesAndCurrencies
                            ? ratesAndCurrencies.rates[selectedCurrencyIndex].rate * amount
                            : 0,
                        payCurrency: ratesAndCurrencies ? ratesAndCurrencies.rates[selectedCurrencyIndex].currency : '',
                    });
                    setLoading(false);
                    if (status === 200 && data.status) {
                        let orderConvert: TrustPayments | undefined = data.returnData;
                        if (orderConvert) {
                            Linking.openURL('https://' +
                                config.EXTERNAL_SOURCES_URL +
                                'mobileplatform/TrustPayments/index.html?jwt=' +
                                orderConvert.jwt +
                                '&livestatus=' +
                                config.TRUST_PAYMENTS_LIVE_STATUS)
                        }
                    } else {
                        setPaymentResponseErrorMessage('trust-payments-error');
                    }
                }
            } catch (error) {
                setPaymentResponseErrorMessage((error as Error).message || 'Error');
                setLoading(false);
            }
        } else {
            setPaymentResponseErrorMessage('please-fill-all-required-fields');
        }
    }

    function enableDepositButton() {
        if (!confirm) {
            return false;
        }
        if(accountDetails.email === 'test@emerchantpay.bg' && !confirmEmerchant) {
            return false;
        } 
        return true;
    }

    function onChangePhone(text: string) {
        setPhone(text);
        if (text === '') {
            setShowPhoneValidationMessage(true);
        } else {
            setShowPhoneValidationMessage(false);
        }
    }

    function onChangePostalCode(text: string) {
        setPostalCode(text);
        if (text === '') {
            setShowPostalValidationMessage(true);
        } else {
            setShowPostalValidationMessage(false);
        }
    }

    function onChangeCity(text: string) {
        setCity(text);
        if (text === '') {
            setShowCityValidationMessage(true);
        } else {
            setShowCityValidationMessage(false);
        }
    }

    function onChangeRegion(text: string) {
        setRegion(text);
        if (text === '') {
            setShowRegionValidationMessage(true);
        } else {
            setShowRegionValidationMessage(false);
        }
    }

    function onChangeGender(value: string) {
        setGender(value);
        if (value === null || value === '') {
            setShowGenderValidationMessage(true);
        } else {
            setShowGenderValidationMessage(false);
        }
    }


    function onChangeCountry(text: string) {
        setCountry(text);
        if (text === '') {
            setShowCountryValidationMessage(true);
        } else {
            setShowCountryValidationMessage(false);
        }
    }

    function onChangeFirstName(text: string) {
        setFirstName(text);
        if (text === '') {
            setShowFirstNameValidationMessage(true);
        } else {
            setShowFirstNameValidationMessage(false);
        }
    }

    function onChangeLastName(text: string) {
        setLastName(text);
        if (text === '') {
            setShowLastNameValidationMessage(true);
        } else {
            setShowLastNameValidationMessage(false);
        }
    }

    function onChangeAddress(text: string) {
        setAddress(text);
        if (text === '') {
            setShowAddressValidationMessage(true);
        } else {
            setShowAddressValidationMessage(false);
        }
    }

    function onChangedCardNumber(text: string) {
        var newText = '';
        var numbers = '0123456789.';
        if (text.length < 1) {
            setCardNumber('');
        }
        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }
        newText = changeCardNumberMask(newText);
        if (!validateCardNumber(newText)) {
            setShowCardNumberValidationMessage(true);
        } else {
            setShowCardNumberValidationMessage(false);
        }
    }

    function changeCardNumberMask(cardNumberText: string): string {
        cardNumberText = cardNumberText.split(' ').join('');
        var newText = '';
        if (cardNumberText.length < 5) {
            newText = cardNumberText;
        }
        if (cardNumberText.length > 4) {
            newText = cardNumberText.substring(0, 4) + ' ' + cardNumberText.substring(4, cardNumberText.length);
        }
        if (cardNumberText.length > 8) {
            newText = cardNumberText.substring(0, 4) + ' ' + cardNumberText.substring(4, 8) + ' ' + cardNumberText.substring(8, cardNumberText.length);
        }
        if (cardNumberText.length > 12) {
            newText = cardNumberText.substring(0, 4) + ' ' + cardNumberText.substring(4, 8) + ' ' + cardNumberText.substring(8, 12) + ' ' + cardNumberText.substring(12, cardNumberText.length);
        }
        if (cardNumberText.length > 16) {
            newText = cardNumberText.substring(0, 4) + ' ' + cardNumberText.substring(4, 8) + ' ' + cardNumberText.substring(8, 12) + ' ' + cardNumberText.substring(12, 16) + ' ' + cardNumberText.substring(16, cardNumberText.length);
        }
        if (cardNumberText.length > 20) {
            newText = cardNumberText.substring(0, 4) + ' ' + cardNumberText.substring(4, 8) + ' ' + cardNumberText.substring(8, 12) + ' ' + cardNumberText.substring(12, 16) + ' ' + cardNumberText.substring(16, 20) + ' ' + cardNumberText.substring(20, cardNumberText.length);
        }
        setCardNumber(newText);
        return newText;
    }

    function checkConfirm() {
        setConfirm(!confirm);
    }
    function usePersonalData() {
        setShowPersonalData(!showPersonalData);
    }

    function onChangeBDateYear(text: string) {
        setBDateYear(text);
        let bdate = text + '-' + bDateMonth + '-' + bDateDay;
        setDob(bdate);
    }
    function onChangeBDateMonth(text: string) {
        setBDateMonth(text);
        let bdate = bDateYear + '-' + text + '-' + bDateDay;
        setDob(bdate);
    }
    function onChangeBDateDay(text: string) {
        setBDateDay(text);
        let bdate = bDateYear + '-' + bDateMonth + '-' + text;
        setDob(bdate);
    }

    function onChangeMonth(text: string) {
        setMonth(text);
        if (text === '') {
            setShowMonthValidationMessage(true);
        } else {
            setShowMonthValidationMessage(false);
        }
        validateExpirationDate(text, year);
    }

    function onChangeYear(text: string) {
        setYear(text);
        if (text === '') {
            setShowYearValidationMessage(true);
        } else {
            setShowYearValidationMessage(false);
        }
        validateExpirationDate(month, text);
    }

    return (
        <SafeAreaView style={styles.safeArea}>
            <Loader loading={loading} />
            {!isDesktop() ? <Header /> : null}
            <DesktopScreenHeader
                goToScreenName="add-money-category"
                title="add-money"
                setActiveRightTab={setActiveRightTab}
            />
            <View style={styles.titleContainer}>
                <Text fontType="LATO_BOLD" style={styles.addFundsTitleText}>
                    {'card-payment-title'}
                </Text>
            </View>
            <ScrollbarWrapper style={styles.scrollView}>
                <View style={styles.container}>
                    <View style={styles.viewContainer}>
                        <KeyboardAvoidingView behavior={'padding'} style={styles.scrollView}>
                            <View style={styles.content}>
                                {cardPaymentConfigData ? (
                                    <View>
                                        {selectedProvider !== 'PAYTIKO' && selectedProvider !== 'ARENAMONEY' ?
                                            <>
                                                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                    {'card-number'}
                                                </Text>
                                                <TextInput
                                                    autoCapitalize="none"
                                                    autoCorrect={false}
                                                    value={cardNumber}
                                                    placeholder="XXXX XXXX XXXX XXXX"
                                                    onChangeText={(text) => onChangedCardNumber(text)}
                                                    keyboardType="numeric"
                                                    maxLength={24}
                                                    placeholderTextColor={'gray'}
                                                    returnKeyType="next"
                                                    containerStyle={styles.inputContainer}
                                                    style={[
                                                        styles.textInputWidth,
                                                        cardNumber && cardNumber !== '' ? styles.textInputText : null,
                                                    ]}
                                                />
                                                <ErrorMessage
                                                    text={'invalid-card-number'}
                                                    visible={showCardNumberValidationMessage}
                                                />
                                            </> : null}
                                        {selectedProvider === 'EMERCHANTPAYFORM' ? (
                                            <>
                                                <View style={styles.expDateAndSCS}>
                                                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                        {'expiration-date'}
                                                    </Text>
                                                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                        {'card-security-code'}
                                                    </Text>
                                                </View>
                                                <View style={styles.expDateAndSCS}>
                                                    <View
                                                        style={[
                                                            styles.expDateField,
                                                            styles.expDateFieldPaddingRight,
                                                        ]}
                                                    >
                                                        <Picker
                                                            style={styles.pickerStyle}
                                                            itemStyle={styles.pickerRow}
                                                            selectedValue={month}
                                                            onValueChange={(itemValue) => onChangeMonth(itemValue)}
                                                        >
                                                            <Picker.Item label={i18n.t('month')} value={''} />
                                                            {generateMonthOptions().map((item, index) => (
                                                                <Picker.Item
                                                                    label={item.label}
                                                                    value={item.value}
                                                                    key={'MonthPicker' + index}
                                                                />
                                                            ))}
                                                        </Picker>
                                                    </View>
                                                    <View style={styles.expDateField}>
                                                        <Picker
                                                            style={styles.pickerStyle}
                                                            itemStyle={styles.pickerRow}
                                                            selectedValue={year}
                                                            onValueChange={(itemValue) => onChangeYear(itemValue)}
                                                        >
                                                            <Picker.Item label={i18n.t('year')} value={''} />
                                                            {generateYearOptions().map((item, index) => (
                                                                <Picker.Item
                                                                    label={item.label}
                                                                    value={item.value}
                                                                    key={'YearPicker' + index}
                                                                />
                                                            ))}
                                                        </Picker>
                                                    </View>
                                                    <View
                                                        style={[
                                                            styles.expDateField,
                                                            styles.expDateFieldPaddingLeft,
                                                        ]}
                                                    >
                                                        <View style={styles.row}>
                                                            <View style={styles.securityCodeInputContainer}>
                                                                <TextInput
                                                                    autoCapitalize="none"
                                                                    autoCorrect={false}
                                                                    value={securityCode}
                                                                    onChangeText={(text) =>
                                                                        onChangeSecurityCode(text)
                                                                    }
                                                                    keyboardType="numeric"
                                                                    maxLength={3}
                                                                    placeholderTextColor={'gray'}
                                                                    returnKeyType="next"
                                                                    rightIcon="lock"
                                                                    containerStyle={styles.inputContainer}
                                                                />
                                                            </View>
                                                            <Pressable
                                                                style={styles.securityCodeRightContainer}
                                                                onPress={() =>
                                                                    Linking.openURL(`${config.CVV_LINK}`)
                                                                }
                                                            >
                                                                <View style={styles.securityIconCircleContainer}>
                                                                    <FontAwesome
                                                                        name={'question'}
                                                                        size={scaleSize(8)}
                                                                        style={styles.questionIcon}
                                                                    />
                                                                </View>
                                                            </Pressable>
                                                        </View>
                                                    </View>
                                                </View>
                                                <ErrorMessage
                                                    text={'please-select-month'}
                                                    visible={showMonthValidationMessage}
                                                />
                                                <ErrorMessage
                                                    text={'please-select-year'}
                                                    visible={showYearValidationMessage}
                                                />
                                                <ErrorMessage
                                                    text={'invalid-expiration-date'}
                                                    visible={showExpirationDateValidationMessage}
                                                />
                                            </>
                                        ) : null}
                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                            {i18n.t('deposit-amount') + ' ( ' + currency + ' )'}
                                        </Text>
                                        <View style={styles.depositAmountContainer}>
                                            <View style={styles.depositAmountFieldContainer}>
                                                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                    {formatNumber(amount, 2) + ' ' + currency}
                                                </Text>
                                            </View>
                                            <View style={styles.depositAmountMinMaxContainer}>
                                                <Text fontType="LATO_REGULAR" style={styles.minMaxText}>
                                                    {i18n.t('deposit-min') +
                                                        ' ' +
                                                        formatNumber(cardPaymentConfigData.minAmount, 2) +
                                                        ' ' +
                                                        currency}
                                                </Text>
                                                <Text fontType="LATO_REGULAR" style={styles.minMaxText}>
                                                    {i18n.t('deposit-max') +
                                                        ' ' +
                                                        formatNumber(cardPaymentConfigData.maxAmount, 2) +
                                                        ' ' +
                                                        currency}
                                                </Text>
                                            </View>
                                        </View>
                                        <ErrorMessage
                                            text={'missing-amount-error'}
                                            visible={showAmountValidationMessage}
                                        />
                                        <ErrorMessage
                                            text={'amount-max-error'}
                                            visible={showMaxAmountValidationMessage}
                                        />
                                        <ErrorMessage
                                            text={'amount-min-error'}
                                            visible={showMinAmountValidationMessage}
                                        />
                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                            {'currency-convertor'}
                                        </Text>
                                        {ratesAndCurrencies ? (
                                            <View style={styles.currencyConvertorContainer}>
                                                <View style={styles.currencyContainer}>
                                                    <Picker
                                                        style={styles.pickerStyle}
                                                        itemStyle={styles.pickerRow}
                                                        selectedValue={
                                                            ratesAndCurrencies.rates[selectedCurrencyIndex].currency
                                                        }
                                                        onValueChange={(itemValue, itemIndex) =>
                                                            onSelchangeCurrency(itemValue, itemIndex)
                                                        }
                                                    >
                                                        {ratesAndCurrencies.rates.map((rate, index) => (
                                                            <Picker.Item
                                                                label={rate.currency}
                                                                value={rate.currency}
                                                                key={'CurrencyPicker' + index}
                                                            />
                                                        ))}
                                                    </Picker>
                                                </View>
                                                <View style={styles.currencyConvertorValueContainer}>
                                                    <Text fontType="LATO_REGULAR" style={styles.minMaxText}>
                                                        {formatNumber(
                                                            ratesAndCurrencies.rates[selectedCurrencyIndex].rate *
                                                            amount,
                                                            2
                                                        )}
                                                    </Text>
                                                    {currency !==
                                                        ratesAndCurrencies.rates[selectedCurrencyIndex].currency ? (
                                                        <Text fontType="LATO_REGULAR" style={styles.minMaxText}>
                                                            {'1 ' +
                                                                currency +
                                                                ' = ' +
                                                                ratesAndCurrencies.rates[selectedCurrencyIndex]
                                                                    .rate +
                                                                ' ' +
                                                                ratesAndCurrencies.rates[selectedCurrencyIndex]
                                                                    .currency}
                                                        </Text>
                                                    ) : null}
                                                </View>
                                            </View>
                                        ) : null}
                                        <View style={[styles.row, styles.checkboxRow]}>
                                            <View style={styles.checkboxContainer}>
                                                <Checkbox
                                                    checked={!showPersonalData}
                                                    setChecked={usePersonalData}
                                                />
                                            </View>
                                            <View style={styles.usePersonalDataTextContainer}>
                                                <Text
                                                    fontType="LATO_REGULAR"
                                                    style={styles.useAddressText}
                                                    numberOfLines={2}
                                                >
                                                    {'use-personal-information-from-registration'}
                                                </Text>
                                            </View>
                                            <Pressable
                                                style={styles.useAddressIconContainer}
                                                onPress={() => Linking.openURL(`${config.USE_ADDRESS_LINK}`)}
                                            >
                                                <FontAwesome
                                                    name={'question'}
                                                    size={scaleSize(8)}
                                                    style={styles.useAddressIcon}
                                                />
                                            </Pressable>
                                        </View>
                                        {showPersonalData ? (
                                            <View>
                                                {selectedProvider === 'EMERCHANTPAYFORM' ? (
                                                    <>
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'first-name'}
                                                        </Text>
                                                        <TextInput
                                                            autoCapitalize="none"
                                                            autoCorrect={false}
                                                            value={firstName}
                                                            onChangeText={(text) => onChangeFirstName(text)}
                                                            keyboardType="default"
                                                            placeholderTextColor={'gray'}
                                                            returnKeyType="next"
                                                            containerStyle={styles.inputContainer}
                                                        />
                                                        <ErrorMessage
                                                            text={'please-enter-first-name'}
                                                            visible={showFirstNameValidationMessage}
                                                        />
                                                    </>
                                                ) : null}
                                                {selectedProvider === 'TRUSTPAYMENTS' || selectedProvider === 'PAYTIKO' || selectedProvider === 'ARENAMONEY' ? (
                                                    <>
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'date-of-birth'}
                                                        </Text>
                                                        <View style={styles.bdateContainer}>
                                                            <View style={styles.bdatePickerContainer}>
                                                                <Picker
                                                                    style={styles.pickerStyle}
                                                                    itemStyle={styles.pickerRow}
                                                                    selectedValue={bDateYear}
                                                                    onValueChange={(itemValue) => onChangeBDateYear(itemValue)}
                                                                >
                                                                    <Picker.Item label={i18n.t('year')} value={''} />
                                                                    {generateBDateYearOptions().map((item, index) => (
                                                                        <Picker.Item
                                                                            label={item.label}
                                                                            value={item.value}
                                                                            key={'BDateYearPicker' + index}
                                                                        />
                                                                    ))}
                                                                </Picker>
                                                            </View>
                                                            <View style={styles.bdatePickerContainer}>
                                                                <Picker
                                                                    style={styles.pickerStyle}
                                                                    itemStyle={styles.pickerRow}
                                                                    selectedValue={bDateMonth}
                                                                    onValueChange={(itemValue) => onChangeBDateMonth(itemValue)}
                                                                >
                                                                    <Picker.Item label={i18n.t('month')} value={''} />
                                                                    {generateMonthOptions().map((item, index) => (
                                                                        <Picker.Item
                                                                            label={item.label}
                                                                            value={item.value}
                                                                            key={'BDateMonthPicker' + index}
                                                                        />
                                                                    ))}
                                                                </Picker>
                                                            </View>
                                                            <View style={styles.bdatePickerContainer}>
                                                                <Picker
                                                                    style={styles.pickerStyle}
                                                                    itemStyle={styles.pickerRow}
                                                                    selectedValue={bDateDay}
                                                                    onValueChange={(itemValue) => onChangeBDateDay(itemValue)}
                                                                >
                                                                    <Picker.Item label={i18n.t('day')} value={''} />
                                                                    {generateBDateDayOptions().map((item, index) => (
                                                                        <Picker.Item
                                                                            label={item.label}
                                                                            value={item.value}
                                                                            key={'BDateDayPicker' + index}
                                                                        />
                                                                    ))}
                                                                </Picker>
                                                            </View>
                                                        </View>
                                                        <ErrorMessage
                                                            text={'please-enter-date-ofbirth'}
                                                            visible={showDOBValidationMessage}
                                                        />
                                                        <ErrorMessage
                                                            text={'invalid-birth-date'}
                                                            visible={showInvalidDOBValidationMessage}
                                                        />
                                                    </>
                                                ) : null}
                                                {selectedProvider === 'PAYTIKO' || selectedProvider === 'ARENAMONEY' ? (
                                                    <>
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'gender'}
                                                        </Text>
                                                        <View style={styles.genderPickerContainer}>
                                                            <Picker
                                                                style={styles.pickerStyle}
                                                                itemStyle={styles.pickerRow}
                                                                selectedValue={gender}
                                                                onValueChange={(itemValue) => onChangeGender(itemValue)}
                                                            >
                                                                <Picker.Item label={i18n.t('choose-gender')} value={''} />
                                                                <Picker.Item
                                                                    label={i18n.t('gender-woman')}
                                                                    value={'true'}
                                                                />
                                                                <Picker.Item
                                                                    label={i18n.t('gender-man')}
                                                                    value={'false'}
                                                                />
                                                            </Picker>
                                                        </View>
                                                        <ErrorMessage
                                                            text={'please-select-gender'}
                                                            visible={showGenderValidationMessage}
                                                        />
                                                    </>
                                                ) : null}
                                                {selectedProvider === 'PAYTIKO' || selectedProvider === 'TRUSTPAYMENTS' || selectedProvider === 'ARENAMONEY' ? (
                                                    <>
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'first-name'}
                                                        </Text>
                                                        <TextInput
                                                            autoCapitalize="none"
                                                            autoCorrect={false}
                                                            value={firstName}
                                                            onChangeText={(text) => onChangeFirstName(text)}
                                                            keyboardType="default"
                                                            placeholderTextColor={'gray'}
                                                            returnKeyType="next"
                                                            containerStyle={styles.inputContainer}
                                                        />
                                                        <ErrorMessage
                                                            text={'please-enter-first-name'}
                                                            visible={showFirstNameValidationMessage}
                                                        />
                                                    </>
                                                ) : null}
                                                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                    {'last-name'}
                                                </Text>
                                                <TextInput
                                                    autoCapitalize="none"
                                                    autoCorrect={false}
                                                    value={lastName}
                                                    onChangeText={(text) => onChangeLastName(text)}
                                                    keyboardType="default"
                                                    placeholderTextColor={'gray'}
                                                    returnKeyType="next"
                                                    containerStyle={styles.inputContainer}
                                                />
                                                <ErrorMessage
                                                    text={'please-enter-last-name'}
                                                    visible={showLastNameValidationMessage}
                                                />
                                                {selectedProvider === 'EMERCHANTPAYFORM' || selectedProvider === 'TRUSTPAYMENTS' || selectedProvider === 'PAYTIKO' || selectedProvider === 'ARENAMONEY' ? (
                                                    <>
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'email'}
                                                        </Text>
                                                        <TextInput
                                                            autoCapitalize="none"
                                                            autoCorrect={false}
                                                            value={email}
                                                            onChangeText={(text) => onChangeEmail(text)}
                                                            keyboardType="default"
                                                            placeholderTextColor={'gray'}
                                                            returnKeyType="next"
                                                            containerStyle={styles.inputContainer}
                                                        />
                                                        <ErrorMessage
                                                            text={'please-enter-email'}
                                                            visible={showEmailValidationMessage}
                                                        />
                                                        <ErrorMessage
                                                            text={'please-enter-valid-email'}
                                                            visible={showEmailInvalidValidationMessage}
                                                        />
                                                    </>) : null}
                                                {selectedProvider === 'EMERCHANTPAYFORM' || selectedProvider === 'PAYTIKO' || selectedProvider === 'ARENAMONEY' ? (
                                                    <>
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'address'}
                                                        </Text>
                                                        <TextInput
                                                            autoCapitalize="none"
                                                            autoCorrect={false}
                                                            value={address}
                                                            onChangeText={(text) => onChangeAddress(text)}
                                                            keyboardType="default"
                                                            placeholderTextColor={'gray'}
                                                            returnKeyType="next"
                                                            containerStyle={styles.inputContainer}
                                                        />
                                                        <ErrorMessage
                                                            text={'please-enter-address'}
                                                            visible={showAddressValidationMessage}
                                                        />
                                                    </>
                                                ) : null}
                                                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                    {'country'}
                                                </Text>
                                                <Picker
                                                    style={styles.pickerStyle}
                                                    itemStyle={styles.pickerRow}
                                                    selectedValue={country}
                                                    onValueChange={(itemValue) => onChangeCountry(itemValue)}
                                                >
                                                    <Picker.Item
                                                        label={i18n.t('please-select-country')}
                                                        value={''}
                                                    />
                                                    {countries
                                                        .filter(
                                                            (item) =>
                                                                cardPaymentConfigData?.availableCountries[0] ===
                                                                'ALL' ||
                                                                cardPaymentConfigData?.availableCountries.find(
                                                                    (s) => s === item.value
                                                                ) !== undefined
                                                        )
                                                        .map((countryMap, index) => (
                                                            <Picker.Item
                                                                label={countryMap.label}
                                                                value={countryMap.value}
                                                                key={'CountryPicker' + index}
                                                            />
                                                        ))}
                                                </Picker>
                                                <ErrorMessage
                                                    text={'please-select-country'}
                                                    visible={showCountryValidationMessage}
                                                />
                                                {country === 'US' || country === 'CA' || country === 'AU' ? (
                                                    <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                        {'state'}
                                                    </Text>
                                                ) : null}
                                                {country === 'US' ? (
                                                    <Picker
                                                        style={styles.pickerStyle}
                                                        itemStyle={styles.pickerRow}
                                                        selectedValue={state}
                                                        onValueChange={(itemValue) => setState(itemValue)}
                                                    >
                                                        {usState.map((stateMap, index) => (
                                                            <Picker.Item
                                                                label={stateMap.label}
                                                                value={stateMap.value}
                                                                key={'CountryPicker' + index}
                                                            />
                                                        ))}
                                                    </Picker>
                                                ) : null}
                                                {country === 'CA' ? (
                                                    <Picker
                                                        style={styles.pickerStyle}
                                                        itemStyle={styles.pickerRow}
                                                        selectedValue={state}
                                                        onValueChange={(itemValue) => setState(itemValue)}
                                                    >
                                                        {caStates.map((stateMap, index) => (
                                                            <Picker.Item
                                                                label={stateMap.label}
                                                                value={stateMap.value}
                                                                key={'CountryPicker' + index}
                                                            />
                                                        ))}
                                                    </Picker>
                                                ) : null}
                                                {country === 'AU' ? (
                                                    <Picker
                                                        style={styles.pickerStyle}
                                                        itemStyle={styles.pickerRow}
                                                        selectedValue={state}
                                                        onValueChange={(itemValue) => setState(itemValue)}
                                                    >
                                                        {auStates.map((stateMap, index) => (
                                                            <Picker.Item
                                                                label={stateMap.label}
                                                                value={stateMap.value}
                                                                key={'CountryPicker' + index}
                                                            />
                                                        ))}
                                                    </Picker>
                                                ) : null}
                                                {selectedProvider === 'PAYTIKO' || selectedProvider === 'ARENAMONEY' ? (
                                                    <>
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'region'}
                                                        </Text>
                                                        <TextInput
                                                            autoCapitalize="none"
                                                            autoCorrect={false}
                                                            value={region}
                                                            onChangeText={(text) => onChangeRegion(text)}
                                                            keyboardType="default"
                                                            placeholderTextColor={'gray'}
                                                            returnKeyType="next"
                                                            containerStyle={styles.inputContainer}
                                                        />
                                                        <ErrorMessage
                                                            text={'please-enter-region'}
                                                            visible={showRegionValidationMessage}
                                                        />
                                                    </>
                                                ) : null}
                                                {selectedProvider === 'EMERCHANTPAYFORM' || selectedProvider === 'PAYTIKO' || selectedProvider === 'ARENAMONEY' ? (
                                                    <>
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'city'}
                                                        </Text>
                                                        <TextInput
                                                            autoCapitalize="none"
                                                            autoCorrect={false}
                                                            value={city}
                                                            onChangeText={(text) => onChangeCity(text)}
                                                            keyboardType="default"
                                                            placeholderTextColor={'gray'}
                                                            returnKeyType="next"
                                                            containerStyle={styles.inputContainer}
                                                        />
                                                        <ErrorMessage
                                                            text={'please-enter-city'}
                                                            visible={showCityValidationMessage}
                                                        />
                                                    </>
                                                ) : null}
                                                <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                    {'postal-code'}
                                                </Text>
                                                <TextInput
                                                    autoCapitalize="none"
                                                    autoCorrect={false}
                                                    value={postalCode}
                                                    onChangeText={(text) => onChangePostalCode(text)}
                                                    keyboardType="default"
                                                    placeholderTextColor={'gray'}
                                                    returnKeyType="next"
                                                    containerStyle={styles.inputContainer}
                                                />
                                                <ErrorMessage
                                                    text={'please-enter-postal'}
                                                    visible={showPostalValidationMessage}
                                                />
                                                {selectedProvider === 'EMERCHANTPAYFORM' || selectedProvider === 'TRUSTPAYMENTS' || selectedProvider === 'PAYTIKO' || selectedProvider === 'ARENAMONEY' ? (
                                                    <>
                                                        <Text fontType="LATO_REGULAR" style={styles.labelText}>
                                                            {'phone'}
                                                        </Text>
                                                        <TextInput
                                                            autoCapitalize="none"
                                                            autoCorrect={false}
                                                            value={phone}
                                                            onChangeText={(text) => onChangePhone(text)}
                                                            keyboardType="default"
                                                            placeholderTextColor={'gray'}
                                                            returnKeyType="next"
                                                            containerStyle={styles.inputContainer}
                                                        />
                                                        <ErrorMessage
                                                            text={'please-enter-phone'}
                                                            visible={showPhoneValidationMessage}
                                                        />
                                                    </>
                                                ) : null}
                                            </View>
                                        ) : null}
                                    </View>
                                ) : null}
                                <View style={[styles.row, styles.checkboxRow]}>
                                    <View style={styles.checkboxContainer}>
                                        <Checkbox checked={confirm} setChecked={checkConfirm} />
                                    </View>
                                    <Text fontType="LATO_REGULAR" style={styles.custumerServiceText}>
                                        {'deposit-confirm'}
                                    </Text>
                                </View>
                                {(accountDetails.email === 'test@emerchantpay.bg') ?
                                    <>
                                        <View style={[styles.row, styles.checkboxRow]}>
                                            <View style={styles.checkboxContainer}>
                                                <Checkbox checked={confirmEmerchant} setChecked={setConfirmEmerchant} />
                                            </View>
                                            <Pressable
                                                onPress={() => Linking.openURL(
                                                    dealer === 'EVER'
                                                        ? `${config.LEGAL_DOCUMENTS_EVER_URL}` :
                                                        dealer === 'MAURITIUS' ? ` ${config.LEGAL_DOCUMENTS_MAURITIUS_URL}`
                                                            : `${config.LEGAL_DOCUMENTS_URL}`
                                                )}
                                                style={styles.privacyPolicyLinkContainer}
                                            >
                                                <Text fontType="LATO_REGULAR" style={styles.custumerServiceText}>
                                                    {'emerchant-policy-text'}
                                                </Text>
                                                </Pressable>
                                        </View>
                                        <Text fontType="LATO_REGULAR" style={[styles.custumerServiceText, styles.paytiko]}>
                                            {'paytiko-text1'}
                                        </Text>
                                        <Text fontType="LATO_REGULAR" style={[styles.custumerServiceText, styles.paytiko]}>
                                            {'paytiko-text2'}
                                        </Text>
                                        <Text fontType="LATO_REGULAR" style={[styles.custumerServiceText, styles.paytiko]}>
                                            {'paytiko-text3'}
                                        </Text>
                                        <Text fontType="LATO_REGULAR" style={[styles.custumerServiceText, styles.paytiko]}>
                                            {'emerchant-text5'}
                                        </Text></>
                                    : null}

                                <View style={styles.addFundsContainer}>
                                    <Button
                                        title="deposit-button-text"
                                        onPress={() => {
                                            payDeposit();
                                        }}
                                        disabled={!enableDepositButton()}
                                    />
                                </View>
                                <ErrorMessage
                                    text={paymentResponseErrorMessage}
                                    visible={paymentResponseErrorMessage !== ''}
                                />
                                <View style={styles.custumerServiceContainer}>
                                    <Text fontType="LATO_REGULAR" style={styles.custumerServiceText}>
                                        {'customer-service-part1'}
                                    </Text>
                                    <View style={styles.row}>
                                        <Text fontType="LATO_REGULAR" style={styles.custumerServiceText}>
                                            {'customer-service-part2'}
                                        </Text>
                                        <Pressable
                                            onPress={() => Linking.openURL(`${config.CUSTOMER_SERVICE_LINK}`)}
                                        >
                                            <Text fontType="LATO_REGULAR" style={styles.custumerServiceActiveLink}>
                                                {'customer-service'}
                                            </Text>
                                        </Pressable>
                                    </View>
                                </View>
                                <CopyRight />
                            </View>
                        </KeyboardAvoidingView>
                    </View>
                </View>
            </ScrollbarWrapper>
        </SafeAreaView>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        privacyPolicyLinkContainer: { width: '100%', paddingRight: scaleSize(25), paddingBottom: scaleSize(25) },
        paytiko: { textAlign: 'center' },
        textInputWidth: { width: '100%' },
        textInputText: {
            color: theme.desktopHeaderTextColor,
            fontFamily: FONT_FAMILY_BOLD,
            letterSpacing: 1,
            fontSize: FONT_SIZE_L,
        },
        safeArea: { flex: 1 },
        usePersonalDataTextContainer: { maxWidth: '85%' },
        scrollView: { maxHeight: '100%' },
        useAddressIcon: { color: theme.white },
        useAddressIconContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.tabIconSelected,
            width: scaleSize(15),
            height: scaleSize(15),
            borderRadius: scaleSize(12.5),
        },
        checkboxContainer: { paddingRight: scaleSize(10) },
        checkboxRow: { alignItems: 'center', paddingTop: scaleSize(20) },
        addFundsContainer: { paddingTop: scaleSize(20) },
        custumerServiceContainer: { paddingTop: scaleSize(20) },
        useAddressText: {
            fontSize: FONT_SIZE_XS,
            color: theme.text_positionsCount,
            textAlign: 'justify',
            paddingRight: scaleSize(10),
        },
        custumerServiceText: { fontSize: FONT_SIZE_XS, color: theme.text_positionsCount },
        custumerServiceActiveLink: { color: theme.switch_trackOn, fontSize: FONT_SIZE_XS },
        securityCodeInputContainer: { width: '80%' },
        row: { flexDirection: 'row' },
        inputContainer: { height: scaleSize(44) },
        securityIconCircleContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.white,
            width: scaleSize(15),
            height: scaleSize(15),
            borderRadius: scaleSize(12.5),
        },
        securityCodeRightContainer: {
            position: 'absolute',
            right: scaleSize(3),
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.tabIconSelected,
            width: '20%',
            height: scaleSize(44),
            borderTopRightRadius: scaleSize(5),
            borderBottomRightRadius: scaleSize(5),
        },
        questionIcon: { color: theme.tabIconSelected },
        currencyConvertorValueContainer: {
            width: '65%',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingLeft: scaleSize(20),
            paddingVertical: scaleSize(8),
        },
        currencyConvertorContainer: { flexDirection: 'row' },
        currencyContainer: { width: '35%' },
        pickerStyle: {
            width: '100%',
            height: scaleSize(44),
            fontSize: scaleSize(FONT_SIZE_S),
            backgroundColor: theme.backgroundSecondary,
            color: theme.desktopHeaderTextColor,
            borderRadius: scaleSize(5),
            padding: 0,
        },
        bdatePickerSeparator: { width: scaleSize(10) },
        bdateContainer: { flexDirection: 'row', justifyContent: 'space-between' },
        bdatePickerContainer: { width: '31%' },
        genderPickerContainer: { width: '100%' },
        pickerRow: {
            width: '100%',
            height: scaleSize(44),
            fontSize: scaleSize(FONT_SIZE_S),
            color: theme.text
        },
        headerContainer: {
            paddingHorizontal: scaleSize(17),
            paddingTop: scaleSize(20),
            height: scaleSize(40),
        },
        depositAmountFieldContainer: { width: '55%' },
        depositAmountMinMaxContainer: { width: '45%', justifyContent: 'space-between', paddingVertical: scaleSize(8) },
        depositAmountContainer: { flexDirection: 'row' },
        expDateField: { width: '33.33%' },
        expDateFieldPaddingLeft: { paddingLeft: scaleSize(10) },
        expDateFieldPaddingRight: { paddingRight: scaleSize(10) },
        expDateAndSCS: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
        minMaxText: {
            fontSize: FONT_SIZE_XS,
            color: theme.text_positionsCount,
            paddingLeft: scaleSize(10),
        },
        labelText: { fontSize: FONT_SIZE_S, color: theme.text_symbol, paddingVertical: scaleSize(10) },
        titleContainer: {
            backgroundColor: theme.tabIconSelected,
            width: scaleSize(375),
            height: scaleSize(30),
            justifyContent: 'center',
            alignItems: 'center',
        },
        addFundsTitleText: { fontSize: FONT_SIZE_S, color: theme.white },
        content: {
            width: '100%',
            paddingTop: scaleSize(20),
            height: '100%',
        },
        container: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingHorizontal: scaleSize(17),
            height: '100%',
            width: '100%',
        },
        viewContainer: { justifyContent: 'space-between', height: '80%', paddingBottom: scaleSize(10) },
    });
