import Constants from 'expo-constants';


export default {
    THEME: Constants.manifest?.extra?.EXPO_PUBLIC_THEME,
    WEBSITE_BASE_URL: Constants.manifest?.extra?.EXPO_PUBLIC_WEBSITE_BASE_URL,
    API_BASE_URL: Constants.manifest?.extra?.EXPO_PUBLIC_API_BASE_URL,
    STREAMING_API_BASE_URL: Constants.manifest?.extra?.EXPO_PUBLIC_STREAMING_API_BASE_URL,
    PLATFORM_BASE_URL: Constants.manifest?.extra?.EXPO_PUBLIC_PLATFORM_BASE_URL,
    WEBSITE: Constants.manifest?.extra?.EXPO_PUBLIC_WEBSITE,
    SOURCE_VERSION: Constants.manifest?.extra?.EXPO_PUBLIC_SOURCE_VERSION,
    API_VERSION: Constants.manifest?.extra?.EXPO_PUBLIC_API_VERSION,
    PRIVATE_POLICY_URL: Constants.manifest?.extra?.EXPO_PUBLIC_PRIVATE_POLICY_URL,
    PRIVATE_POLICY_EVER_URL: Constants.manifest?.extra?.EXPO_PUBLIC_PRIVATE_POLICY_EVER_URL,
    PRIVATE_POLICY_MAURITIUS_URL: Constants.manifest?.extra?.EXPO_PUBLIC_PRIVATE_POLICY_MAURITIUS_URL,
    CONTACT_US_URL: Constants.manifest?.extra?.EXPO_PUBLIC_CONTACT_US_URL,
    LEGAL_DOCUMENTS_URL: Constants.manifest?.extra?.EXPO_PUBLIC_LEGAL_DOCUMENTS_URL,
    LEGAL_DOCUMENTS_EVER_URL: Constants.manifest?.extra?.EXPO_PUBLIC_LEGAL_DOCUMENTS_EVER_URL,
    LEGAL_DOCUMENTS_MAURITIUS_URL: Constants.manifest?.extra?.EXPO_PUBLIC_LEGAL_DOCUMENTS_MAURITIUS_URL,
    BRAND_NAME: Constants.manifest?.extra?.EXPO_PUBLIC_BRAND_NAME,
    PLATFORM_NAME: Constants.manifest?.extra?.EXPO_PUBLIC_PLATFORM_NAME,
    BANK_WIRE_EMAIL: Constants.manifest?.extra?.EXPO_PUBLIC_BANK_WIRE_EMAIL,
    BANK_WIRE_EMAIL_EVER: Constants.manifest?.extra?.EXPO_PUBLIC_BANK_WIRE_EMAIL_EVER,
    PHONE: Constants.manifest?.extra?.EXPO_PUBLIC_PHONE,
    PHONE_EVER: Constants.manifest?.extra?.EXPO_PUBLIC_PHONE_EVER,
    ID_CHECK_LINK: Constants.manifest?.extra?.EXPO_PUBLIC_ID_CHECK_LINK,
    CUSTOMER_SERVICE_LINK: Constants.manifest?.extra?.EXPO_PUBLIC_CUSTOMER_SERVICE_LINK,
    USE_ADDRESS_LINK: Constants.manifest?.extra?.EXPO_PUBLIC_USE_ADDRESS_LINK,
    CREDIT_CARDS_LINK: Constants.manifest?.extra?.EXPO_PUBLIC_CREDIT_CARDS_LINK,
    CVV_LINK: Constants.manifest?.extra?.EXPO_PUBLIC_CVV_LINK,
    CARD_PAYMENT_SUCCESS_URL: Constants.manifest?.extra?.EXPO_PUBLIC_CARD_PAYMENT_SUCCESS_URL,
    CARD_PAYMENT_ERROR_URL: Constants.manifest?.extra?.EXPO_PUBLIC_CARD_PAYMENT_ERROR_URL,
    COPY_TRADING_AGREEMENT: Constants.manifest?.extra?.EXPO_PUBLIC_COPY_TRADING_AGREEMENT,
    TRUST_PAYMENTS_LIVE_STATUS: Constants.manifest?.extra?.EXPO_PUBLIC_TRUST_PAYMENTS_LIVE_STATUS,
    EXTERNAL_SOURCES_URL: Constants.manifest?.extra?.EXPO_PUBLIC_EXTERNAL_SOURCES_URL,
    ENABLE_DEALER_EVER: Constants.manifest?.extra?.EXPO_PUBLIC_ENABLE_DEALER_EVER,
    ENABLE_DEALER_MERIDIAN: Constants.manifest?.extra?.EXPO_PUBLIC_ENABLE_DEALER_MERIDIAN,
    ENABLE_DEALER_MERIDIAN2: Constants.manifest?.extra?.EXPO_PUBLIC_ENABLE_DEALER_MERIDIAN2,
    ENABLE_DEALER_MAURITIUS: Constants.manifest?.extra?.EXPO_PUBLIC_ENABLE_DEALER_MAURITIUS,
    DEFAULT_DEALER: Constants.manifest?.extra?.EXPO_PUBLIC_DEFAULT_DEALER,
    HOME_LOGO_SIZE_IN_PERCENT: Constants.manifest?.extra?.EXPO_PUBLIC_HOME_LOGO_SIZE_IN_PERCENT,
    AFFILIATE_NEW_COOKIE_NAME: Constants.manifest?.extra?.EXPO_PUBLIC_AFFILIATE_NEW_COOKIE_NAME,
    PROJECT_ID: Constants.manifest?.extra?.EXPO_PUBLIC_PROJECT_ID,
    PAYTIKO_URL: Constants.manifest?.extra?.EXPO_PUBLIC_PAYTIKO_URL,
};
