import React from 'react';
import ResetPasswordForm from '../features/Authentication/ResetPasswordForm';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

export default function ResetPasswordScreen() {
    return (
        <ScreenWrapper>
            <ResetPasswordForm />
        </ScreenWrapper>
    );
}
