import React from 'react';

import ScreenWrapper from '../features/Theme/ScreenWrapper';
import UploadDocuments from '../features/UploadDocuments';

type Props = {
    setActiveRightTab?: Function;
};

export default function UploadDocumentsScreen({ setActiveRightTab }: Props) {
    return (
        <ScreenWrapper>
            <UploadDocuments setActiveRightTab={setActiveRightTab} />
        </ScreenWrapper>
    );
}
