import React from 'react';
import { Image, StyleSheet, View, Pressable } from 'react-native';
import { useStyles, useAppDispatch } from '~/hooks';
import { Text } from '~/components/core';
import { Theme } from '~/theme';
import { saveAppSettings } from '~/state/reducers/appSettingsSlice';

type Props = {
    setOpenLanguageMenu: Function;
};

export default function LanguagePicker({ setOpenLanguageMenu }: Props) {
    const dispatch = useAppDispatch();
    const styles = useStyles(stylesGenerator);
    return (
        <View style={styles.languegePickerContainer}>
            <Pressable style={styles.flagContainer} onPress={() => {dispatch(saveAppSettings({language: 'en',}));setOpenLanguageMenu(false);}}>
                <Image
                    source={require('../../../../assets/images/flags/en.png')}
                    style={styles.languageFlag}
                />
                <Text>language-en</Text>
            </Pressable>
            <Pressable style={styles.flagContainer} onPress={() => {dispatch(saveAppSettings({language: 'de',}));setOpenLanguageMenu(false);}}>
                <Image
                    source={require('../../../../assets/images/flags/de.png')}
                    style={styles.languageFlag}
                />
                <Text>language-de</Text>
            </Pressable>
            <Pressable style={styles.flagContainer} onPress={() => {dispatch(saveAppSettings({language: 'es',}));setOpenLanguageMenu(false);}}>
                <Image
                    source={require('../../../../assets/images/flags/es.png')}
                    style={styles.languageFlag}
                />
                <Text>language-es</Text>
            </Pressable>
            <Pressable style={styles.flagContainer} onPress={() => {dispatch(saveAppSettings({language: 'ru',}));setOpenLanguageMenu(false);}}>
                <Image
                    source={require('../../../../assets/images/flags/ru.png')}
                    style={styles.languageFlag}
                />
                <Text>language-ru</Text>
            </Pressable>
            <Pressable style={styles.flagContainer} onPress={() => {dispatch(saveAppSettings({language: 'fr',}));setOpenLanguageMenu(false);}}>
                <Image
                    source={require('../../../../assets/images/flags/fr.png')}
                    style={styles.languageFlag}
                />
                <Text>language-fr</Text>
            </Pressable>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        flagContainer: {flexDirection: 'row', paddingVertical: 5},
        languegePickerContainer: {position: 'absolute', right: 91, top: 51, zIndex: 100, backgroundColor: theme.background, padding: 10, borderRadius: 6},
        languageFlag: { width: 30, height: 20, marginRight: 10 },
        
    });
