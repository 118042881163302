import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { Text } from '~/components/core';
import { useStyles, useAppSelector } from '~/hooks';
import { dealerSelector, pendingMessagesCountSelector, ptSelector, watchlistDataSelector } from '~/state/selectors';
import i18n from '~/i18n';
import { Theme } from '~/theme';
import { FONT_SIZE_M, FONT_SIZE_XS } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { WatchlistItem } from '~/api-services/LoadService/types';

type Props = {
    activeWatchList: number;
    activeRightTab: string;
    setActiveRightTab: Function;
    setActiveWatchlist: Function;
    setOpenEditFavorites: Function;
};

export default function DesktopHeaderNavigation({
    activeRightTab,
    setActiveRightTab,
    activeWatchList,
    setActiveWatchlist,
    setOpenEditFavorites,
}: Props) {
    const styles = useStyles(stylesGenerator);
    const watchlist = useAppSelector(watchlistDataSelector);
    const pendingMessagesCount = useAppSelector(pendingMessagesCountSelector);
    const dealer = useAppSelector(dealerSelector);
    const pt = useAppSelector(ptSelector);


    const rightTabsArray = [
        'open-trades',
        'result',
        'cash',
        'all-orders',
        'position-commission',
        'interest-swap',
        'notifications-label',
        'bottom-tab-news',
    ];

    return (
        <View style={styles.container}>
            <View style={styles.contentContainer}>
                <View style={styles.leftContainer}>
                    {watchlist.slice(0, 1).map((item: WatchlistItem, index: number) => (
                        <Pressable
                            onPress={() => {
                                setActiveWatchlist(item.id);
                                setOpenEditFavorites(false);
                            }}
                            style={styles.watchListTabContainer}
                            key={index}
                        >
                            <Text
                                upperCase={true}
                                fontType={item.id === activeWatchList ? 'LATO_BOLD' : 'LATO_REGULAR'}
                                style={[
                                    item.id === activeWatchList
                                        ? styles.watchListActiveTabstyle
                                        : styles.watchListInactiveTabstyle,
                                    styles.watchListTabstyle,
                                ]}
                            >
                                {item.custom && item.name ? item.name : i18n.t(item.code || '')}
                            </Text>
                        </Pressable>
                    ))}
                    {dealer !== 'EVER' && pt !== true ?
                        <Pressable
                            onPress={() => {
                                setActiveWatchlist(-1);
                                setOpenEditFavorites(false);
                            }}
                            style={styles.watchListTabContainer}
                            key={'CopyTradingTab'}
                        >
                            <Text
                                upperCase={true}
                                fontType={-1 === activeWatchList ? 'LATO_BOLD' : 'LATO_REGULAR'}
                                style={[
                                    -1 === activeWatchList
                                        ? styles.watchListActiveTabstyle
                                        : styles.watchListInactiveTabstyle,
                                    styles.watchListTabstyle,
                                ]}
                            >
                                {i18n.t('CopyTrading')}
                            </Text>
                        </Pressable> : null}
                    {watchlist.slice(1, watchlist.length).map((item: WatchlistItem, index: number) => (
                        <Pressable
                            onPress={() => {
                                setActiveWatchlist(item.id);
                                setOpenEditFavorites(false);
                            }}
                            style={styles.watchListTabContainer}
                            key={index}
                        >
                            <Text
                                upperCase={true}
                                fontType={item.id === activeWatchList ? 'LATO_BOLD' : 'LATO_REGULAR'}
                                style={[
                                    item.id === activeWatchList
                                        ? styles.watchListActiveTabstyle
                                        : styles.watchListInactiveTabstyle,
                                    styles.watchListTabstyle,
                                ]}
                            >
                                {item.custom && item.name ? item.name : i18n.t(item.code || '')}
                            </Text>
                        </Pressable>
                    ))}
                </View>
                <View style={styles.rightContainer}>
                    {rightTabsArray.map((item, index: number) => (
                        <Pressable
                            onPress={() => setActiveRightTab(item)}
                            style={styles.watchListTabContainer}
                            key={index}
                        >
                            <Text
                                upperCase={true}
                                fontType={item === activeRightTab ? 'LATO_BOLD' : 'LATO_REGULAR'}
                                style={[
                                    item === activeRightTab
                                        ? styles.watchListActiveTabstyle
                                        : styles.watchListInactiveTabstyle,
                                    styles.watchListTabstyle,
                                ]}
                            >
                                {item}
                            </Text>
                            {item === 'notifications-label' && pendingMessagesCount ? (
                                <View style={styles.badgeContainer}>
                                    <Text fontType="LATO_REGULAR" style={styles.badgeText}>
                                        {pendingMessagesCount > 9 ? '9+' : pendingMessagesCount.toString()}
                                    </Text>
                                </View>
                            ) : null}
                        </Pressable>
                    ))}
                </View>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        badgeText: { fontSize: scaleSize(FONT_SIZE_XS), color: theme.white },
        badgeContainer: {
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.tabIconSelected,
            top: scaleSize(-4),
            right: scaleSize(-6),
            width: scaleSize(16),
            height: scaleSize(16),
            borderRadius: scaleSize(8),
        },
        watchListTabContainer: {},
        watchListActiveTabstyle: { color: theme.greenLineDesktop },
        watchListInactiveTabstyle: { color: theme.equityValue },
        watchListTabstyle: { marginHorizontal: 10, fontSize: FONT_SIZE_M },
        contentContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.backgroundSecondary,
        },
        rightContainer: {
            flexDirection: 'row',
            height: 34,
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        leftContainer: {
            flexDirection: 'row',
            height: 34,
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        container: {
            width: '100%',
            height: 35,
        },
    });
