import { useAppSelector } from './useAppSelector';
import { appSettingsSelector, themeSelector } from '~/state/selectors';
import { themes } from '~/theme';

export const useTheme = () => {
    const { colorScheme } = useAppSelector(appSettingsSelector);
    const selectedTheme = useAppSelector(themeSelector);

    return themes[selectedTheme][colorScheme];
};
