import React from 'react';
import Investments from '../features/CopyTrading/Investments';

import ScreenWrapper from '../features/Theme/ScreenWrapper';

type Props = {
    selectedId?: string;
    setActiveWatchlist?: Function;
};

export default function InvestmentsScreen({ selectedId, setActiveWatchlist }: Props) {
    return (
        <ScreenWrapper>
            <Investments selectedId={selectedId} setActiveWatchlist={setActiveWatchlist} />
        </ScreenWrapper>
    );
}
