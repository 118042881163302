import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import PaymentConverterService from '~/api-services/PaymentConverterService';
import { ConfigData } from '~/api-services/PaymentConverterService/types';
import config from '~/config';

export const useAddFunds = (amount: number) => {
    const [configData, setConfigData] = useState<ConfigData>();

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        const { status, data } = await PaymentConverterService.getConfig({
            all: false,
            source: Platform.OS === 'web' ? 'WEB' : 'MOBILE',
            website: config.WEBSITE,
            amount: amount
        });
        if (status === 200 && data.status) {
            setConfigData(data.returnData);
        }
    }

    return {
        configData,
    };
};
