import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '~/components/core';
import { FONT_SIZE_XL } from '~/constants';
import { isDesktop, scaleSize } from '~/constants/scale';
import { useStyles } from '~/hooks';
import { Theme } from '~/theme';

type Props = {
    side: string;
    name: string;
};

export default function BuySellHeader({ side, name }: Props) {
    const styles = useStyles(stylesGenerator);
    return (
        <View style={styles.title}>
            <View style={[styles.sideBox, side === 'buy' ? styles.sideBoxBuyColor : styles.sideBoxSellColor]}>
                <Text fontType="LATO_BOLD" style={styles.side}>
                    {side.toUpperCase()}
                </Text>
            </View>
            <Text fontType="LATO_BOLD" style={styles.symbol}>
                {name}
            </Text>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        title: {
            justifyContent: 'center',
            alignItems: 'center',
            width: isDesktop() ? '98.13%' : '100%',
            flexDirection: 'row',
            backgroundColor: theme.backgroundSecondary,
        },
        sideBox: {
            width: scaleSize(48),
            height: scaleSize(20),
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: scaleSize(3),
            margin: scaleSize(10),
        },
        sideBoxBuyColor: { backgroundColor: theme.text_upPrice },
        sideBoxSellColor: { backgroundColor: theme.text_downPrice },
        side: { fontSize: FONT_SIZE_XL, letterSpacing: 0, color: theme.white },
        symbol: { fontSize: FONT_SIZE_XL, color: theme.text_symbol, letterSpacing: 0 },
    });
