import { useEffect, useState } from 'react';
import { ChartDataService } from '~/api-services';
import { PriceChanges24h } from '~/api-services/ChartDataService/types';
import { useIsScreenActive } from '.';

export const useLiveStatistic = (symbol: string, averagePrice: number) => {
    const [liveStat5M, setLiveStat5M] = useState<number>();
    const [liveStat1H, setLiveStat1H] = useState<number>();
    const [liveStat24H, setLiveStat24H] = useState<number>();
    const [serviceData, setServiceData] = useState<PriceChanges24h>();
    const isScreenActive = useIsScreenActive();
    const [startLoadingPriceChange, setStartLoadingPriceChange] = useState(false);

    useEffect(() => {
        if (serviceData) {
            if (serviceData.m5) {
                setLiveStat5M(((serviceData.m5 - averagePrice) / averagePrice) * 100);
            }
            if (serviceData.h1) {
                setLiveStat1H(((serviceData.h1 - averagePrice) / averagePrice) * 100);
            }
            if (serviceData.h24) {
                setLiveStat24H(((serviceData.h24 - averagePrice) / averagePrice) * 100);
            }
        }
        if (
            isScreenActive &&
            (!startLoadingPriceChange || (serviceData && serviceData.nextChange < new Date().getTime()))
        ) {
            setStartLoadingPriceChange(true);
            (async () => {
                const { status, data } = await ChartDataService.getPriceChangesList24h({ symbols: [symbol] });
                if (status === 200 && data.status) {
                    setServiceData(data.returnData[symbol]);
                }
            })();
        }
    }, [averagePrice, isScreenActive, serviceData, startLoadingPriceChange, symbol]);

    return {
        liveStat5M,
        liveStat1H,
        liveStat24H,
    };
};
