import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { CoreDataService } from '~/api-services';
import { thunkGenericApiReducerBuilder } from './utils';
import type { ThunkAPI } from '../store';
import { symbolName } from '~/api-services/CoreDataService/types';

export interface AllSymbolNamesState extends ResponseMetadata {
    data: symbolName[];
}

const initialState: AllSymbolNamesState = {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    data: [],
};

export const loadAllSymbolNames = createAsyncThunk<symbolName[], void, ThunkAPI>(
    'symbols/loadAllSymbolNames',
    async (_, thunkAPI) => {
        try {
            const { status, data } = await CoreDataService.getAllSymbolNames();
            if (status !== 200 || !data.returnData) {
                throw new Error(data.errorCode || 'Unauthorized');
            }
            return data.returnData;
        } catch (e) {
            return thunkAPI.rejectWithValue('Unauthorized');
        }
    }
);

export const allSymbolNamesSlice = createSlice({
    name: 'allSymbolNames',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        thunkGenericApiReducerBuilder(
            builder,
            loadAllSymbolNames,
            (state: AllSymbolNamesState, payload: symbolName[]) => {
                if (state.data) {
                    state.data = payload;
                }
            }
        );
    },
});

export const { clearState } = allSymbolNamesSlice.actions;

export default allSymbolNamesSlice.reducer;
