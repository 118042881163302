import { useEffect, useState, useRef } from 'react';
import { CoreDataService } from '~/api-services';
import CopyTradingService from '~/api-services/CopyTradingService';
import { getData } from '~/api-services/CopyTradingService/types';
import { InvestorOpenedPosition } from '~/api-services/CoreDataService/types';
import ReportsService from '~/api-services/ReportsService';
import { InvestorResult } from '~/api-services/ReportsService/types';
import i18n from '~/i18n';
import { useAppSelector } from './useAppSelector';
import { appSettingsSelector, userIsLoggedInSelector } from '~/state/selectors';
import { useIsAppActive } from './useIsAppActive';

export const useInvestments = (investorUid: string) => {
    const [getResponse, setGetResponse] = useState<getData>(null);
    const [investorResultResponse, setInvestorResultResponse] = useState<InvestorResult[]>(null);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [confirmationProps, setConfirmationProps] = useState<{ text: string; error: boolean }>({
        text: '',
        error: false,
    });
    const [investorOpenedPositions, setInvestorOpenedPositions] = useState<InvestorOpenedPosition[]>(null);
    const [loading, setloading] = useState(true);
    const { language } = useAppSelector(appSettingsSelector);
    const reloadDataPollRef = useRef<NodeJS.Timer>();
    const isAppActive = useIsAppActive();
    const isLoggedIn = useAppSelector(userIsLoggedInSelector);

    useEffect(() => {
        if (isAppActive && isLoggedIn) {
            reloadDataPollRef.current = setInterval(() => {
                getInvestorResults();
                getInvestorOpenedPositions();
            }, 60000); // refetch 60 seconds
            getInvestorResults();
            getInvestorOpenedPositions();
        } else {
            reloadDataPollRef.current && clearInterval(reloadDataPollRef.current);
        }
    }, [isLoggedIn, isAppActive]);

    async function get() {
        setloading(true);
        const { status, data } = await CopyTradingService.get(investorUid);
        if (status === 200 && data.status) {
            setGetResponse(data.returnData);
        }
        setloading(false);
    }

    async function getInvestorResults() {
        const { status, data } = await ReportsService.getInvestorResults(investorUid, language.toUpperCase());
        if (status === 200 && data.status) {
            setInvestorResultResponse(data.returnData);
        }
    }

    async function getInvestorOpenedPositions() {
        const { status, data } = await CoreDataService.getInvestorOpenedPositions(investorUid);
        if (status === 200 && data.status) {
            setInvestorOpenedPositions(data.returnData);
        }
    }

    async function addFollower(investorUid: string, investorName: string): Promise<string> {
        setloading(true);
        const { status, data } = await CopyTradingService.addFollower(investorUid);
        if (status === 200 && data.status) {
            get();
            setloading(false);
            return '';
        }
        if (status === 200 && !data.status) {
            if (data.errorCode === 'CopyTradingNotEnoughMoney') {
                var messageContext: any = {};
                messageContext.investorName = investorName;
                var message = i18n.t('copy-trading-not-enough-money', messageContext);
                setConfirmationProps({ text: message, error: true });
                setConfirmationVisible(true);
            } else if (data.errorCode === 'CopyTradingMaxFollowing') {
                var messageContext: any = {};
                messageContext.maxFollow = data.errorContext.maxFollow;
                var message = i18n.t('max-follow', messageContext);
                setConfirmationProps({ text: message, error: true });
                setConfirmationVisible(true);
            } else {
                if (data.errorCode !== 'CopyTradingNotConfirmed') {
                    setConfirmationProps({ text: 'error-' + data.errorCode, error: true });
                    setConfirmationVisible(true);
                }
            }
            setloading(false);
            return data.errorCode;
        }
    }

    async function removeFollower(investorUid: string) {
        setloading(true);
        const { status, data } = await CopyTradingService.removeFollower(investorUid);
        if (status === 200 && data.status) {
            get();
        }
        if (status === 200 && !data.status) {
            setConfirmationProps({ text: 'error-' + data.errorCode, error: true });
            setConfirmationVisible(true);
        }
        setloading(false);
    }

    useEffect(() => {
        setloading(true);
        get();
        setloading(false);
    }, []);

    return {
        getResponse,
        investorResultResponse,
        addFollower,
        removeFollower,
        confirmationVisible,
        setConfirmationVisible,
        confirmationProps,
        setConfirmationProps,
        investorOpenedPositions,
        loading,
    };
};
