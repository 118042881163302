import { useAppDispatch } from './useAppDispatch';
import { logoutUser } from '~/state/reducers/userAuthSlice';

export const useLogout = () => {
    const dispatch = useAppDispatch();

    return () => {
        dispatch(logoutUser());
    };
};
