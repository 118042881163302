import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '~/components/core';
import { useAppSelector, useStyles } from '~/hooks';
import { appSettingsSelector } from '~/state/selectors';
import { FONT_SIZE_XS, FONT_SIZE_S } from '~/constants';
import { scaleSize } from '~/constants/scale';
import { Theme } from '~/theme';
import { InvestorOpenedPosition } from '~/api-services/CoreDataService/types';

type Props = {
    position: InvestorOpenedPosition;
};

export default function PositionGroup({ position }: Props) {
    const styles = useStyles(stylesGenerator);
    const { colorScheme } = useAppSelector(appSettingsSelector);
    return (
        <View
            style={[
                styles.positionGroupContainer,
                colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
            ]}
            key={'instrumentContainer' + position.symbolNameTranslated}
        >
            <View
                style={[
                    styles.positionItemSide,
                    colorScheme === 'dark' ? styles.borderColorDark : styles.borderColorWhite,
                ]}
            >
                <View style={[styles.rotateTextWrapper, styles.buySell]}>
                    <Text
                        fontType="LATO_BLACK"
                        style={position.sideTranslated === 'Buy' ? styles.buyText : styles.sellText}
                    >
                        {position.sideTranslated.toUpperCase()}
                    </Text>
                </View>
            </View>
            <View style={styles.instrumentName}>
                <View>
                    <Text fontType="LATO_BOLD" style={styles.instrumentNameText}>
                        {position.symbolNameTranslated}
                    </Text>
                </View>
            </View>
            <View style={styles.positionGroupPrice}>
                <View style={styles.openClosePriceContainer}>
                    <Text style={[styles.positionItemPriceText]}>{position.openPriceFormatted}</Text>
                </View>
            </View>
            <View style={styles.positionGroupResult}>
                <Text
                    style={[
                        styles.positionItemPriceText,
                        position.resultFormatted.indexOf('-') >= 0 ? styles.negativeResult : styles.positiveResult,
                    ]}
                >
                    {position.resultFormatted.replace('-', '')}
                </Text>
            </View>
        </View>
    );
}

const stylesGenerator = (theme: Theme) =>
    StyleSheet.create({
        instrumentName: { flexWrap: 'wrap', width: '35%', justifyContent: 'center', paddingLeft: scaleSize(10) },
        sellText: {
            fontSize: FONT_SIZE_S,
            color: theme.text_downPrice,
        },
        buySell: { width: scaleSize(50), alignItems: 'center' },
        instrumentNameText: { fontSize: FONT_SIZE_S, letterSpacing: 0, color: theme.text_symbol },
        buyText: {
            fontSize: FONT_SIZE_S,
            color: theme.text_upPrice,
        },
        rotateTextWrapper: { transform: [{ rotate: '-90deg' }] },
        openClosePriceContainer: { flexDirection: 'row', paddingVertical: scaleSize(5) },
        positionGroupPrice: { width: '35%', justifyContent: 'center', alignItems: 'center' },
        timeContainer: { paddingBottom: scaleSize(10) },
        positiveResult: { color: theme.text_upPrice },
        negativeResult: { color: theme.text_downPrice },
        positionItemPriceText: { fontSize: FONT_SIZE_XS, color: theme.text_pendingOrder },
        positionItemSide: {
            width: '5%',
            borderRightWidth: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        positionGroupResult: {
            justifyContent: 'center',
            alignItems: 'flex-end',
            width: '25%',
            paddingRight: scaleSize(10),
        },
        positionGroupContainer: {
            flexDirection: 'row',
            borderWidth: 1,
            width: '100%',
            borderRadius: scaleSize(5),
            marginTop: scaleSize(7),
            height: scaleSize(50),
            backgroundColor: theme.backgroundSecondary,
        },
        borderColorDark: { borderColor: theme.background },
        borderColorWhite: { borderColor: theme.border_2 },
    });
